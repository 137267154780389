













import Vue from "vue";

export default Vue.extend({
  name: "LoadingModal",
  props: {
    item: {
      type: String,
    },
  },

  data: () => ({}),
  methods: {},
  computed: {
    maxSize(): number {
      return 15;
    },
  },
});
