import { InputType, listBoolParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface ElectricParkingBrakeOptions {
  electricParkingBrakeEnabled: listBoolParameter;
}

export const defaultElectricParkingBrakeOptions: ElectricParkingBrakeOptions = {
  electricParkingBrakeEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Electric Parking Brake",
    info: "Electric Parking Brake",
  },
};
