import { Module } from "vuex";
import { RootState } from "./index";

interface MessageStoreState {
  errorMessage: string | null;
}

export const MessageStore: Module<MessageStoreState, RootState> = {
  state: () => ({
    errorMessage: null as string | null,
  }),
  getters: {
    errorMessage: (state) => state.errorMessage,
  },
  actions: {
    setErrorMessage({ commit }, message: string) {
      commit("SET_ERROR_MESSAGE", message);
    },
  },
  mutations: {
    SET_ERROR_MESSAGE(state, message: string) {
      state.errorMessage = message;
      setTimeout(() => {
        state.errorMessage = null;
      }, 5000);
    },
  },
};
