import { InputType, listBoolParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface SecondaryTempSensorOptions {
  secondaryTempSensorEnabled: listBoolParameter;
}

export const defaultSecondaryTempSensorOptions: SecondaryTempSensorOptions = {
  secondaryTempSensorEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Secondary Temp Sensor Option",
    info:
      "This option is used to maintain the climate in a second location. The secondary temp sensor uses the same set point on the operator screen as the main temp sensor. An auxiliary heater or air conditioning unit must be installed in the location which needs to be maintained and the signal wire for this unit must be connected to the EPEQ® IM controller. NOTE: For this option to work, a secondary temp sensor must be installed on the vehicle.",
  },
};
