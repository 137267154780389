






































































import Vue from "vue";

import Chart from "@/components/Chart.vue";

export default Vue.extend({
  components: { Chart },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    segmentDescriptions: {
      type: Array,
      required: true,
    },
    segmentLabels: {
      type: Array,
      required: true,
    },
    additionalInfo: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isInfoModalOpen: false,
  }),
  methods: {
    /**
     * Close the Info Dialog
     */
    close() {
      this.isInfoModalOpen = false;
    },
    /**
     * Open the Info Dialog
     */
    info() {
      this.isInfoModalOpen = true;
    },
    /**
     * Print the entire component. If you want something to be ignored during print, add the class "no-print" to the element
     */
    print() {
      // print content element
      var content = document.createElement("div");

      var canvas = document.getElementById(this.chartData.id) as HTMLCanvasElement;

      // add basic stylesheet
      var style = document.createElement("style");
      style.setAttribute("type", "text/css");
      style.innerHTML = `
        body {
          font-family: "Roboto", sans-serif;
          line-height: 1.5;
        }
      `;
      content.appendChild(style);

      // add title
      var title = document.createElement("div");
      title.style.textAlign = "center";
      title.style.fontSize = "125%";
      title.innerText = this.title;
      content.appendChild(title);

      // add description
      var description = document.createElement("div");
      description.style.textAlign = "center";
      description.innerText = this.description;
      content.appendChild(description);

      // add canvas image
      var img = document.createElement("img");
      img.setAttribute("src", canvas.toDataURL());
      content.appendChild(img);

      // add help items
      var helpItems = document.createElement("dl");
      for (var i = 0; i < this.segmentDescriptions.length; i++) {
        if (i >= 0 && i < this.segmentLabels.length) {
          var helpLabel = document.createElement("dt");
          helpLabel.innerText = this.segmentLabels[i] as string;
          helpItems.appendChild(helpLabel);
        }
        var helpDescription = document.createElement("dd");
        helpDescription.innerText = this.segmentDescriptions[i] as string;
        helpItems.appendChild(helpDescription);
      }
      content.appendChild(helpItems);

      // create window
      var h = window.outerHeight;
      var w = window.outerWidth;
      var left = window.screenLeft;
      var top = window.screenTop;
      var WinPrint = window.open("", "", `left=${left},top=${top},width=${w},height=${h},toolbar=0,scrollbars=0,status=0`);

      // add print content to window
      WinPrint?.document.write(content.innerHTML);

      WinPrint?.document.close();
      WinPrint?.focus();

      /**
       * Not sure why but have to have a timeout here otherwise the chart is not being display in the print window
       */
      setTimeout(() => {
        WinPrint?.print();
        WinPrint?.close();
      }, 1);
    },
  },
  computed: {
    chartId(): string {
      return "chart" + this.chartData.id;
    },
    infoModalTitle(): string {
      return this.title;
    },
  },
});
