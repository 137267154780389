import { InputType, inputParameter } from "../OptionParameter";

export interface Inputs {
  engineStart: inputParameter;
  engineStop: inputParameter;
  heater: inputParameter;
  solenoid: inputParameter;
  alarm: inputParameter;
  bunkHeater: inputParameter;
  bunkAC: inputParameter;
  parkBrake: inputParameter;
  neutralSwitch: inputParameter;
  inputHighIdle: inputParameter;
  shorePower: inputParameter;
  pto: inputParameter;
  auxAntiTheft: inputParameter;
  doorStart: inputParameter;
  compressor: inputParameter;
  rearFacility: inputParameter;
  safeMode: inputParameter;
}

export const defaultInputs: Inputs = {
  engineStart: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Engine Start`,
    info: "",
  },
  engineStop: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Engine Stop`,
    info: "",
  },
  heater: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Heater On`,
    info: "",
  },
  solenoid: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Solenoid On`,
    info: "",
  },
  alarm: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Alarm On`,
    info: "",
  },
  bunkHeater: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Bunk Heater`,
    info: "",
  },
  bunkAC: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Bunk A/C`,
    info: "",
  },
  parkBrake: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Park Brake`,
    info: "",
  },
  neutralSwitch: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Neutral Switch`,
    info: "",
  },
  inputHighIdle: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `High Idle`,
    info: "",
  },
  shorePower: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Shore Power`,
    info: "",
  },
  pto: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `PTO`,
    info: "",
  },
  auxAntiTheft: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Aux Anti-Theft`,
    info: "",
  },
  doorStart: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Door Start`,
    info: "",
  },
  compressor: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Compressor Fault`,
    info: "",
  },
  rearFacility: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Rear Facility`,
    info: "",
  },
  safeMode: {
    type: InputType.input,
    value: null,
    defaultValue: null,
    signalType: null,
    defaultSignalType: null,
    display: `Safe Mode`,
    info: "",
  },
};
