
































































































































import Vue from "vue";

import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";

import { Vehicle } from "@/model/API/Vehicle";

import VehicleProfileData from "@/components/VehicleProfileData.vue";

export default Vue.extend({
  name: "VehicleFilter",
  components: { VehicleProfileData },
  props: [
    "vehicles", // Contains all of the vehicles the user has access to
    "headers", // Header for the add filter data table
  ],
  data: () => ({
    newSelectedVehicles: [] as Vehicle[], // Filter the vehicles to only the ones selected
    addFilterDialog: false, // Whether the add filter dialog is open or closed
    searchVehicles: "", // Allows the user to search the vehicles in the add filter data table
  }),
  methods: {
    ...mapActions(["setSelectedVehicles"]),
    addFilter() {
      this.newSelectedVehicles = this.selectedVehicles;
      this.addFilterDialog = true;
    },
    cancelFilters() {
      this.addFilterDialog = false;
    },
    saveFilters() {
      this.addFilterDialog = false;
      this.setSelectedVehicles(this.newSelectedVehicles);
      this.$emit("save", this.newSelectedVehicles);
    },
    getFormattedDate(dateReleased: string) {
      if (dateReleased) {
        return dayjs(dateReleased).format("YYYY/MM/DD");
      }
      return "";
    },
  },
  computed: {
    ...mapGetters(["selectedVehicles"]),
  },
});
