import { Module } from "vuex";
import { RootState } from "./index";

import jwt_decode from "jwt-decode";

import { temperatureDisplay, distanceDisplay, fuelDisplay } from "@/helpers/UnitService";

import { User } from "oidc-client";
import { User as APIUser } from "@/model/API/User";
import { Country, Currency } from "@/model/Localization";
import { DistanceUnit, FuelUnit, TemperatureUnit } from "@/model/Units";

interface UserState {
  user: User | null;
  apiUser: APIUser | null;
  isClientAdmin: boolean;
  isResourceViewer: boolean;
}

export const UserStore: Module<UserState, RootState> = {
  state: () => ({
    user: null as User | null, // The user object returned from identity server for the current user
    apiUser: null as APIUser | null, // The user object returned from the API for the current user
    isClientAdmin: false,
    isResourceViewer: false,
  }),
  getters: {
    isResourceViewer: (state) => state.isResourceViewer,
    isUserAuthorized(state): boolean {
      if (state.user) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const token: any = jwt_decode(state.user.access_token);
        if (
          token.role == "gripidle.superuser" ||
          token.role == "gripidle.adminportalviewuser" ||
          token.role == "gripidle.gripportaluser" ||
          token.role == "gripidle.adminportaluser"
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    userId(state): string {
      if (state.user) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const token: any = jwt_decode(state.user.access_token);
        return token.sub;
      }
      return "";
    },
    apiUser(state): APIUser | null {
      return state.apiUser;
    },
    userCountry(state): Country {
      return state.apiUser?.country ?? Country.Canada;
    },
    userCurrency(state): Currency {
      return state.apiUser?.currency ?? Currency.CAD;
    },
    userTemperatureUnits(state): TemperatureUnit {
      return state.apiUser?.temperatureUnit ?? TemperatureUnit.Celsius;
    },
    userDistanceUnits(state): DistanceUnit {
      return state.apiUser?.distanceUnit ?? DistanceUnit.Kilometers;
    },
    userFuelUnits(state): FuelUnit {
      return state.apiUser?.fuelUnit ?? FuelUnit.Litres;
    },
    userTemperatureUnitsDisplay(state): string {
      return temperatureDisplay(state.apiUser?.temperatureUnit ?? TemperatureUnit.Celsius);
    },
    userDistanceUnitsDisplay(state): string {
      return distanceDisplay(state.apiUser?.distanceUnit ?? DistanceUnit.Kilometers);
    },
    userFuelUnitsDisplay(state): string {
      return fuelDisplay(state.apiUser?.fuelUnit ?? FuelUnit.Litres);
    },
    /** Whether the current user is a client admin. This will determine if they can create users under the client or not */
    isClientAdmin(state): boolean {
      return state.isClientAdmin;
    },
    isSuperAdmin(state): boolean {
      if (state.user) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const token: any = jwt_decode(state.user.access_token);
        if (token.role == "gripidle.superuser") {
          return true;
        }
        return false;
      }
      return false;
    },
    isGripAdmin(state): boolean {
      if (state.user) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const token: any = jwt_decode(state.user.access_token);
        if (token.role == "gripidle.superuser" || token.role == "gripidle.adminportaluser" || token.role == "gripidle.adminportalviewuser") {
          return true;
        }
        return false;
      }
      return false;
    },
    token(state): string | null {
      return state.user?.access_token ?? null;
    },
    user(state): User | null {
      return state.user ?? null;
    },
    isUserLoggedIn(state): boolean {
      return state.user != null;
    },
  },
  actions: {
    setAPIUser({ commit }, user: APIUser) {
      commit("SET_API_USER", user);
      if (user.userRole == 2) {
        commit("SET_CLIENT_ADMIN", true);
      } else if (user.userRole == 5 || user.userRole == 4) {
        commit("SET_RESOURCE_VIEWER", true);
      }
    },
    /**
     * Update the vuex store with the logged in user
     */
    setUser({ commit }, user: User) {
      commit("SET_USER", user);
    },
  },
  mutations: {
    SET_RESOURCE_VIEWER(state, status: boolean) {
      state.isResourceViewer = status;
    },
    /**
     * Sets the user
     */
    SET_USER(state, user: User) {
      state.user = user;
    },
    /**
     * Sets whether the current user is a grip portal admin (Meaning they can create users for the client)
     */
    SET_CLIENT_ADMIN(state, status: boolean) {
      state.isClientAdmin = status;
    },
    SET_API_USER(state, user: APIUser) {
      state.apiUser = user;
    },
  },
};
