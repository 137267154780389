var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(!_vm.isResourceViewer)?_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.exportCSV}},[_vm._v("Export CSV")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addVehicle}},[_vm._v("Add Vehicle")])],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Vehicles")])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.isLoadingVehicleError)?_c('v-data-table',{staticClass:"elevation-1 background",attrs:{"loading":_vm.isLoading,"search":_vm.search,"headers":_vm.headers,"items":_vm.vehicles,"items-per-page":5,"item-key":"vehicleId"},on:{"click:row":_vm.vehicleSelected},scopedSlots:_vm._u([{key:"item.idleTrackerIMEI",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.idleTrackerIMEI}})]}},{key:"item.lastDateReported",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.lastDateReported))+" ")]}},{key:"item.frequency",fn:function(ref){
var item = ref.item;
return [(item.frequency)?_c('span',[_vm._v(" "+_vm._s(item.frequency.charAt(0).toUpperCase() + item.frequency.slice(1))+" ")]):_vm._e()]}},{key:"item.assetName",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.assetName}})]}},{key:"item.make",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.make}})]}},{key:"item.model",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.model}})]}},{key:"item.series",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.series}})]}},{key:"item.bodyType",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.bodyType}})]}},{key:"item.gvwrClass",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.gvwrClass}})]}},{key:"item.vehicleType",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.vehicleType}})]}},{key:"item.vehicleClass",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.vehicleClass}})]}},{key:"item.fuelType",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.fuelType}})]}},{key:"item.fuelCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.fuelCost != null ? item.fuelCost.toFixed(2) : "")+" ")]}},{key:"item.engineType",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.engineType}})]}},{key:"item.engineManufacturer",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.engineManufacturer}})]}},{key:"item.brakeSystem",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.brakeSystem}})]}},{key:"item.driveLineType",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.driveLineType}})]}},{key:"item.controller",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.controller}})]}},{key:"item.screen",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.screen}})]}},{key:"item.other",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.other}})]}}],null,true)}):_c('span',[_vm._v(" Error loading vehicles. "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.loadVehicles}},[_vm._v(" Try Again ")])],1)],1)],1),(_vm.isLoadingVehicle)?_c('LoadingModal',{attrs:{"isOpen":_vm.isLoadingVehicle,"message":_vm.loadingVehicleMessage}}):_vm._e(),(_vm.isError)?_c('InfoModal',{attrs:{"isOpen":_vm.isError,"title":_vm.errorTitle,"message":_vm.errorMessage},on:{"close":function($event){_vm.isError = false}}}):_vm._e(),(_vm.isImportDialogOpen)?_c('ImportDialog',{attrs:{"isOpen":_vm.isImportDialogOpen},on:{"close":function($event){_vm.isImportDialogOpen = false},"vehiclesImported":_vm.vehiclesImported}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }