import { InputType, listBoolParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface HightIdleOptions {
  highIdleEnabled: listBoolParameter;
}

export const defaultHightIdleOptions: HightIdleOptions = {
  highIdleEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "High Idle",
    info:
      "This feature, if enabled, will run the engine at high idle if either air conditioning or battery charging is required. This is controlled by setting up the input and output activation in the configurable I/O (default disabled). NOTE: Not all vehicles have high idle features available, additional equipment may be required.",
  },
};
