














































import Vue from "vue";

// Components
import KitInfo from "@/components/AddVehicle/KitInfo.vue";

// Plugin Helpers
import instance from "@/axios";
import { mapActions, mapGetters } from "vuex";

// Models
import { Kit } from "@/model/API/Kit";
import { GeneralInfo } from "@/model/VehicleProfile/GeneralInfo";
import { Part } from "@/model/API/Part";
import { Equipment } from "@/model/VehicleProfile/Equipment";
import { defaultVehicleEquipment } from "@/model/VehicleProfile/VehicleEquipment";

export default Vue.extend({
  name: "GeneralInfo",
  components: { KitInfo },
  destroyed: function() {
    this.save();
  },
  created: function() {
    if (this.selectedKit) {
      this.panel = 0;
    } else {
      this.panel = 1;
      this.retrieveKits();
    }
  },
  data: () => ({
    kits: [] as Kit[],
    isLoading: false,
    panel: 0,
  }),
  methods: {
    ...mapActions(["setGeneralInfo", "updateVehicle", "setEquipmentOptions"]),
    save() {
      this.setGeneralInfo(this.form);
    },
    /**
     * Called everytime an input value is changed.
     * If it's an existing vehicle submit the updated settings.
     * If the vehicle already exists, don't submit the updates and wait for the user to submit the entire settings file
     */
    updated() {
      if (!this.isCreatingNewVehicle) {
        this.setGeneralInfo(this.form);
        this.updateVehicle();
      } else {
        this.setGeneralInfo(this.form);
      }
    },
    kitRemoved() {
      this.form.kit = null;

      this.setEquipmentOptions(defaultVehicleEquipment());

      this.updated();
      this.panel = 0;
    },
    /**
     * Update the selected kit when the user selects the kit
     */
    kitAdded(kit: Kit) {
      this.form.kit = kit;

      let kitOptions = kit.kitOptions;
      let ignitionBypass: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Ignition Bypass")?.parts ?? null;
      let alarm: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Alarm")?.parts ?? null;
      let antiTheft: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Option - Anti Theft")?.parts ?? null;
      let solenoid85: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Option - Solenoid" && e.option == "85A")?.parts ?? null;
      let solenoid200: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Option - Solenoid" && e.option == "200A")?.parts ?? null;
      let battery: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Option Battery")?.parts ?? null;
      let heatCoolantPump: Part[] | null =
        kitOptions.find((e) => e.heading.trim() == "Optional Equipment - Heat" && e.option == "Coolant Pump")?.parts ?? null;
      let heatCoolantHeater: Part[] | null =
        kitOptions.find((e) => e.heading.trim() == "Optional Equipment - Heat" && e.option == "Coolant Heater")?.parts ?? null;
      let heatAirHeater: Part[] | null =
        kitOptions.find((e) => e.heading.trim() == "Optional Equipment - Heat" && e.option == "Air Heater")?.parts ?? null;
      let airConditioning: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Optional Equipment - A/C")?.parts ?? null;
      let currentSensor: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Optional - Current Sensor")?.parts ?? null;
      let autoHVAC: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Optional Auto HVAC")?.parts ?? null;
      let humidity: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Optional Humidity")?.parts ?? null;
      let tempSensing: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Secondary Temp Sensing")?.parts ?? null;
      let rearFacilityControl: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Rear Facility Control")?.parts ?? null;

      let equipment: Equipment = {
        ignitionBypass: ignitionBypass,
        antiTheft: antiTheft,
        alarm: alarm,
        solenoid85: solenoid85,
        solenoid200: solenoid200,
        battery: battery,
        heatCoolantPump: heatCoolantPump,
        heatCoolantHeater: heatCoolantHeater,
        heatAirHeater: heatAirHeater,
        airConditioning: airConditioning,
        currentSensor: currentSensor,
        autoHVAC: autoHVAC,
        humidity: humidity,
        tempSensing: tempSensing,
        rearFacilityControl: rearFacilityControl,
        useMyOwnAlarm: false,
        useMyOwnBattery: false,
        useMyOwnHeating: false,
        useMyOwnSolenoid: false,
      };

      this.setEquipmentOptions(equipment);

      this.updated();
      this.panel = 0;
    },
    /**
     * Retrieve a list of kits available based on the make, model and year of the vehicle
     */
    async retrieveKits() {
      if (this.kits.length == 0) {
        if (!this.form.make && !this.form.model && !this.form.year) {
          return;
        }

        this.isLoading = true;

        let queryParams = "";

        if (this.form.make && this.form.make != "") {
          queryParams += `make=${this.form.make}&`;
        }

        if (this.form.model && this.form.model != "") {
          queryParams += `model=${this.form.model}&`;
        }

        if (this.form.year) {
          queryParams += `year=${this.form.year}&`;
        }

        if (this.form.series && this.form.series != "") {
          queryParams += `series=${this.form.series}&`;
        }

        instance
          .get<Kit[]>(`/Kit/Filter?${queryParams}`)
          .then((response) => {
            if (response.status == 200 && response.data) {
              this.kits = response.data;
            }
          })
          .finally(() => (this.isLoading = false));
      }
    },
  },
  computed: {
    ...mapGetters(["generalInfo", "isCreatingNewVehicle", "selectedKit"]),
    form(): GeneralInfo {
      return this.generalInfo;
    },
  },
});
