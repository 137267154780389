import { InputType, listBoolParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface HumidityOptions {
  humidityEnabled: listBoolParameter;
}

export const defaultHumidityOptions: HumidityOptions = {
  humidityEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Humidity Option",
    info:
      "This option is used to prevent fogging of the windshield. It accomplishes this by monitoring the humidity of the air at the windshield as well as the outside temperature, inside temperature and windshield temperature. When the vehicle is running for this option, the humidity icon will be displayed on the screen (default disabled). NOTE: For this option to work, a humidity sensor must be installed on the vehicle.",
  },
};
