import { Module } from "vuex";
import { RootState } from "./index";

import { Vehicle } from "@/model/API/Vehicle";

interface FilterState {
  selectedVehicles: Array<Vehicle>;
}

export const FilterStore: Module<FilterState, RootState> = {
  state: () => ({
    selectedVehicles: [] as Vehicle[],
  }),
  getters: {
    selectedVehicles(state): Array<Vehicle> {
      return state.selectedVehicles;
    },
  },
  actions: {
    setSelectedVehicles({ state, commit }, vehicles: Array<Vehicle>) {
      commit("SET_SELECTED_VEHICLES", vehicles);
    },
    // adds a vehicle to the selected list
    addSelectedVehicle({ state, commit }, vehicle: Vehicle) {
      // check if vehicle already exists in the array
      if (
        !state.selectedVehicles.some((e) => e.vehicleId == vehicle.vehicleId)
      ) {
        commit("ADD_SELECTED_VEHICLE", vehicle);
      } // else the vehicle is already in the selected list
    },
  },
  mutations: {
    SET_SELECTED_VEHICLES(state, vehicles: Array<Vehicle>) {
      state.selectedVehicles = vehicles;
    },
    ADD_SELECTED_VEHICLE(state, vehicle: Vehicle) {
      state.selectedVehicles.push(vehicle);
    },
  },
};
