import { KitOption } from "../API/KitOption";

export enum KitOptionTypes {
  ignitionBypass,
  antiTheft,
  solenoid85,
  solenoid200,
  battery,
  heatCoolantPump,
  heatCoolantHeater,
  heatAirHeater,
  airConditioning,
  currentSensor,
  autoHVAC,
  humidity,
  secondaryTemp,
  rearFacility,
}

export interface KitOptions {
  ignitionBypass?: KitOption;
  antiTheft?: KitOption;
  solenoid85?: KitOption;
  solenoid200?: KitOption;
  battery?: KitOption;
  heatCoolantPump?: KitOption;
  heatCoolantHeater?: KitOption;
  heatAirHeater?: KitOption;
  airConditioning?: KitOption;
  currentSensor?: KitOption;
  autoHVAC?: KitOption;
  humidity?: KitOption;
  secondaryTemp?: KitOption;
  rearFacility?: KitOption;
}

export interface VehicleKitOptions {
  possibleOptions: KitOptions;
  selectedOptions: KitOptions;
}

const defaultVehicleKitOptionsObject: VehicleKitOptions = {
  possibleOptions: {
    currentSensor: {
      kitOptionId: 45,
      heading: "Test Heading",
      option: "Optiona",
      parts: [],
    },
  },
  selectedOptions: {},
};

function defaultStandardOptionsCopy(): VehicleKitOptions {
  return JSON.parse(JSON.stringify(defaultVehicleKitOptionsObject));
}

export const defaultVehicleKitOptions = defaultStandardOptionsCopy;
