





























































































import Vue from "vue";
import instance from "@/axios";
import { AxiosRequestConfig } from "axios";

import { VehicleImport } from "@/model/API/ImportVehicles";
import LoadingModal from "@/components/LoadingModal.vue";

export default Vue.extend({
  name: "ImportDialog",
  components: {
    LoadingModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    file: null as any,
    response: null as VehicleImport | null,
    isWrongFileType: false,
    isSaving: false,
    loadingMessage: "",
    error: false,
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    fileUploaded() {
      this.error = false;
      this.loadingMessage = "Validating file";
      this.uploadFile(false)
        .then((response) => {
          if (response && response.status == 200) {
            this.response = response.data;
          } else {
            this.error = true;
          }
        })
        .catch(() => {
          this.error = true;
        });
    },
    confirmImport() {
      this.error = false;
      this.loadingMessage = "Uploading file";
      this.uploadFile(true)
        .then((response) => {
          if (response) {
            this.$emit("vehiclesImported");
          }
        })
        .catch(() => {
          this.error = true;
        });
    },
    async uploadFile(isConfirmed: boolean) {
      this.isWrongFileType = false;

      if (this.file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        this.isWrongFileType = true;
        return;
      }

      const content_ = new FormData();
      content_.append("formFile", this.file, this.file.name);

      let config: AxiosRequestConfig = {
        data: content_,
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "text/csv",
        },
      };

      this.isSaving = true;
      return instance.put<VehicleImport>(`/vehicle/Import?isConfirmed=${isConfirmed}`, content_, config).finally(() => {
        this.isSaving = false;
      });
    },
  },
  computed: {},
});
