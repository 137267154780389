import { InputType, outputParameter } from "../OptionParameter";

export interface Outputs {
  dcac: outputParameter;
  comboHeater: outputParameter;
  airHeater: outputParameter;
  highIdle: outputParameter;
  antiTheft: outputParameter;
  clutch: outputParameter;
  hvac: outputParameter;
  condensor: outputParameter;
  engineRunning: outputParameter;
}

export const defaultOutputs: Outputs = {
  dcac: {
    type: InputType.output,
    value: null,
    defaultValue: null,
    display: `DC-AC`,
    info: "",
  },
  comboHeater: {
    type: InputType.output,
    value: null,
    defaultValue: null,
    display: `Combo Heater`,
    info: "",
  },
  airHeater: {
    type: InputType.output,
    value: null,
    defaultValue: null,
    display: `Air Heater`,
    info: "",
  },
  highIdle: {
    type: InputType.output,
    value: null,
    defaultValue: null,
    display: `High Idle`,
    info: "",
  },
  antiTheft: {
    type: InputType.output,
    value: null,
    defaultValue: null,
    display: `Anti-Theft Relay (shifter lock)`,
    info: "",
  },
  clutch: {
    type: InputType.output,
    value: null,
    defaultValue: null,
    display: `Clutch`,
    info: "",
  },
  hvac: {
    type: InputType.output,
    value: null,
    defaultValue: null,
    display: `HVAC PWR auto HVAC`,
    info: "",
  },
  condensor: {
    type: InputType.output,
    value: null,
    defaultValue: null,
    display: `Condensor Fan`,
    info: "",
  },
  engineRunning: {
    type: InputType.output,
    value: null,
    defaultValue: null,
    display: `Engine Running`,
    info: "",
  },
};
