import { StartStopAlarmOptions, defaultStartStopAlarmOptions } from "@/model/VehicleProfile/OptionalEquipment/StartStopAlarm";
import { CurrentSensorOptions, defaultCurrentSensorOptions } from "@/model/VehicleProfile/OptionalEquipment/CurrentSensor";
import { HumidityOptions, defaultHumidityOptions } from "@/model/VehicleProfile/OptionalEquipment/Humidity";

import { SecondaryTempSensorOptions, defaultSecondaryTempSensorOptions } from "@/model/VehicleProfile/OptionalEquipment/SecondaryTempSensor";
import { RearFacilityOptions, defaultRearFacilityOptions } from "@/model/VehicleProfile/OptionalEquipment/RearFacilityControl";
import { IgnitionBypassOptions, defaultIgnitionBypassOptions } from "@/model/VehicleProfile/OptionalEquipment/IgnitionBypass";

import {
  AutomaticClimateControlOptions,
  defaultAutomaticClimateControlOptions,
} from "@/model/VehicleProfile/OptionalEquipment/AutomaticClimateControl";
import { airConditioningOptions, defaultAirConditioningOptions } from "../StandardOptions/AirConditioning";
import { heatingOptions, defaultHeatingOptions } from "../StandardOptions/HeatingOptions";
import { AuxHeatingOptions, defaultAuxHeatingOptions } from "./AuxiliaryHeating";
import { engineRunOptions, defaultEngineRunOptions } from "../StandardOptions/EngineRun";
import { engineStartOptions, defaultEngineStartOptions } from "../StandardOptions/EngineStart";

export interface OptionalEquipment {
  startStopAlarm: StartStopAlarmOptions;
  currentSensor: CurrentSensorOptions;
  automaticClimateControl: AutomaticClimateControlOptions;
  humidity: HumidityOptions;
  secondaryTempSensor: SecondaryTempSensorOptions;
  rearFacility: RearFacilityOptions;
  ignitionBypass: IgnitionBypassOptions;
  airConditioningOptions: airConditioningOptions;
  heatingOptions: heatingOptions;
  auxHeatingOptions: AuxHeatingOptions;
  engineStartOptions: engineStartOptions;
  engineRunOptions: engineRunOptions;
}

const defaultOptionalEquipmentObject: OptionalEquipment = {
  startStopAlarm: Object.assign({}, defaultStartStopAlarmOptions),
  currentSensor: Object.assign({}, defaultCurrentSensorOptions),
  automaticClimateControl: Object.assign({}, defaultAutomaticClimateControlOptions),
  humidity: Object.assign({}, defaultHumidityOptions),
  secondaryTempSensor: Object.assign({}, defaultSecondaryTempSensorOptions),
  rearFacility: Object.assign({}, defaultRearFacilityOptions),
  ignitionBypass: Object.assign({}, defaultIgnitionBypassOptions),
  airConditioningOptions: Object.assign({}, defaultAirConditioningOptions),
  heatingOptions: Object.assign({}, defaultHeatingOptions),
  auxHeatingOptions: Object.assign({}, defaultAuxHeatingOptions),
  engineStartOptions: Object.assign({}, defaultEngineStartOptions),
  engineRunOptions: Object.assign({}, defaultEngineRunOptions),
};

function defaultOptionalEquipmentCopy(): OptionalEquipment {
  return JSON.parse(JSON.stringify(defaultOptionalEquipmentObject));
}

export const defaultOptionalEquipment = defaultOptionalEquipmentCopy;
