import Oidc from "oidc-client";

import { identityServerURL, localURL } from "@/helpers/Settings";

const config = {
  authority: `${identityServerURL}`,
  client_id: "gripidle.grip.portal",
  redirect_uri: `${localURL}/callback`,
  response_type: "code",
  scope: "openid profile gripidle.api IdentityServerApi",
  post_logout_redirect_uri: `${localURL}/index.html`,
};
export const mgr = new Oidc.UserManager(config);
