











import Vue from "vue";

export default Vue.extend({
  name: "LoadingModal",
  props: {
    message: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({}),
  methods: {},
});
