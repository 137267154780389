import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { VehicleStore } from "./vehicle";
import { UserStore } from "./user";
import { FilterStore } from "./filter";
import { MessageStore } from "./messageStore";

export class RootState {}

const store = new Vuex.Store<RootState>({
  state: () => ({}),
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    VehicleStore,
    UserStore,
    FilterStore,
    MessageStore,
  },
});

export default store;
