import { TemperatureUnit } from "@/model/Units";
import { InputType } from "@/model/VehicleProfile/OptionParameter";
import { convertTemperatureFromCelsius } from "./UnitService";

export const SetHiddenFieldValidation = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: Record<string, any>,
  section: string,
  param: string,
  userTemperature: TemperatureUnit
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
  // If we aren't adding front-end validation, ensure that if the value is somehow out of range
  // that was reset it back to the default value.
  // This is just a fail-safe so the user doesn't get prevented from saving settings if the values end off
  // This shouldn't happen in theory but was happening from bad test data before validation was added to the API.
  let minValue = form[section][param].minValue;
  let maxValue = form[section][param].maxValue;
  let defaultValue = form[section][param].defaultValue;
  let currentValue = form[section][param].value;

  // If we are dealing with temperature and the user is set to Fahrenheit, we need to convert the values
  if (
    form[section][param].type == InputType.temperature &&
    userTemperature == TemperatureUnit.Fahrenheit
  ) {
    minValue = convertTemperatureFromCelsius(
      minValue,
      TemperatureUnit.Fahrenheit
    );

    maxValue = convertTemperatureFromCelsius(
      maxValue,
      TemperatureUnit.Fahrenheit
    );

    defaultValue = convertTemperatureFromCelsius(
      defaultValue,
      TemperatureUnit.Fahrenheit
    );

    currentValue = convertTemperatureFromCelsius(
      currentValue,
      TemperatureUnit.Fahrenheit
    );
  }

  if (minValue != null && currentValue < minValue) {
    form[section][param].value = defaultValue;
  } else if (maxValue != null && currentValue > maxValue) {
    form[section][param].value = defaultValue;
  }

  return form;
};
