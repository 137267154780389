var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.addFilter}},[_vm._v(" Add Filter ")]),_c('v-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.addFilterDialog),callback:function ($$v) {_vm.addFilterDialog=$$v},expression:"addFilterDialog"}},[_c('v-card',{staticClass:"background"},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchVehicles),callback:function ($$v) {_vm.searchVehicles=$$v},expression:"searchVehicles"}})],1)],1)],1),_c('v-card-text',{staticStyle:{"max-height":"50vh"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 background",attrs:{"headers":_vm.headers,"items":_vm.vehicles,"search":_vm.searchVehicles,"item-key":"vehicleId","show-select":"","items-per-page":5},scopedSlots:_vm._u([{key:"item.idleTrackerIMEI",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.idleTrackerIMEI}})]}},{key:"item.lastDateReported",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.lastDateReported))+" ")]}},{key:"item.frequency",fn:function(ref){
var item = ref.item;
return [(item.frequency)?_c('span',[_vm._v(" "+_vm._s(item.frequency.charAt(0).toUpperCase() + item.frequency.slice(1))+" ")]):_vm._e()]}},{key:"item.assetName",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.assetName}})]}},{key:"item.make",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.make}})]}},{key:"item.model",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.model}})]}},{key:"item.series",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.series}})]}},{key:"item.bodyType",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.bodyType}})]}},{key:"item.gvwrClass",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.gvwrClass}})]}},{key:"item.vehicleType",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.vehicleType}})]}},{key:"item.vehicleClass",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.vehicleClass}})]}},{key:"item.fuelType",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.fuelType}})]}},{key:"item.engineType",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.engineType}})]}},{key:"item.engineManufacturer",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.engineManufacturer}})]}},{key:"item.brakeSystem",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.brakeSystem}})]}},{key:"item.driveLineType",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.driveLineType}})]}},{key:"item.controller",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.controller}})]}},{key:"item.screen",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.screen}})]}},{key:"item.other",fn:function(ref){
var item = ref.item;
return [_c('VehicleProfileData',{attrs:{"item":item.other}})]}}],null,true),model:{value:(_vm.newSelectedVehicles),callback:function ($$v) {_vm.newSelectedVehicles=$$v},expression:"newSelectedVehicles"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelFilters}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveFilters}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }