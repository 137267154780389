export enum Country {
	Canada,
	UnitedStates,
}

export enum Currency {
	CAD,
	USD,
}

export enum Timezone {
	Eastern,
	Atlantic,
	Central,
	Mountain,
	Pacific,
}
