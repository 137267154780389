





































































































import Vue from "vue";

import { mgr } from "@/helpers/AuthService";
import { mapGetters } from "vuex";

import { adminPortalURL } from "@/helpers/Settings";

export default Vue.extend({
  name: "NavigationDrawer",

  data: () => ({
    drawer: null,
    items: [
      { title: "Vehicle Profiles", icon: "mdi-car", path: "VehicleProfiles" },
      { title: "User Groups", icon: "mdi-car-2-plus", path: "UserGroups" },
      { title: "Charts & Graphs", icon: "mdi-chart-bar", path: "Charts" },
      { title: "Resources", icon: "mdi-file-document-outline", path: "Resources" },
      { title: "Events", icon: "mdi-calendar-range-outline", path: "Events" },
      { title: "Raw Data", icon: "mdi-calendar-range-outline", path: "RawData" },
      { title: "Account Information", icon: "mdi-account", path: "Account" },
      { title: "Users", icon: "mdi-account-box-multiple", path: "Users" },
      { title: "Savings Calculator", icon: "mdi-currency-usd", path: "SavingsCalculator"},
    ],
  }),
  methods: {
    login() {
      mgr.signinRedirect();
    },
    logout() {
      mgr.signoutRedirect();
    },
    viewAdminPortal() {
      window.location.href = `${adminPortalURL}`;
    },
    viewMainSite() {
      window.location.href = "https://epeqidlemanagement.com";
    },
  },
  computed: {
    ...mapGetters(["isUserLoggedIn", "isGripAdmin", "user", "isClientAdmin", "isResourceViewer"]),
    itemsFiltered() {
      let items = this.items.filter(() => true); // clone items

      if (!this.isUserLoggedIn) {
        items = items.filter((e) => e.path == "SavingsCalculator");
      }

      // remove everything but resources and account if user is a resource viewer
      if (this.isResourceViewer) { 
        items = items.filter((e) => e.path == "Resources" || e.path == "Account");
      }

      // remove user groups if user is not a client admin
      if (!this.isClientAdmin) {
        items = items.filter((e) => e.path != "UserGroups");
      }

      // remove users if user is not an admin
      if (!this.isGripAdmin && !this.isClientAdmin) {
        items = items.filter((e) => e.path != "Users");
      }

      return items;
    },
  },
});
