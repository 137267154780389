import { Part } from "../API/Part";

export interface Equipment {
  ignitionBypass: Part[] | null;
  antiTheft: Part[] | null;
  alarm: Part[] | null;
  solenoid85: Part[] | null;
  solenoid200: Part[] | null;
  battery: Part[] | null;
  heatCoolantPump: Part[] | null;
  heatCoolantHeater: Part[] | null;
  heatAirHeater: Part[] | null;
  airConditioning: Part[] | null;
  currentSensor: Part[] | null;
  autoHVAC: Part[] | null;
  humidity: Part[] | null;
  tempSensing: Part[] | null;
  rearFacilityControl: Part[] | null;
  useMyOwnBattery: boolean;
  useMyOwnHeating: boolean;
  useMyOwnAlarm: boolean;
  useMyOwnSolenoid: boolean;
}

const defaultEquip: Equipment = {
  ignitionBypass: null,
  antiTheft: null,
  alarm: null,
  solenoid85: null,
  solenoid200: null,
  battery: null,
  heatCoolantPump: null,
  heatCoolantHeater: null,
  heatAirHeater: null,
  airConditioning: null,
  currentSensor: null,
  autoHVAC: null,
  humidity: null,
  tempSensing: null,
  rearFacilityControl: null,
  useMyOwnBattery: false,
  useMyOwnHeating: false,
  useMyOwnAlarm: false,
  useMyOwnSolenoid: false,
};

export function defaultEquipment(): Equipment {
  return JSON.parse(JSON.stringify(defaultEquip));
}
