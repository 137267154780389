






















































































































import Vue from "vue";

import instance from "@/axios";
import { Vehicle } from "@/model/API/Vehicle";
import dayjs from "dayjs";

import { mapActions, mapGetters } from "vuex";

import LoadingModal from "@/components/LoadingModal.vue";
import InfoModal from "@/components/InfoModal.vue";
import ImportDialog from "@/components/ImportCSVDialog.vue";
import VehicleProfileData from "@/components/VehicleProfileData.vue";
import { AxiosRequestConfig } from "axios";

export default Vue.extend({
  name: "VehicleProfiles",
  components: { LoadingModal, InfoModal, ImportDialog, VehicleProfileData },
  created: async function() {
    if (this.isResourceViewer) {
      this.$router.push({ name: "Resources" });
    } else {
      this.loadVehicles();
    }
  },
  data: () => ({
    search: "" as string,
    isLoadingVehicle: false,
    loadingVehicleMessage: "Loading vehicle",
    isError: false,
    isLoadingVehicleError: false,
    isImportDialogOpen: false,
    errorTitle: "",
    errorMessage: "",
    isLoading: true,
    headers: [
      { text: "IMEI", value: "idleTrackerIMEI" },
      { text: "VIN", value: "vin" },
      { text: "Last Date Reported", value: "lastDateReported" },
      { text: "Frequency", value: "frequency" },
      { text: "Asset Name", value: "assetName" },
      { text: "Client Company", value: "vehicleClient.company" },
      { text: "Zone", value: "vehicleZone.zoneName" },
      { text: "Year", value: "year" },
      { text: "Make", value: "make" },
      { text: "Model", value: "model" },
      { text: "Series", value: "series" },
      { text: "Body Type", value: "bodyType" },
      { text: "GVWR Class", value: "gvwrClass" },
      { text: "Vehicle Type", value: "vehicleType" },
      { text: "Vehicle Class", value: "vehicleClass" },
      { text: "Fuel Type", value: "fuelType" },
      { text: "Fuel Cost", value: "fuelCost" },
      { text: "Engine Type", value: "engineType" },
      { text: "Engine Manufacturer", value: "engineManufacturer" },
      { text: "Brake System", value: "brakeSystem" },
      { text: "Drive Line Type", value: "driveLineType" },
      { text: "Controller", value: "controller" },
      { text: "Screen", value: "screen" },
      { text: "Other", value: "other" },
    ],
    vehicles: [] as Vehicle[],
  }),
  computed: {
    ...mapGetters(["isSuperAdmin", "isResourceViewer"]),
  },
  methods: {
    ...mapActions(["setGeneralInfo", "loadVehicle", "resetStore", "setVehicles", "setSelectedVehicles"]),
    /**
     * Make a request to load all of the vehicles and display them in the table.
     * If any errors occur, display the error dialog
     */
    async loadVehicles() {
      this.isLoadingVehicleError = false;
      this.isLoading = true;
      await instance
        .get<Vehicle[]>("/Vehicle")
        .then((response) => {
          this.vehicles = response.data;
          this.setVehicles(this.vehicles);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoadingVehicleError = true;
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /**
     * When a vehicle is selected from the datatabe, load the details of the vehicle and then display the AddUpdateVehicle page
     * If any errors occur, display the error dialog
     */
    async vehicleSelected(e: Vehicle) {
      this.isLoadingVehicle = true;

      instance.get<Vehicle>(`/Vehicle/${e.vehicleId}`).then((response) => {
        let vehicle = response.data;

        this.loadVehicle(vehicle)
          .then(() => {
            this.setSelectedVehicles([vehicle]);
            this.$router.push({ name: "AddVehicle", params: { vehicleID: vehicle.vehicleId.toString() } });
          })
          .catch(() => {
            this.isError = true;
            this.errorTitle = "Error";
            this.errorMessage = `Error loading ${vehicle.assetName}`;
          })
          .finally(() => {
            this.isLoadingVehicle = false;
          });
      });
    },
    addVehicle() {
      this.resetStore();
      this.$router.push({ name: "AddVehicle" });
    },
    /**
     * Create and open a CSV document containing all vehicles in the profile
     */
    exportCSV() {
      let config: AxiosRequestConfig = {
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "text/csv",
        },
      };
      instance
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>("/Vehicle/Export", config)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Vehicles - " + new Date().toString() + ".xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          //
        });
    },
    /**
     * Open the dialog to import vehicles
     */
    importCSV() {
      this.isImportDialogOpen = true;
    },
    /**
     * If vehicles were imported, reload the list of vehicles
     */
    vehiclesImported() {
      this.isImportDialogOpen = false;
      this.loadVehicles();
    },
    getFormattedDate(dateReleased: string) {
      if (dateReleased) {
        return dayjs(dateReleased).format("YYYY/MM/DD");
      }
      return "";
    },
  },
});
