








































































































import Vue from "vue";
import { mapGetters } from "vuex";

import instance from "@/axios";
import { GetMachineDto } from "@/model/API/ChartsGraphs/GetMachineDto";
import { Vehicle } from "@/model/API/Vehicle";
import VehicleFilter from "@/components/VehicleFilter.vue";

interface Section {
  title: string; // The title of the section
  description: string; // The description that will appear under the section
  data: number[]; // This is the numebr that will be displayed in the tooltip for the segment. Typically is the same as the dataset number but will be different if the dataset is in percentage as the tooltip should display actual value not percentages
  segmentLabels: string[]; // This is the segments that exist in the chart. This will usually be the same as the labels but without the % included.
  segmentDescriptions: string[]; // Longer description of each label. Must be in the same order as the labels. Will be displayed on hover and in info dialogs
  additionalInfo?: string; // Additional info that will be displayed at the bottom of the info dialog for the chart
}

export default Vue.extend({
  components: { VehicleFilter },
  name: "RawData",
  created() {
    this.loadMachines();
  },
  data: () => ({
    machines: [] as GetMachineDto[], // Contains all of the machines returned from API
    allVins: [] as string[], // Contains all of the VIN's for all the vehicles the user can see.
    allVehicles: [] as Vehicle[], // Contains all of the vehicles the user has access to, including ones without a machine
    selectedSection: null as Section | null,
    isInfoModalOpen: false,
    headers: [
      // Header for the add filter data table
      { text: "IMEI", value: "idleTrackerIMEI" },
      { text: "VIN", value: "vin" },
      { text: "Asset Name", value: "assetName" },
      { text: "Client Company", value: "vehicleClient.company" },
      { text: "Last Date Reported", value: "lastDateReported" },
      { text: "Frequency", value: "frequency" },
      { text: "Zone", value: "vehicleZone.zoneName" },
      { text: "Year", value: "year" },
      { text: "Make", value: "make" },
      { text: "Model", value: "model" },
      { text: "Series", value: "series" },
      { text: "Body Type", value: "bodyType" },
      { text: "GVWR Class", value: "gvwrClass" },
      { text: "Vehicle Type", value: "vehicleType" },
      { text: "Vehicle Class", value: "vehicleClass" },
      { text: "Fuel Type", value: "fuelType" },
      { text: "Fuel Cost", value: "fuelCost" },
      { text: "Engine Type", value: "engineType" },
      { text: "Engine Manufacturer", value: "engineManufacturer" },
      { text: "Brake System", value: "brakeSystem" },
      { text: "Drive Line Type", value: "driveLineType" },
      { text: "Controller", value: "controller" },
      { text: "Screen", value: "screen" },
      { text: "Other", value: "other" },
    ],
  }),
  methods: {
    loadMachines() {
      instance.get<Vehicle[]>("/vehicle").then((e) => {
        this.allVehicles = e.data;

        if (this.vehiclesFiltered.length > 0) {
          let allVins = [] as string[];
          for (let i = 0; i < this.vehiclesFiltered.length; i++) {
            let vehicle = this.vehiclesFiltered[i];
            if (vehicle.machineId) {
              allVins.push(vehicle.machineId);
            }
          }
          this.allVins = allVins;

          instance.post<GetMachineDto[]>(`Machine/machineId`, allVins).then((e) => {
            this.machines = e.data;
          });
        }
      });
    },
    roundTo2Decimal(value: number): number {
      return Math.round((value + Number.EPSILON) * 100) / 100;
    },
    downloadData() {
      let text = "";

      this.allData.forEach((section) => {
        for (let i = 0; i < section.data.length; i++) {
          text += `${section.segmentLabels[i]},${section.data[i]}\n`;
        }
      });

      var blob = new Blob([text], { type: "text/csv" });
      let filename = "VehicleDataPoints.csv";

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    },
    getNumericMeasurements(key: string): number {
      let total = 0;
      for (let i = 0; i < this.selectedMachines.length; i++) {
        let machine = this.selectedMachines[i];
        total += this.getNumericMeasurement(key, machine);
      }
      return this.roundTo2Decimal(total);
    },
    getNumericMeasurement(key: string, machine: GetMachineDto): number {
      if (machine.measurement && machine.measurement.numeric) {
        if (machine.measurement.numeric[key]) {
          return this.roundTo2Decimal(machine.measurement.numeric[key].value);
        }
      }
      return 0;
    },
    viewInfo(section: Section) {
      this.selectedSection = section;
      this.isInfoModalOpen = true;
    },
    closeInfo() {
      this.selectedSection = null;
      this.isInfoModalOpen = false;
    },
  },
  computed: {
    ...mapGetters(["selectedVehicles"]),
    allData() {
      const parkAndNeutral = this.getNumericMeasurements("valdef.ParkandNeutralHours"); // Park and Neutral Hours
      const idlingInDrive = this.getNumericMeasurements("valdef.IdlinginDriveHours"); // Idling in Drive Hours
      const drivingHours = this.getNumericMeasurements("valdef.DrivingHours"); // Driving Hours
      const monitorHours = this.getNumericMeasurements("valdef.MonitorHours"); // Monitor Hours
      const lifeHours = this.getNumericMeasurements("valdef.LifeHours"); // Life Hours
      const hoodOpenHours = this.getNumericMeasurements("valdef.HoodOpensHours"); // Hood Open Hours
      const engineRunningForAirConditioning = this.getNumericMeasurements("valdef.EngineRunningforAirConditioning"); // Engine Running for Air Conditioning
      const airConditionEngineOff = this.getNumericMeasurements("valdef.AirConditionEngineOff"); // Air Condition - Engine Off
      const engineRunningForHeating = this.getNumericMeasurements("valdef.EngineRunningforHeating"); // Engine Running for Heating
      const heatingHoursEngineOff = this.getNumericMeasurements("valdef.HeatingHoursEngineOff"); // Heating Hours - Engine Off
      const lowBatteryChargingHours = this.getNumericMeasurements("valdef.LowBatteryChargingHours"); // Low Battery Charging Hours
      const schedulerHeatingHours = this.getNumericMeasurements("valdef.SchedulerHeatingHours"); // Scheduler Heating Hours
      const brakePedalEngagedInDrive = this.getNumericMeasurements("valdef.BrakePedalEngagedInDrive"); // Brake Pedal Engaged - In Drive
      const parkingBrakeEngagedInDrive = this.getNumericMeasurements("valdef.ParkingBrakeEngagedInDrive"); // Parking Brake Engaged - In Drive
      const PTOLightsAuxHours = this.getNumericMeasurements("valdef.PTOLightsAuxHours"); // PTO/Lights/Aux Hours
      const doorLeftOpenHours = this.getNumericMeasurements("valdef.DoorLeftOpenHours"); // Door Left Open Hours
      const brakeStartHours = this.getNumericMeasurements("valdef.BrakeStartHours"); // Brake Start Hours
      const seatBeltStartHours = this.getNumericMeasurements("valdef.SeatBeltStartHours"); // Seat Belt Start Hours
      const engineRunningForHumidity = this.getNumericMeasurements("valdef.EngineRunningForHumidity"); // Engine Running For Humidity
      const lowCoolantTemperatureHours = this.getNumericMeasurements("valdef.LowCoolantTemperatureHours"); // Low Coolant Temperature Hours
      const throttleOverrideHours = this.getNumericMeasurements("valdef.ThrottleOverrideHours"); // Throttle Override Hours
      const seatBeltOnlyHours = this.getNumericMeasurements("valdef.SeatBeltOnlyHours"); // Seat Belt Only Hours
      const antiTheftOnlyHours = this.getNumericMeasurements("valdef.AntiTheftOnlyHours"); // ANti Theft Only Hours
      const doorStartHours = this.getNumericMeasurements("valdef.DoorStartHours"); // Door Start Hours
      const shorePowerHours = this.getNumericMeasurements("valdef.ShorePowerHours"); // SHore Power Hours
      const airPressureHours = this.getNumericMeasurements("valdef.AirPressureHours"); // Air Pressure Hours
      const monitoringWithoutClimate = this.getNumericMeasurements("valdef.MonitoringWithoutClimate"); // Monitoring Without Climate
      const hydraulicHours = this.getNumericMeasurements("valdef.HydraulicHours"); // Hydraulic Hours
      const throttleOverrideCount = this.getNumericMeasurements("valdef.ThrottleOverrideCount"); // Throttle Override Count
      const doorStartCount = this.getNumericMeasurements("valdef.DoorStartCount"); // Door Start Count
      const brakeStartCount = this.getNumericMeasurements("valdef.BrakeStartCount"); // Brake Start Count
      const seatBeltStartCount = this.getNumericMeasurements("valdef.SeatBeltStartCount"); // Seat Belt Start Count
      const antiTheftHours = this.getNumericMeasurements("valdef.AntiTheftHours"); // Anti Theft Hours
      const airConditioningShorePower = this.getNumericMeasurements("valdef.AirConditioningShorePower"); // Air Conditioning - Shore Power
      const heatingShorePower = this.getNumericMeasurements("valdef.HeatingShorePower"); // Heating - Shore Power
      const keyInRunEngineOff = this.getNumericMeasurements("valdef.KeyInRunEngineOff"); // Key In Run Engine Off
      const initIdleTimeHighIdleHours = this.getNumericMeasurements("valdef.InitHighIdleTime"); // Init Idle Time Hiigh Idle
      const initIdleTimeV2 = this.getNumericMeasurements("valdef.InitIdleTimeV2"); // Init Idle Time V2

      let result: Section[] = [
        {
          title: "Vehicle Use Hours",
          description: "Displays the total hours that the vehicle is in use.",
          data: [lifeHours, shorePowerHours],
          segmentLabels: ["Life Hours", "Shore Power Hours"],
          segmentDescriptions: [
            "Total hours that the vehicle ignition state is in run. Either by the key or the anti-theft module.",
            "Total hours that the vehicle has been connected to shore power.",
          ],
        },
        {
          title: "Vehicle Operations",
          description: "Displays the total hours for each operational state.",
          data: [parkAndNeutral, idlingInDrive, drivingHours, monitorHours, hoodOpenHours, keyInRunEngineOff],
          segmentLabels: [
            "Park and Neutral Hours",
            "Idling in Drive Hours",
            "Driving Hours",
            "Monitoring Hours",
            "Hood Open Hours",
            "Key In Run Engine Off Hours",
          ],
          segmentDescriptions: [
            "Total hours that the vehicle has been running in park or neutral.",
            "Total hours that the vehicle has been running while not in park or neutral and moving less than 1 km/h",
            "Total hours that the vehicle has been running while not in park or neutral and moving faster than 1 km/h",
            "Total hours that the EPEQ® IM system is in control and the engine is off.",
            "Total hours that the hood has been open.",
            "Total hours that the operator has left the vehicle in run without turing on the engine.",
          ],
        },
        {
          title: "Behavioural Analysis",
          description: "Displays the total hours which the EPEQ® IM is running for a specific function.",
          data: [
            engineRunningForHeating,
            engineRunningForAirConditioning,
            engineRunningForHumidity,
            lowBatteryChargingHours,
            PTOLightsAuxHours,
            lowCoolantTemperatureHours,
            throttleOverrideHours,
            doorStartHours,
            doorLeftOpenHours,
            seatBeltStartHours,
            brakeStartHours,
            seatBeltOnlyHours,
            antiTheftOnlyHours,
            airPressureHours,
            hydraulicHours,
            initIdleTimeHighIdleHours,
            initIdleTimeV2,
          ],
          segmentLabels: [
            "Engine Running For Heating",
            "Engine Running For Air Conditioning",
            "Engine Running For Humidity",
            "Low Battery Charging Hours",
            "PTO/Lights/Aux Hours",
            "Low Coolant Temperature Hours",
            "Throttle Override Hours",
            "Door Start Hours",
            "Door Left Open Hours",
            "Seat Belt Start Hours",
            "Brake Start Hours",
            "Seat Belt Only Hours",
            "Anti Theft Only Hours",
            "Air Pressure Hours",
            "Hydraulic Hours",
            "Init Idle Time High Idle Hours",
            "Init Idle Time V2",
          ],
          segmentDescriptions: [
            "Total hours the engine has been running to heat the cab.",
            "Total hours the engine has been running to cool the cab.",
            "Total hours the engine has been running to defog the windows",
            "Total hours the engine has been running to charge the batteries.",
            "Total hours the engine has been running for PTO",
            "Total hours the engine has been running to heat the coolant.",
            "Total hours the engine has been running due to operator overriding the shutdown with the throttle override.",
            "Total hours that the engine has been running due to the operator triggering the door start.",
            "Total hours that the engine has been running due to the operator leaving the drivers door open.",
            "Total hours that the engine has been running due to the operator buckling their seatbelt.",
            "Total hours that the engine has been running due to the operator using the brake start feature.",
            "Total hours that the engine has continued to run due to the seatbelt being buckled.",
            "Total hours that the engine has run due to the system being in 'Anti Theft' mode, when the 'Anti Theft Only' option is enabled.",
            "Total hours that the engine has been running to build up air pressure.",
            "Total hours after the operator starts the vehicle before the vehicle goes into EPEQ® IM monitoring mode.",
          ],
          additionalInfo: "These hours should add up to the Park and Neutral Hours less the total amount of initial run time.",
        },
        {
          title: "Engine-Off Monitoring Climate Analysis",
          description: "Displays the total hours that the EPEQ® IM kept the engine off with regards to climate control.",
          data: [airConditionEngineOff, heatingHoursEngineOff, monitoringWithoutClimate],
          segmentLabels: ["Air Conditioning - Engine Off", "Cab Heating Hours - Engine Off", "Monitoring Without Climate"],
          segmentDescriptions: [
            "Total hours that the engine is off while the air conditioning is running.",
            "Total hours that the engine has been off while heat is running.",
            "Total hours that the engine has been off while heat and air conditioning are not running.",
          ],
          additionalInfo: "These hours should add up to equal the Monitoring Hours.",
        },
        {
          title: "Shore Power Climate Analysis",
          description: "Displays the total hours in shore power with regards to climate control.",
          data: [airConditioningShorePower, heatingShorePower, shorePowerHours - airConditioningShorePower - heatingShorePower],
          segmentLabels: ["Air Conditioning - Shore Power", "Heating - Shore Power", "Shore Power Without Climate"],
          segmentDescriptions: [
            "Total hours that the vehicle is hooked to shore power while the air conditioning is running.",
            "Total hours that the vehicle is hooked to shore power while heat is running.",
            "Total hours that the vehicle is hooked to shore power while heat and air conditioning are not running.",
          ],
          additionalInfo: "These hours should add up to equal the Shore Power Hours.",
        },
        {
          title: "Process Counts",
          description: "Displays the counts for which a specific function is performed by the operator.",
          data: [doorStartCount, seatBeltStartCount, throttleOverrideCount, brakeStartCount],
          segmentLabels: ["Door Start Count", "Seat Belt Start Count", "Throttle Override Count", "Brake Start Count"],
          segmentDescriptions: [
            "Total number of times the driver has started the vehicle by opening the drivers door.",
            "Total number of times the driver has started the vehicle by buckling their seat belt.",
            "Total number of times the operator has overridden the engine shutting down by pressing the throttle.",
            "Total number of times the driver has started the vehicle by pressing the brake pedal",
          ],
        },
        {
          title: "Additional Data",
          description: "",
          data: [parkingBrakeEngagedInDrive, brakePedalEngagedInDrive, antiTheftHours, schedulerHeatingHours],
          segmentLabels: ["Parking Brake Engaged - In Drive", "Brake Pedal Engaged - In Drive", "Anti Theft Hours", "Scheduler Heating Hours"],
          segmentDescriptions: [
            "Total hours that the engine has been running while in drive and the parking brake engaged.",
            "Total hours that the engine has been running while in drive and the brake pedal engaged.",
            "Total hours that the engine has been running for while in 'Anti Theft' mode.",
            "Total hours that the engine has been running due to the scheduler being set to heat the cab.",
          ],
        },
      ];

      return result;
    },
    selectedMachines(): GetMachineDto[] {
      // If the user has selected no vehicles, or all vehicles, return all machines
      if (this.selectedVehicles.length == 0 || this.selectedVehiclesFiltered.length == this.vehiclesFiltered.length) {
        return this.machines;
      }

      let allVins = this.selectedVehiclesFiltered.map((e: Vehicle) => e.machineId);

      return this.machines.filter((e) => allVins.includes(e.machine.id));
    },
    vehiclesFiltered(): Vehicle[] {
      // Contains all of the vehicles the user has access to
      return this.allVehicles.filter((e) => e.machineId != null);
    },
    selectedVehiclesFiltered(): Vehicle[] {
      // Contains all the selected vehicles that have a machine id
      return this.selectedVehicles.filter((e: Vehicle) => e.machineId != null);
    },
  },
});
