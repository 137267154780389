export enum TemperatureUnit {
  Celsius,
  Fahrenheit,
}

export enum FuelUnit {
  Litres,
  Gallons,
}

export enum DistanceUnit {
  Kilometers,
  Miles,
}

export enum MassUnit {
  Kilograms,
  Pounds,
}
