import { Outputs, defaultOutputs } from "@/model/VehicleProfile/InputsOutputs/Outputs";
import { Inputs, defaultInputs } from "./Inputs";

export interface InputsOutputs {
  inputs: Inputs;
  outputs: Outputs;
}

const defaultStandardOptionsObject: InputsOutputs = {
  inputs: Object.assign({}, defaultInputs),
  outputs: Object.assign({}, defaultOutputs),
};

export function defaultIntputsOutputs(): InputsOutputs {
  return JSON.parse(JSON.stringify(defaultStandardOptionsObject));
}
