






























































import Vue from "vue";

import ChartCard from "@/components/ChartCard.vue";
import instance from "@/axios";
import { GetMachineDto } from "@/model/API/ChartsGraphs/GetMachineDto";
import { Vehicle } from "@/model/API/Vehicle";

import VehicleFilter from "@/components/VehicleFilter.vue";

import { mapGetters } from "vuex";

import { calculateFuelConsumption, calculateEmissions, calculateEmissionsGallon, calculateMaintenanceCost, parseGVWRClass } from "@/helpers/vehicleProfiles";
import { convertFuelFromLitres, convertDistanceFromKilometers, convertMass } from "@/helpers/UnitService";
import { DistanceUnit, FuelUnit, MassUnit } from "@/model/Units";

const COLORS = {
  blue: "rgb(65,105,255)",
  red: "rgb(220,20,60)",
  green: "rgb(34,139,34)",
  yellow: "rgb(227,214,30)",
  orange: "rgb(244, 149, 66)",
  purple: "rgb(128, 0, 128)",
  lightBlue: "rgb(88, 112, 115)",
  otherBlue: "rgb(30, 144, 255)",
  lightGreen: "rgb(72, 119, 72)",
  darkGreen: "rgb(107, 142, 35)",
  lighterGreen: "rgb(0, 241, 154)",
  lightOrange: "rgb(244, 164, 96)",
  brown: "rgb(205, 133, 63)",
  otherBrown: "rgb(210, 105, 30)",
  darkRed: "rgb(188, 0, 28)",
  darkBlue: "rgb(33, 73, 95)",
  darkerGreen: "rgb(2, 107, 2)",
};

export default Vue.extend({
  components: { ChartCard, VehicleFilter },
  name: "Charts",
  created() {
    this.load();
  },
  data: () => ({
    machines: [] as GetMachineDto[], // Contains all of the machines returned from API
    allVins: [] as string[], // Contains all of the VIN's for all the vehicles the user can see.
    allVehicles: [] as Vehicle[], // Contains all of the vehicles the user has access to, including ones without a machine
    isLoading: true,
    errorLoadingMessage: null as string | null,
    isSyncingData: false,
    headers: [
      // Header for the add filter data table
      { text: "IMEI", value: "idleTrackerIMEI" },
      { text: "VIN", value: "vin" },
      { text: "Asset Name", value: "assetName" },
      { text: "Client Company", value: "vehicleClient.company" },
      { text: "Last Date Reported", value: "lastDateReported" },
      { text: "Frequency", value: "frequency" },
      { text: "Zone", value: "vehicleZone.zoneName" },
      { text: "Year", value: "year" },
      { text: "Make", value: "make" },
      { text: "Model", value: "model" },
      { text: "Series", value: "series" },
      { text: "Body Type", value: "bodyType" },
      { text: "GVWR Class", value: "gvwrClass" },
      { text: "Vehicle Type", value: "vehicleType" },
      { text: "Vehicle Class", value: "vehicleClass" },
      { text: "Fuel Type", value: "fuelType" },
      { text: "Fuel Cost", value: "fuelCost" },
      { text: "Engine Type", value: "engineType" },
      { text: "Engine Manufacturer", value: "engineManufacturer" },
      { text: "Brake System", value: "brakeSystem" },
      { text: "Drive Line Type", value: "driveLineType" },
      { text: "Controller", value: "controller" },
      { text: "Screen", value: "screen" },
      { text: "Other", value: "other" },
    ],
  }),
  methods: {
    async load() {
      this.isLoading = true;
      this.errorLoadingMessage = null;
      instance
        .get<Vehicle[]>("/vehicle")
        .then((e) => {
          this.allVehicles = e.data;

          if (this.vehiclesFiltered.length > 0) {
            let allVins = [] as string[];
            for (let i = 0; i < this.vehiclesFiltered.length; i++) {
              let vehicle = this.vehiclesFiltered[i];
              if (vehicle.machineId) {
                allVins.push(vehicle.machineId);
              }
            }
            this.allVins = allVins;

            instance
              .post<GetMachineDto[]>(`Machine/machineId`, allVins)
              .then((e) => {
                if (e && e.data) {
                  this.machines = e.data;
                } else {
                  this.errorLoadingMessage = "Error loading charts. Try again later";
                }
              })
              .catch(() => {
                this.errorLoadingMessage = "Error loading charts. Try again later";
              });
          }
        })
        .catch(() => {
          this.errorLoadingMessage = "Error loading charts. Try again later";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async resyncData() {
      this.isSyncingData = true;
      await instance
        .get<Vehicle[]>("/vehicle/SyncData")
        .then(() => this.load())
        .finally(() => (this.isSyncingData = false));
    },
    roundTo2Decimal(value: number): number {
      return Math.round((value + Number.EPSILON) * 100) / 100;
    },
    formatNumberWithCommas(value: number | undefined): string {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return "";
      }
    },
    getNumericMeasurements(key: string): number {
      let total = 0;
      for (let i = 0; i < this.selectedMachines.length; i++) {
        let machine = this.selectedMachines[i];
        total += this.getNumericMeasurement(key, machine);
      }
      return this.roundTo2Decimal(total);
    },
    getNumericMeasurement(key: string, machine: GetMachineDto): number {
      if (machine.measurement && machine.measurement.numeric) {
        if (machine.measurement.numeric[key]) {
          return machine.measurement.numeric[key].value;
        }
      }
      return 0;
    },
    getChart(key: string) {
      interface ChartInfo {
        title: string; // The title of the chart
        description: string; // The description that will appear above the chart
        chartType: string; // The type of chart. Correspondes to type of charts supported by chartJS. Use the ChartType enum to set this value
        labels: string[]; // This is the labels that will be displayed in the legend of the chart. These will usually include the % for pie charts
        dataset: number[];
        data: number[]; // This is the numebr that will be displayed in the tooltip for the segment. Typically is the same as the dataset number but will be different if the dataset is in percentage as the tooltip should display actual value not percentages
        segmentLabels: string[]; // This is the segments that exist in the chart. This will usually be the same as the labels but without the % included.
        segmentDescriptions: string[]; // Longer description of each label. Must be in the same order as the labels. Will be displayed on hover and in info dialogs
        additionalChartInfo?: string; // Additional info that will be displayed at the bottom of the info dialog for the chart
        columns?: number; // How many columns the chart should take up in the row. Defaults to 6. Some charts should take the entire width and be set to 12
        colors: string[]; // List of colors for each segment. Must be the same length as the dataset
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: (context: any) => string[]; // Callback to display the label on hover of the chart.
        hasData: boolean; // whether this chart has any data to display
      }

      enum ChartType {
        pie = "pie",
        bar = "bar",
      }

      let info = null as ChartInfo | null;

      const parkAndNeutral = this.getNumericMeasurements("valdef.ParkandNeutralHours"); // Park and Neutral Hours
      const idlingInDrive = this.getNumericMeasurements("valdef.IdlinginDriveHours"); // Idling in Drive Hours
      const drivingHours = this.getNumericMeasurements("valdef.DrivingHours"); // Driving Hours
      const monitorHours = this.getNumericMeasurements("valdef.MonitorHours"); // Monitor Hours
      // const lifeHours = this.getNumericMeasurements("valdef.LifeHours"); // Life Hours
      const hoodOpenHours = this.getNumericMeasurements("valdef.HoodOpensHours"); // Hood Open Hours
      const engineRunningForAirConditioning = this.getNumericMeasurements("valdef.EngineRunningforAirConditioning"); // Engine Running for Air Conditioning
      const airConditionEngineOff = this.getNumericMeasurements("valdef.AirConditionEngineOff"); // Air Condition - Engine Off
      const engineRunningForHeating = this.getNumericMeasurements("valdef.EngineRunningforHeating"); // Engine Running for Heating
      const heatingHoursEngineOff = this.getNumericMeasurements("valdef.HeatingHoursEngineOff"); // Heating Hours - Engine Off
      const lowBatteryChargingHours = this.getNumericMeasurements("valdef.LowBatteryChargingHours"); // Low Battery Charging Hours
      const schedulerHeatingHours = this.getNumericMeasurements("valdef.SchedulerHeatingHours"); // Scheduler Heating Hours
      const brakePedalEngagedInDrive = this.getNumericMeasurements("valdef.BrakePedalEngagedInDrive"); // Brake Pedal Engaged - In Drive
      const parkingBrakeEngagedInDrive = this.getNumericMeasurements("valdef.ParkingBrakeEngagedInDrive"); // Parking Brake Engaged - In Drive
      const PTOLightsAuxHours = this.getNumericMeasurements("valdef.PTOLightsAuxHours"); // PTO/Lights/Aux Hours
      const doorLeftOpenHours = this.getNumericMeasurements("valdef.DoorLeftOpenHours"); // Door Left Open Hours
      const brakeStartHours = this.getNumericMeasurements("valdef.BrakeStartHours"); // Brake Start Hours
      const seatBeltStartHours = this.getNumericMeasurements("valdef.SeatBeltStartHours"); // Seat Belt Start Hours
      const engineRunningForHumidity = this.getNumericMeasurements("valdef.EngineRunningForHumidity"); // Engine Running For Humidity
      const lowCoolantTemperatureHours = this.getNumericMeasurements("valdef.LowCoolantTemperatureHours"); // Low Coolant Temperature Hours
      const throttleOverrideHours = this.getNumericMeasurements("valdef.ThrottleOverrideHours"); // Throttle Override Hours
      const seatBeltOnlyHours = this.getNumericMeasurements("valdef.SeatBeltOnlyHours"); // Seat Belt Only Hours
      const antiTheftOnlyHours = this.getNumericMeasurements("valdef.AntiTheftOnlyHours"); // ANti Theft Only Hours
      const doorStartHours = this.getNumericMeasurements("valdef.DoorStartHours"); // Door Start Hours
      const shorePowerHours = this.getNumericMeasurements("valdef.ShorePowerHours"); // SHore Power Hours
      const airPressureHours = this.getNumericMeasurements("valdef.AirPressureHours"); // Air Pressure Hours
      const monitoringWithoutClimate = this.getNumericMeasurements("valdef.MonitoringWithoutClimate"); // Monitoring Without Climate
      const hydraulicHours = this.getNumericMeasurements("valdef.HydraulicHours"); // Hydraulic Hours
      // const throttleOverrideCount = this.getNumericMeasurements("valdef.ThrottleOverrideCount"); // Throttle Override Count
      const doorStartCount = this.getNumericMeasurements("valdef.DoorStartCount"); // Door Start Count
      const brakeStartCount = this.getNumericMeasurements("valdef.BrakeStartCount"); // Brake Start Count
      const seatBeltStartCount = this.getNumericMeasurements("valdef.SeatBeltStartCount"); // Seat Belt Start Count
      const antiTheftHours = this.getNumericMeasurements("valdef.AntiTheftHours"); // Anti Theft Hours
      const airConditioningShorePower = this.getNumericMeasurements("valdef.AirConditioningShorePower"); // Air Conditioning - Shore Power
      const heatingShorePower = this.getNumericMeasurements("valdef.HeatingShorePower"); // Heating - Shore Power
      const keyInRunEngineOff = this.getNumericMeasurements("valdef.KeyInRunEngineOff"); // Key In Run Engine Off
      const initIdleTimeHighIdleHours = this.getNumericMeasurements("valdef.InitHighIdleTime"); // Init Idle Time Hiigh Idle
      const initIdleTimeV2 = this.getNumericMeasurements("valdef.InitIdleTimeV2"); // Init Idle Time V2

      switch (key) {
        // Vehicle Utilization
        case "operationOverview": {
          // How much does my vehicle idle?
          let total = parkAndNeutral + idlingInDrive + drivingHours + monitorHours + hoodOpenHours + keyInRunEngineOff;

          let hasData = true;
          // If there is no data, set the total to 1 so all the segments show as 0%
          if (total == 0) {
            total = 1;
            hasData = false;
          }

          let parkAndNeutralPercent = Math.round((parkAndNeutral / total) * 100 * 10) / 10;
          let idlingInDrivePercent = Math.round((idlingInDrive / total) * 100 * 10) / 10;
          let drivingHoursPercent = Math.round((drivingHours / total) * 100 * 10) / 10;
          let monitorHoursPercent = Math.round((monitorHours / total) * 100 * 10) / 10;
          let hoodOpenHoursPercent = Math.round((hoodOpenHours / total) * 100 * 10) / 10;
          let keyInRunEngineOffPercent = Math.round((keyInRunEngineOff / total) * 100 * 10) / 10;

          info = {
            title: "How much time does my vehicle idle? (Hours)",
            description: "Compares what state the vehicle is in as a percentage of total operation.",
            chartType: ChartType.pie,
            labels: [
              `Park and Neutral: ${parkAndNeutralPercent}%`,
              `Idling in Drive: ${idlingInDrivePercent}%`,
              `Driving: ${drivingHoursPercent}%`,
              `Monitoring: ${monitorHoursPercent}%`,
              `Hood Open: ${hoodOpenHoursPercent}%`,
              `Key In Run Engine Off: ${keyInRunEngineOffPercent}%`,
            ],
            segmentLabels: [`Park and Neutral`, `Idling in Drive`, `Driving`, `Monitoring`, `Hood Open:`, `Key In Run Engine Off`],
            segmentDescriptions: [
              "Total hours that the vehicle has been running in park or neutral.",
              "Total hours that the vehicle has been running while not in park or neutral and moving less than 1 km/h",
              "Total hours that the vehicle has been running while not in park or neutral and moving faster than 1 km/h",
              "Total hours that the EPEQ® IM system is in control and the engine is off.",
              "Total hours that the hood has been open.",
              "Total hours that the ignition has been in run with engine off and outside of monitoring mode.",
            ],
            data: [parkAndNeutral, idlingInDrive, drivingHours, monitorHours, hoodOpenHours, keyInRunEngineOff],
            dataset: [
              parkAndNeutralPercent,
              idlingInDrivePercent,
              drivingHoursPercent,
              monitorHoursPercent,
              hoodOpenHoursPercent,
              keyInRunEngineOffPercent,
            ],
            colors: [COLORS.darkRed, COLORS.otherBlue, COLORS.darkBlue, COLORS.darkerGreen, COLORS.orange, COLORS.purple],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `${this.formatNumberWithCommas(info?.data[context.dataIndex])} Hours`;
              return [firstLabel, secondLabel];
            },
            hasData,
          };
          break;
        }

        case "howMuchIdle": {
          // What was my reduction of idle in hours
          let savings = this.roundTo2Decimal(monitorHours);
          let actual = this.roundTo2Decimal(parkAndNeutral);
          let total = this.roundTo2Decimal(savings + actual);
          info = {
            title: "What was my reduction of idle? (Hours)",
            description: "Displays the actual idling hours, hours saved using EPEQ® IM, and total idling hours without EPEQ® IM installed.",
            chartType: ChartType.bar,
            labels: ["Your Savings", "Actual Idling Hours", "Total without EPEQ® IM"],
            segmentLabels: ["Your Savings", "Actual Idling Hours", "Total without EPEQ® IM"],
            segmentDescriptions: [
              "Amount of idle hours saved using the EPEQ® IM system.",
              "Actual idle hours using the EPEQ® IM system.",
              "Amount of idle hours that would have occurred without the EPEQ® IM installed.",
            ],
            data: [savings, actual, total],
            dataset: [savings, actual, total],
            colors: [COLORS.green, COLORS.red, COLORS.blue],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `Hours: ${this.formatNumberWithCommas(info?.data[context.dataIndex])}`;
              return [firstLabel, secondLabel];
            },
            hasData: savings != 0 || actual != 0 || total != 0,
          };
          break;
        }
        case "whyIdle": {
          let total =
            engineRunningForHeating +
            engineRunningForAirConditioning +
            engineRunningForHumidity +
            lowBatteryChargingHours +
            PTOLightsAuxHours +
            lowCoolantTemperatureHours +
            throttleOverrideHours +
            doorStartHours +
            doorLeftOpenHours +
            seatBeltStartHours +
            brakeStartHours +
            seatBeltOnlyHours +
            antiTheftOnlyHours +
            airPressureHours +
            hydraulicHours +
            initIdleTimeHighIdleHours +
            initIdleTimeV2;

          // If there is no data, set the total to 1 so all the segments show as 0%
          if (total == 0) {
            total = 1;
          }

          let engineRunengineRunningForHeatingPercent = Math.round((engineRunningForHeating / total) * 100 * 10) / 10;
          let engineRunningForAirConditioningPercent = Math.round((engineRunningForAirConditioning / total) * 100 * 10) / 10;
          let engineRunningForHumidityPercent = Math.round((engineRunningForHumidity / total) * 100 * 10) / 10;
          let lowBatteryChargingHoursPercent = Math.round((lowBatteryChargingHours / total) * 100 * 10) / 10;
          let PTOLightsAuxHoursPercent = Math.round((PTOLightsAuxHours / total) * 100 * 10) / 10;
          let lowCoolantTemperatureHoursPercent = Math.round((lowCoolantTemperatureHours / total) * 100 * 10) / 10;
          let throttleOverrideHoursPercent = Math.round((throttleOverrideHours / total) * 100 * 10) / 10;
          let doorStartHoursPercent = Math.round((doorStartHours / total) * 100 * 10) / 10;
          let doorLeftOpenHoursPercent = Math.round((doorLeftOpenHours / total) * 100 * 10) / 10;
          let seatBeltStartHoursPercent = Math.round((seatBeltStartHours / total) * 100 * 10) / 10;
          let brakeStartHoursPercent = Math.round((brakeStartHours / total) * 100 * 10) / 10;
          let seatBeltOnlyHoursPercent = Math.round((seatBeltOnlyHours / total) * 100 * 10) / 10;
          let antiTheftOnlyHoursPercent = Math.round((antiTheftOnlyHours / total) * 100 * 10) / 10;
          let airPressureHoursPercent = Math.round((airPressureHours / total) * 100 * 10) / 10;
          let hydraulicHoursPercent = Math.round((hydraulicHours / total) * 100 * 10) / 10;
          let initIdleTimeHighIdlePercent = Math.round((initIdleTimeHighIdleHours / total) * 100 * 10) / 10;
          let initIdleTimeV2Percent = Math.round((initIdleTimeV2 / total) * 100 * 10) / 10;

          let labels = [] as string[];
          let segmentLabels = [] as string[];
          let dataset = [] as number[];
          let segmentDescriptions = [] as string[];
          let data = [] as number[];

          if (engineRunengineRunningForHeatingPercent > 0) {
            labels.push(`Heat: ${engineRunengineRunningForHeatingPercent}%`);
            segmentLabels.push(`Heat`);
            dataset.push(engineRunengineRunningForHeatingPercent);
            data.push(engineRunningForHeating);
            segmentDescriptions.push("Total hours the engine has been running to heat the cab.");
          }

          if (engineRunningForAirConditioningPercent > 0) {
            labels.push(`AC: ${engineRunningForAirConditioningPercent}%`);
            segmentLabels.push(`AC`);
            dataset.push(engineRunningForAirConditioningPercent);
            data.push(engineRunningForAirConditioning);
            segmentDescriptions.push("Total hours the engine has been running to cool the cab.");
          }

          if (engineRunningForHumidityPercent > 0) {
            labels.push(`Humidity: ${engineRunningForHumidityPercent}%`);
            segmentLabels.push(`Humidity`);
            dataset.push(engineRunningForHumidityPercent);
            data.push(engineRunningForHumidity);
            segmentDescriptions.push("Total hours the engine has been running to defog the windows.");
          }

          if (lowBatteryChargingHoursPercent > 0) {
            labels.push(`Low Battery Charging: ${lowBatteryChargingHoursPercent}%`);
            segmentLabels.push(`Low Battery Charging`);
            dataset.push(lowBatteryChargingHoursPercent);
            data.push(lowBatteryChargingHours);
            segmentDescriptions.push("Total hours the engine has been running to charge the batteries.");
          }

          if (PTOLightsAuxHoursPercent > 0) {
            labels.push(`PTO/Lights/Aux: ${PTOLightsAuxHoursPercent}%`);
            segmentLabels.push(`PTO/Lights/Aux`);
            dataset.push(PTOLightsAuxHoursPercent);
            data.push(PTOLightsAuxHours);
            segmentDescriptions.push("Total hours the engine has been running for PTO.");
          }

          if (lowCoolantTemperatureHoursPercent > 0) {
            labels.push(`Low Coolant Temp: ${lowCoolantTemperatureHoursPercent}%`);
            segmentLabels.push(`Low Coolant Temp`);
            dataset.push(lowCoolantTemperatureHoursPercent);
            data.push(lowCoolantTemperatureHours);
            segmentDescriptions.push("Total hours the engine has been running to heat the coolant.");
          }

          if (throttleOverrideHoursPercent > 0) {
            labels.push(`Throttle Override Hours: ${throttleOverrideHoursPercent}%`);
            segmentLabels.push(`Throttle Override Hours`);
            dataset.push(throttleOverrideHoursPercent);
            data.push(throttleOverrideHours);
            segmentDescriptions.push("Total hours the engine has been running due to operator overriding the shutdown with the throttle override");
          }

          if (doorStartHoursPercent > 0) {
            labels.push(`Door Start: ${doorStartHoursPercent}%`);
            segmentLabels.push(`Door Start`);
            dataset.push(doorStartHoursPercent);
            data.push(doorStartHours);
            segmentDescriptions.push("Total hours that the engine has been running due to the operator triggering the door start.");
          }

          if (doorLeftOpenHoursPercent > 0) {
            labels.push(`Door Left Open: ${doorLeftOpenHoursPercent}%`);
            segmentLabels.push(`Door Left Open`);
            dataset.push(doorLeftOpenHoursPercent);
            data.push(doorLeftOpenHours);
            segmentDescriptions.push("Total hours that the engine has been running due to the operator leaving the drivers door open.");
          }

          if (seatBeltStartHoursPercent > 0) {
            labels.push(`Seat Belt Start: ${seatBeltStartHoursPercent}%`);
            segmentLabels.push(`Seat Belt Start`);
            dataset.push(seatBeltStartHoursPercent);
            data.push(seatBeltStartHours);
            segmentDescriptions.push("Total hours that the engine has been running due to the operator buckling their seatbelt.");
          }

          if (brakeStartHoursPercent > 0) {
            labels.push(`Brake Start: ${brakeStartHoursPercent}%`);
            segmentLabels.push(`Brake Start`);
            dataset.push(brakeStartHoursPercent);
            data.push(brakeStartHours);
            segmentDescriptions.push("Total hours that the engine has been running due to the operator using the brake start feature.");
          }

          if (seatBeltOnlyHoursPercent > 0) {
            labels.push(`Seat Belt Only Start: ${seatBeltOnlyHoursPercent}%`);
            segmentLabels.push(`Seat Belt Only Start`);
            dataset.push(seatBeltOnlyHoursPercent);
            data.push(seatBeltOnlyHours);
            segmentDescriptions.push("Total hours that the engine has continued to run due to the seatbelt being buckled.");
          }

          if (antiTheftOnlyHoursPercent > 0) {
            labels.push(`Anti Theft Only Hours: ${antiTheftOnlyHoursPercent}%`);
            segmentLabels.push(`Anti Theft Only Hours`);
            dataset.push(antiTheftOnlyHoursPercent);
            data.push(antiTheftOnlyHours);
            segmentDescriptions.push(
              "Total hours that the engine has run due to the system being in 'Anti Theft' mode, when the 'Anti Theft Only' option is enabled."
            );
          }

          if (airPressureHoursPercent > 0) {
            labels.push(`Air Pressure Hours: ${airPressureHoursPercent}%`);
            segmentLabels.push(`Air Pressure Hours`);
            dataset.push(airPressureHoursPercent);
            data.push(airPressureHours);
            segmentDescriptions.push("Total hours that the engine has been running to build up air pressure.");
          }

          if (hydraulicHoursPercent > 0) {
            labels.push(`Hydraulic Hours: ${hydraulicHoursPercent}%`);
            segmentLabels.push(`Hydraulic Hours`);
            dataset.push(hydraulicHoursPercent);
            data.push(hydraulicHours);
            segmentDescriptions.push("TODO: No description");
          }

          if (initIdleTimeHighIdleHours > 0) {
            labels.push(`Init Idle Time in V2: ${initIdleTimeV2Percent}%`);
            segmentLabels.push(`Init Idle Time V2`);
            dataset.push(initIdleTimeHighIdlePercent);
            data.push(initIdleTimeHighIdleHours);
            segmentDescriptions.push("TODO: No description");
          }

          if (initIdleTimeV2Percent > 0) {
            labels.push(`Init Idle Time in High Idle: ${initIdleTimeV2Percent}%`);
            segmentLabels.push(`Init Idle Time in High Idle`);
            dataset.push(initIdleTimeV2Percent);
            data.push(initIdleTimeV2);
            segmentDescriptions.push("TODO: No description");
          }

          // Why is my vehicle idling
          info = {
            title: "Why is my vehicle still idling? (%)",
            description: "Displays the percentage of purpose for which the EPEQ® IM idles the vehicle.",
            chartType: ChartType.pie,
            labels: labels,
            segmentLabels: segmentLabels,
            segmentDescriptions: segmentDescriptions,
            data: data,
            dataset: dataset,
            colors: [
              /*
              COLORS.red,
              COLORS.blue,
              COLORS.green,
              COLORS.yellow,
              COLORS.orange,
              COLORS.purple,
              COLORS.lightBlue,
              COLORS.otherBlue,
              COLORS.lightGreen,
              COLORS.darkGreen,
              COLORS.lighterGreen,
              COLORS.lightOrange,
              COLORS.brown,
              COLORS.otherBrown,
              COLORS.lightBlue,
              COLORS.lightOrange,
              COLORS.lighterGreen,
              COLORS.lightGreen,
              */
              // taken from source files on original site with changes
              "#218C56",
              "#AC042C",
              "#F49542",
              "#6841E0",
              "#800080",
              "#98FB98",
              "#00F19A",
              "#90EE90",
              "#32CD32",
              "#6B8E23",
              "#000080",
              "#1E90FF",
              "#00BFFF",
              "#B0E0E6",
              "#CD853F",
              "#D2691E",
              "#F4A460",
              "#000000",
              "#73879C",
              "#BAFFBB",
              "#FFB0B0",
              "#D4D4D4",
              "#FF8678",
            ],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `${this.formatNumberWithCommas(info?.data[context.dataIndex])} Hours`;
              return [firstLabel, secondLabel];
            },
            hasData: data.length > 0,
          };
          break;
        }
        case "idleReduced": {
          // How much idling have I reduced when the vehicle is in park?
          let total = monitorHours + parkAndNeutral;

          let hasData = true;
          // If there is no data, set the total to 1 so all the segments show as 0%
          if (total == 0) {
            total = 1;
            hasData = false;
          }

          // Calculate monitor and parkneutral as percentages
          let monitorPercent = Math.round((monitorHours / total) * 100);
          let parkAndNeutralPercent = 100 - monitorPercent;
          info = {
            title: "How much idling have I reduced when the vehicle is in park? (%)",
            description: "Displays the actual percentage of idling and the percentage of idling saved using EPEQ® IM.",
            chartType: ChartType.pie,
            labels: [`Your Savings: ${monitorPercent}%`, `Actual Idling: ${parkAndNeutralPercent}%`],
            segmentLabels: ["Your Savings", "Actual Idling"],
            segmentDescriptions: ["Amount of idle saved using the EPEQ® IM system.", "Actual Idling:	Actual amount of idling using the EPEQ® IM system."],
            data: [monitorHours, parkAndNeutral],
            dataset: [monitorPercent, parkAndNeutralPercent],
            colors: [COLORS.green, COLORS.red],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `${this.formatNumberWithCommas(info?.dataset[context.dataIndex])}%`;
              return [firstLabel, secondLabel];
            },
            hasData,
          };
          break;
        }
        case "engineOffPercent": {
          // How much time was the engine off where I didn't require climate?
          let total = airConditionEngineOff + heatingHoursEngineOff + monitoringWithoutClimate;

          let hasData = true;
          // If there is no data, set the total to 1 so all the segments show as 0%
          if (total == 0) {
            total = 1;
            hasData = false;
          }

          let airConditionEngineOffPercent = Math.round((airConditionEngineOff / total) * 100 * 10) / 10;
          let heatingHoursEngineOffPercent = Math.round((heatingHoursEngineOff / total) * 100 * 10) / 10;
          let monitoringWithoutClimatePercent = Math.round((monitoringWithoutClimate / total) * 100 * 10) / 10;

          info = {
            title: "Engine-Off Monitoring Analysis (%)",
            description: "How much time was the engine off where I didn't require climate?",
            chartType: ChartType.pie,
            labels: [
              `Cooling w/ Enginge Off: ${airConditionEngineOffPercent}%`,
              `Heating w/ Enginge Off: ${heatingHoursEngineOffPercent}%`,
              `Monitoring w/o Climate: ${monitoringWithoutClimatePercent}%`,
            ],
            segmentLabels: [`Cooling w/ Enginge Off`, `Heating w/ Enginge Off`, `Monitoring w/o Climate`],
            segmentDescriptions: [
              "Percentage of time the vehicle is cooling without idling.",
              "Percentage of time the vehicle is heating without idling.",
              "Percentage of time the engine is off and the EPEQ® IM is monitoring without climate.",
            ],
            data: [airConditionEngineOff, heatingHoursEngineOff, monitoringWithoutClimate],
            dataset: [airConditionEngineOffPercent, heatingHoursEngineOffPercent, monitoringWithoutClimatePercent],
            colors: [COLORS.darkBlue, COLORS.orange, COLORS.purple],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `${this.formatNumberWithCommas(info?.data[context.dataIndex])} Hours`;
              return [firstLabel, secondLabel];
            },
            hasData,
          };
          break;
        }
        case "engineOffTime":
          {
            // How much time was the engine off where I didn't require climate?
            let cooling = this.roundTo2Decimal(airConditionEngineOff);
            let heating = this.roundTo2Decimal(heatingHoursEngineOff);
            let withoutClimate = this.roundTo2Decimal(monitoringWithoutClimate);
            info = {
              title: "Engine-Off Monitoring Analysis (Hours)",
              description: "How much time did I require while the engine was off?",
              chartType: ChartType.bar,
              labels: ["Cooling w/ Enginge Off", "Heating w/ Enginge Off", "Monitoring w/o Climate"],
              segmentLabels: ["Cooling w/ Enginge Off", "Heating w/ Enginge Off", "Monitoring w/o Climate"],
              segmentDescriptions: [
                "Number of hours the vehicle is cooling without idling.",
                "Number of hours the vehicle is heating without idling.",
                "Number of hours the engine is off and the EPEQ® IM is monitoring without climate.",
              ],
              data: [cooling, heating, withoutClimate],
              dataset: [cooling, heating, withoutClimate],
              colors: [COLORS.darkBlue, COLORS.orange, COLORS.purple],
              callback: (context) => {
                var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
                var secondLabel = `Hours: ${this.formatNumberWithCommas(info?.data[context.dataIndex])}`;
                return [firstLabel, secondLabel];
              },
              hasData: cooling != 0 || heating != 0 || withoutClimate != 0,
            };
          }
          break;
        // // Emissions
        case "co2": {
          // How much CO2 have I reduced?

          // What was my Fuel savings by volume?
          let savings = 0;
          let consumed = 0;
          let totalWithoutGrip = 0;

          for (let i = 0; i < this.selectedMachines.length; i++) {
            let machine = this.selectedMachines[i];

            let vehicle = this.vehiclesFiltered[i];
            if (this.selectedVehiclesFiltered.length > 0) {
              vehicle = this.selectedVehiclesFiltered[i];
            }

            //let fuelConsumpion = vehicle.fuelConsumption ?? calculateFuelConsumption(vehicle.engineDisplacement ?? -1) ?? 1;
            let monitorHours = this.getNumericMeasurement("valdef.MonitorHours", machine);
            let consumeHours = this.getNumericMeasurement("valdef.ParkandNeutralHours", machine);

            let fuelConsumption = 0;
            let emissionRate = 0;

            switch (this.userFuelUnits) {
              case FuelUnit.Litres:
                fuelConsumption = vehicle.fuelConsumption ?? calculateFuelConsumption(vehicle.engineDisplacement ?? -1) ?? 1;
                emissionRate = calculateEmissions(vehicle.fuelType ?? "");
                break;
              case FuelUnit.Gallons:
                fuelConsumption =
                  convertFuelFromLitres(vehicle.fuelConsumption ?? calculateFuelConsumption(vehicle.engineDisplacement ?? -1), FuelUnit.Gallons) ?? 1;
                emissionRate = calculateEmissionsGallon(vehicle.fuelType ?? "");
                break;
            }

            let vehicleSavings = monitorHours * fuelConsumption * emissionRate;
            let vehicleConsumed = consumeHours * fuelConsumption * emissionRate;

            savings += vehicleSavings;
            consumed += vehicleConsumed;
            totalWithoutGrip += vehicleSavings + vehicleConsumed;
          }

          savings = Math.round(savings * 100) / 100;
          consumed = Math.round(consumed * 100) / 100;
          totalWithoutGrip = Math.round(totalWithoutGrip * 100) / 100;

          savings = this.roundTo2Decimal(savings);
          consumed = this.roundTo2Decimal(consumed);
          totalWithoutGrip = this.roundTo2Decimal(totalWithoutGrip);

          let units = this.userFuelUnits == FuelUnit.Gallons ? "Pounds" : "Kilograms";

          info = {
            title: `How much CO₂ have I reduced? (${units})`,
            description: "Displays the actual amount of CO₂ emmissions, amount saved using EPEQ® IM, and total without EPEQ® IM installed.",
            chartType: ChartType.bar,
            labels: ["Your Savings", "Actual CO₂ Emissions", "Total without EPEQ® IM"],
            data: [savings, consumed, totalWithoutGrip],
            dataset: [savings, consumed, totalWithoutGrip],
            segmentDescriptions: [
              "Amount of CO₂ saved at idle using the EPEQ® IM system.",
              "Actual amount of CO₂ emissions at idle with the EPEQ® IM system.",
              "Amount of CO₂ emissions that would have occurred at idle without the EPEQ® IM.",
            ],
            additionalChartInfo: `CO₂ Multipliers used from:
            <a href="https://www.epa.gov/sites/production/files/2015-07/documents/emission-factors_2014.pdf" target="_blank">
              https://www.epa.gov/sites/production/files/2015-07/documents/emission-factors_2014.pdf
            </a>`,
            segmentLabels: ["Your Savings", "Actual CO₂ Emissions", "Total without EPEQ® IM"],
            colors: [COLORS.green, COLORS.red, COLORS.blue],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `${this.formatNumberWithCommas(info?.data[context.dataIndex])} ${units}`;
              return [firstLabel, secondLabel];
            },
            hasData: savings != 0 || consumed != 0 || totalWithoutGrip != 0,
          };
          break;
        }
        // Fuel Reduction
        case "fuelSavingsDollars": {
          // What was my Fuel savings in Dollars?
          // What was my Fuel savings by volume?
          let savings = 0;
          let consumed = 0;
          let totalWithoutGrip = 0;

          let defaultFuelCost = 3; // TODO: The fuel cost to use if the user hasn't provided a value

          for (let i = 0; i < this.selectedMachines.length; i++) {
            let machine = this.selectedMachines[i];

            let vehicle = this.vehiclesFiltered[i];
            if (this.selectedVehiclesFiltered.length > 0) {
              vehicle = this.selectedVehiclesFiltered[i];
            }

            let fuelConsumpion = convertFuelFromLitres(vehicle.fuelConsumption ?? calculateFuelConsumption(vehicle.engineDisplacement ?? -1)) ?? 1;
            savings += this.getNumericMeasurement("valdef.MonitorHours", machine) * fuelConsumpion * (vehicle.fuelCost ?? defaultFuelCost);
            consumed += this.getNumericMeasurement("valdef.ParkandNeutralHours", machine) * fuelConsumpion * (vehicle.fuelCost ?? defaultFuelCost);
          }

          totalWithoutGrip += savings + consumed;

          savings = Math.round(savings * 100) / 100;
          consumed = Math.round(consumed * 100) / 100;
          totalWithoutGrip = Math.round(totalWithoutGrip * 100) / 100;

          savings = this.roundTo2Decimal(savings);
          consumed = this.roundTo2Decimal(consumed);
          totalWithoutGrip = this.roundTo2Decimal(totalWithoutGrip);
          info = {
            title: "What was my fuel savings? (Dollars)",
            description: "Displays the actual fuel cost, amount saved using EPEQ® IM, and total cost without EPEQ® IM installed.",
            chartType: ChartType.bar,
            labels: ["Your Savings", "Amount Spent", "Total without EPEQ® IM"],
            data: [savings, consumed, totalWithoutGrip],
            dataset: [savings, consumed, totalWithoutGrip],
            segmentLabels: ["Your Savings", "Amount Spent", "Total without EPEQ® IM"],
            segmentDescriptions: [
              "Amount of fuel cost saved at idle using the EPEQ® IM system.",
              "Actual fuel cost at idle using the EPEQ® IM system.",
              "Amount that would have been spent on fuel at idle without the EPEQ® IM installed.",
            ],
            colors: [COLORS.green, COLORS.red, COLORS.blue],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `Dollars ($): ${this.formatNumberWithCommas(info?.data[context.dataIndex])}`;
              return [firstLabel, secondLabel];
            },
            hasData: savings != 0 || consumed != 0 || totalWithoutGrip != 0,
          };
          break;
        }
        case "fuelConsumption": {
          // What was my Fuel savings by volume?
          let savings = 0;
          let consumed = 0;
          let totalWithoutGrip = 0;

          for (let i = 0; i < this.selectedMachines.length; i++) {
            let machine = this.selectedMachines[i];

            let vehicle = this.vehiclesFiltered[i];
            if (this.selectedVehiclesFiltered.length > 0) {
              vehicle = this.selectedVehiclesFiltered[i];
            }

            let fuelConsumpion = convertFuelFromLitres(vehicle.fuelConsumption ?? calculateFuelConsumption(vehicle.engineDisplacement ?? -1)) ?? 1;

            savings += this.getNumericMeasurement("valdef.MonitorHours", machine) * fuelConsumpion;
            consumed += this.getNumericMeasurement("valdef.ParkandNeutralHours", machine) * fuelConsumpion;
          }

          totalWithoutGrip += savings + consumed;

          savings = Math.round(savings * 100) / 100;
          consumed = Math.round(consumed * 100) / 100;
          totalWithoutGrip = Math.round(totalWithoutGrip * 100) / 100;

          savings = this.roundTo2Decimal(savings);
          consumed = this.roundTo2Decimal(consumed);
          totalWithoutGrip = this.roundTo2Decimal(totalWithoutGrip);

          info = {
            title: `What was my fuel savings by volume? (${this.userFuelUnitsDisplay})`,
            description: "Displays the actual amount of fuel consumed, amount saved using EPEQ® IM, and total without EPEQ® IM installed.",
            chartType: ChartType.bar,
            labels: ["Your Savings", "Amount Consumed", "Total without EPEQ® IM"],
            data: [savings, consumed, totalWithoutGrip],
            dataset: [savings, consumed, totalWithoutGrip],
            segmentLabels: ["Your Savings", "Amount Consumed", "Total without EPEQ® IM"],
            segmentDescriptions: [
              "Amount of fuel saved at idle using the EPEQ® IM system.",
              "Actual amount of fuel consumed at idle with the EPEQ® IM system.",
              "Amount of fuel that would have been consumed at idle without the EPEQ® IM installed.",
            ],
            colors: [COLORS.green, COLORS.red, COLORS.blue],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `${this.userFuelUnitsDisplay}: ${this.formatNumberWithCommas(info?.data[context.dataIndex])}`;
              return [firstLabel, secondLabel];
            },
            hasData: savings != 0 || consumed != 0 || totalWithoutGrip != 0,
          };
          break;
        }
        // Maintenance
        case "engineWear": {
          let multiplier = this.userDistanceUnits == DistanceUnit.Kilometers ? 53.1 : 33; // Wear is 53.1 kilometers per hour or 33 miles per hour
          let monitorWearKM = monitorHours * multiplier;
          let parkAndNeutralWearKM = parkAndNeutral * multiplier;
          let savings = Math.round(monitorWearKM * 100) / 100; // Round to 2 decimal places
          let actual = Math.round(parkAndNeutralWearKM * 100) / 100; // Round to 2 decimal places
          let totalKM = (parkAndNeutral + monitorHours) * multiplier;
          let total = Math.round(totalKM * 100) / 100; // Round to 2 decimal places

          savings = this.roundTo2Decimal(savings);
          actual = this.roundTo2Decimal(actual);
          total = this.roundTo2Decimal(total);

          // How much engine wear have I removed?
          info = {
            title: `How much engine wear have I removed? (${this.userDistanceUnitsDisplay})`,
            description: "Displays the actual amount of engine wear, amount saved using EPEQ® IM, and total without EPEQ® IM installed.",
            chartType: ChartType.bar,
            labels: ["Your Savings", "Actual Engine Wear", "Total without EPEQ® IM"],
            segmentLabels: ["Your Savings", "Actual Engine Wear", "Total without EPEQ® IM"],
            segmentDescriptions: [
              "Amount of engine wear saved at idle using the EPEQ® IM system.",
              "Amount of engine wear at idle using the EPEQ® IM system.",
              "Amount of engine wear that would have occurred at idle without the EPEQ® IM installed.",
            ],
            additionalChartInfo: "Calculated based on engine wear of 33 miles or 53.1 kilometers per hour of idling.",
            data: [savings, actual, total],
            dataset: [savings, actual, total],
            colors: [COLORS.green, COLORS.red, COLORS.blue],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `${this.userDistanceUnitsDisplay}: ${this.formatNumberWithCommas(info?.data[context.dataIndex])}`;
              return [firstLabel, secondLabel];
            },
            hasData: savings != 0 || actual != 0 || total != 0,
          };
          break;
        }
        case "maintenanceCost": {
          let multiplier = this.userDistanceUnits == DistanceUnit.Kilometers ? 53.1 : 33; // Wear is 53.1 kilometers per hour or 33 miles per hour
          // let savings = Math.round(monitorWearKM * 100) / 100; // Round to 2 decimal places
          // let actual = Math.round(parkAndNeutralWearKM * 100) / 100; // Round to 2 decimal places
          // let totalKM = (parkAndNeutral + monitorHours) * multiplier;
          // let total = Math.round(totalKM * 100) / 100; // Round to 2 decimal places

          let savings = 0;
          let actual = 0;
          let total = 0;

          for (let i = 0; i < this.selectedMachines.length; i++) {
            let machine = this.selectedMachines[i];

            let vehicle = this.vehiclesFiltered[i];
            if (this.selectedVehiclesFiltered.length > 0) {
              vehicle = this.selectedVehiclesFiltered[i];
            }

            let gvwr = parseGVWRClass(vehicle.gvwrClass);

            let monitorWearKM = this.getNumericMeasurement("valdef.MonitorHours", machine) * multiplier;
            let parkAndNeutralWearKM = this.getNumericMeasurement("valdef.ParkandNeutralHours", machine) * multiplier;

            let maintenanceCost = calculateMaintenanceCost(gvwr, this.userCurrency);
            savings += Math.round(monitorWearKM * maintenanceCost * 100) / 100;
            actual += Math.round(parkAndNeutralWearKM * maintenanceCost * 100) / 100; // Round to 2 decimal places
          }

          total += savings + actual;

          savings = this.roundTo2Decimal(savings);
          actual = this.roundTo2Decimal(actual);
          total = this.roundTo2Decimal(total);

          // How much engine wear have I removed?
          info = {
            title: `Maintenance Costs (Dollars)`,
            description: "Displays the actual amount of engine wear, amount saved using EPEQ® IM, and total without EPEQ® IM installed.",
            chartType: ChartType.bar,
            labels: ["Your Savings", "Actual Engine Wear", "Total without EPEQ® IM"],
            segmentLabels: ["Your Savings", "Actual Engine Wear", "Total without EPEQ® IM"],
            segmentDescriptions: [
              "Amount of engine wear saved at idle using the EPEQ® IM system.",
              "Amount of engine wear at idle using the EPEQ® IM system.",
              "Amount of engine wear that would have occurred at idle without the EPEQ® IM installed.",
            ],
            additionalChartInfo: "Calculated based on engine wear of 33 miles or 53.1 kilometers per hour of idling.",
            data: [savings, actual, total],
            dataset: [savings, actual, total],
            colors: [COLORS.green, COLORS.red, COLORS.blue],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `$: ${this.formatNumberWithCommas(info?.data[context.dataIndex])}`;
              return [firstLabel, secondLabel];
            },
            hasData: savings != 0 || actual != 0 || total != 0,
          };
          break;
        }
        // Job Specific Details
        case "throttleOverride": {
          let total = parkAndNeutral + throttleOverrideHours;

          let hasData = true;
          // If there is no data, set the total to 1 so all the segments show as 0%
          if (total == 0) {
            total = 1;
            hasData = false;
          }

          // Calculate monitor and parkneutral as percentages
          let parkAndNeutralPercent = Math.round((parkAndNeutral / total) * 100);
          let throttlePercent = 100 - parkAndNeutralPercent;

          info = {
            columns: 12,
            title: "Operator Contribution To Idle (Hours)",
            description: "Displays the operators contribution to increased idling by overriding the EPEQ® IM system shutdown.",
            chartType: ChartType.pie,
            labels: [
              `Park and Neutral Hours - Actual Idle Time Without Throttle Override: ${parkAndNeutralPercent}%`,
              `Throttle Override: Time accumulated that the operator has used the throttle override function: ${throttlePercent}%`,
            ],
            segmentLabels: [
              "Park and Neutral Hours - Actual Idle Time Without Throttle Override",
              "Throttle Override: Time accumulated that the operator has used the throttle override function",
            ],
            segmentDescriptions: [
              "Park or Neutral, idle before shutdown, idle before placed into gear.",
              "Time accumulated that the operator has used the throttle override function.",
            ],
            data: [parkAndNeutral, throttleOverrideHours],
            dataset: [parkAndNeutralPercent, throttlePercent],
            colors: [COLORS.darkBlue, COLORS.purple],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `${this.formatNumberWithCommas(info?.data[context.dataIndex])} Hours`;
              return [firstLabel, secondLabel];
            },
            hasData,
          };
          break;
        }
        case "vehicleStart":
          info = {
            columns: 6,
            title: "Vehicle Start Comparison (Count)",
            description: "The number of times the vehicle has been started by opening the door, fastening the seatbelt or pressing the brake",
            chartType: ChartType.bar,
            labels: ["Door Start", "Seat Belt Start", "Brake Start"],
            segmentLabels: ["Door Start", "Seat Belt Start", "Brake Start"],
            segmentDescriptions: [
              "Number of times that the engine has started using the 'Door Start' feature.",
              "Number of times that the engine has started using the 'Seat Belt Start' feature.",
              "Total number of times the driver has started the vehicle by pressing the brake pedal",
            ],
            data: [doorStartCount, seatBeltStartCount, brakeStartCount],
            dataset: [doorStartCount, seatBeltStartCount, brakeStartCount],
            colors: [COLORS.darkBlue, COLORS.orange, COLORS.purple],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `Count: ${info?.data[context.dataIndex]}`;
              return [firstLabel, secondLabel];
            },
            hasData: doorStartCount != 0 || seatBeltStartCount != 0 || brakeStartCount != 0,
          };
          break;
        case "shorePowerHours":
          info = {
            title: "Shore Power Analysis (Hours)",
            description: "Hours the vehicle is heating, cooling, or neither while in shore power",
            chartType: ChartType.bar,
            labels: ["Cooling w/ Shore Power", "Heating w/ Shore Power", "Shore Power w/o Climate"],
            segmentLabels: ["Cooling w/ Shore Power", "Heating w/ Shore Power", "Shore Power w/o Climate"],
            segmentDescriptions: [
              "Number of hours the vehicle is cooling in shore power.",
              "Number of hours the vehicle is heating in shore power.",
              "Number of hours in shore power without climate.",
            ],
            data: [airConditioningShorePower, heatingShorePower, shorePowerHours],
            dataset: [airConditioningShorePower, heatingShorePower, shorePowerHours],
            colors: [COLORS.darkBlue, COLORS.orange, COLORS.purple],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `${this.formatNumberWithCommas(info?.data[context.dataIndex])}`;
              return [firstLabel, secondLabel];
            },
            hasData: airConditioningShorePower != 0 || heatingShorePower != 0 || shorePowerHours != 0,
          };
          break;
        case "shorePowerPercent": {
          let total = airConditioningShorePower + heatingShorePower + shorePowerHours;

          // If there is no data, set the total to 1 so all the segments show as 0%
          if (total == 0) {
            total = 1;
          }

          let airConditioningShorePowerPercent = Math.round((airConditioningShorePower / total) * 100 * 10) / 10;
          let heatingShorePowerPercent = Math.round((heatingShorePower / total) * 100 * 10) / 10;
          let shorePowerHoursPercent = Math.round((shorePowerHours / total) * 100 * 10) / 10;

          info = {
            title: "Shore Power Analysis (%)",
            description: "Amount of time the vehicle is heating, cooling, or neither while in shore power",
            chartType: ChartType.bar,
            labels: [
              `Cooling w/ Shore Power: ${airConditioningShorePowerPercent}%`,
              `Heating w/ Shore Power: ${heatingShorePowerPercent}%`,
              `Shore Power w/o Climate: ${shorePowerHoursPercent}%`,
            ],
            segmentLabels: [`Cooling w/ Shore Power`, `Heating w/ Shore Power`, `Shore Power w/o Climate`],
            segmentDescriptions: [
              "Percentage of time the vehicle is cooling in shore power.",
              "Percentage of time the vehicle is heating in shore power.",
              "Percentage of time in shore power without climate.",
            ],
            data: [airConditioningShorePower, heatingShorePower, shorePowerHours],
            dataset: [airConditioningShorePowerPercent, heatingShorePowerPercent, shorePowerHoursPercent],
            colors: [COLORS.darkBlue, COLORS.orange, COLORS.purple],
            callback: (context) => {
              var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
              var secondLabel = `${this.formatNumberWithCommas(info?.data[context.dataIndex])} Hours`;
              return [firstLabel, secondLabel];
            },
            hasData: airConditioningShorePower != 0 || heatingShorePower != 0 || shorePowerHours != 0,
          };
          break;
        }
        case "jobAdditionalData":
          {
            let parkBrake = this.roundTo2Decimal(parkingBrakeEngagedInDrive);
            let brakePedal = this.roundTo2Decimal(brakePedalEngagedInDrive);
            let antiTheft = this.roundTo2Decimal(antiTheftHours);
            let schedulerHeating = this.roundTo2Decimal(schedulerHeatingHours);
            info = {
              columns: 6,
              title: "Additional Data (Hours)",
              description: "The number of hours for which specific behaviours occurred",
              chartType: ChartType.bar,
              labels: ["Parking Brake Engaged in Drive", "Brake Pedal Engaged in Drive", "Anti-Theft", "Scheduler Heating"],
              segmentLabels: ["Parking Brake Engaged in Drive", "Brake Pedal Engaged in Drive", "Anti-Theft", "Scheduler Heating"],
              segmentDescriptions: [
                "Number of hours that the parking break was engaged in drive.",
                "Number of hours that the break pedal was engaged in drive.",
                "Number of hours that the 'Anti-Theft' feature was active.",
                "Number of hours that the engine was running for the scheduler.",
              ],
              data: [parkBrake, brakePedal, antiTheft, schedulerHeating],
              dataset: [parkBrake, brakePedal, antiTheft, schedulerHeating],
              colors: [COLORS.darkBlue, COLORS.orange, COLORS.purple, COLORS.yellow],
              callback: (context) => {
                var firstLabel = `${info?.segmentDescriptions[context.dataIndex]}`;
                var secondLabel = `Hours: ${this.formatNumberWithCommas(info?.data[context.dataIndex])}`;
                return [firstLabel, secondLabel];
              },
              hasData: parkBrake != 0 || brakePedal != 0 || antiTheft != 0 || schedulerHeating != 0,
            };
          }
          break;
      }

      if (info) {
        const data = {
          labels: info.labels,
          datasets: [
            {
              data: info.dataset,
              backgroundColor: info.colors,
            },
          ],
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let config: any = {
          meta: {
            columns: info.columns ?? 6,
            title: info.title,
            description: info.description,
            segmentDescriptions: info.segmentDescriptions,
            segmentLabels: info.segmentLabels,
            additionalInfo: info.additionalChartInfo,
          },
          data: {
            id: key,
            type: info.chartType,
            hasData: info.hasData,
            data: data,
            options: {
              plugins: {
                legend: {
                  position: "right",
                },
                tooltip: {
                  callbacks: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    label: function(context: any) {
                      return info?.callback(context);
                    },
                    /**
                     * Removes the title from the tooltip
                     */
                    title: function() {
                      return "";
                    },
                  },
                },
              },
            },
          },
        };

        if (info.chartType == ChartType.bar) {
          config.data.options.plugins.legend = {
            ...config.data.options.plugins.legend,
            display: false,
          };
        }

        return config;
      }
    },
    displayedChartCards(charts: any[], section: string) {
      return charts.filter((chart) => {
        if (section == "jobSpecificDetails") {
          return chart.data.hasData;
        }
        return true;
      });
    },
  },
  computed: {
    ...mapGetters([
      "userFuelUnits",
      "userFuelUnitsDisplay",
      "userDistanceUnitsDisplay",
      "userDistanceUnits",
      "userCurrency",
      "selectedVehicles",
      "isGripAdmin",
    ]),
    selectedMachines(): GetMachineDto[] {
      // If the user has selected no vehicles, or all vehicles, return all machines
      if (this.selectedVehicles.length == 0 || this.selectedVehiclesFiltered.length == this.vehiclesFiltered.length) {
        return this.machines;
      }

      let allVins = this.selectedVehiclesFiltered.map((e: Vehicle) => e.machineId);

      return this.machines.filter((e) => allVins.includes(e.machine.id));
    },
    vehiclesFiltered(): Vehicle[] {
      // Contains all of the vehicles the user has access to
      return this.allVehicles.filter((e) => e.machineId != null);
    },
    selectedVehiclesFiltered(): Vehicle[] {
      // Contains all the selected vehicles that have a machine id
      return this.selectedVehicles.filter((e: Vehicle) => e.machineId != null);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allData(): any {
      return [
        {
          sectionId: "overview",
          title: "Overview",
          charts: [
            this.getChart("operationOverview"), // How much time does my vehicle idle
            this.getChart("whyIdle"), // Why is my vehicle still idling
            this.getChart("idleReduced"), // How much idling have I reduced when the vehicle is in park
            this.getChart("engineOffPercent"), // How much time was the engine off where I didn't require climate
            this.getChart("engineOffTime"), // How much time did I require climate while the engine was off
            this.getChart("howMuchIdle"), // What was my reduction of idle in hours
          ],
        },
        {
          sectionId: "emissions",
          title: "Emissions",
          charts: [
            this.getChart("co2"), // How much CO2 have I reduced
          ],
        },
        {
          sectionId: "fuelReduction",
          title: "Fuel Reduction",
          charts: [
            this.getChart("fuelSavingsDollars"), // What was my fuel savings in dollars
            this.getChart("fuelConsumption"), // What was my fuel savings by volume
          ],
        },
        {
          sectionId: "maintenance",
          title: "Maintenance",
          charts: [
            this.getChart("engineWear"), // How much engine wear have I removed
            this.getChart("maintenanceCost"), // Maintenance costs
          ],
        },
        {
          sectionId: "jobSpecificDetails",
          title: "Job Specific Details",
          charts: [
            this.getChart("throttleOverride"), // Operator contribution to idle
            this.getChart("shorePowerHours"), // Shore power analysis hours
            this.getChart("shorePowerPercent"), // Shore power analysis percent
            this.getChart("vehicleStart"), // Vehicle start comparison
            this.getChart("jobAdditionalData"), // Additional data hours
          ],
        },
      ];
    },
  },
});
