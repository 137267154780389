<template>
  <div>
    <span>Not authorized to view this portal</span>
    <v-btn @click="logout">Logout</v-btn>
  </div>
</template>

<script>
import Vue from "vue";
import { mgr } from "@/helpers/AuthService";

export default Vue.extend({
  name: "Unauthorized",
  data: () => ({}),
  methods: {
    logout() {
      mgr.signoutRedirect();
    },
  },
  computed: {},
});
</script>
