import { InputType, listBoolParameter, listParameter, numberParameter, temperatureParameter } from "../OptionParameter";

import { enabledUnits } from "@/model/VehicleProfile/Lists";

export enum HeatingSchedulerOption {
  FleetScheduler = "Fleet Scheduler",
  SevenDayScheduler = "Seven Day Scheduler",
}

export interface AuxHeatingOptions {
  auxHeaterInitialRun: listBoolParameter;
  manualAuxiliaryHeater: listBoolParameter;
  heatingSchedulerRequired: listBoolParameter;
}

export const defaultAuxHeatingOptions: AuxHeatingOptions = {
  auxHeaterInitialRun: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Auxiliary Heater Initial Run Option",
    info:
      "If a fuel fire heater, that is operated by the EPEQ® IM System is equipped on the vehicle, this option can be enabled. Once enabled, the system will run the fuel fire heater whenever the engine is initially started, and the coolant temperature is less than 65°C (149°F). The heater will continue to run until the coolant has reached an operating temperature of 70°C (158°F). NOTE: The heater will continue to operate even while the vehicle is not stationary until the coolant temperature has reached the operating temperature (default disabled).",
  },
  manualAuxiliaryHeater: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Manual Auxiliary Heater Option",
    info: "This setting will enable the operator to access the manual heater button (default is OFF).",
  },
  heatingSchedulerRequired: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: [
      {
        display: "Yes",
        value: true,
      },
      {
        display: "No",
        value: false,
      },
    ],
    display: "Scheduler Required",
    info:
      "There are two parts to the scheduler, the ‘Fleet Scheduler’ and the ‘Seven Day Scheduler’. Both are used to turn on the auxiliary heater at there scheduled times. This is used to pre-heat the vehicle when the auxiliary heater is installed and controlled by the EPEQ® IM system. ",
  },
};
