import { InputType, listParameter, listBoolParameter, temperatureParameter } from "../OptionParameter";

export interface ClimateSettingOptions {
  celsiusOrFahrenheit: listParameter;
  climateControlEnable: listBoolParameter;
  temperatureSetPointMinimum: temperatureParameter;
  temperatureSetPointMaximum: temperatureParameter;
}

export const defaultClimateSettingOptions: ClimateSettingOptions = {
  celsiusOrFahrenheit: {
    type: InputType.list,
    value: "c",
    defaultValue: "c",
    display: "Celsius/Fahrenheit",
    list: [
      {
        display: "C",
        value: "c",
      },
      {
        display: "F",
        value: "f",
      },
    ],
    info: "This setting will set the screen preference for temperature (default is °C)",
  },
  climateControlEnable: {
    type: InputType.list,
    value: true,
    defaultValue: true,
    display: "Climate Control Enable",
    list: [
      {
        display: "On",
        value: true,
      },
      {
        display: "Off",
        value: false,
      },
    ],
    info:
      "This setting enables the operator to have control over the ‘Temperature Set Point’. In some cases, the temperature is best to be fixed such as heating or cooling products (default is ON).",
  },
  temperatureSetPointMinimum: {
    type: InputType.temperature,
    value: 18,
    defaultValue: 18,
    display: "Temperature Set Point Minimum",
    info: "This setting sets the lower limit to which the operator will be allowed to adjust the temperature (default is 18°C/64°F)",
    rules: ["required"],
    minValue: 5,
    maxValue: 20,
  },
  temperatureSetPointMaximum: {
    type: InputType.temperature,
    value: 35,
    defaultValue: 35,
    display: "Temperature Set Point Maximum",
    info: "This sets the upper limit to which the operator will be allowed to adjust the temperature. (default is 35°C/95°F).",
    rules: ["required"],
    minValue: 21,
    maxValue: 35,
  },
};
