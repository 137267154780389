import { DistanceUnit, FuelUnit, TemperatureUnit, MassUnit } from "@/model/Units";
import store from "@/store";

export const temperatureDisplay = (temperatureUnit: TemperatureUnit): string => {
  switch (temperatureUnit) {
    case TemperatureUnit.Celsius:
      return "Celsius";
    case TemperatureUnit.Fahrenheit:
      return "Fahrenheit";
  }
};

export const distanceDisplay = (distanceUnit: DistanceUnit): string => {
  switch (distanceUnit) {
    case DistanceUnit.Kilometers:
      return "Kilometers";
    case DistanceUnit.Miles:
      return "Miles";
  }
};

export const fuelDisplay = (fuelUnit: FuelUnit): string => {
  switch (fuelUnit) {
    case FuelUnit.Gallons:
      return "Gallons";
    case FuelUnit.Litres:
      return "Litres";
  }
};

export const convertTemperatureFromCelsius = (
  value: number,
  convertingTo: TemperatureUnit = store?.getters.userTemperatureUnits as TemperatureUnit
): number => {
  switch (convertingTo) {
    case TemperatureUnit.Celsius:
      return value;
    case TemperatureUnit.Fahrenheit:
      return (value * 9) / 5 + 32;
  }
};

export const convertTemperatureToCelsius = (
  value: number,
  convertingTo: TemperatureUnit = store?.getters.userTemperatureUnits as TemperatureUnit
): number => {
  switch (convertingTo) {
    case TemperatureUnit.Celsius:
      return value;
    case TemperatureUnit.Fahrenheit:
      return ((value - 32) * 5) / 9;
  }
};

export const convertFuelFromLitres = (value: number | null, convertingTo: FuelUnit = store?.getters.userFuelUnits as FuelUnit): number | null => {
  if (value === null) {
    return null;
  }

  switch (convertingTo) {
    case FuelUnit.Litres:
      return value;
    case FuelUnit.Gallons:
      return value * 0.264172;
  }
};

export const convertFuelToLitres = (value: number | null, convertingTo: FuelUnit = store?.getters.userFuelUnits as FuelUnit): number | null => {
  if (value === null) {
    return null;
  }

  switch (convertingTo) {
    case FuelUnit.Litres:
      return value;
    case FuelUnit.Gallons:
      return value * 3.78541;
  }
};

export const convertDistanceFromKilometers = (value: number, convertingTo: DistanceUnit = store?.getters.userFuelUnits as DistanceUnit): number => {
  switch (convertingTo) {
    case DistanceUnit.Kilometers:
      return value;
    case DistanceUnit.Miles:
      return value / 1.60934;
  }
};

export const convertDistanceToKilometers = (value: number, convertingFrom: DistanceUnit = store?.getters.userDistanceUnits as DistanceUnit): number => {
  switch (convertingFrom) {
    case DistanceUnit.Kilometers:
      return value;
    case DistanceUnit.Miles:
      return value * 1.60934;
  }
};

export const convertMass = (value: number, convertingFrom: MassUnit, convertingTo: MassUnit): number => {
  switch (convertingFrom) {
    case MassUnit.Kilograms:
      switch (convertingTo) {
        case MassUnit.Pounds:
          return value * 2.204623;
      }
      break;
    case MassUnit.Pounds:
      switch (convertingTo) {
        case MassUnit.Kilograms:
          return value * 0.4535924;
      }
      break;
  }
  return value;
}
