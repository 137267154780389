import { InputType, numberParameter, listParameter, listBoolParameter } from "../OptionParameter";

export interface OperatorControlOptions {
  dimScreen: listBoolParameter;
  clockDisplayOnOff: listBoolParameter;
  clockDisplayHours: listParameter;
  daylightSavings: listBoolParameter;
  screenOnInDrive: listBoolParameter;
}

export const defaultOperatorControlOptions: OperatorControlOptions = {
  dimScreen: {
    type: InputType.list,
    value: true,
    defaultValue: true,
    display: "Dim Screen On/Off",
    list: [
      {
        display: "On",
        value: true,
      },
      {
        display: "Off",
        value: false,
      },
    ],
    info: "This setting allows the operator to change the screen backlighting (default is ON)",
  },
  clockDisplayOnOff: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    display: "Clock Display On/Off",
    list: [
      {
        display: "On",
        value: true,
      },
      {
        display: "Off",
        value: false,
      },
    ],
    info: "This setting allows the operator to display or hide the clock (default is OFF).",
  },
  clockDisplayHours: {
    type: InputType.list,
    value: "24",
    defaultValue: "24",
    display: "Clock Display 12/24 Hour",
    list: [
      {
        display: "12-hour",
        value: "12",
      },
      {
        display: "24-hour",
        value: "24",
      },
    ],
    info: "This setting allows the operator to set the clock for either 12-hour or 24-hour display (default is 24-Hour).",
  },
  daylightSavings: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    display: "Daylight Savings On/Off",
    list: [
      {
        display: "On",
        value: true,
      },
      {
        display: "Off",
        value: false,
      },
    ],
    info: "This setting will enable the adjustment for daylight savings time (default is OFF).",
  },
  screenOnInDrive: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    display: "Screen On In Drive",
    list: [
      {
        display: "On",
        value: true,
      },
      {
        display: "Off",
        value: false,
      },
    ],
    info:
      "This setting allows the operator screen to remain on when the vehicle is drive. This feature is used for application that require a secondary locations climate to be maintained.",
  },
};
