import { InputType, numberParameter, listBoolParameter, listNumberParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface AutomaticClimateControlOptions {
  automaticClimateControlEnabled: listBoolParameter;
  blowerInitialSpeed: listNumberParameter;
  dontShutBlowerOff: listBoolParameter;
  dontRampUpBlower: listBoolParameter;
  passengerSeatbelt: listBoolParameter;
}

export const defaultAutomaticClimateControlOptions: AutomaticClimateControlOptions = {
  automaticClimateControlEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Automatic Climate Control",
    info:
      "This Option is available for specific vehicles, to synchronize the vehicle climate systems settings with the EPEQ® IM settings and to maximize the efficiency of the HVAC operation of the EPEQ® IM. A module will need to be installed to the vehicles HVAC wiring system to autonomously control the vehicle’s climate system (default disabled).",
  },
  blowerInitialSpeed: {
    type: InputType.list,
    value: 4,
    defaultValue: 4,
    display: "Blower Initial Speed",
    info: "This is used to set the starting speed of the vehicle blower when the EPEQ® IM system runs for Heat/AC.",
    list: [
      {
        display: "1",
        value: 1,
      },
      {
        display: "2",
        value: 2,
      },
      {
        display: "3",
        value: 3,
      },
      {
        display: "4",
        value: 4,
      },
      {
        display: "5",
        value: 5,
      },
      {
        display: "6",
        value: 6,
      },
      {
        display: "7",
        value: 7,
      },
      {
        display: "8",
        value: 8,
      },
    ],
  },
  dontShutBlowerOff: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Don't Shut Blower Off",
    info: " This feature, if enabled keeps the vehicle's blower on even after a heating or cooling event has ended.",
  },
  dontRampUpBlower: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Don't Ramp Up Blower",
    info: "This feature if enabled, stop the EPEQ® IM system from increasing the blower speed.",
  },
  passengerSeatbelt: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Passenger Seatbelt Enable",
    info:
      "This feature if enabled and the passenger seat belt is buckled, will allow the EPEQ® IM “Auto Climate Control System” to adjust the blower speed according to the necessary heating or cooling requirements of the vehicle (default disabled).",
  },
};
