import { InputType, listBoolParameter, listNumberParameter } from "../OptionParameter";

import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface AntiTheftOptions {
  AntiTheftEnabled: listBoolParameter;
  AuxiliaryAntiTheftEnabled: listBoolParameter;
  AntiTheftOnlyEnabled: listBoolParameter;
  AntiTheftMaxIdleNoOccupants: listNumberParameter;
}

export const defaultAntiTheftOptions: AntiTheftOptions = {
  AntiTheftEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Anti Theft",
    info:
      "The anti-theft function allows the operator to remove the keys while still allowing the EPEQ® IM System to monitor the vehicle. Some vehicles require an anti-theft module to be programmed. If the kit is equipped with an anti-theft module, see Teach Key tab for instructions on the teaching module after the vehicle has been selected (default disabled).",
  },
  AuxiliaryAntiTheftEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Auxiliary Anti-Theft",
    info:
      "If the anti-theft enable/disable button is to be applied by an existing keypad or a secondary switch, the auxiliary anti-theft harness will need to be installed. The connection should be made to an analog input of the Configurable I/O and plugged into either X11–10 or X11-01. NOTE: The button on the keypad that controls the anti-theft feature can only be a momentary type button for this feature to work (default disabled).",
  },
  AntiTheftOnlyEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Anti-Theft Only",
    info:
      "The ‘Anti-Theft Option’ must be enabled to allow the use of the ‘Anti-Theft Only Option’. When this option is enabled, the EPEQ® IM System will only go into monitoring mode while anti-theft is applied or the perator shuts down the engine with the EPEQ® IM system shutdown button (default disabled).",
  },
  AntiTheftMaxIdleNoOccupants: {
    type: InputType.list,
    value: 6,
    defaultValue: 6,
    list: [
      {
        display: "",
        value: 0,
      },
      {
        display: "1 second",
        value: 1,
      },
      {
        display: "2 seconds",
        value: 2,
      },
      {
        display: "5 seconds",
        value: 3,
      },
      {
        display: "10 seconds",
        value: 4,
      },
      {
        display: "30 seconds",
        value: 5,
      },
      {
        display: "1 minute",
        value: 6,
      },
      {
        display: "2 minutes",
        value: 7,
      },
      {
        display: "3 minutes",
        value: 8,
      },
      {
        display: "4 minutes",
        value: 9,
      },
      {
        display: "5 minutes",
        value: 10,
      },
      {
        display: "6 minutes",
        value: 11,
      },
      {
        display: "7 minutes",
        value: 12,
      },
      {
        display: "8 minutes",
        value: 13,
      },
      {
        display: "9 minutes",
        value: 14,
      },
      {
        display: "10 minutes",
        value: 15,
      },
      {
        display: "15 minutes",
        value: 16,
      },
      {
        display: "20 minutes",
        value: 17,
      },
      {
        display: "25 minutes",
        value: 18,
      },
      {
        display: "30 minutes",
        value: 19,
      },
      {
        display: "35 minutes",
        value: 20,
      },
      {
        display: "40 minutes",
        value: 21,
      },
      {
        display: "45 minutes",
        value: 22,
      },
      {
        display: "50 minutes",
        value: 23,
      },
      {
        display: "55 minutes",
        value: 24,
      },
      {
        display: "60 minutes",
        value: 25,
      },
    ],
    display: "Max Idle No Occupants",
    info:
      "This is the amount of time the vehicle will run if the ‘Anti-Theft Option’ or the ‘Driver’s Door Start Option’ is enabled, before the EPEQ® IM System shuts the engine down. A 10 second to 1 minute is recommended; however, the maximum setting is 60 minutes. If the operator is away from the vehicle, this can increase the savings significantly by reducing the ide time before the engine shuts down. Adding more time than what is required will significantly reduce the savings (default is 1 minute).",
  },
};
