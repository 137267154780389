








import Vue from "vue";

import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default Vue.extend({
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    chartData: function({ data }) {
      if (this.chart && data) {
        this.chart.destroy();
        Vue.nextTick(() => {
          this.createChart();
        });
      }
    },
  },
  data() {
    return {
      chart: null as Chart | null,
    };
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      // Don't override any options that were already defined
      if (!this.chartData.options) {
        this.chartData.options = {};
      }

      if (!this.chartData.options.plugins) {
        this.chartData.options.plugins = {};
      }

      this.chartData.options = {
        ...this.chartData.options,
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 20,
        },
        tooltips: {
          callbacks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            label: function(tooltipItem: any, data: any) {
              return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
            },
          },
        },
        plugins: {
          ...this.chartData.options.plugins,

          legend: {
            ...this.chartData.options.plugins.legend,
            position: "bottom",
          },

          datalabels: {
            color: "white",
            display: "auto",
            clamp: true,
            labels: {
              title: {
                font: {
                  weight: "bold",
                },
              },
              value: {
                color: "white",
              },
            },
            formatter: function(value: any, context: any) {
              let rounded = Math.round((value + Number.EPSILON) * 100) / 100;
              return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      };

      if (this.chartData.type == "bar") {
        this.chartData.options.plugins.datalabels.align = "end";
        this.chartData.options.plugins.datalabels.anchor = "end";
        this.chartData.options.plugins.datalabels.offset = -5;
        this.chartData.options.plugins.datalabels.color = "#666";
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ctx: any = document.getElementById(`${this.chartData.id}`);

      const plugins = [ChartDataLabels];
      this.chartData.plugins = plugins;

      this.chart = new Chart(ctx, this.chartData);
    },
  },
});
