



































































































import { InputsOutputs } from "@/model/VehicleProfile/InputsOutputs/InputsOutputs";
import {
  inputParameter,
  AvailableOutputs,
  optionParameter,
  listItem,
  AvailableInputsPositive,
  AvailableInputsNegative,
  SignalType,
} from "@/model/VehicleProfile/OptionParameter";
import Vue from "vue";
import InfoModal from "@/components/InfoModal.vue";

import { mapGetters, mapActions } from "vuex";
import { StandardOptions } from "@/model/VehicleProfile/StandardOptions/StandardOptions";
import { HeatingOption } from "@/model/VehicleProfile/StandardOptions/HeatingOptions";
import { AirConditioningOption } from "@/model/VehicleProfile/StandardOptions/AirConditioning";
import { OptionalFeatures } from "@/model/VehicleProfile/OptionalFeatures/OptionalFeatures";
import { OptionalEquipment } from "@/model/VehicleProfile/OptionalEquipment/OptionalEquipment";
import { VehicleEquipment } from "@/model/VehicleProfile/VehicleEquipment";
import { GeneralInfo } from "@/model/VehicleProfile/GeneralInfo";
import { VehicleTransmissionType } from "@/model/API/TransmissionType";

export default Vue.extend({
  name: "InputsOutputs",
  components: { InfoModal },
  data: () => ({
    isInfoModalOpen: false,
    infoModalTitle: "",
    infoModalMessage: "",
    resetIcon: "mdi-close",
    infoIcon: "mdi-information",
  }),
  methods: {
    ...mapActions(["setInputsOutputs", "updateInputsOutputs"]),
    /**
     * Determine the available outputs. This will show all outputs that have no already been selected
     */
    availableOutputs(param: inputParameter) {
      let outputs: listItem[] = [
        {
          display: "X2107",
          value: AvailableOutputs.x2107,
        },
        {
          display: "X2108",
          value: AvailableOutputs.x2108,
        },
        {
          display: "X2006",
          value: AvailableOutputs.x2006,
        },
        {
          display: "X2008",
          value: AvailableOutputs.x2008,
        },
      ];

      if (this.form.outputs.dcac.value != null && param.display != this.form.outputs.dcac.display && this.shouldDisplay("dcac")) {
        outputs = outputs.filter((o) => o.value != this.form.outputs.dcac.value);
      }
      if (
        this.form.outputs.comboHeater.value != null &&
        param.display != this.form.outputs.comboHeater.display &&
        this.shouldDisplay("comboHeater")
      ) {
        outputs = outputs.filter((o) => o.value != this.form.outputs.comboHeater.value);
      }
      if (this.form.outputs.airHeater.value != null && param.display != this.form.outputs.airHeater.display && this.shouldDisplay("airHeater")) {
        outputs = outputs.filter((o) => o.value != this.form.outputs.airHeater.value);
      }
      if (this.form.outputs.highIdle.value != null && param.display != this.form.outputs.highIdle.display && this.shouldDisplay("highIdle")) {
        outputs = outputs.filter((o) => o.value != this.form.outputs.highIdle.value);
      }
      if (this.form.outputs.antiTheft.value != null && param.display != this.form.outputs.antiTheft.display && this.shouldDisplay("antiTheft")) {
        outputs = outputs.filter((o) => o.value != this.form.outputs.antiTheft.value);
      }
      if (this.form.outputs.clutch.value != null && param.display != this.form.outputs.clutch.display && this.shouldDisplay("clutch")) {
        outputs = outputs.filter((o) => o.value != this.form.outputs.clutch.value);
      }
      if (this.form.outputs.hvac.value != null && param.display != this.form.outputs.hvac.display && this.shouldDisplay("hvac")) {
        outputs = outputs.filter((o) => o.value != this.form.outputs.hvac.value);
      }
      if (this.form.outputs.condensor.value != null && param.display != this.form.outputs.condensor.display && this.shouldDisplay("condensor")) {
        outputs = outputs.filter((o) => o.value != this.form.outputs.condensor.value);
      }
      if (
        this.form.outputs.engineRunning.value != null &&
        param.display != this.form.outputs.engineRunning.display &&
        this.shouldDisplay("engineRunning")
      ) {
        outputs = outputs.filter((o) => o.value != this.form.outputs.engineRunning.value);
      }

      return outputs;
    },
    getSignalTypes(key: string): listItem[] {
      let signalTypes: listItem[] = [
        {
          display: "Positive",
          value: "positive",
        },
        {
          display: "Negative",
          value: "negative",
        },
      ];

      if (key == "rearFacility") {
        signalTypes.push({
          display: "Software",
          value: "software",
        });
      }
      return signalTypes;
    },
    /**
     * Determine the available outputs. This will show all outputs that have no already been selected
     */
    availableInputs(key: string, param: inputParameter) {
      if (param.signalType == null) {
        return [];
      }

      if (key == "rearFacility" && param.signalType == SignalType.Software) {
        let inputs: listItem[] = [
          {
            display: "HVAC PWR (AUTO HVAC)",
            value: "autohvac",
          },
        ];
        return inputs;
      } else if (param.signalType == SignalType.Positive) {
        let positiveInputs: listItem[] = [
          {
            display: "X12-14",
            value: AvailableInputsPositive.x1214,
          },
          {
            display: "X12-16",
            value: AvailableInputsPositive.x1216,
          },
          {
            display: "X12-18",
            value: AvailableInputsPositive.x1218,
          },
          {
            display: "X10-12",
            value: AvailableInputsPositive.x1012,
          },
          {
            display: "X10-16",
            value: AvailableInputsPositive.x1016,
          },
          {
            display: "X10-18",
            value: AvailableInputsPositive.x1018,
          },
        ];

        if (
          this.form.inputs.engineStart.signalType == SignalType.Positive &&
          this.form.inputs.engineStart.value != null &&
          param.display != this.form.inputs.engineStart.display &&
          this.shouldDisplay("engineStart")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.engineStart.value);
        }

        if (
          this.form.inputs.engineStop.signalType == SignalType.Positive &&
          this.form.inputs.engineStop.value != null &&
          param.display != this.form.inputs.engineStop.display &&
          this.shouldDisplay("engineStop")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.engineStop.value);
        }

        if (
          this.form.inputs.heater.signalType == SignalType.Positive &&
          this.form.inputs.heater.value != null &&
          param.display != this.form.inputs.heater.display &&
          this.shouldDisplay("heater")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.heater.value);
        }

        if (
          this.form.inputs.solenoid.signalType == SignalType.Positive &&
          this.form.inputs.solenoid.value != null &&
          param.display != this.form.inputs.solenoid.display &&
          this.shouldDisplay("solenoid")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.solenoid.value);
        }

        if (
          this.form.inputs.alarm.signalType == SignalType.Positive &&
          this.form.inputs.alarm.value != null &&
          param.display != this.form.inputs.alarm.display &&
          this.shouldDisplay("alarm")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.alarm.value);
        }

        if (
          this.form.inputs.bunkHeater.signalType == SignalType.Positive &&
          this.form.inputs.bunkHeater.value != null &&
          param.display != this.form.inputs.bunkHeater.display &&
          this.shouldDisplay("bunkHeater")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.bunkHeater.value);
        }

        if (
          this.form.inputs.bunkAC.signalType == SignalType.Positive &&
          this.form.inputs.bunkAC.value != null &&
          param.display != this.form.inputs.bunkAC.display &&
          this.shouldDisplay("bunkAC")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.bunkAC.value);
        }

        if (
          this.form.inputs.parkBrake.signalType == SignalType.Positive &&
          this.form.inputs.parkBrake.value != null &&
          param.display != this.form.inputs.parkBrake.display &&
          this.shouldDisplay("parkBrake")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.parkBrake.value);
        }

        if (
          this.form.inputs.neutralSwitch.signalType == SignalType.Positive &&
          this.form.inputs.neutralSwitch.value != null &&
          param.display != this.form.inputs.neutralSwitch.display &&
          this.shouldDisplay("neutralSwitch")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.neutralSwitch.value);
        }

        if (
          this.form.inputs.inputHighIdle.signalType == SignalType.Positive &&
          this.form.inputs.inputHighIdle.value != null &&
          param.display != this.form.inputs.inputHighIdle.display &&
          this.shouldDisplay("inputHighIdle")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.inputHighIdle.value);
        }

        if (
          this.form.inputs.shorePower.signalType == SignalType.Positive &&
          this.form.inputs.shorePower.value != null &&
          param.display != this.form.inputs.shorePower.display &&
          this.shouldDisplay("shorePower")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.shorePower.value);
        }

        if (
          this.form.inputs.pto.signalType == SignalType.Positive &&
          this.form.inputs.pto.value != null &&
          param.display != this.form.inputs.pto.display &&
          this.shouldDisplay("pto")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.pto.value);
        }

        if (
          this.form.inputs.auxAntiTheft.signalType == SignalType.Positive &&
          this.form.inputs.auxAntiTheft.value != null &&
          param.display != this.form.inputs.auxAntiTheft.display &&
          this.shouldDisplay("auxAntiTheft")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.auxAntiTheft.value);
        }

        if (
          this.form.inputs.doorStart.signalType == SignalType.Positive &&
          this.form.inputs.doorStart.value != null &&
          param.display != this.form.inputs.doorStart.display &&
          this.shouldDisplay("doorStart")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.doorStart.value);
        }

        if (
          this.form.inputs.compressor.signalType == SignalType.Positive &&
          this.form.inputs.compressor.value != null &&
          param.display != this.form.inputs.compressor.display &&
          this.shouldDisplay("compressor")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.compressor.value);
        }

        if (
          this.form.inputs.rearFacility.signalType == SignalType.Positive &&
          this.form.inputs.rearFacility.value != null &&
          param.display != this.form.inputs.rearFacility.display &&
          this.shouldDisplay("rearFacility")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.rearFacility.value);
        }

        if (
          this.form.inputs.safeMode.signalType == SignalType.Positive &&
          this.form.inputs.safeMode.value != null &&
          param.display != this.form.inputs.safeMode.display &&
          this.shouldDisplay("safeMode")
        ) {
          positiveInputs = positiveInputs.filter((o) => o.value != this.form.inputs.safeMode.value);
        }

        return positiveInputs;
      } else if (param.signalType == SignalType.Negative) {
        let negativeInputs: listItem[] = [
          {
            display: "X10-12",
            value: AvailableInputsNegative.x1012,
          },
          {
            display: "X10-16",
            value: AvailableInputsNegative.x1016,
          },
          {
            display: "X10-18",
            value: AvailableInputsNegative.x1018,
          },
        ];

        if (
          this.form.inputs.engineStart.signalType == SignalType.Negative &&
          this.form.inputs.engineStart.value != null &&
          param.display != this.form.inputs.engineStart.display &&
          this.shouldDisplay("engineStart")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.engineStart.value);
        }

        if (
          this.form.inputs.engineStop.signalType == SignalType.Negative &&
          this.form.inputs.engineStop.value != null &&
          param.display != this.form.inputs.engineStop.display &&
          this.shouldDisplay("engineStop")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.engineStop.value);
        }

        if (
          this.form.inputs.heater.signalType == SignalType.Negative &&
          this.form.inputs.heater.value != null &&
          param.display != this.form.inputs.heater.display &&
          this.shouldDisplay("heater")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.heater.value);
        }

        if (
          this.form.inputs.solenoid.signalType == SignalType.Negative &&
          this.form.inputs.solenoid.value != null &&
          param.display != this.form.inputs.solenoid.display &&
          this.shouldDisplay("solenoid")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.solenoid.value);
        }

        if (
          this.form.inputs.alarm.signalType == SignalType.Negative &&
          this.form.inputs.alarm.value != null &&
          param.display != this.form.inputs.alarm.display &&
          this.shouldDisplay("alarm")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.alarm.value);
        }

        if (
          this.form.inputs.bunkHeater.signalType == SignalType.Negative &&
          this.form.inputs.bunkHeater.value != null &&
          param.display != this.form.inputs.bunkHeater.display &&
          this.shouldDisplay("bunkHeater")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.bunkHeater.value);
        }

        if (
          this.form.inputs.bunkAC.signalType == SignalType.Negative &&
          this.form.inputs.bunkAC.value != null &&
          param.display != this.form.inputs.bunkAC.display &&
          this.shouldDisplay("bunkAC")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.bunkAC.value);
        }

        if (
          this.form.inputs.parkBrake.signalType == SignalType.Negative &&
          this.form.inputs.parkBrake.value != null &&
          param.display != this.form.inputs.parkBrake.display &&
          this.shouldDisplay("parkBrake")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.parkBrake.value);
        }

        if (
          this.form.inputs.neutralSwitch.signalType == SignalType.Negative &&
          this.form.inputs.neutralSwitch.value != null &&
          param.display != this.form.inputs.neutralSwitch.display &&
          this.shouldDisplay("neutralSwitch")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.neutralSwitch.value);
        }

        if (
          this.form.inputs.inputHighIdle.signalType == SignalType.Negative &&
          this.form.inputs.inputHighIdle.value != null &&
          param.display != this.form.inputs.inputHighIdle.display &&
          this.shouldDisplay("inputHighIdle")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.inputHighIdle.value);
        }

        if (
          this.form.inputs.shorePower.signalType == SignalType.Negative &&
          this.form.inputs.shorePower.value != null &&
          param.display != this.form.inputs.shorePower.display &&
          this.shouldDisplay("shorePower")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.shorePower.value);
        }

        if (
          this.form.inputs.pto.signalType == SignalType.Negative &&
          this.form.inputs.pto.value != null &&
          param.display != this.form.inputs.pto.display &&
          this.shouldDisplay("pto")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.pto.value);
        }

        if (
          this.form.inputs.auxAntiTheft.signalType == SignalType.Negative &&
          this.form.inputs.auxAntiTheft.value != null &&
          param.display != this.form.inputs.auxAntiTheft.display &&
          this.shouldDisplay("auxAntiTheft")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.auxAntiTheft.value);
        }

        if (
          this.form.inputs.doorStart.signalType == SignalType.Negative &&
          this.form.inputs.doorStart.value != null &&
          param.display != this.form.inputs.doorStart.display &&
          this.shouldDisplay("doorStart")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.doorStart.value);
        }

        if (
          this.form.inputs.compressor.signalType == SignalType.Negative &&
          this.form.inputs.compressor.value != null &&
          param.display != this.form.inputs.compressor.display &&
          this.shouldDisplay("compressor")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.compressor.value);
        }

        if (
          this.form.inputs.rearFacility.signalType == SignalType.Negative &&
          this.form.inputs.rearFacility.value != null &&
          param.display != this.form.inputs.rearFacility.display &&
          this.shouldDisplay("rearFacility")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.rearFacility.value);
        }

        if (
          this.form.inputs.safeMode.signalType == SignalType.Negative &&
          this.form.inputs.safeMode.value != null &&
          param.display != this.form.inputs.safeMode.display &&
          this.shouldDisplay("safeMode")
        ) {
          negativeInputs = negativeInputs.filter((o) => o.value != this.form.inputs.safeMode.value);
        }

        return negativeInputs;
      }

      return [];
    },
    /**
     * Determine whether the input should be displayed. This will check to ensure that the option has been enabled before asking for settings
     */
    shouldDisplay(key: string) {
      const generalInfo: GeneralInfo = this.generalInfo;
      const standardOptions: StandardOptions = this.standardOptions;
      const optionalFeatures: OptionalFeatures = this.optionalFeatures;
      const optionalEquipment: OptionalEquipment = this.optionalEquipment;
      switch (key) {
        // Outputs
        case "dcac":
          return (
            optionalEquipment.airConditioningOptions.ACEnabled.value &&
            optionalEquipment.airConditioningOptions.ACOption.value == AirConditioningOption.ECoolPark
          );
        case "comboHeater":
          return (
            optionalEquipment.heatingOptions.heatingEnabled.value && optionalEquipment.heatingOptions.heatingOption.value == HeatingOption.ComboHeater
          );
        case "airHeater":
          return (
            optionalEquipment.heatingOptions.heatingEnabled.value && optionalEquipment.heatingOptions.heatingOption.value == HeatingOption.AirHeater
          );
        case "highIdle":
          return optionalFeatures.highIdle.highIdleEnabled.value;
        case "antiTheft":
          return optionalFeatures.antiTheft.AntiTheftEnabled.value;
        case "clutch":
          // return stan
          return true;
        //TODO: AAAAA return optionalFeatures.manualTransmission.manualTransmissionEnabled.value;
        case "hvac":
          return this.isHVACEquipped() && optionalEquipment.automaticClimateControl.automaticClimateControlEnabled.value;
        case "condensor":
          return (
            optionalEquipment.airConditioningOptions.ACEnabled.value &&
            optionalEquipment.airConditioningOptions.ACOption.value == AirConditioningOption.DCACSystem
          );
        case "engineRunning":
          return true;

        // Inputs
        case "engineStart":
          return true;
        case "engineStop":
          return true;
        case "heater":
          return (
            this.isHeatingEquipped() &&
            optionalEquipment.heatingOptions.heatingEnabled.value == true &&
            (optionalEquipment.heatingOptions.heatingOption.value == HeatingOption.AuxiliaryHeater ||
              optionalEquipment.heatingOptions.heatingOption.value == HeatingOption.ProheatX30)
          );
        case "solenoid":
          return this.isSolenoidEquipped();
        case "alarm":
          return this.isAlarmEquipped();
        case "bunkHeater":
          return this.isHeatingEquipped();
        case "bunkAC":
          return true;
        case "parkBrake":
          return this.supportsParkBrake;
        case "neutralSwitch":
          return generalInfo.transmissionType == VehicleTransmissionType.Manual;
        case "inputHighIdle":
          return optionalFeatures.highIdle.highIdleEnabled.value;
        case "shorePower":
          return optionalFeatures.shorePower.shorePowerEnabled.value;
        case "pto":
          return optionalFeatures.ptoAuxLight.ptoLightAuxEnabled.value;
        case "auxAntiTheft":
          return this.isAntiTheftEquipped() && optionalFeatures.antiTheft.AntiTheftEnabled.value;
        case "doorStart":
          return optionalFeatures.doorStart.driverDoorStartEnabled.value;
        case "compressor":
          return (
            optionalEquipment.airConditioningOptions.ACEnabled.value &&
            optionalEquipment.airConditioningOptions.ACOption.value == AirConditioningOption.DCACSystem
          );
        case "rearFacility":
          return this.isRearFacilityEquipped() && optionalEquipment.rearFacility.rearFacilityEnabled.value;
        case "safeMode":
          return optionalFeatures.safeMode.safeModeEnabled.value;
      }
      return true;
    },
    /**
     * Called everything an input value is changed.
     * If it's an existing vehicle submit the updated settings.
     * If the vehicle already exists, don't submit the updates and wait for the user to submit the entire settings file
     */
    updated() {
      if (!this.isCreatingNewVehicle) {
        this.setInputsOutputs(this.form);
        this.updateInputsOutputs();
      } else {
        this.setInputsOutputs(this.form);
      }
    },
    /**
     * Determine if rear facility equipment is either installed in the vehicle by default or if the user has indicated to install equipment for this feature
     */
    isRearFacilityEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.rearFacilityControl != null;
    },
    /**
     * Determine if HVAC equipment is either installed in the vehicle by default or if the user has indicated to install equipment for this feature
     */
    isHVACEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.autoHVAC != null;
    },
    /**
     * Determine if anti theft equipment is either installed in the vehicle by default or if the user has indicated to install equipment for this feature
     */
    isAntiTheftEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.antiTheft != null;
    },
    isHeatingEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.heat != null || vehicleEquipment.selected.useMyOwnHeating;
    },
    isBatteryEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.battery != null || vehicleEquipment.selected.useMyOwnBattery;
    },
    isAlarmEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.alarm != null || vehicleEquipment.selected.useMyOwnAlarm;
    },
    isSolenoidEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.solenoid != null || vehicleEquipment.selected.useMyOwnSolenoid;
    },
    /**
     * Reset the input back to the default value
     */
    reset(key: optionParameter) {
      key.value = key.defaultValue;
    },
    resetSignalType(key: inputParameter) {
      key.signalType = key.defaultSignalType;
    },
    /**
     * Get the display label for the field
     */
    getLabel(key: optionParameter) {
      return key.display;
    },
    /**
     * Display the info modal to the user for the selected field
     */
    displayMessage(key: optionParameter) {
      this.isInfoModalOpen = true;
      this.infoModalTitle = this.getLabel(key);
      this.infoModalMessage = key.info;
    },
    /**
     * Close the Info Dialog
     */
    close() {
      this.isInfoModalOpen = false;
    },
  },
  computed: {
    ...mapGetters([
      "generalInfo",
      "inputsOutputs",
      "standardOptions",
      "optionalFeatures",
      "optionalEquipment",
      "vehicleEquipment",
      "isCreatingNewVehicle",
      "isKitSelected",
      "supportsParkBrake",
    ]),
    form(): InputsOutputs {
      return this.inputsOutputs;
    },
  },
});
