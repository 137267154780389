import { InputType, numberParameter, listBoolParameter, listParameter, listNumberParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export enum BatteryOption {
  MainBattery = "MainBattery",
  AuxBatteryExtend = "AuxBatteryExtend",
  AuxBatteryEither = "AuxBatteryEither",
}

export interface batteryOptions {
  batteryEnabled: listBoolParameter;
  batteryOption: listParameter;
  mainBatteryVoltage: listNumberParameter;
  mainBatteryLowSetPoint: numberParameter;
  auxiliaryBatteryVoltage: listNumberParameter;
  auxiliaryBatteryLowSetPoint: numberParameter;
  controllerShutdownTime: listNumberParameter;
  controllerShutdownLowBatterySetPoint: numberParameter;
  batterySaverShutdownOverride: numberParameter;
  batteryMonitoringEnabled: listBoolParameter;
}

export const defaultBatteryOptions: batteryOptions = {
  batteryEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    display: `Battery Enabled`,
    list: enabledUnits,
    info: `Spelling Error in the info There are different ways that the batteries can be connected on the vehicle. Each of the following are options and will change the operation of the system depending on the selection:`,
  },
  batteryOption: {
    type: InputType.list,
    value: BatteryOption.MainBattery,
    defaultValue: BatteryOption.MainBattery,
    display: `Battery Option`,
    list: [
      {
        display: "Main Battery Only",
        value: BatteryOption.MainBattery,
      },
      {
        display: "Aux Battery Extend",
        value: BatteryOption.AuxBatteryExtend,
      },
      {
        display: "Aux Battery Either",
        value: BatteryOption.AuxBatteryEither,
      },
    ],
    info: `<ul>  
      <li>Main Battery Only – This is used when a single voltage source is to be monitored. This can be from a single battery or from a bank of batteries which share a common voltage.</li>
      <li>Aux Battery Extend – This is used when two voltage source readings need to be monitored. This would require a solenoid which will separate the two positive voltages. This selection will use the secondary battery as an additional power source to the main battery. When the main battery voltage drops below the ‘Main Battery Low Set Point’, the solenoid will latch the secondary battery to the main battery. When the secondary battery voltage drops below the ‘Auxiliary Battery Low Set Point’ the EPEQ® IM System will start to charge both batteries (recommended for Light Duty).</li>
      <li>Aux Battery Either – This is used when two voltage source readings need to be monitored. This may require a solenoid which will separate the two positive sources. When either of the battery sources drop below their set points, the EPEQ® IM System will start for low battery. If a solenoid is installed, the solenoid will connect the two batteries together while the vehicle is running. (Recommended for Heavy Duty).</li>
    </ul>`,
  },
  mainBatteryVoltage: {
    type: InputType.list,
    value: 12,
    defaultValue: 12,
    display: `Main Battery Voltage`,
    info: `Main battery voltage of the system can be12V or 24V (default is 12V)`,
    list: [
      {
        display: "12V",
        value: 12,
      },
      {
        display: "24V",
        value: 24,
      },
    ],
  },

  mainBatteryLowSetPoint: {
    type: InputType.number,
    value: 11.9,
    defaultValue: 11.9,
    display: `Main Battery Low Set Point`,
    info: `Voltage levels are monitored while the load is applied on the battery. Therefore, the setting for restarting the vehicle can be set lower than what may be expected. Adjust to a reasonable setting that can be recovered easily such as 11. 7or 11.8 volts (23.7 or 23.8 volts) (. If the vehicle is only a one battery system these voltages should be adjusted higher such as 11.9 - 12 volts (23.9 or 24 volts) to ensure the vehicle will start. Main Battery is the vehicle battery (default is 11.9V).`,
    rules: ["required"],
    minValue: 5.0,
    maxValue: 30.0,
    increment: 0.1,
  },
  auxiliaryBatteryVoltage: {
    type: InputType.list,
    value: 12,
    defaultValue: 12,
    display: `Auxiliary Battery Voltage`,
    info: `The Auxiliary Battery Voltage may be 12V or 24V. (Do not separate the two batteries with a solenoid when the main is set to 12V and the auxiliary is set to 24V or vise versa). (default is12V).`,
    list: [
      {
        display: "12V",
        value: 12,
      },
      {
        display: "24V",
        value: 24,
      },
    ],
  },
  auxiliaryBatteryLowSetPoint: {
    type: InputType.number,
    value: 11.8,
    defaultValue: 11.8,
    display: `Auxiliary Battery Low Set Point`,
    info: `The Aux Battery is the optional battery that is separated from the Main Battery. This setting should be set based on the closed circuit voltage when all normal loads are applied to achieve only 50% of the battery capacity, (default is 11.8V)`,
    rules: ["required"],
    minValue: 11.0,
    maxValue: 30.0,
    increment: 0.1,
  },
  controllerShutdownTime: {
    type: InputType.list,
    value: 12,
    defaultValue: 12,
    display: `Controller Shutdown Time`,
    info: `This controls the maximum amount of time that the controller will stay live for. If consuming power is a priority you can set this lower but if the scheduler is required then you need to set the time accordingly (default is 72 hours)`,
    list: [
      {
        display: "5 minutes",
        value: 1,
      },
      {
        display: "15 minutes",
        value: 2,
      },
      {
        display: "30 minutes",
        value: 3,
      },
      {
        display: "1 hour",
        value: 4,
      },
      {
        display: "2 hours",
        value: 5,
      },
      {
        display: "4 hours",
        value: 6,
      },
      {
        display: "8 hours",
        value: 7,
      },
      {
        display: "12 hours",
        value: 8,
      },
      {
        display: "24 hours",
        value: 9,
      },
      {
        display: "36 hours",
        value: 10,
      },
      {
        display: "2 days",
        value: 11,
      },
      {
        display: "3 days",
        value: 12,
      },
      {
        display: "Infinite ON",
        value: 13,
      },
    ],
  },
  controllerShutdownLowBatterySetPoint: {
    type: InputType.number,
    value: 11.5,
    defaultValue: 11.5,
    display: `Controller Shutdown Low Battery Set Point`,
    info: `This controls the lowest setting that the controller will operate at, in order to protect the vehicle battery. Since the EPEQ® IM controller does consume some power it is important to shed this load before the vehicle is unable to start (default is 11.5V).`,
    rules: ["required"],
    minValue: 10.0,
    maxValue: 30.0,
    increment: 0.1,
  },
  batterySaverShutdownOverride: {
    type: InputType.number,
    value: 0,
    defaultValue: 0,
    display: `Battery Saver Shutdown Override`,
    info: `The controls how long before the EPEQ® IM system will cycle the key as so the vehicle does not shutdown due to a factory configured battery saver`,
    rules: ["required"],
    minValue: 0,
    maxValue: 60,
  },
  batteryMonitoringEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Main Battery Monitoring",
    info:
      "The system will monitor the performance of the battery when the vehicle shuts down to see how much voltage drop occurs in a specified time. If the main battery discharges rapidly, the system will display a flashing low battery symbol indicating a “bad battery” (default disabled).",
  },
};
