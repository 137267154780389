import { InputType, listBoolParameter, temperatureParameter } from "../OptionParameter";

import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface engineStartOptions {
  engineStartEnabled: listBoolParameter;
  engineBoostAuxHeater: listBoolParameter;
  engineStartHigh: temperatureParameter;
  engineStartLow: temperatureParameter;
  auxHeaterHigh: temperatureParameter;
  auxHeaterLow: temperatureParameter;
}

export const defaultEngineStartOptions: engineStartOptions = {
  engineStartEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Engine Start For Low Coolant Temp",
    info: "The system will start the engine to maintain the coolant temperature of the engine",
  },
  engineBoostAuxHeater: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Engine Boost Aux Heater Required",
    info: "", // TODO: Find out default value
  },
  engineStartHigh: {
    type: InputType.temperature,
    value: 75,
    defaultValue: 75,
    display: "Engine Start High Engine Coolant Temp Set Point",
    info:
      "If ‘Engine Start for Low Coolant Temp’ is enabled in the ‘Options Setting Tab’, the engine will run to bring the engine coolant temperature up to this set point before shutting off the engine.",
    rules: ["required"],
    minValue: 50,
    maxValue: 100,
  },
  engineStartLow: {
    type: InputType.temperature,
    value: 45,
    defaultValue: 45,
    display: "Engine Start Low Engine Coolant Temp Set Point",
    info:
      "If ‘Engine Start for Low Coolant Temp’ is enabled in the ‘Options Setting Tab’, the engine will start for low coolant temperature once below this set point.",
    rules: ["required"],
    minValue: -10,
    maxValue: 97,
  },
  auxHeaterHigh: {
    type: InputType.temperature,
    value: 75,
    defaultValue: 75,
    display: "Aux Heater High Engine Coolant Temp Set Point",
    info:
      "This is the temperature at which the engine will shut off, after it has been started for ‘Low Engine Coolant Temp’. See ‘Low Engine Coolant Temp Set Point’ in which the system will start the engine if the temperature of the coolant falls below. (default is 75°C/167°F).",
    rules: ["required"],
    minValue: 40,
    maxValue: 100,
  },
  auxHeaterLow: {
    type: InputType.temperature,
    value: 45,
    defaultValue: 45,
    display: "Aux Heater Low Engine Coolant Temp Set Point",
    info:
      "This is the temperature at which the engine will start due to the ‘Low Engine Coolant Temp Set Point’. The ‘Auxiliary Heater’ option must be selected from the ‘Heating Option Selector’ to allow the EPEQ® IM system to start for low coolant temperature (default is 45°C/113°F).",
    rules: ["required"],
    minValue: 40,
    maxValue: 100,
  },
};
