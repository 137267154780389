import { Currency } from "@/model/Localization";

export const calculateMaintenanceCost = (GVWR: number, currency: Currency): number => {
  switch (currency) {
    case Currency.USD:
      if (GVWR == 1 || GVWR == 2 || GVWR == 3) {
        return 0.035;
      } else if (GVWR == 4 || GVWR == 5 || GVWR == 6) {
        return 0.07;
      } else if (GVWR == 7 || GVWR == 8) {
        return 0.105;
      }
      return 0.07;
    case Currency.CAD:
      if (GVWR == 1 || GVWR == 2 || GVWR == 3) {
        return 0.044;
      } else if (GVWR == 4 || GVWR == 5 || GVWR == 6) {
        return 0.088;
      } else if (GVWR == 7 || GVWR == 8) {
        return 0.132;
      }
      return 0.088;
  }
};

/**
 * Determine the emissions (KG/L) based on the fuel type
 */
export const calculateEmissions = (fuelType: string) => {
  switch (fuelType.toLowerCase()) {
    case "diesel":
      return 2.7;
    case "gasoline":
      return 2.3;
    case "e10":
      return 2.2;
    case "e85":
      return 1.6;
    case "b5":
      return 2.7;
    case "b20":
      return 2.6;
    case "propane":
      return 1.5;
    case "cng":
      return 0.1;
    case "lng":
      return 1.2;
    case "lpg":
      return 1.5;
    case "ethanol":
      return 1.5;
    default:
      return 1.5; // TODO: Default?
  }
};

/**
 * Determine the emissions (lbs/G) based on the fuel type
 */
export const calculateEmissionsGallon = (fuelType: string) => {
  switch (fuelType.toLowerCase()) {
    case "diesel":
      return 22.2;
    case "gasoline":
      return 19.2;
    case "e10":
      return 18.4;
    case "e85":
      return 13.4;
    case "b5":
      return 22.1;
    case "b20":
      return 21.9;
    case "propane":
      return 12.3;
    case "cng":
      return 0.5;
    case "lng":
      return 9.6;
    case "lpg":
      return 12.3;
    case "ethanol":
      return 12.4;
    default:
      return 12.4; // TODO: Default?
  }
};

/**
 * Determine the idle fuel consumption (L/hr) based on the engineSize (L)
 */
export const calculateFuelConsumption = (engineSize: number): number => {
  if (engineSize < 0) {
    return 1.2; // TODO: Default?
  } else if (engineSize < 3) {
    return 1.2;
  } else if (engineSize < 4) {
    return 1.8;
  } else if (engineSize < 5) {
    return 2.4;
  } else if (engineSize < 6) {
    return 3.0;
  } else if (engineSize < 7) {
    return 3.6;
  } else if (engineSize < 8) {
    return 4.2;
  } else if (engineSize < 9) {
    return 4.8;
  } else if (engineSize < 10) {
    return 5.4;
  } else if (engineSize < 11) {
    return 6.0;
  } else if (engineSize < 12) {
    return 6.6;
  } else if (engineSize < 13) {
    return 7.2;
  } else if (engineSize < 14) {
    return 7.8;
  } else if (engineSize < 15) {
    return 8.4;
  } else if (engineSize < 16) {
    return 9.0;
  } else if (engineSize < 17) {
    return 9.6;
  } else {
    return 9.6;
  }
};

export const getLabel = (key: string): string => {
  let message = "";

  switch (key) {
    case "make":
      message = "Make";
      break;
    case "model":
      message = "Model";
      break;
    case "year":
      message = "Year";
      break;
    case "displacement":
      message = "Displacement";
      break;
    case "fuelConsumption":
      message = "Fuel Consumption";
      break;
    case "fuelType":
      message = "Fuel Type";
      break;
    case "fuelCost":
      message = "Fuel Cost";
      break;
    case "engineType":
      message = "Engine Type";
      break;
    case "engineManufacturer":
      message = "Engine Manufacturer";
      break;
    case "brakeSystem":
      message = "Brake System";
      break;
    case "driveLineType":
      message = "Drive Line Type";
      break;
    case "gvwrClass":
      message = "GVWR Class";
      break;
    case "vehicleType":
      message = "Vehicle Type";
      break;
    case "frequency":
      message = "Frequency";
      break;
    case "vehicleGroup":
      message = "Zone";
      break;
    case "series":
      message = "Series";
      break;
    case "bodyType":
      message = "Body Type";
      break;
    case "other":
      message = "Other";
      break;
    case "transmissionType":
      message = "Transmission Type";
      break;
    case "vehicleCompany":
      return "Company";
  }

  return message;
};

export const parseGVWRClass = (gvwrClass: string | null): number => {
  if (gvwrClass == null) {
    return 3;
  } else if (gvwrClass.toLowerCase().includes("class 1")) {
    return 1;
  } else if (gvwrClass.toLowerCase().includes("class 2")) {
    return 2;
  } else if (gvwrClass.toLowerCase().includes("class 3")) {
    return 3;
  } else if (gvwrClass.toLowerCase().includes("class 4")) {
    return 4;
  } else if (gvwrClass.toLowerCase().includes("class 5")) {
    return 5;
  } else if (gvwrClass.toLowerCase().includes("class 6")) {
    return 6;
  } else if (gvwrClass.toLowerCase().includes("class 7")) {
    return 7;
  } else if (gvwrClass.toLowerCase().includes("class 8")) {
    return 8;
  } else {
    return 3;
  }
};
