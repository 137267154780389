






















import Vue from "vue";

import NavigationDrawer from "./components/NavigationDrawer.vue";

import { mapGetters } from "vuex";
import { mgr } from "@/helpers/AuthService";
import store from "./store";
import instance from "@/axios";
import { User as APIUser } from "@/model/API/User";

export default Vue.extend({
  async created() {
    if (!this.isUserAuthorized) {
      try {
        // get user from portal session
        let user = await mgr.getUser();

        // if token expired or will expire in 60 seconds, the user is invalid.
        // note that this compares server time to client time, so the user will appear as logged out on unauthenticated pages if their time is too far ahead.
        if (user == null || user.expires_at - 60 < new Date().getTime() / 1000) {
          user = null;
        }

        // get user from identity server
        if (user == null && this.pageRequiresAuthentication) {
          let currentRoute = this.$router.currentRoute.fullPath ?? "";
          if (!currentRoute.includes("callback")) {
            localStorage.setItem("previouspath", currentRoute);
          }

          user = await mgr.signinCallback();
        }

        try {
          if (user) {
            // save user and get their details from API
            await store.dispatch("setUser", user);

            let userId = store.getters["userId"];
            let apiUserData = await instance.get<APIUser>(`/user/${userId}`);
            let apiUser = apiUserData.data;
            await store.dispatch("setAPIUser", apiUser);

            this.doneAuthentication = true;
          } else if (this.pageRequiresAuthentication) {
            // user isn't set and page requires authentication
            mgr.signinRedirect();
            return;
          } else {
            // user isn't set, but we don't need authentication
            this.doneAuthentication = true;
          }
        } catch {
          // if an exception happens after the user is signed in with Identity, it causes an infinite redirect loop
          mgr.signoutRedirect();
          return;
        }
      } catch {
        mgr.signinRedirect();
        return;
      }
    } else {
      this.doneAuthentication = true;
    }
  },
  name: "App",
  components: { NavigationDrawer },
  data: () => ({
    doneAuthentication: false,
  }),
  computed: {
    ...mapGetters(["isUserAuthorized", "errorMessage"]),

    pageRequiresAuthentication() {
      return this.$route.meta?.requiresAuthentication ?? true;
    },
  },
});
