














































































































import Vue from "vue";

import InfoModal from "@/components/InfoModal.vue";

import { mapGetters, mapActions } from "vuex";

import { optionParameter, InputType, temperatureParameter } from "@/model/VehicleProfile/OptionParameter";

import { OptionalFeatures } from "@/model/VehicleProfile/OptionalFeatures/OptionalFeatures";

import { required, minValue, maxValue, ValidationRule } from "vuelidate/lib/validators";
import { VehicleEquipment } from "@/model/VehicleProfile/VehicleEquipment";
import { convertTemperatureFromCelsius } from "@/helpers/UnitService";
import { TemperatureUnit } from "@/model/Units";
import { SetHiddenFieldValidation } from "@/helpers/VehicleValidation";

export default Vue.extend({
  name: "OptionalFeatures",
  components: { InfoModal },
  /**
   * Ensure the form is saved when the component is destroyed
   */
  destroyed: function() {
    this.save();
  },
  /**
   * Create the vuelidate validation object by looping through all the input's to create the object.
   */
  validations() {
    let validations: Record<string, Record<string, Record<string, Record<string, Record<string, ValidationRule | (() => ValidationRule)>>>>> = {
      form: {},
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var form: Record<string, any> = this.form;

    // For every property in the form, loop through and generate the vuelidates rules.
    // For example: Go through every property in the OptionalFeatures interface, then go through every property inside the DoorStart interface.
    for (const section in this.form) {
      validations["form"][section] = {};
      for (const param in form[section]) {
        // If we shouldn't display the input we do not want to add validation for it, but we may need to update the form values
        if (!this.shouldDisplay(param)) {
          form = SetHiddenFieldValidation(
            form,
            section,
            param,
            this.userTemperatureUnits
          );
          continue;
        }

        validations["form"][section][param] = {
          value: {},
        };

        // If the input is required, add the required method from vuelidate
        if (form[section][param].rules && form[section][param].rules.includes("required")) {
          validations["form"][section][param]["value"] = {
            ...validations["form"][section][param]["value"],
            required,
          };
        }

        // If the input has a minValue, add the minValue method from vuelidate
        if (form[section][param].minValue != null) {
          let value = form[section][param].minValue;
          if (form[section][param].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          validations["form"][section][param]["value"] = {
            ...validations["form"][section][param]["value"],
            minValue: minValue(value),
          };
        }

        // If the input has a maxValue, add the maxValue method from vuelidate
        if (form[section][param].maxValue != null) {
          let value = form[section][param].maxValue;
          if (form[section][param].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          validations["form"][section][param]["value"] = {
            ...validations["form"][section][param]["value"],
            maxValue: maxValue(value),
          };
        }
      }
    }

    return validations;
  },
  data: () => ({
    isInfoModalOpen: false,
    infoModalTitle: "",
    infoModalMessage: "",
    InputType: InputType,
    resetIcon: "mdi-close",
    infoIcon: "mdi-information",
    temperatureUnits: [
      {
        text: "C",
        value: "c",
      },
      {
        text: "F",
        value: "f",
      },
    ],

    validationSnackbar: false,
  }),
  methods: {
    ...mapActions(["setOptionalFeatures", "updateOptionalFeatures", "setStandardOptions", "updatedStandardOptions"]),
    // Saves the data to the vuex store
    save() {
      this.setOptionalFeatures(this.form);
    },
    /**
     * Verifies that if a parent setting is disabled, the child setting is also disabled.
     */
    veryifySettings() {
      // When the Anti theft option gest disabled the Anti-theft only should be automatically disabled.
      if (!this.form.antiTheft.AntiTheftEnabled.value) {
        this.form.antiTheft.AntiTheftOnlyEnabled.value = false;
      }
      // When seat belt start gets disabled the seat belt start only should be automatically disabled.
      if (!this.form.seatBeltStart.seatBeltStartEnabled.value) {
        this.form.seatBeltStart.seatBeltOffOnly.value = false;
      }
      // When Drivers door start is disabled the Door Start Activation Signal should be automatically disabled
      if (!this.form.doorStart.driverDoorStartEnabled.value) {
        this.form.doorStart.doorStartActivationSignal.value = false;
      }
    },
    /**
     * Called everything an input value is changed.
     * If it's an existing vehicle submit the updated settings.
     * If the vehicle already exists, don't submit the updates and wait for the user to submit the entire settings file
     */
    updated() {
      this.veryifySettings();

      if (!this.isCreatingNewVehicle) {
        if (!this.$v.form.$invalid) {
          this.setOptionalFeatures(this.form);
          this.updateOptionalFeatures();
        } else {
          this.validationSnackbar = true;
        }
      }
    },
    resetTemperature(key: temperatureParameter) {
      key.value = convertTemperatureFromCelsius(key.defaultValue, this.userTemperatureUnits);
      this.updated();
    },
    /**
     * Reset the input back to the default value
     */
    reset(key: optionParameter) {
      key.value = key.defaultValue;
      this.updated();
    },
    /**
     * Determine the error messages for the input field.
     * @classKey The key of the outer interface. This would be the keys of the OptionalFeatures interface (Such as DoorStart)
     * @classKey The key of the property being displayed. For example this would be the maxIdleTimeDoorOpen that is inside the DoorStart interface.
     * @label The display value of the input. This is used to generate a specific error message with the input name in it.
     */
    errorMessages(classKey: string, propertyKey: string, label: string) {
      const errors: string[] = [];

      // Ensure that the form is created
      if (!this.$v.form) {
        return errors;
      }

      // Ensure that the interface is part of the form
      if (!this.$v.form[classKey]) {
        return errors;
      }

      // Ensure that the property exists in the interface
      if (!this.$v.form[classKey]?.[propertyKey]) {
        return errors;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let form: any = this.form;

      // If the property has the required rule and there is a required error, add the error message
      if (form[classKey][propertyKey].rules && form[classKey][propertyKey].rules.includes("required")) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        !this.$v.form[classKey]![propertyKey]["value"].required && errors.push(`${label} is required`);
      }

      // If the property is a number type and has the min value rule and there is a min error, display a message
      if (form[classKey][propertyKey].type == InputType.number || form[classKey][propertyKey].type == InputType.temperature) {
        if (form[classKey][propertyKey].minValue != null) {
          let value = form[classKey][propertyKey].minValue;
          if (form[classKey][propertyKey].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          !this.$v.form[classKey]![propertyKey]["value"].minValue && errors.push(`${label} must be at least ${value}`);
        }
        // If the property is a number type and has the max value rule and there is a max error, display a message
        if (form[classKey][propertyKey].maxValue != null) {
          let value = form[classKey][propertyKey].maxValue;
          if (form[classKey][propertyKey].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          !this.$v.form[classKey]![propertyKey]["value"].maxValue && errors.push(`${label} must not exceed ${value}`);
        }
      }

      return errors;
    },
    /**
     * Determine whether the input should be displayed. This will check to ensure that the option has been enabled before asking for settings
     */
    shouldDisplay(key: string) {
      switch (key) {
        // Brake start
        case "brakeStartEnabled":
          return this.supportsBrakeStart;

        // Seat Belt Start
        case "seatBeltStartEnabled":
          return this.supportsSeatBeltStart;
        case "seatBeltOffOnly":
          return this.supportsSeatBeltStart && this.form.seatBeltStart.seatBeltStartEnabled.value;

        // Engine Start For Hydraulic Temp Settings
        case "engineStartHydraulicEnabled":
          return this.supportsEngineStartHydraulic;
        case "highHydraulicTempSetPoint":
          return this.supportsEngineStartHydraulic && this.form.engineStartHydraulic.engineStartHydraulicEnabled.value;
        case "lowHydraulicTempSetPoint":
          return this.supportsEngineStartHydraulic && this.form.engineStartHydraulic.engineStartHydraulicEnabled.value;

        // Air Pressure Monitoring
        case "airPressureMonitoringEnabled":
          return this.supportsAirPressureMonitoring;
        case "airPressureSetPoint":
          return this.supportsAirPressureMonitoring && this.form.airPressureMonitoring.airPressureMonitoringEnabled.value;
        case "airPressureOffset":
          return this.supportsAirPressureMonitoring && this.form.airPressureMonitoring.airPressureMonitoringEnabled.value;

        // Door Start
        case "driverDoorStartEnabled":
          return this.supportsDoorStartOnCan;
        case "doorOpenStartEnabled":
          return this.supportsDoorStartOnCan;
        case "maxIdleNoOccupants":
          return this.supportsDoorStartOnCan && this.form.doorStart.driverDoorStartEnabled.value;
        case "doorStartActivationSignal":
          return this.supportsDoorStartOnCan && (this.form.doorStart.driverDoorStartEnabled.value || this.form.doorStart.doorOpenStartEnabled.value);
        case "doorOpenDelay":
          return this.supportsDoorStartOnCan && this.form.doorStart.doorOpenStartEnabled.value;
        case "maxIdleTimeDoorOpen":
          return this.supportsDoorStartOnCan && this.form.doorStart.doorOpenStartEnabled.value;

        // Shore Power
        case "shorePowerEnabled":
          return true;

        // Electric Parking Brake
        case "electricParkingBrakeEnabled":
          return this.supportsElectricBrake;
        // High Idle
        case "highIdleEnabled":
          return true;

        // Anti Theft
        case "AntiTheftEnabled":
          return this.isAntiTheftEquipped();
        case "AuxiliaryAntiTheftEnabled":
          return this.isAntiTheftEquipped();
        case "AntiTheftOnlyEnabled":
          return this.isAntiTheftEquipped() && this.form.antiTheft.AntiTheftEnabled.value;
        case "AntiTheftMaxIdleNoOccupants":
          return this.isAntiTheftEquipped() && this.form.antiTheft.AntiTheftEnabled.value;

        // Safe Mode
        case "safeModeEnabled":
          return true;
        case "safeMainBatteryLowSetPoint":
          return this.form.safeMode.safeModeEnabled.value;
        case "safeAuxiliaryBatteryLowSetPoint":
          return this.form.safeMode.safeModeEnabled.value;
        case "safeMonitorAirConditioningEnabled":
          return this.form.safeMode.safeModeEnabled.value;
        case "safeMonitorAirConditioningHighTempSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeMonitorAirConditioningEnabled.value;
        case "safeMonitorHeatEnabled":
          return this.form.safeMode.safeModeEnabled.value;
        case "safeMonitorHeatLowTempSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeMonitorHeatEnabled.value;
        case "safeEnginePumpLowCoolantTempEnabled":
          return this.form.safeMode.safeModeEnabled.value;
        case "safeEnginePumpLowCoolantTempHighSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeEnginePumpLowCoolantTempEnabled.value;
        case "safeEnginePumpLowCoolantTempLowSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeEnginePumpLowCoolantTempEnabled.value;
        case "safeEngineStartHydraulicTempEnabled":
          return this.form.safeMode.safeModeEnabled.value;
        case "safeEngineStartHydraulicTempHighSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeEngineStartHydraulicTempEnabled.value;
        case "safeEngineStartHydraulicTempLowSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeEngineStartHydraulicTempEnabled.value;
        case "safeEngineStartLowCoolantTempEnabled":
          return this.form.safeMode.safeModeEnabled.value;
        case "safeEngineStartLowCoolantTempHighSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeEngineStartLowCoolantTempEnabled.value;
        case "safeEngineStartLowCoolantTempLowSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeEngineStartLowCoolantTempEnabled.value;
        case "safeAuxiliaryHeaterEngineEnabled":
          return this.form.safeMode.safeModeEnabled.value;
        case "safeAuxiliaryHeaterEngineHighSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeAuxiliaryHeaterEngineEnabled.value;
        case "safeAuxiliaryHeaterEngineLowSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeAuxiliaryHeaterEngineEnabled.value;
        case "safeMonitorRearEnabled":
          return this.form.safeMode.safeModeEnabled.value;
        case "safeMonitorRearHighSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeMonitorRearEnabled.value;
        case "safeMonitorRearLowSetPoint":
          return this.form.safeMode.safeModeEnabled.value && this.form.safeMode.safeMonitorRearEnabled.value;
      }

      return true;
    },
    /**
     * Determine if anti theft equipment is either installed in the vehicle by default or if the user has indicated to install equipment for this feature
     */
    isAntiTheftEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.options.antiTheft == null || vehicleEquipment.selected.antiTheft != null;
    },
    /**
     * Determine whether a section should be displayed.
     * The section should be displayed if at least one property within the section should be displayed
     */
    shouldDisplaySection(properties: Record<string, unknown>) {
      for (const property in properties) {
        if (this.shouldDisplay(property)) return true;
      }
      return false;
    },
    /**
     * Display the info modal to the user for the selected field
     */
    displayMessage(key: optionParameter) {
      this.isInfoModalOpen = true;
      this.infoModalTitle = this.getLabel(key);
      this.infoModalMessage = key.info;
    },
    /**
     * Get the display label for the field
     */
    getLabel(key: optionParameter) {
      return key.display;
    },
    /**
     * Close the Info Dialog
     */
    close() {
      this.isInfoModalOpen = false;
    },
    /**
     * Determine the display value of the tab based on the key
     */
    getKeyDisplay(key: string) {
      switch (key) {
        case "brakeStart":
          return "Brake Start";
        case "seatBeltStart":
          return "Seat Belt Start";
        case "engineStartHydraulic":
          return "Engine Start For Hydraulic Temp Settings";
        case "airPressureMonitoring":
          return "Air Pressure Monitoring";
        case "doorStart":
          return "Door Start";
        case "shorePower":
          return "Shore Power";
        case "highIdle":
          return "High Idle";
        case "antiTheft":
          return "Anti Theft";

        case "safeMode":
          return "Safe Mode";
        case "ptoAuxLight":
          return "PTO/Light/Aux";
        case "electricParkBrake":
          return "Electric Parking Brake";
        case "overrideShutdown":
          return "OEM Shutdown Override";
      }
      return "";
    },
    // gets the hint to display under the input field
    getHint(feature: string, value: any) {
      switch (feature) {
        case "shorePowerEnabled":
          if (value == true) {
            return "You need to select an input in the Input/Output section";
          }
          break;
        case "ptoLightAuxEnabled":
          if (value == true) {
            return "You need to select an input in the Input/Output section";
          }
          break;
        case "highIdleEnabled":
          if (value == true) {
            return "You need to select an output in the Input/Output section";
          }
          break;
        case "AuxiliaryAntiTheftEnabled":
          if (value == true) {
            return "You need to select an input in the Input/Output section";
          }
          break;
      }
      return "";
    },
  },
  computed: {
    ...mapGetters([
      "optionalFeatures",
      "isCreatingNewVehicle",
      "supportsAirPressureMonitoring",
      "supportsBrakeStart",
      "supportsEngineStartHydraulic",
      "supportsSeatBeltStart",
      "supportsElectricBrake",
      "supportsDoorStartOnCan",
      "vehicleEquipment",
      "isKitSelected",
      "userTemperatureUnitsDisplay",
      "userTemperatureUnits",
      "generalInfo",
    ]),
    form(): OptionalFeatures {
      return this.optionalFeatures;
    },
    validationErrors(): string[] {
      let errors = this.$v
        .$flattenParams()
        .map((x) => this.errorMessages(x.path[1], x.path[2], (this.form as any)[x.path[1]][x.path[2]].display))
        .flat()
        .filter((value, index, array) => array.indexOf(value) == index);
      return errors;
    },
  },
});
