import { InputType, numberParameter, listBoolParameter, listNumberParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface DoorStartOptions {
  driverDoorStartEnabled: listBoolParameter;
  doorOpenStartEnabled: listBoolParameter;
  maxIdleNoOccupants: listNumberParameter;
  doorStartActivationSignal: listBoolParameter;
  doorOpenDelay: listNumberParameter;
  maxIdleTimeDoorOpen: listNumberParameter;
}

export const defaultDoorStartOptions: DoorStartOptions = {
  driverDoorStartEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Drivers Door Start",
    info: `This feature if enabled and the system is in the monitoring mode, will monitor the driver’s door being opened and closed. Upon the first opening or closing, place the vehicle into the ‘No Occupants Idle Time’. When the operator returns and opens the door, the vehicle engine will start and run for the ‘Max Idle Time’ (default disabled).`,
  },
  doorOpenStartEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Door Left Open Start",
    info: `This feature if Enabled and the system is in the monitoring mode, will start the engine After the ‘Door Open Delay’setting time and will stay running for the ‘Max Idle Time - Door Left Open’ (default disabled)`,
  },
  maxIdleNoOccupants: {
    type: InputType.list,
    value: 6,
    defaultValue: 6,
    list: [
      {
        display: "1 second",
        value: 1,
      },
      {
        display: "2 seconds",
        value: 2,
      },
      {
        display: "5 seconds",
        value: 3,
      },
      {
        display: "10 seconds",
        value: 4,
      },
      {
        display: "30 seconds",
        value: 5,
      },
      {
        display: "1 minute",
        value: 6,
      },
      {
        display: "2 minutes",
        value: 7,
      },
      {
        display: "3 minutes",
        value: 8,
      },
      {
        display: "4 minutes",
        value: 9,
      },
      {
        display: "5 minutes",
        value: 10,
      },
      {
        display: "6 minutes",
        value: 11,
      },
      {
        display: "7 minutes",
        value: 12,
      },
      {
        display: "8 minutes",
        value: 13,
      },
      {
        display: "9 minutes",
        value: 14,
      },
      {
        display: "10 minutes",
        value: 15,
      },
      {
        display: "15 minutes",
        value: 16,
      },
      {
        display: "20 minutes",
        value: 17,
      },
      {
        display: "25 minutes",
        value: 18,
      },
      {
        display: "30 minutes",
        value: 19,
      },
      {
        display: "35 minutes",
        value: 20,
      },
      {
        display: "40 minutes",
        value: 21,
      },
      {
        display: "45 minutes",
        value: 22,
      },
      {
        display: "50 minutes",
        value: 23,
      },
      {
        display: "55 minutes",
        value: 24,
      },
      {
        display: "60 minutes",
        value: 25,
      },
    ],
    display: "Max Idle No Occupants",
    info: `This is the amount of time the vehicle will run if the ‘Anti-Theft Option’ or the ‘Driver’s Door Start Option’ is enabled, before the EPEQ® IM System shuts the engine down. A 10 second to 1 minute is recommended; however, the maximum setting is 60 minutes. If the operator is away from the vehicle, this can increase the savings significantly by reducing the ide time before the engine shuts down. Adding more time than what is required will significantly reduce the savings (default is 1 minute)`,
  },
  doorStartActivationSignal: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Door Start Activation Signal",
    info: `This feature if enabled and the ‘Drivers Door Start’ feature is enabled, will only allow the engine to start when the door is opened and the Configurable I/O ‘Door Start’ is activated. `,
  },
  doorOpenDelay: {
    type: InputType.list,
    value: 6,
    defaultValue: 6,
    display: "Door Open Delay",
    info: `This is the amount of time which is required for the door to be left open before the EPEQ® IM System will start the engine. The ‘Door Left Open Start’ feature must be enabled for this feature to work. See ‘Max Idle Time Door Left Open’ for the time which the engine will run for after starting. The range for door Open Delay is 10 seconds to 60 minutes (default is 1 minute). Max Idle Time Door Left Open - This is the amount of time that the engine will run for before shutting off, due to the door left open. The ‘Door Left Open Start’ feature must be enabled for this feature to work. See ‘Door Open Delay’ for duration time required before starting (default 1 minute).`,
    list: [
      {
        display: "1 second",
        value: 1,
      },
      {
        display: "2 seconds",
        value: 2,
      },
      {
        display: "5 seconds",
        value: 3,
      },
      {
        display: "10 seconds",
        value: 4,
      },
      {
        display: "30 seconds",
        value: 5,
      },
      {
        display: "1 minute",
        value: 6,
      },
      {
        display: "2 minutes",
        value: 7,
      },
      {
        display: "3 minutes",
        value: 8,
      },
      {
        display: "4 minutes",
        value: 9,
      },
      {
        display: "5 minutes",
        value: 10,
      },
      {
        display: "6 minutes",
        value: 11,
      },
      {
        display: "7 minutes",
        value: 12,
      },
      {
        display: "8 minutes",
        value: 13,
      },
      {
        display: "9 minutes",
        value: 14,
      },
      {
        display: "10 minutes",
        value: 15,
      },
      {
        display: "15 minutes",
        value: 16,
      },
      {
        display: "20 minutes",
        value: 17,
      },
      {
        display: "25 minutes",
        value: 18,
      },
      {
        display: "30 minutes",
        value: 19,
      },
      {
        display: "35 minutes",
        value: 20,
      },
      {
        display: "40 minutes",
        value: 21,
      },
      {
        display: "45 minutes",
        value: 22,
      },
      {
        display: "50 minutes",
        value: 23,
      },
      {
        display: "55 minutes",
        value: 24,
      },
      {
        display: "60 minutes",
        value: 25,
      },
    ],
  },
  maxIdleTimeDoorOpen: {
    type: InputType.list,
    value: 6,
    defaultValue: 6,
    list: [
      {
        display: "10 seconds",
        value: 4,
      },
      {
        display: "30 seconds",
        value: 5,
      },
      {
        display: "1 minute",
        value: 6,
      },
      {
        display: "2 minutes",
        value: 7,
      },
      {
        display: "3 minutes",
        value: 8,
      },
      {
        display: "4 minutes",
        value: 9,
      },
      {
        display: "5 minutes",
        value: 10,
      },
      {
        display: "6 minutes",
        value: 11,
      },
      {
        display: "7 minutes",
        value: 12,
      },
      {
        display: "8 minutes",
        value: 13,
      },
      {
        display: "9 minutes",
        value: 14,
      },
      {
        display: "10 minutes",
        value: 15,
      },
      {
        display: "15 minutes",
        value: 16,
      },
      {
        display: "20 minutes",
        value: 17,
      },
      {
        display: "25 minutes",
        value: 18,
      },
      {
        display: "30 minutes",
        value: 19,
      },
      {
        display: "35 minutes",
        value: 20,
      },
      {
        display: "40 minutes",
        value: 21,
      },
      {
        display: "45 minutes",
        value: 22,
      },
      {
        display: "50 minutes",
        value: 23,
      },
      {
        display: "55 minutes",
        value: 24,
      },
      {
        display: "60 minutes",
        value: 25,
      },
    ],
    display: "Max Idle Time Door Left Open",
    info: `This is the amount of time that the engine will run for before shutting off, due to the door left open. The ‘Door Left Open Start’ feature must be enabled for this feature to work. See ‘Door Open Delay’ for duration time required before starting (default 1 minute).`,
  },
};
