import { InputType, listBoolParameter, temperatureParameter } from "../OptionParameter";

import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface EngineStartHydraulicOptions {
  engineStartHydraulicEnabled: listBoolParameter;
  highHydraulicTempSetPoint: temperatureParameter;
  lowHydraulicTempSetPoint: temperatureParameter;
}

export const defaultEngineStartHydraulicOptions: EngineStartHydraulicOptions = {
  engineStartHydraulicEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Engine Start For Hydraulic Temp Settings",
    info: "The system will start to keep Hydraulic fluid warm. ",
  },
  highHydraulicTempSetPoint: {
    type: InputType.temperature,
    value: 75,
    defaultValue: 75,
    display: "High Hydraulic Temp Set Point",
    info:
      "If ‘Hydraulic Temperature’ is enabled under the ‘Option Settings Tab’, this set-point defines the temperature at which the EPEQ® IM will shut off the engine when running for low hydraulic temperature",
    rules: ["required"],
    minValue: 0,
    maxValue: 100,
  },
  lowHydraulicTempSetPoint: {
    type: InputType.temperature,
    value: 45,
    defaultValue: 45,
    display: "Low Hydraulic Temp Set Point",
    info:
      "If ‘Hydraulic Temperature’ is enabled under the ‘Option Settings Tab’, this set-point defines the temperature at which the EPEQ® IM will turn on the engine when in monitoring mode.",
    rules: ["required"],
    minValue: 20,
    maxValue: 97,
  },
};
