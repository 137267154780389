import { InputType, listBoolParameter, listParameter, temperatureParameter } from "../OptionParameter";

import { enabledUnits } from "@/model/VehicleProfile/Lists";

import { convertTemperatureFromCelsius } from "@/helpers/UnitService";

export enum HeatingOption {
  EngineOnly = "EngineOnly",
  CoolantPump = "CoolantPump",
  AuxiliaryHeater = "AuxiliaryHeater",
  AirHeater = "AirHeater",
  ComboHeater = "ComboHeater",
  ProheatX30 = "ProheatX30",
}

export enum HeatingSchedulerOption {
  FleetScheduler = "Fleet Scheduler",
  SevenDayScheduler = "Seven Day Scheduler",
}

export interface heatingOptions {
  heatingEnabled: listBoolParameter;
  heatingOption: listParameter;
  coolantPumpHighCoolantTempSetPoint: temperatureParameter;
  coolantPumpLowEngineCoolantTempSetPoint: temperatureParameter;
  heatContinuousOperationOnOff: listBoolParameter;
  heatContinuousOperationSetPoint: temperatureParameter;
  climateControlEnable: listBoolParameter;
  heaterTempOffset: temperatureParameter;
  heatingSchedulerManager: listParameter;
}

export const defaultHeatingOptions: heatingOptions = {
  heatingEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Monitor for Heat",
    info: "This feature if enabled and the system is in monitoring mode, will monitor the cab for heating (default is OFF).",
  },
  heatingOption: {
    type: InputType.list,
    value: HeatingOption.CoolantPump,
    defaultValue: HeatingOption.CoolantPump,
    list: [
      {
        display: "Engine Only",
        value: HeatingOption.EngineOnly,
      },
      {
        display: "Coolant Pump",
        value: HeatingOption.CoolantPump,
      },
      {
        display: "Auxiliary Heater",
        value: HeatingOption.AuxiliaryHeater,
      },
      {
        display: "Air Heater",
        value: HeatingOption.AirHeater,
      },
      {
        display: "Combo Heater",
        value: HeatingOption.ComboHeater,
      },
      {
        display: "Proheat X30",
        value: HeatingOption.ProheatX30,
      },
    ],
    display: "Heating Option",
    info: `
    <ul>
      <li>Engine Only - If selected, this will start the vehicle whenever heat is required. The vehicle climate controls must be set to heat in the cab.</li>
      <li>Coolant Pump - If selected, this option will utilize the heat created from the engine running and will circulate that hot coolant through the existing vehicle heating system. The system will monitor the coolant temperature and when it reaches the ‘Low Engine Coolant Set Point’, will start the engine and run until it reaches the ‘High Engine Coolant Temp Set Point’.</li>
      <li>Auxiliary Heater - If selected, this option will allow the EPEQ® IM System to operate a fuel fired heater to produce heat rather than using the engine.</li>
      <li>Air Heater - If selected, this option will allow the EPEQ® IM System to control the fuel fired air heater. This heater will provide an alternate heat source for the cab. The air heater is controlled by an output which is configured by the user in the ‘Configurable I/O’ tab.</li>
      <li>Combo Heater - If selected, this option will allow the EPEQ® IM System to control two different heat sources: an air heater and a coolant heater. The air heater will work the same as the above ‘Air Heater’ option. The coolant heater will only work when the scheduler is selected and configured. See ‘Scheduler Settings’ to setup the scheduler and proper operation.</li>
      <li>ProHeat X30 – If selected, this option will allow the EPEQ® IM system to control the ProHeat X30 fuel fired heater. This heater will provide an alternate heat source for the coolant/cab. The heater is controlled by CAN communication and allows for data collection of the heater operation.</li>
    </ul>
    `,
  },
  coolantPumpHighCoolantTempSetPoint: {
    type: InputType.temperature,
    value: 75,
    defaultValue: 75,
    display: "Coolant Pump High Coolant Temp Set Point",
    info:
      "This is the temperature at which the engine will shut off, after it has been started for ‘Low Engine Coolant Temp’. See ‘Low Engine Coolant Temp Set Point’ in which the system will start the engine if the temperature of the coolant falls below. (default is 75°C/167°F).",
    rules: ["required"],
    minValue: 60,
    maxValue: 100,
  },
  coolantPumpLowEngineCoolantTempSetPoint: {
    type: InputType.temperature,
    value: 45,
    defaultValue: 45,
    display: "Coolant Pump Low Engine Coolant Temp Set Point",
    info:
      "This is the temperature at which the engine will start due to the ‘Low Engine Coolant Temp Set Point’. The ‘Auxiliary Heater’ option must be selected from the ‘Heating Option Selector’ to allow the EPEQ® IM system to start for low coolant temperature (default is 45°C/113°F).",
    rules: ["required"],
    minValue: 30,
    maxValue: 100,
  },
  heatContinuousOperationOnOff: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: [
      {
        display: "On",
        value: true,
      },
      {
        display: "Off",
        value: false,
      },
    ],
    display: "Heat Continuous Operation On/Off",
    info:
      "When ‘Engine Only’ is selected in the ‘Heating Option’, this will allow the vehicle to stay running for heat when the outside temperature is less than the ‘Heat Continuous Operation Set Point’. See ‘Heat Continuous Operation Set Point’ for further information (default is OFF).",
  },
  heatContinuousOperationSetPoint: {
    type: InputType.temperature,
    value: -20,
    defaultValue: -20,
    display: "Heat Continuous Operation Set Point",
    info:
      "This is the outside temperature in which the system will prevent the engine from shutting down for ‘Max Run Time’. This should be set to a point that the vehicles climate system will struggle to heat the cab (default is -20°C/ 4°F).",
    rules: ["required"],
    minValue: null,
    maxValue: null,
  },
  climateControlEnable: {
    type: InputType.list,
    value: true,
    defaultValue: true,
    list: enabledUnits,
    display: "Climate Control Enable",
    info:
      "This setting enables the operator to have control over the ‘Temperature Set Point’. In some cases, the temperature is best to be fixed such as heating or cooling products (default is ON)",
  },
  heaterTempOffset: {
    type: InputType.temperature,
    value: 5,
    defaultValue: 5,
    display: "Heater Temp Offset",
    info: "This setting is the number of degrees below the ‘Temperature Set Point’ that the heater will be activated. (default is 5°C offset).",
    rules: ["required"],
    minValue: 0,
    maxValue: 40,
  },
  heatingSchedulerManager: {
    type: InputType.list,
    value: HeatingSchedulerOption.FleetScheduler,
    defaultValue: HeatingSchedulerOption.FleetScheduler,
    list: [
      {
        display: "Fleet Scheduler",
        value: HeatingSchedulerOption.FleetScheduler,
      },
      {
        display: "Seven Day Scheduler",
        value: HeatingSchedulerOption.SevenDayScheduler,
      },
    ],
    display: "Scheduler Manager",
    info: `
    <ul>
      <li>The ‘Fleet Scheduler’ uses scheduler 1, 2, and 3, which can be set up independently of each. This scheduler can only be set up
      and maintained by the Service Tool. (Scheduler set up is on the vehicle profile on the dashboard)</li>
      <li>
        The ‘Seven Day Scheduler’ can be set up and maintained by the operator through the EPEQ® IM Screen or the service tool. Unlike the
        Fleet scheduler it can only be programmed to run for one interval before the operator needs to reset for the next scheduled period to
        follow.
        <br>
        Seven Day Scheduler
          <ul>
            <li>Enable - If enabled this will turn on the auxiliary heater to run for the selected time and day.</li>
            <li>Time Select - Select the time in which the auxiliary heater willstart.</li>
            <li>Day Select – Selects the day of the week in which the heater will start.</li>
            <li>Scheduler Running - This indicates when the scheduler is in operation for troubleshooting.</li>
            <li>Scheduler Max Heater Run Time - Selects the amount of time the auxiliary heater will be set to run (default is 90 Minutes).</li>
            <li>Current Time - Displays the current time of the clock.
          </ul>  
        (Scheduler set up is on the vehicle profile on the dashboard)
      </li>
    </ul>
    `,
  },
};
