import { InputType, listBoolParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface PtoLightAuxOptions {
  ptoLightAuxEnabled: listBoolParameter;
}

export const defaultPtoLightAuxOptions: PtoLightAuxOptions = {
  ptoLightAuxEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "PTO/Light/Aux Option",
    info:
      "If this option is enabled, the input signaled from the Configurable I/O will then allow the vehicle to run continuously. If the EPEQ® IM System is in monitoring mode and the engine is off, the signal will allow the engine to start and stay running (default disabled).",
  },
};
