


























































































import Vue from "vue";

// Plugin Helpers
import instance from "@/axios";

// Models
import { Client } from "@/model/API/Client";
import { Zone } from "@/model/API/Zone";
import { CreateZone } from "@/model/API/CreateZone";
import { UpdateZone } from "@/model/API/UpdateZone";

export default Vue.extend({
  name: "UserGroups",
  components: {},
  created: async function() {
    const responseCompany = await instance.get<Client[]>(`/Client`);

    if (responseCompany.data) {
      this.companies = responseCompany.data;
      if (this.companies.length == 1) {
        this.clientId = this.companies[0].clientId;
      }
      this.getZones();
    }
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    clientId: null as string | null,
    zones: [] as Zone[],
    companies: [] as Client[],
    selectedZone: null as Zone | null,
    headers: [
      { text: "Zone name", value: "zoneName" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    newZoneName: null as string | null,
  }),
  computed: {
    companyName(): string {
      let companies = this.companies.filter((e) => e.clientId == this.clientId);

      if (companies.length > 0) {
        return companies[0].company;
      } else {
        return "";
      }
    },
  },
  methods: {
    async getZones() {
      let url = "/Zone/Filter";
      if (this.clientId) {
        url += `?clientId=${this.clientId}`;
      }

      const response = await instance.get<Zone[]>(url);

      this.zones = response.data;
    },
    companyUpdated() {
      this.getZones();
    },
    close() {
      this.dialog = false;
      this.selectedZone = null;
      this.newZoneName = null;
    },
    async save() {
      if (this.selectedZone) {
        let newZone: UpdateZone = {
          clientId: this.clientId ?? "",
          zoneName: this.newZoneName ?? "",
          zoneId: this.selectedZone.zoneId,
        };
        await instance
          .put(`/Zone/${this.selectedZone.zoneId}`, newZone)
          .then(() => {
            this.dialog = false;
            this.close();
            this.getZones();
          })
          .catch(() => {
            // TODO
          });
      } else {
        let newZone: CreateZone = {
          clientId: this.clientId ?? "",
          zoneName: this.newZoneName ?? "",
        };

        await instance
          .post("/Zone", newZone)
          .then(() => {
            this.dialog = false;
            this.close();
            this.getZones();
          })
          .catch(() => {
            // TODO
          });
      }
    },
    editZone(item: Zone) {
      this.dialog = true;
      this.selectedZone = item;
      this.newZoneName = item.zoneName;
    },
    deleteZone(item: Zone) {
      this.selectedZone = item;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedZone = null;
    },
    async deleteZoneConfirm() {
      if (this.selectedZone) {
        await instance
          .delete(`/Zone/${this.selectedZone.zoneId}`)
          .then(() => {
            this.closeDelete();
            this.getZones();
          })
          .catch(() => {
            // TODO
          });
      }
    },
  },
});
