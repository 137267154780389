import { InputType, listBoolParameter } from "../OptionParameter";

import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface BrakeStartOptions {
  brakeStartEnabled: listBoolParameter;
}

export const defaultBrakeStartOptions: BrakeStartOptions = {
  brakeStartEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Brake Start",
    info:
      "This feature if enabled and the system is in the monitoring mode, will start the engine by the driver depressing the brake pedal. (default disabled)",
  },
};
