import { Equipment, defaultEquipment } from "./Equipment";
import { EquipmentSelected, defaultEquipmentSelected } from "./EquipmentSelected";

export interface VehicleEquipment {
  options: Equipment;
  selected: EquipmentSelected;
}

const defaulEquipment = {
  options: defaultEquipment(),
  selected: defaultEquipmentSelected(),
};

export function defaultVehicleEquipment(): VehicleEquipment {
  return JSON.parse(JSON.stringify(defaulEquipment));
}
