












































































































import Vue from "vue";

import InfoModal from "@/components/InfoModal.vue";

import { mapGetters, mapActions } from "vuex";
import { optionParameter, InputType, temperatureParameter } from "@/model/VehicleProfile/OptionParameter";

import { OperatorControls } from "@/model/VehicleProfile/OperatorControls/OperatorControls";

import { required, minValue, maxValue, ValidationRule } from "vuelidate/lib/validators";

import { convertTemperatureFromCelsius } from "@/helpers/UnitService";
import { OptionalEquipment } from "@/model/VehicleProfile/OptionalEquipment/OptionalEquipment";
import { HeatingOption } from "@/model/VehicleProfile/StandardOptions/HeatingOptions";
import { TemperatureUnit } from "@/model/Units";
import { SetHiddenFieldValidation } from "@/helpers/VehicleValidation";

export default Vue.extend({
  name: "OperatorControls",
  components: { InfoModal },
  /**
   * Ensure the form is saved when the component is destroyed
   */
  destroyed: function() {
    this.save();
  },
  /**
   * Create the vuelidate validation object by looping through all the input's to create the object.
   */
  validations() {
    let validations: Record<string, Record<string, Record<string, Record<string, Record<string, ValidationRule | (() => ValidationRule)>>>>> = {
      form: {},
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var form: Record<string, any> = this.form;

    // For every property in the form, loop through and generate the vuelidates rules.
    // For example: Go through every property in the operatorControl interface, then go through every property inside the operatorControl interface.
    for (const section in this.form) {
      validations["form"][section] = {};
      for (const param in form[section]) {
        // If we shouldn't display the input we do not want to add validation for it, but we may need to update the form values
        if (!this.shouldDisplay(param)) {
          form = SetHiddenFieldValidation(
            form,
            section,
            param,
            this.userTemperatureUnits
          );
          continue;
        }

        validations["form"][section][param] = {
          value: {},
        };

        // If the input is required, add the required method from vuelidate
        if (form[section][param].rules && form[section][param].rules.includes("required")) {
          validations["form"][section][param]["value"] = {
            ...validations["form"][section][param]["value"],
            required,
          };
        }

        // If the input has a minValue, add the minValue method from vuelidate
        if (form[section][param].minValue != null) {
          let value = form[section][param].minValue;
          if (form[section][param].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          validations["form"][section][param]["value"] = {
            ...validations["form"][section][param]["value"],
            minValue: minValue(value),
          };
        }

        // If the input has a maxValue, add the maxValue method from vuelidate
        if (form[section][param].maxValue != null) {
          let value = form[section][param].maxValue;
          if (form[section][param].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          validations["form"][section][param]["value"] = {
            ...validations["form"][section][param]["value"],
            maxValue: maxValue(value),
          };
        }
      }
    }

    return validations;
  },
  data: () => ({
    isInfoModalOpen: false,
    infoModalTitle: "",
    infoModalMessage: "",
    InputType: InputType,
    resetIcon: "mdi-close",
    infoIcon: "mdi-information",
    temperatureUnits: [
      {
        text: "C",
        value: "c",
      },
      {
        text: "F",
        value: "f",
      },
    ],

    validationSnackbar: false,
  }),
  methods: {
    ...mapActions(["setOperatorControls", "updateOperatorControls"]),
    // Saves the data to the vuex store
    save() {
      this.setOperatorControls(this.form);
    },
    /**
     * Called everything an input value is changed.
     * If it's an existing vehicle submit the updated settings.
     * If the vehicle already exists, don't submit the updates and wait for the user to submit the entire settings file
     */
    updated() {
      if (!this.isCreatingNewVehicle) {
        if (!this.$v.form.$invalid) {
          this.setOperatorControls(this.form);
          this.updateOperatorControls();
        } else {
          this.validationSnackbar = true;
        }
      }
    },
    resetTemperature(key: temperatureParameter) {
      key.value = convertTemperatureFromCelsius(key.defaultValue, this.userTemperatureUnits);
      this.updated();
    },
    /**
     * Reset the input back to the default value
     */
    reset(key: optionParameter) {
      key.value = key.defaultValue;
      this.updated();
    },
    /**
     * Determine the error messages for the input field.
     * @classKey The key of the outer interface. This would be the keys of the OperatorControls interface (Such as OperatorControlOptions)
     * @classKey The key of the property being displayed. For example this would be the celsiusOrFahrenheit that is inside the OperatorControlOptions interface.
     * @label The display value of the input. This is used to generate a specific error message with the input name in it.
     */
    errorMessages(classKey: string, propertyKey: string, label: string) {
      const errors: string[] = [];

      // Ensure that the form is created
      if (!this.$v.form) {
        return errors;
      }

      // Ensure that the interface is part of the form
      if (!this.$v.form[classKey]) {
        return errors;
      }

      // Ensure that the property exists in the interface
      if (!this.$v.form[classKey]?.[propertyKey]) {
        return errors;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let form: any = this.form;

      // If the property has the required rule and there is a required error, add the error message
      if (form[classKey][propertyKey].rules && form[classKey][propertyKey].rules.includes("required")) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        !this.$v.form[classKey]![propertyKey]["value"].required && errors.push(`${label} is required`);
      }

      // If the property is a number type and has the min value rule and there is a min error, display a message
      if (form[classKey][propertyKey].type == InputType.number || form[classKey][propertyKey].type == InputType.temperature) {
        if (form[classKey][propertyKey].minValue != null) {
          let value = form[classKey][propertyKey].minValue;
          if (form[classKey][propertyKey].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          !this.$v.form[classKey]![propertyKey]["value"].minValue && errors.push(`${label} must be at least ${value}`);
        }
        // If the property is a number type and has the max value rule and there is a max error, display a message
        if (form[classKey][propertyKey].maxValue != null) {
          let value = form[classKey][propertyKey].maxValue;
          if (form[classKey][propertyKey].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          !this.$v.form[classKey]![propertyKey]["value"].maxValue && errors.push(`${label} must not exceed ${value}`);
        }
      }

      return errors;
    },
    /**
     * Determine whether the input should be displayed. This will check to ensure that the option has been enabled before asking for settings
     */
    shouldDisplay(key: string) {
      switch (key) {
        // Operator Control Settings
        case "brakeStartEnabled":
          return true;

        case "sevenDayScheduler":
          return (
            this.optionalEquipmentSettings.heatingOptions.heatingOption.value == HeatingOption.AuxiliaryHeater ||
            this.optionalEquipmentSettings.heatingOptions.heatingOption.value == HeatingOption.ComboHeater ||
            this.optionalEquipmentSettings.heatingOptions.heatingOption.value == HeatingOption.ProheatX30
          );
      }

      return true;
    },
    /**
     * Determine whether a section should be displayed.
     * The section should be displayed if at least one property within the section should be displayed
     */
    shouldDisplaySection(properties: Record<string, unknown>) {
      for (const property in properties) {
        if (this.shouldDisplay(property)) return true;
      }
      return false;
    },
    /**
     * Display the info modal to the user for the selected field
     */
    displayMessage(key: optionParameter) {
      this.isInfoModalOpen = true;
      this.infoModalTitle = this.getLabel(key);
      this.infoModalMessage = key.info;
    },
    /**
     * Get the display label for the field
     */
    getLabel(key: optionParameter) {
      return key.display;
    },
    /**
     * Close the Info Dialog
     */
    close() {
      this.isInfoModalOpen = false;
    },
    /**
     * Determine the display value of the tab based on the key
     */
    getKeyDisplay(key: string) {
      switch (key) {
        case "operatorControl":
          return "System Settings";
        case "climateSettingOptions":
          return "Climate Settings";
        case "auxiliaryHeaterFunctionOptions":
          return "Auxiliary Heater Functions";
      }
      return "TODO";
    },
  },
  computed: {
    ...mapGetters(["operatorControls", "isCreatingNewVehicle", "isKitSelected", "userTemperatureUnitsDisplay", "userTemperatureUnits", "optionalEquipment"]),
    form(): OperatorControls {
      return this.operatorControls;
    },
    optionalEquipmentSettings(): OptionalEquipment {
      return this.optionalEquipment;
    },
    validationErrors(): string[] {
      let errors = this.$v
        .$flattenParams()
        .map((x) => this.errorMessages(x.path[1], x.path[2], (this.form as any)[x.path[1]][x.path[2]].display))
        .flat()
        .filter((value, index, array) => array.indexOf(value) == index);
      return errors;
    },
  },
});
