import { InputType, numberParameter, listBoolParameter, listNumberParameter, listParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export enum WatchDogOption {
  main = "main",
  auxiliary = "auxiliary",
  both = "both",
  off = "off",
}

export enum BatteryOption {
  MainBattery = "MainBattery",
  AuxBatteryExtend = "AuxBatteryExtend",
  AuxBatteryEither = "AuxBatteryEither",
}

export interface idleControlOptions {
  maxRunTime: numberParameter;
  maxIdleTime: numberParameter;
  maxRPMForIdle: numberParameter;
  startStopWarningTime: numberParameter;
  throttleOverride: listBoolParameter;
  throttleOverrideLimit: listNumberParameter;
}

export const defaultIdleControlOptions: idleControlOptions = {
  maxRunTime: {
    type: InputType.number,
    value: 15,
    defaultValue: 15,
    display: "Max Run Time",
    info: `This is the maximum amount of time that the vehicle will run once it has started in the monitoring mode for the following conditions: (default 15 Mins):
      <ul>
        <li>Low Battery (provided the Current Sensor Option is not installed)</li>
        <li>Air Conditioning</li>
        <li>Heating</li>
      </ul>`,
    rules: ["required"],
    minValue: 5,
    maxValue: 60,
  },
  maxIdleTime: {
    type: InputType.number,
    value: 1,
    defaultValue: 1,
    display: "Max Idle Time",
    info: `This is the amount of time that the engine runs in Park or Neutral before the EPEQ® IM System shuts the engine down. 1 to 5 minutes is recommended; however, the setting maximum is 60 minutes. Jurisdictions can vary with regards to limits on idle time, this may be a consideration when programming. Adding more than what is required will significantly reduce the savings (default is 1 minute)`,
    rules: ["required"],
    minValue: 1,
    maxValue: 60,
  },
  maxRPMForIdle: {
    type: InputType.number,
    value: 900,
    defaultValue: 900,
    display: "Max RPM For Idle",
    info: `The max idle rpm should only be set once. This setting is used to identify whether the vehicle is idling or it is above idle (default RPM is 900).`,
    rules: ["required"],
    minValue: 550,
    maxValue: 3000,
    increment: 50,
  },
  startStopWarningTime: {
    type: InputType.number,
    value: 15,
    defaultValue: 15,
    display: "Start Stop Warning Time",
    info: `This is the amount of time that the system counts down to warn the operator before the engine will shut down or start up (default is 15s).`,
    rules: ["required"],
    minValue: 5,
    maxValue: 60,
  },
  throttleOverride: {
    type: InputType.list,
    display: "Throttle Override",
    list: [
      {
        display: "On",
        value: true,
      },
      {
        display: "Off",
        value: false,
      },
    ],
    value: false,
    defaultValue: false,
    info: `This setting enables the Throttle Override to be used by the operator. If the system is counting to shut down the engine and the operator snaps the throttle over the idle rpm, it will reset the count for the ‘Max Idle Time’. This is typically a safety override (default is OFF)`,
  },
  throttleOverrideLimit: {
    type: InputType.list,
    value: 1,
    defaultValue: 1,
    display: "Throttle Override Limit",
    info: `This setting limits the number of times the operator will be able to use the Throttle Override to prevent the vehicle from shutting down. (Default is empty)`,
    list: [
      {
        display: "Infinite",
        value: 0,
      },
      {
        display: "1",
        value: 1,
      },
      {
        display: "2",
        value: 2,
      },
      {
        display: "3",
        value: 3,
      },
      {
        display: "4",
        value: 4,
      },
      {
        display: "5",
        value: 5,
      },
      {
        display: "6",
        value: 6,
      },
      {
        display: "7",
        value: 7,
      },
      {
        display: "8",
        value: 8,
      },
      {
        display: "9",
        value: 9,
      },
      {
        display: "10",
        value: 10,
      },
    ],
  },
};
