import {
  BrakeStartOptions,
  defaultBrakeStartOptions,
} from "@/model/VehicleProfile/OptionalFeatures/BrakeStart";
import {
  SeatBeltStartOptions,
  defaultSeatBeltStartOptions,
} from "@/model/VehicleProfile/OptionalFeatures/SeatBeltStart";
import {
  EngineStartHydraulicOptions,
  defaultEngineStartHydraulicOptions,
} from "@/model/VehicleProfile/OptionalFeatures/EngineStartHydraulic";
import {
  AirPressureMonitoringOptions,
  defaultAirPressureMonitoringOptions,
} from "@/model/VehicleProfile/OptionalFeatures/AirPressureMonitoring";
import {
  DoorStartOptions,
  defaultDoorStartOptions,
} from "@/model/VehicleProfile/OptionalFeatures/DoorStart";
import {
  ShorePowerOptions,
  defaultShorePowerOptions,
} from "@/model/VehicleProfile/OptionalFeatures/ShorePower";
import {
  HightIdleOptions,
  defaultHightIdleOptions,
} from "@/model/VehicleProfile/OptionalFeatures/HighIdle";
import {
  AntiTheftOptions,
  defaultAntiTheftOptions,
} from "@/model/VehicleProfile/OptionalFeatures/AntiTheft";
import {
  defaultSafeModeOptions,
  SafeModeOptions,
} from "@/model/VehicleProfile/OptionalFeatures/SafeMode";
import {
  defaultPtoLightAuxOptions,
  PtoLightAuxOptions,
} from "@/model/VehicleProfile/OptionalFeatures/PtoLightAux";
import {
  defaultElectricParkingBrakeOptions,
  ElectricParkingBrakeOptions,
} from "@/model/VehicleProfile/OptionalFeatures/ElectricParkingBrake";
import {
  defaultOverrideShutdownOptions,
  OverrideShutdownOptions,
} from "@/model/VehicleProfile/OptionalFeatures/ShutdownOverride";

export interface OptionalFeatures {
  brakeStart: BrakeStartOptions;
  seatBeltStart: SeatBeltStartOptions;
  engineStartHydraulic: EngineStartHydraulicOptions;
  airPressureMonitoring: AirPressureMonitoringOptions;
  doorStart: DoorStartOptions;
  shorePower: ShorePowerOptions;
  highIdle: HightIdleOptions;
  antiTheft: AntiTheftOptions;
  safeMode: SafeModeOptions;
  ptoAuxLight: PtoLightAuxOptions;
  electricParkBrake: ElectricParkingBrakeOptions;
  overrideShutdown: OverrideShutdownOptions;
}

const defaultOptionalFeaturesObject: OptionalFeatures = {
  brakeStart: Object.assign({}, defaultBrakeStartOptions),
  seatBeltStart: Object.assign({}, defaultSeatBeltStartOptions),
  engineStartHydraulic: Object.assign({}, defaultEngineStartHydraulicOptions),
  airPressureMonitoring: Object.assign({}, defaultAirPressureMonitoringOptions),
  doorStart: Object.assign({}, defaultDoorStartOptions),
  shorePower: Object.assign({}, defaultShorePowerOptions),
  highIdle: Object.assign({}, defaultHightIdleOptions),
  antiTheft: Object.assign({}, defaultAntiTheftOptions),
  safeMode: Object.assign({}, defaultSafeModeOptions),
  ptoAuxLight: Object.assign({}, defaultPtoLightAuxOptions),
  electricParkBrake: Object.assign({}, defaultElectricParkingBrakeOptions),
  overrideShutdown: Object.assign({}, defaultOverrideShutdownOptions),
};

function defaultOptionalFeaturesCopy(): OptionalFeatures {
  return JSON.parse(JSON.stringify(defaultOptionalFeaturesObject));
}

export const defaultOptionalFeatures = defaultOptionalFeaturesCopy;
