import { batteryOptions, defaultBatteryOptions } from "./BatterOptions";
import { idleControlOptions, defaultIdleControlOptions } from "./IdleControlOptions";
import { ignitionSignalControl, defaultIgnitionSignalControl } from "./IgnitionSignalControl";

export interface StandardOptions {
  idleControlOptions: idleControlOptions;
  ignitionSignalControl: ignitionSignalControl;
  batteryOptions: batteryOptions;
}

const defaultStandardOptionsObject: StandardOptions = {
  idleControlOptions: Object.assign({}, defaultIdleControlOptions),
  ignitionSignalControl: Object.assign({}, defaultIgnitionSignalControl),
  batteryOptions: Object.assign({}, defaultBatteryOptions),
};

function defaultStandardOptionsCopy(): StandardOptions {
  return JSON.parse(JSON.stringify(defaultStandardOptionsObject));
}

export const defaultStandardOptions = defaultStandardOptionsCopy;
