import { InputType, numberParameter, listBoolParameter } from "../OptionParameter";

import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface AirPressureMonitoringOptions {
  airPressureMonitoringEnabled: listBoolParameter;
  airPressureSetPoint: numberParameter;
  airPressureOffset: numberParameter;
}

export const defaultAirPressureMonitoringOptions: AirPressureMonitoringOptions = {
  airPressureMonitoringEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Air Pressure Monitoring",
    info:
      "This feature if enabled and the system is in the monitoring mode, will start the engine once the air pressure drops below the ‘Air Pressure Set Point’ and then run until it reaches the‘Air Pressure Set Point + the Air Pressure Offset’. When the vehicle is running for this option, the air pressure icon will be displayed on the screen (default disabled).",
  },
  airPressureSetPoint: {
    type: InputType.number,
    value: 100,
    defaultValue: 100,
    display: "Air Pressure Set Point",
    info:
      "This is the set point at which the EPEQ® IM System will shut the engine off when the vehicle is running to build up air pressure. The Range is from 75 to 120 PSI (default 100 PSI).",
    rules: ["required"],
    minValue: 75,
    maxValue: 120,
  },
  airPressureOffset: {
    type: InputType.number,
    value: 10,
    defaultValue: 10,
    display: "Air Pressure Offset",
    info:
      "This setting is the amount of pressure below the ‘Air Pressure Set Point’ that the engine will start and run until it reaches the ‘Air Pressure Set Point. The ‘Air Pressure Start’ feature must be enabled, and the vehicle must also be equipped with air brakes (which the EPEQ® IM System can read) for this option to work. Check ‘Air Pressure’ gauge on ‘Real Time Information 2’ tab to see if the air pressure can be read by the EPEQ® IM System. The Range is from 5 to 50 PSI (default 10 PSI).",
    rules: ["required"],
    minValue: 5,
    maxValue: 50,
  },
};
