import instance from "@/axios";

import { Module } from "vuex";
import { RootState } from "./index";

import { CreateVehicle } from "@/model/API/CreateVehicle";

import { convertTemperatureFromCelsius, convertTemperatureToCelsius, convertFuelFromLitres, convertFuelToLitres } from "@/helpers/UnitService";
import { GeneralInfo, defaultGeneralInfo } from "@/model/VehicleProfile/GeneralInfo";

import { defaultStandardOptions, StandardOptions } from "@/model/VehicleProfile/StandardOptions/StandardOptions";
import { CreateStandardOptions } from "@/model/API/CreateVehicleOptions";
import { CreateOptionalFeatures } from "@/model/API/CreateVehicleOptionalFeatures";
import { CreateOptionalEquipment } from "@/model/API/CreateVehicleOptionalEquipment";
import { CreateInputsOutputs } from "@/model/API/CreateVehicleInputOutputs";
import { Vehicle } from "@/model/API/Vehicle";
import { VehicleStandardOptions } from "@/model/API/VehicleStandardOptions";
import { VehicleOptionalFeatures } from "@/model/API/VehicleOptionalFeatures";
import { VehicleOptionalEquipment } from "@/model/API/VehicleOptionalEquipment";
import { VehicleInputsOutputs } from "@/model/API/VehicleInputsOutputs";

import { defaultOptionalFeatures, OptionalFeatures } from "@/model/VehicleProfile/OptionalFeatures/OptionalFeatures";
import { defaultOptionalEquipment, OptionalEquipment } from "@/model/VehicleProfile/OptionalEquipment/OptionalEquipment";
import { defaultOperatorControls, OperatorControls } from "@/model/VehicleProfile/OperatorControls/OperatorControls";

import { VehicleOperatorControls } from "@/model/API/VehicleOperatorControls";
import { CreateOperatorControls } from "@/model/API/CreateVehicleOperatorControls";

import { VehicleEquipment, defaultVehicleEquipment } from "@/model/VehicleProfile/VehicleEquipment";
import { Equipment } from "@/model/VehicleProfile/Equipment";
import { EquipmentSelected } from "@/model/VehicleProfile/EquipmentSelected";
import { Part } from "@/model/API/Part";
import { VehicleKitEquipment } from "@/model/API/VehicleKitEquipment";
import { CreateVehicleKitEquipment } from "@/model/API/CreateVehicleKitEquipment";
import { Kit } from "@/model/API/Kit";
import { defaultIntputsOutputs, InputsOutputs } from "@/model/VehicleProfile/InputsOutputs/InputsOutputs";
import { InputType } from "@/model/VehicleProfile/OptionParameter";

interface VehicleState {
  isCreatingNewVehicle: boolean;
  isUpdatingVehicle: boolean;
  vehicleID: number | null;
  generalInfo: GeneralInfo;
  standardOptions: StandardOptions;
  optionalFeatures: OptionalFeatures;
  optionalEquipment: OptionalEquipment;
  operatorControls: OperatorControls;
  vehicles: Vehicle[];
  vehicleEquipment: VehicleEquipment;
  vehicle: Vehicle | null;
  inputsOutputs: InputsOutputs;
}

export const VehicleStore: Module<VehicleState, RootState> = {
  state: () => ({
    isCreatingNewVehicle: false,
    isUpdatingVehicle: false,
    vehicleID: null as number | null,
    generalInfo: defaultGeneralInfo() as GeneralInfo,
    standardOptions: defaultStandardOptions() as StandardOptions,
    optionalFeatures: defaultOptionalFeatures() as OptionalFeatures,
    optionalEquipment: defaultOptionalEquipment() as OptionalEquipment,
    operatorControls: defaultOperatorControls() as OperatorControls,
    vehicles: [] as Vehicle[],
    vehicleEquipment: defaultVehicleEquipment() as VehicleEquipment,
    vehicle: null as Vehicle | null,
    inputsOutputs: defaultIntputsOutputs() as InputsOutputs,
  }),
  getters: {
    vehicleEquipment(state: VehicleState): VehicleEquipment {
      return state.vehicleEquipment;
    },
    isKitSelected(state: VehicleState): boolean {
      return state.generalInfo.kit ? true : false;
    },
    selectedKit(state: VehicleState): Kit | null {
      return state.generalInfo.kit;
    },
    generalInfo(state: VehicleState): GeneralInfo {
      return state.generalInfo;
    },
    inputsOutputs(state: VehicleState): InputsOutputs {
      return state.inputsOutputs;
    },
    standardOptions(state: VehicleState): StandardOptions {
      return state.standardOptions;
    },
    operatorControls(state: VehicleState): OperatorControls {
      return state.operatorControls;
    },
    optionalFeatures(state: VehicleState): OptionalFeatures {
      return state.optionalFeatures;
    },
    optionalEquipment(state: VehicleState): OptionalEquipment {
      return state.optionalEquipment;
    },
    isCreatingNewVehicle(state: VehicleState): boolean {
      return state.isCreatingNewVehicle;
    },
    isCreatingOrUpdating(state: VehicleState): boolean {
      return state.isCreatingNewVehicle || state.isUpdatingVehicle;
    },
    assetName(state: VehicleState): string {
      return state.generalInfo.assetName;
    },
    vehicleId(state: VehicleState): number {
      return state.vehicleID ?? 0;
    },
    vehicleVIN(state: VehicleState): string | null {
      return state.vehicle?.vin ?? null;
    },
    vehicleIMEI(state: VehicleState): string | null {
      return state.vehicle?.idleTrackerIMEI ?? null;
    },
    machineId(state: VehicleState): string | null {
      return state.vehicle?.machineId ?? null;
    },
    vehicles(state: VehicleState): Vehicle[] {
      return state.vehicles;
    },
    supportsAirPressureMonitoring(state: VehicleState): boolean {
      return state.generalInfo.kit?.airPressureMonitoring ?? false;
    },
    supportsBrakeStart(state: VehicleState) {
      return state.generalInfo.kit?.brakeStart;
    },
    supportsEngineStartHydraulic(state: VehicleState): boolean {
      return state.generalInfo.kit?.engineStartHydraulic ?? false;
    },
    supportsSeatBeltStart(state: VehicleState): boolean {
      return state.generalInfo.kit?.seatBeltStart ?? false;
    },
    supportsParkBrake(state: VehicleState): boolean {
      return state.generalInfo.kit?.parkBrake ?? false;
    },
    supportsElectricBrake(state: VehicleState): boolean {
      return state.generalInfo.kit?.electricBrake ?? false;
    },
    supportsHydraulicBrake(state: VehicleState): boolean {
      return state.generalInfo.kit?.hydraulicBrake ?? false;
    },
    supportsDoorStartOnCan(state: VehicleState): boolean {
      return state.generalInfo.kit?.doorOnCan ?? false;
    },
  },
  actions: {
    /**
     * Reset the store back to default settings
     */
    resetStore({ commit }) {
      commit("RESET_STORE");
    },
    /**
     * Sets the optional equipment that is available on the vehicle
     */
    setEquipmentOptions({ commit }, options: Equipment) {
      commit("SET_EQUIPMENT_OPTIONS", options);
    },
    /**
     * Sets the input output configuration for the vehicle
     */
    setInputsOutputs({ commit }, inputsOutputs: InputsOutputs) {
      commit("SET_INPUTS_OUTPUTS", inputsOutputs);
    },
    /**
     * Sets the optional equipment that the user has selected
     */
    setEquipmentOptionSelected({ commit }, options: Equipment) {
      commit("SET_EQUIPMENT_OPTIONS_SELECTED", options);
    },
    /**
     * Update the vuex store with a new list of vehicles
     */
    setVehicles({ commit }, vehicles: Vehicle[]) {
      commit("SET_VEHICLES", vehicles);
    },
    /**
     * Update the local copy of the general info for a vehicle
     */
    setGeneralInfo({ commit }, generalInfo: GeneralInfo) {
      commit("SET_GENERAL_INFO", generalInfo);
    },
    /**
     * Update the local copy of the standard options for a vehicle
     */
    setStandardOptions({ commit }, standardOptions: StandardOptions) {
      commit("SET_STANDARD_OPTIONS", standardOptions);
    },
    /**
     * Update the local copy of the optional features for a vehicle
     */
    setOptionalFeatures({ commit }, optionalFeatures: OptionalFeatures) {
      commit("SET_OPTIONAL_FEATURES", optionalFeatures);
    },
    /**
     * Update the local copy of the optional equipment for a vehicle
     */
    setOptionalEquipment({ commit }, optionalEquipment: OptionalEquipment) {
      commit("SET_OPTIONAL_EQUIPMENT", optionalEquipment);
    },
    /**
     * Update the local copy of the operator controls for a vehicle
     */
    setOperatorControls({ commit }, operatorControls: OperatorControls) {
      commit("SET_OPERATOR_CONTROLS", operatorControls);
    },
    setVehicle({ commit }, vehicle: Vehicle) {
      commit("SET_VEHICLE", vehicle);
    },
    /**
     * Set a flag to indicate that a new vehicle is being created
     */
    setCreatingVehicle({ commit }) {
      commit("CREATING_VEHICLE");
    },

    /**
     * Retrieve all of the settings (Optional Equipment, Standard Options, Optional Features, Operator Controls) of a vehicle from the API and set the values locally
     */
    async retrieveSettings({ dispatch, getters }, e: Vehicle) {
      return new Promise<void>(async (myResolve, myReject) => {
        if (e.kit) {
          const msg = "Added from kit import";
          const kitOptions = e.kit.kitOptions;
          const ignitionBypass: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Ignition Bypass")?.parts ?? null;
          const alarm: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Alarm")?.parts ?? null;
          const antiTheft: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Option - Anti Theft")?.parts ?? null;
          const solenoid85: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Option - Solenoid" && e.option == "85A")?.parts ?? null;
          const solenoid200: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Option - Solenoid" && e.option == "200A")?.parts ?? null;
          const battery: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Option Battery")?.parts ?? null;
          const heatCoolantPump: Part[] | null =
            kitOptions.find((e) => e.heading.trim() == "Optional Equipment - Heat" && e.option == "Coolant Pump")?.parts ?? null;
          const heatCoolantHeater: Part[] | null =
            kitOptions.find((e) => e.heading.trim() == "Optional Equipment - Heat" && e.option == "Coolant Heater")?.parts ?? null;
          const heatAirHeater: Part[] | null =
            kitOptions.find((e) => e.heading.trim() == "Optional Equipment - Heat" && e.option == "Air Heater")?.parts ?? null;
          const airConditioning: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Optional Equipment - A/C")?.parts ?? null;
          const currentSensor: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Optional - Current Sensor")?.parts ?? null;
          const autoHVAC: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Optional Auto HVAC")?.parts ?? null;
          const humidity: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Optional Humidity")?.parts ?? null;
          const tempSensing: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Secondary Temp Sensing")?.parts ?? null;
          const rearFacilityControl: Part[] | null = kitOptions.find((e) => e.heading.trim() == "Rear Facility Control")?.parts ?? null;

          const equipment: Equipment = {
            ignitionBypass: ignitionBypass,
            antiTheft: antiTheft,
            alarm: alarm,
            solenoid85: solenoid85,
            solenoid200: solenoid200,
            battery: battery,
            heatCoolantPump: heatCoolantPump,
            heatCoolantHeater: heatCoolantHeater,
            heatAirHeater: heatAirHeater,
            airConditioning: airConditioning,
            currentSensor: currentSensor,
            autoHVAC: autoHVAC,
            humidity: humidity,
            tempSensing: tempSensing,
            rearFacilityControl: rearFacilityControl,
            useMyOwnBattery: false,
            useMyOwnAlarm: false,
            useMyOwnSolenoid: false,
            useMyOwnHeating: false,
          };

          dispatch("setEquipmentOptions", equipment);
        }

        const responseKitEquipment = await instance.get<VehicleKitEquipment>(`/Vehicle/${e.vehicleId}/KitEquipment`).catch(() => myReject());

        if (responseKitEquipment && responseKitEquipment.status == 200) {
          const ke: VehicleKitEquipment = responseKitEquipment.data;
          const kitEquipment: EquipmentSelected = {
            ignitionBypass: ke.ignitionBypass,
            antiTheft: ke.antiTheft,
            alarm: ke.alarm,
            solenoid: ke.solenoid,
            battery: ke.battery,
            batteryCount: ke.batteryCount,
            heat: ke.heat,
            airConditioning: ke.airConditioning,
            currentSensor: ke.currentSensor,
            autoHVAC: ke.autoHVAC,
            humidity: ke.humidity,
            tempSensing: ke.tempSensing,
            rearFacilityControl: ke.rearFacilityControl,
            useMyOwnBattery: ke.useMyOwnBattery,
            useMyOwnHeating: ke.useMyOwnHeating,
            useMyOwnAlarm: ke.useMyOwnAlarm,
            useMyOwnSolenoid: ke.useMyOwnSolenoid,
          };

          dispatch("setEquipmentOptionSelected", kitEquipment);
        }

        const responseStandardOptions = await instance.get<VehicleStandardOptions>(`/Vehicle/${e.vehicleId}/StandardOptions`).catch(() => myReject());

        if (responseStandardOptions && responseStandardOptions.status == 200) {
          const so: VehicleStandardOptions = responseStandardOptions.data;
          const standardOptions: StandardOptions = defaultStandardOptions();

          standardOptions.batteryOptions.batteryEnabled.value = so.batteryEnabled;
          standardOptions.batteryOptions.batteryOption.value = so.batteryOption;
          standardOptions.batteryOptions.mainBatteryVoltage.value = so.mainBatteryVoltage;
          standardOptions.batteryOptions.auxiliaryBatteryVoltage.value = so.auxiliaryBatteryVoltage;
          standardOptions.batteryOptions.mainBatteryLowSetPoint.value = so.mainBatteryLowSetPoint;
          standardOptions.batteryOptions.auxiliaryBatteryLowSetPoint.value = so.auxilliaryBatteryLowSetPoint;
          standardOptions.batteryOptions.controllerShutdownLowBatterySetPoint.value = so.controllerShutdownLowBatterySetPoint;
          standardOptions.batteryOptions.controllerShutdownTime.value = so.controllerShutdownTime;
          standardOptions.batteryOptions.batterySaverShutdownOverride.value =
            so.batterySaverShutdownOverride ?? standardOptions.batteryOptions.batterySaverShutdownOverride.defaultValue;

          standardOptions.batteryOptions.batteryMonitoringEnabled.value = so.mainBatteryMonitoring;

          standardOptions.idleControlOptions.maxRunTime.value = so.maxRunTime;
          standardOptions.idleControlOptions.maxIdleTime.value = so.maxIdleTime;
          standardOptions.idleControlOptions.maxRPMForIdle.value = so.maxRPMForIdle;
          standardOptions.idleControlOptions.startStopWarningTime.value = so.startStopWarningTime;
          standardOptions.idleControlOptions.throttleOverride.value = so.throttleOverride;
          standardOptions.idleControlOptions.throttleOverrideLimit.value =
            so.throttleOverrideLimit ?? standardOptions.idleControlOptions.throttleOverrideLimit.defaultValue;

          standardOptions.ignitionSignalControl.watchDog.value = so.watchDog;
          standardOptions.ignitionSignalControl.watchDogVoltageSetPoint.value = so.watchDogVoltageSetPoint;
          standardOptions.ignitionSignalControl.accessoryTimeDelay.value = so.accessoryTimeDelay;

          dispatch("setStandardOptions", standardOptions);
        }

        const responseOptionalFeatures = await instance.get<VehicleOptionalFeatures>(`/Vehicle/${e.vehicleId}/OptionalFeatures`).catch(() => myReject());

        if (responseOptionalFeatures && responseOptionalFeatures.status == 200) {
          const ro: VehicleOptionalFeatures = responseOptionalFeatures.data;
          const optionalFeatures: OptionalFeatures = defaultOptionalFeatures();

          optionalFeatures.airPressureMonitoring.airPressureMonitoringEnabled.value = ro.airPressureMonitoringEnabled;
          optionalFeatures.airPressureMonitoring.airPressureSetPoint.value = ro.airPressureSetPoint;
          optionalFeatures.airPressureMonitoring.airPressureOffset.value = ro.airPressureOffset;

          optionalFeatures.shorePower.shorePowerEnabled.value = ro.shorePowerEnabled;

          optionalFeatures.seatBeltStart.seatBeltStartEnabled.value = ro.seatBeltStartEnabled;
          optionalFeatures.seatBeltStart.seatBeltOffOnly.value = ro.seatBeltOffOnly;

          optionalFeatures.highIdle.highIdleEnabled.value = ro.highIdleEnabled;

          optionalFeatures.engineStartHydraulic.engineStartHydraulicEnabled.value = ro.engineStartHydraulicEnabled;
          optionalFeatures.engineStartHydraulic.highHydraulicTempSetPoint.value = convertTemperatureFromCelsius(ro.highHydraulicTempSetPointValue);
          optionalFeatures.engineStartHydraulic.lowHydraulicTempSetPoint.value = convertTemperatureFromCelsius(ro.lowHydraulicTempSetPointValue);

          optionalFeatures.doorStart.driverDoorStartEnabled.value = ro.driverDoorStartEnabled;
          optionalFeatures.doorStart.doorOpenStartEnabled.value = ro.doorOpenStartEnabled;
          optionalFeatures.doorStart.maxIdleNoOccupants.value = ro.maxIdleNoOccupants;
          optionalFeatures.doorStart.doorStartActivationSignal.value = ro.doorStartActivationSignal;
          optionalFeatures.doorStart.doorOpenDelay.value = ro.doorOpenDelay;
          optionalFeatures.doorStart.maxIdleTimeDoorOpen.value = ro.maxIdleTimeDoorOpen;

          optionalFeatures.brakeStart.brakeStartEnabled.value = ro.brakeStartEnabled;

          optionalFeatures.antiTheft.AntiTheftEnabled.value = ro.antiTheftEnabled;
          optionalFeatures.antiTheft.AuxiliaryAntiTheftEnabled.value = ro.auxiliaryAntiTheftEnabled;
          optionalFeatures.antiTheft.AntiTheftOnlyEnabled.value = ro.antiTheftOnlyEnabled;

          optionalFeatures.safeMode.safeModeEnabled.value = ro.safeModeEnabled;
          optionalFeatures.safeMode.safeMainBatteryLowSetPoint.value = ro.safeMainBatteryLowSetPoint;
          optionalFeatures.safeMode.safeAuxiliaryBatteryLowSetPoint.value = ro.safeAuxiliaryBatteryLowSetPoint;
          optionalFeatures.safeMode.safeMonitorAirConditioningEnabled.value = ro.safeMonitorAirConditioningEnabled;
          optionalFeatures.safeMode.safeMonitorAirConditioningHighTempSetPoint.value = convertTemperatureFromCelsius(
            ro.safeMonitorAirConditioningHighTempSetPointValue
          );
          optionalFeatures.safeMode.safeMonitorHeatEnabled.value = ro.safeMonitorHeatEnabled;
          optionalFeatures.safeMode.safeMonitorHeatLowTempSetPoint.value = convertTemperatureFromCelsius(ro.safeMonitorHeatLowTempSetPointValue);
          optionalFeatures.safeMode.safeEnginePumpLowCoolantTempEnabled.value = ro.safeEnginePumpLowCoolantTempEnabled;
          optionalFeatures.safeMode.safeEnginePumpLowCoolantTempHighSetPoint.value = convertTemperatureFromCelsius(
            ro.safeEnginePumpLowCoolantTempHighSetPointValue
          );
          optionalFeatures.safeMode.safeEnginePumpLowCoolantTempLowSetPoint.value = convertTemperatureFromCelsius(
            ro.safeEnginePumpLowCoolantTempLowSetPointValue
          );
          optionalFeatures.safeMode.safeEngineStartHydraulicTempEnabled.value = ro.safeEngineStartHydraulicTempEnabled;
          optionalFeatures.safeMode.safeEngineStartHydraulicTempHighSetPoint.value = convertTemperatureFromCelsius(
            ro.safeEngineStartHydraulicTempHighSetPointValue
          );
          optionalFeatures.safeMode.safeEngineStartHydraulicTempLowSetPoint.value = convertTemperatureFromCelsius(
            ro.safeEngineStartHydraulicTempLowSetPointValue
          );
          optionalFeatures.safeMode.safeEngineStartLowCoolantTempEnabled.value = ro.safeEngineStartLowCoolantTempEnabled;
          optionalFeatures.safeMode.safeEngineStartLowCoolantTempHighSetPoint.value = convertTemperatureFromCelsius(
            ro.safeEngineStartLowCoolantTempHighSetPointValue
          );
          optionalFeatures.safeMode.safeEngineStartLowCoolantTempLowSetPoint.value = convertTemperatureFromCelsius(
            ro.safeEngineStartLowCoolantTempLowSetPointValue
          );
          optionalFeatures.safeMode.safeAuxiliaryHeaterEngineEnabled.value = ro.safeAuxiliaryHeaterEngineEnabled;
          optionalFeatures.safeMode.safeAuxiliaryHeaterEngineHighSetPoint.value = convertTemperatureFromCelsius(ro.safeAuxiliaryHeaterEngineHighSetPointValue);
          optionalFeatures.safeMode.safeAuxiliaryHeaterEngineLowSetPoint.value = convertTemperatureFromCelsius(ro.safeAuxiliaryHeaterEngineLowSetPointValue);
          optionalFeatures.safeMode.safeMonitorRearEnabled.value = ro.safeMonitorRearEnabled;
          optionalFeatures.safeMode.safeMonitorRearHighSetPoint.value = convertTemperatureFromCelsius(ro.safeMonitorRearHighSetPointValue);
          optionalFeatures.safeMode.safeMonitorRearLowSetPoint.value = convertTemperatureFromCelsius(ro.safeMonitorRearLowSetPointValue);

          optionalFeatures.ptoAuxLight.ptoLightAuxEnabled.value = ro.ptoAuxLightEnabled;

          optionalFeatures.electricParkBrake.electricParkingBrakeEnabled.value = ro.electricParkingBrakeEnabled;

          optionalFeatures.overrideShutdown.overrideShutdown.value = ro.overrideShutdown;
          dispatch("setOptionalFeatures", optionalFeatures);
        }

        const responseOptionalEquipment = await instance.get<VehicleOptionalEquipment>(`/Vehicle/${e.vehicleId}/OptionalEquipment`).catch(() => myReject());

        if (responseOptionalEquipment && responseOptionalEquipment.status == 200) {
          const voe: VehicleOptionalEquipment = responseOptionalEquipment.data;
          const optionalEquipment: OptionalEquipment = defaultOptionalEquipment();

          optionalEquipment.startStopAlarm.startStopAlarmEnabled.value = voe.startStopEnabled;
          optionalEquipment.startStopAlarm.startWarningAlarm.value = voe.startWarningAlaram;
          optionalEquipment.startStopAlarm.startAlarmFrequency.value = voe.startAlarmFrequency;
          optionalEquipment.startStopAlarm.stopWarningAlarm.value = voe.stopWarningAlaram;
          optionalEquipment.startStopAlarm.stopAlarmFrequency.value = voe.stopAlarmFrequency;
          optionalEquipment.startStopAlarm.breakPedalAlarm.value = voe.breakPedalAlarm;

          optionalEquipment.automaticClimateControl.automaticClimateControlEnabled.value = voe.automaticClimateControlEnabled;
          optionalEquipment.automaticClimateControl.blowerInitialSpeed.value = voe.blowerInitialSpeed;
          optionalEquipment.automaticClimateControl.dontShutBlowerOff.value = voe.dontShutBlowerOff;
          optionalEquipment.automaticClimateControl.dontRampUpBlower.value = voe.dontRampUpBlower;
          optionalEquipment.automaticClimateControl.passengerSeatbelt.value = voe.passengerSeatBelt;

          optionalEquipment.currentSensor.currentSensorOption.value = voe.currentSensorOption;
          optionalEquipment.currentSensor.chargeDischargeSetPoint.value = voe.chargeDischargeSetPoint;

          optionalEquipment.humidity.humidityEnabled.value = voe.humidityEnabled;

          optionalEquipment.ignitionBypass.ignitionBypassEnabled.value = voe.ignitionBypassEnabled;

          optionalEquipment.rearFacility.rearFacilityEnabled.value = voe.rearFacilityEnabled;
          optionalEquipment.rearFacility.rearFacilityTemperatureSetPoint.value = convertTemperatureFromCelsius(voe.rearFacilityTemperatureSetPointValue);
          optionalEquipment.rearFacility.rearFacilityAirConditioningTempOffset.value = convertTemperatureFromCelsius(
            voe.rearFacilityAirConditioningTempOffsetValue
          );
          optionalEquipment.rearFacility.rearFacilityHeatingTempOffset.value = convertTemperatureFromCelsius(voe.rearFacilityHeatingTempOffsetValue);
          optionalEquipment.rearFacility.rearFacilityEngineRunInitial.value = voe.rearFacilityEngineRunInitial;
          optionalEquipment.rearFacility.rearFacilityEngineAssistRearAir.value = voe.rearFacilityEngineAssistRearAir;
          optionalEquipment.rearFacility.rearFacilityEngineAssistCabAir.value = voe.rearFacilityEngineAssistCabAir;
          optionalEquipment.rearFacility.rearFacilityEngineAssistAirOffset.value = convertTemperatureFromCelsius(voe.rearFacilityEngineAssistAirOffsetValue);
          optionalEquipment.rearFacility.rearFacilityEngineAssistAirTimer.value = voe.rearFacilityEngineAssistAirTimer;

          optionalEquipment.secondaryTempSensor.secondaryTempSensorEnabled.value = voe.secondaryTempSensorEnabled;

          optionalEquipment.airConditioningOptions.ACEnabled.value = voe.acEnabled;
          optionalEquipment.airConditioningOptions.ACOption.value = voe.acOptions;
          optionalEquipment.airConditioningOptions.dontStart.value = convertTemperatureFromCelsius(voe.acDontStartSetPointValue);
          optionalEquipment.airConditioningOptions.ACTemperatureOffset.value = convertTemperatureFromCelsius(voe.acTemperatureOffsetValue);
          optionalEquipment.airConditioningOptions.ACContinuousOperationOnOff.value = voe.acContinuousOperationOnOff;
          optionalEquipment.airConditioningOptions.ACContinuousOperationSetPoint.value = convertTemperatureFromCelsius(voe.acContinuousOperationSetPointValue);
          optionalEquipment.airConditioningOptions.ACClimateControlEnable.value = voe.acClimateControlEnabled;

          optionalEquipment.heatingOptions.heatingEnabled.value = voe.heatingEnabled;
          optionalEquipment.heatingOptions.heatingOption.value = voe.heatingOption;
          optionalEquipment.heatingOptions.heatContinuousOperationOnOff.value = voe.heatContinuousOperationOnOff;
          optionalEquipment.heatingOptions.heatContinuousOperationSetPoint.value = convertTemperatureFromCelsius(voe.heatContinuousOperationSetPointValue);
          optionalEquipment.heatingOptions.climateControlEnable.value = voe.heatingClimateControlEnabled;
          optionalEquipment.heatingOptions.coolantPumpHighCoolantTempSetPoint.value = convertTemperatureFromCelsius(
            voe.coolantPumpHighCoolantTempSetPointValue
          );
          optionalEquipment.heatingOptions.coolantPumpLowEngineCoolantTempSetPoint.value = convertTemperatureFromCelsius(
            voe.coolantPumpLowEngineCoolantTempSetPointValue
          );
          optionalEquipment.heatingOptions.heaterTempOffset.value = convertTemperatureFromCelsius(voe.heaterTempOffsetValue);
          optionalEquipment.auxHeatingOptions.auxHeaterInitialRun.value = voe.auxiliaryHeaterInitialRun;
          optionalEquipment.auxHeatingOptions.manualAuxiliaryHeater.value = voe.manualAUxiliaryHeaterOption;
          optionalEquipment.auxHeatingOptions.heatingSchedulerRequired.value = voe.heatingSchedulerRequired;
          optionalEquipment.heatingOptions.heatingSchedulerManager.value = voe.heatingSchedulerManager;

          optionalEquipment.engineRunOptions.engineRunEnabled.value = voe.engineRunInitialLowCoolant;
          optionalEquipment.engineRunOptions.engineRunForLowCoolantSetPoint.value = convertTemperatureFromCelsius(voe.engineRunLowCoolantSetPointValue);

          optionalEquipment.engineStartOptions.engineStartEnabled.value = voe.engineStartLowCoolantTemp;
          optionalEquipment.engineStartOptions.engineBoostAuxHeater.value = voe.engineBoostAuxHeater;
          optionalEquipment.engineStartOptions.engineStartHigh.value = convertTemperatureFromCelsius(voe.engineStartHighEngineCoolantTempSetPointValue);
          optionalEquipment.engineStartOptions.engineStartLow.value = convertTemperatureFromCelsius(voe.engineStartLowEngineCoolantTempSetPointValue);
          optionalEquipment.engineStartOptions.auxHeaterHigh.value = convertTemperatureFromCelsius(voe.auxHeaterHighValue);
          optionalEquipment.engineStartOptions.auxHeaterLow.value = convertTemperatureFromCelsius(voe.auxHeaterLowValue);

          dispatch("setOptionalEquipment", optionalEquipment);
        }

        const responseOperatorControls = await instance.get<VehicleOperatorControls>(`/Vehicle/${e.vehicleId}/OperatorControls`).catch(() => myReject());

        if (responseOperatorControls && responseOperatorControls.status == 200) {
          const voc: VehicleOperatorControls = responseOperatorControls.data;
          const operatorControls: OperatorControls = defaultOperatorControls();

          operatorControls.climateSettingOptions.celsiusOrFahrenheit.value = voc.celsiusOrFahrenheit;
          operatorControls.operatorControl.dimScreen.value = voc.dimScreen;
          operatorControls.auxiliaryHeaterFunctionOptions.sevenDayScheduler.value = voc.sevenDayScheduler;
          operatorControls.operatorControl.clockDisplayOnOff.value = voc.clockDisplayOnOff;
          operatorControls.operatorControl.clockDisplayHours.value = voc.clockDisplayHours;
          operatorControls.operatorControl.daylightSavings.value = voc.daylightSavings;
          operatorControls.climateSettingOptions.climateControlEnable.value = voc.climateControlEnable;
          operatorControls.operatorControl.screenOnInDrive.value = voc.screenOnInDrive;
          operatorControls.climateSettingOptions.temperatureSetPointMaximum.value = convertTemperatureFromCelsius(voc.temperatureSetPointMaximumValue);
          operatorControls.climateSettingOptions.temperatureSetPointMinimum.value = convertTemperatureFromCelsius(voc.temperatureSetPointMinimumValue);

          dispatch("setOperatorControls", operatorControls);
        }

        const responseInputsOutputs = await instance.get<VehicleInputsOutputs>(`/Vehicle/${e.vehicleId}/InputsOutputs`).catch(() => myReject());

        if (responseInputsOutputs && responseInputsOutputs.status == 200) {
          const vio: VehicleInputsOutputs = responseInputsOutputs.data;
          const inputsOutputs: InputsOutputs = defaultIntputsOutputs();

          inputsOutputs.outputs.dcac.value = vio.outputDcac;
          inputsOutputs.outputs.comboHeater.value = vio.outputComboHeater;
          inputsOutputs.outputs.airHeater.value = vio.outputAirHeater;
          inputsOutputs.outputs.highIdle.value = vio.outputHighIdle;
          inputsOutputs.outputs.antiTheft.value = vio.outputAntiTheft;
          inputsOutputs.outputs.clutch.value = vio.outputClutch;
          inputsOutputs.outputs.hvac.value = vio.outputHvac;
          inputsOutputs.outputs.condensor.value = vio.outputCondensor;
          inputsOutputs.outputs.engineRunning.value = vio.outputEngiineRunning;

          inputsOutputs.inputs.engineStart.value = vio.inputEngineStart;
          inputsOutputs.inputs.engineStart.signalType = vio.inputEngineStartSignalType;
          inputsOutputs.inputs.engineStop.value = vio.inputEngineStop;
          inputsOutputs.inputs.engineStop.signalType = vio.inputEngineStopSignalType;
          inputsOutputs.inputs.heater.value = vio.inputHeater;
          inputsOutputs.inputs.heater.signalType = vio.inputHeaterSignalType;
          inputsOutputs.inputs.solenoid.value = vio.inputSolenoid;
          inputsOutputs.inputs.solenoid.signalType = vio.inputSolenoidSignalType;
          inputsOutputs.inputs.alarm.value = vio.inputAlarm;
          inputsOutputs.inputs.alarm.signalType = vio.inputAlarmSignalType;
          inputsOutputs.inputs.bunkHeater.value = vio.inputBunkHeater;
          inputsOutputs.inputs.bunkHeater.signalType = vio.inputBunkHeaterSignalType;
          inputsOutputs.inputs.bunkAC.value = vio.inputBunkAC;
          inputsOutputs.inputs.bunkAC.signalType = vio.inputBunkACSignalType;
          inputsOutputs.inputs.parkBrake.value = vio.inputParkBrake;
          inputsOutputs.inputs.parkBrake.signalType = vio.inputParkBrakeSignalType;
          inputsOutputs.inputs.neutralSwitch.value = vio.inputNeutralSwitch;
          inputsOutputs.inputs.neutralSwitch.signalType = vio.inputNeutralSwitchSignalType;
          inputsOutputs.inputs.inputHighIdle.value = vio.inputHighIdle;
          inputsOutputs.inputs.inputHighIdle.signalType = vio.inputHighIdleSignalType;
          inputsOutputs.inputs.shorePower.value = vio.inputShorePower;
          inputsOutputs.inputs.shorePower.signalType = vio.inputShorePowerSignalType;
          inputsOutputs.inputs.pto.value = vio.inputPTO;
          inputsOutputs.inputs.pto.signalType = vio.inputPTOSignalType;
          inputsOutputs.inputs.auxAntiTheft.value = vio.inputAuxAntiTheft;
          inputsOutputs.inputs.auxAntiTheft.signalType = vio.inputAuxAntiTheftSignalType;
          inputsOutputs.inputs.doorStart.value = vio.inputDoorStart;
          inputsOutputs.inputs.doorStart.signalType = vio.inputDoorStartSignalType;
          inputsOutputs.inputs.compressor.value = vio.inputCompressor;
          inputsOutputs.inputs.compressor.signalType = vio.inputCompressorSignalType;
          inputsOutputs.inputs.rearFacility.value = vio.inputRearFacility;
          inputsOutputs.inputs.rearFacility.signalType = vio.inputRearFacilitySignalType;
          inputsOutputs.inputs.safeMode.value = vio.inputSafeMode;
          inputsOutputs.inputs.safeMode.signalType = vio.inputSafeModeSignalType;

          dispatch("setInputsOutputs", inputsOutputs);
        }

        myResolve();
      });
    },
    /**
     * Set the local general info of a new vehicle to the values of the vehicle being imported (Note this does not import the assetName, VIN or IMEI).
     * Once the general info is set, retrieve all the settings of the imported vehicle and set them for the new vehicle
     */
    async importVehicle({ dispatch }, e: Vehicle) {
      const generalInfo: GeneralInfo = {
        assetName: "",
        VIN: null,
        IMEI: null,
        make: e.make,
        model: e.model,
        year: e.year,
        engineDisplacement: e.engineDisplacement,
        fuelConsumption: convertFuelFromLitres(e.fuelConsumption),
        fuelType: e.fuelType,
        fuelCost: e.fuelCost,
        engineType: e.engineType,
        engineManufacturer: e.engineManufacturer,
        brakeSystem: e.brakeSystem,
        driveLineType: e.driveLineType,
        gvwrClass: e.gvwrClass,
        vehicleType: e.vehicleType,
        frequency: e.frequency,
        vehicleZoneId: e.vehicleZone?.zoneId ?? null,
        vehicleClientId: e.vehicleClient?.clientId ?? null,
        series: e.series,
        bodyType: e.bodyType,
        other: e.other,
        transmissionType: e.transmissionType,
        kit: e.kit,
      };

      dispatch("setGeneralInfo", generalInfo);

      return dispatch("retrieveSettings", e);
    },
    /**
     * Load a vehicle from the API and store the information locally
     */
    async loadVehicle({ dispatch, commit }, e: Vehicle) {
      commit("UPDATING_VEHICLE", e);

      const generalInfo: GeneralInfo = {
        assetName: e.assetName ?? "",
        VIN: e.vin,
        IMEI: e.idleTrackerIMEI,
        make: e.make,
        model: e.model,
        year: e.year,
        engineDisplacement: e.engineDisplacement,
        fuelConsumption: convertFuelFromLitres(e.fuelConsumption),
        fuelType: e.fuelType,
        fuelCost: e.fuelCost,
        engineType: e.engineType,
        engineManufacturer: e.engineManufacturer,
        brakeSystem: e.brakeSystem,
        driveLineType: e.driveLineType,
        gvwrClass: e.gvwrClass,
        vehicleType: e.vehicleType,
        frequency: e.frequency,
        vehicleZoneId: e.vehicleZone?.zoneId ?? null,
        vehicleClientId: e.vehicleClient?.clientId ?? null,
        series: e.series,
        bodyType: e.bodyType,
        other: e.other,
        transmissionType: e.transmissionType,
        kit: e.kit,
      };

      dispatch("setGeneralInfo", generalInfo);

      return dispatch("retrieveSettings", e);
    },
    /**
     * Perform a network request to persist the updates made to the kit equipment
     */
    async updateKitEquipment({ state }) {
      const equipment: EquipmentSelected = state.vehicleEquipment.selected;
      const vehicleId = state.vehicleID ?? 0;
      const standardOptions: CreateVehicleKitEquipment = {
        vehicleID: vehicleId,
        ignitionBypass: equipment.ignitionBypass?.partNumber ?? null,
        antiTheft: equipment.antiTheft?.partNumber ?? null,
        alarm: equipment.alarm?.partNumber ?? null,
        solenoid: equipment.solenoid?.partNumber ?? null,
        battery: equipment.battery?.partNumber ?? null,
        batteryCount: equipment.batteryCount,
        heat: equipment.heat?.partNumber ?? null,
        airConditioning: equipment.airConditioning?.partNumber ?? null,
        currentSensor: equipment.currentSensor?.partNumber ?? null,
        autoHVAC: equipment.autoHVAC?.partNumber ?? null,
        humidity: equipment.humidity?.partNumber ?? null,
        tempSensing: equipment.tempSensing?.partNumber ?? null,
        rearFacilityControl: equipment.rearFacilityControl?.partNumber ?? null,
        useMyOwnAlarm: equipment.useMyOwnAlarm,
        useMyOwnHeating: equipment.useMyOwnHeating,
        useMyOwnSolenoid: equipment.useMyOwnSolenoid,
        useMyOwnBattery: equipment.useMyOwnBattery,
      };

      await instance.put(`/Vehicle/CreateKitEquipment`, standardOptions); // TODO: Handle response?
    },
    /**
     * Perform a network request to persist the updates made to the inputs/outputs
     */
    async updateInputsOutputs({ state }) {
      const inputsOutputs: InputsOutputs = state.inputsOutputs;
      const vehicleId = state.vehicleID ?? 0;
      const createInputsOutputs: CreateInputsOutputs = {
        vehicleID: vehicleId,
        outputDcac: inputsOutputs.outputs.dcac.value,
        outputComboHeater: inputsOutputs.outputs.comboHeater.value,

        outputAirHeater: inputsOutputs.outputs.airHeater.value,
        outputHighIdle: inputsOutputs.outputs.highIdle.value,
        outputAntiTheft: inputsOutputs.outputs.antiTheft.value,
        outputClutch: inputsOutputs.outputs.clutch.value,
        outputHvac: inputsOutputs.outputs.hvac.value,
        outputCondensor: inputsOutputs.outputs.condensor.value,
        outputEngiineRunning: inputsOutputs.outputs.engineRunning.value,

        inputEngineStart: inputsOutputs.inputs.engineStart.value,
        inputEngineStartSignalType: inputsOutputs.inputs.engineStart.signalType,
        inputEngineStop: inputsOutputs.inputs.engineStop.value,
        inputEngineStopSignalType: inputsOutputs.inputs.engineStop.signalType,
        inputHeater: inputsOutputs.inputs.heater.value,
        inputHeaterSignalType: inputsOutputs.inputs.heater.signalType,
        inputSolenoid: inputsOutputs.inputs.solenoid.value,
        inputSolenoidSignalType: inputsOutputs.inputs.solenoid.signalType,
        inputAlarm: inputsOutputs.inputs.alarm.value,
        inputAlarmSignalType: inputsOutputs.inputs.alarm.signalType,
        inputBunkHeater: inputsOutputs.inputs.bunkHeater.value,
        inputBunkHeaterSignalType: inputsOutputs.inputs.bunkHeater.signalType,
        inputBunkAC: inputsOutputs.inputs.bunkAC.value,
        inputBunkACSignalType: inputsOutputs.inputs.bunkAC.signalType,
        inputParkBrake: inputsOutputs.inputs.parkBrake.value,
        inputParkBrakeSignalType: inputsOutputs.inputs.parkBrake.signalType,
        inputNeutralSwitch: inputsOutputs.inputs.neutralSwitch.value,
        inputNeutralSwitchSignalType: inputsOutputs.inputs.neutralSwitch.signalType,
        inputHighIdle: inputsOutputs.inputs.inputHighIdle.value,
        inputHighIdleSignalType: inputsOutputs.inputs.inputHighIdle.signalType,
        inputShorePower: inputsOutputs.inputs.shorePower.value,
        inputShorePowerSignalType: inputsOutputs.inputs.shorePower.signalType,
        inputPTO: inputsOutputs.inputs.pto.value,
        inputPTOSignalType: inputsOutputs.inputs.pto.signalType,
        inputAuxAntiTheft: inputsOutputs.inputs.auxAntiTheft.value,
        inputAuxAntiTheftSignalType: inputsOutputs.inputs.auxAntiTheft.signalType,
        inputDoorStart: inputsOutputs.inputs.doorStart.value,
        inputDoorStartSignalType: inputsOutputs.inputs.doorStart.signalType,
        inputCompressor: inputsOutputs.inputs.compressor.value,
        inputCompressorSignalType: inputsOutputs.inputs.compressor.signalType,
        inputRearFacility: inputsOutputs.inputs.rearFacility.value,
        inputRearFacilitySignalType: inputsOutputs.inputs.rearFacility.signalType,
        inputSafeMode: inputsOutputs.inputs.safeMode.value,
        inputSafeModeSignalType: inputsOutputs.inputs.safeMode.signalType,
      };

      await instance.put(`/Vehicle/CreateInputsOutputs`, createInputsOutputs); // TODO: Handle response?
    },
    /**
     * Perform an network request to persist the updates made to the standard options
     */
    async updateStandardOptions({ state }) {
      const options: StandardOptions = state.standardOptions;
      const vehicleId = state.vehicleID ?? 0;
      const standardOptions: CreateStandardOptions = {
        vehicleID: vehicleId,
        maxRunTime: options.idleControlOptions.maxRunTime.value,
        maxIdleTime: options.idleControlOptions.maxIdleTime.value,
        maxRPMForIdle: options.idleControlOptions.maxRPMForIdle.value,
        startStopWarningTime: options.idleControlOptions.startStopWarningTime.value,
        throttleOverride: options.idleControlOptions.throttleOverride.value,
        throttleOverrideLimit: options.idleControlOptions.throttleOverrideLimit.value,
        watchDog: options.ignitionSignalControl.watchDog.value,
        watchDogVoltageSetPoint: options.ignitionSignalControl.watchDogVoltageSetPoint.value,
        accessoryTimeDelay: options.ignitionSignalControl.accessoryTimeDelay.value,
        batteryEnabled: options.batteryOptions.batteryEnabled.value,
        batteryOption: options.batteryOptions.batteryOption.value,
        mainBatteryVoltage: options.batteryOptions.mainBatteryVoltage.value,
        auxiliaryBatteryVoltage: options.batteryOptions.auxiliaryBatteryVoltage.value,
        mainBatteryLowSetPoint: options.batteryOptions.mainBatteryLowSetPoint.value,
        controllerShutdownLowBatterySetPoint: options.batteryOptions.controllerShutdownLowBatterySetPoint.value,
        controllerShutdownTime: options.batteryOptions.controllerShutdownTime.value,
        batterySaverShutdownOverride: options.batteryOptions.batterySaverShutdownOverride.value,
        auxilliaryBatteryLowSetPoint: options.batteryOptions.auxiliaryBatteryLowSetPoint.value,
        mainBatteryMonitoring: options.batteryOptions.batteryMonitoringEnabled.value,
      };

      await instance.put(`/Vehicle/CreateStandardOptions`, standardOptions); // TODO: Handle response?
    },
    /**
     * Perform an network request to persist the updates made to the optional features
     */
    async updateOptionalFeatures({ state }) {
      const options = state.optionalFeatures;
      const vehicleId = state.vehicleID ?? 0;

      const optionalFeatures: CreateOptionalFeatures = {
        vehicleID: vehicleId,
        airPressureMonitoringEnabled: options.airPressureMonitoring.airPressureMonitoringEnabled.value,
        airPressureSetPoint: options.airPressureMonitoring.airPressureSetPoint.value,
        airPressureOffset: options.airPressureMonitoring.airPressureOffset.value,
        brakeStartEnabled: options.brakeStart.brakeStartEnabled.value,
        driverDoorStartEnabled: options.doorStart.driverDoorStartEnabled.value,
        doorOpenStartEnabled: options.doorStart.doorOpenStartEnabled.value,
        maxIdleNoOccupants: options.doorStart.maxIdleNoOccupants.value,
        doorStartActivationSignal: options.doorStart.doorStartActivationSignal.value,
        doorOpenDelay: options.doorStart.doorOpenDelay.value,
        maxIdleTimeDoorOpen: options.doorStart.maxIdleTimeDoorOpen.value,
        engineStartHydraulicEnabled: options.engineStartHydraulic.engineStartHydraulicEnabled.value,
        highHydraulicTempSetPointValue: convertTemperatureToCelsius(options.engineStartHydraulic.highHydraulicTempSetPoint.value),
        lowHydraulicTempSetPointValue: convertTemperatureToCelsius(options.engineStartHydraulic.lowHydraulicTempSetPoint.value),
        highIdleEnabled: options.highIdle.highIdleEnabled.value,
        seatBeltStartEnabled: options.seatBeltStart.seatBeltStartEnabled.value,
        seatBeltOffOnly: options.seatBeltStart.seatBeltOffOnly.value,
        shorePowerEnabled: options.shorePower.shorePowerEnabled.value,
        antiTheftEnabled: options.antiTheft.AntiTheftEnabled.value,
        auxiliaryAntiTheftEnabled: options.antiTheft.AuxiliaryAntiTheftEnabled.value,
        antiTheftOnlyEnabled: options.antiTheft.AntiTheftOnlyEnabled.value,
        safeModeEnabled: options.safeMode.safeModeEnabled.value,
        safeMainBatteryLowSetPoint: options.safeMode.safeMainBatteryLowSetPoint.value,
        safeAuxiliaryBatteryLowSetPoint: options.safeMode.safeAuxiliaryBatteryLowSetPoint.value,
        safeMonitorAirConditioningEnabled: options.safeMode.safeMonitorAirConditioningEnabled.value,
        safeMonitorAirConditioningHighTempSetPointValue: convertTemperatureToCelsius(options.safeMode.safeMonitorAirConditioningHighTempSetPoint.value),
        safeMonitorHeatEnabled: options.safeMode.safeMonitorHeatEnabled.value,
        safeMonitorHeatLowTempSetPointValue: convertTemperatureToCelsius(options.safeMode.safeMonitorHeatLowTempSetPoint.value),
        safeEnginePumpLowCoolantTempEnabled: options.safeMode.safeEnginePumpLowCoolantTempEnabled.value,
        safeEnginePumpLowCoolantTempHighSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEnginePumpLowCoolantTempHighSetPoint.value),
        safeEnginePumpLowCoolantTempLowSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEnginePumpLowCoolantTempLowSetPoint.value),
        safeEngineStartHydraulicTempEnabled: options.safeMode.safeEngineStartHydraulicTempEnabled.value,
        safeEngineStartHydraulicTempHighSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEngineStartHydraulicTempHighSetPoint.value),
        safeEngineStartHydraulicTempLowSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEngineStartHydraulicTempLowSetPoint.value),
        safeEngineStartLowCoolantTempEnabled: options.safeMode.safeEngineStartLowCoolantTempEnabled.value,
        safeEngineStartLowCoolantTempHighSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEngineStartLowCoolantTempHighSetPoint.value),
        safeEngineStartLowCoolantTempLowSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEngineStartLowCoolantTempLowSetPoint.value),
        safeAuxiliaryHeaterEngineEnabled: options.safeMode.safeAuxiliaryHeaterEngineEnabled.value,
        safeAuxiliaryHeaterEngineHighSetPointValue: convertTemperatureToCelsius(options.safeMode.safeAuxiliaryHeaterEngineHighSetPoint.value),
        safeAuxiliaryHeaterEngineLowSetPointValue: convertTemperatureToCelsius(options.safeMode.safeAuxiliaryHeaterEngineLowSetPoint.value),
        safeMonitorRearEnabled: options.safeMode.safeMonitorRearEnabled.value,
        safeMonitorRearHighSetPointValue: convertTemperatureToCelsius(options.safeMode.safeMonitorRearHighSetPoint.value),
        safeMonitorRearLowSetPointValue: convertTemperatureToCelsius(options.safeMode.safeMonitorRearLowSetPoint.value),
        ptoAuxLightEnabled: options.ptoAuxLight.ptoLightAuxEnabled.value,
        electricParkingBrakeEnabled: options.electricParkBrake.electricParkingBrakeEnabled.value,
        overrideShutdown: options.overrideShutdown.overrideShutdown.value
      };

      await instance.put(`/Vehicle/CreateOptionalFeatures`, optionalFeatures); // TODO: Handle response?
    },
    /**
     * Perform an network request to persist the updates made to the optional equipment
     */
    async updateOptionalEquipment({ state }) {
      const options: OptionalEquipment = state.optionalEquipment;
      const vehicleId = state.vehicleID ?? 0;

      const optionalEquipment: CreateOptionalEquipment = {
        vehicleID: vehicleId,
        startStopEnabled: options.startStopAlarm.startStopAlarmEnabled.value,
        startWarningAlaram: options.startStopAlarm.startWarningAlarm.value,
        startAlarmFrequency: options.startStopAlarm.startAlarmFrequency.value,
        stopWarningAlaram: options.startStopAlarm.stopWarningAlarm.value,
        stopAlarmFrequency: options.startStopAlarm.stopAlarmFrequency.value,
        breakPedalAlarm: options.startStopAlarm.breakPedalAlarm.value,
        automaticClimateControlEnabled: options.automaticClimateControl.automaticClimateControlEnabled.value,
        blowerInitialSpeed: options.automaticClimateControl.blowerInitialSpeed.value,
        dontShutBlowerOff: options.automaticClimateControl.dontShutBlowerOff.value,
        dontRampUpBlower: options.automaticClimateControl.dontRampUpBlower.value,
        passengerSeatBelt: options.automaticClimateControl.passengerSeatbelt.value,
        currentSensorOption: options.currentSensor.currentSensorOption.value,
        chargeDischargeSetPoint: options.currentSensor.chargeDischargeSetPoint.value,
        humidityEnabled: options.humidity.humidityEnabled.value,
        ignitionBypassEnabled: options.ignitionBypass.ignitionBypassEnabled.value,
        rearFacilityEnabled: options.rearFacility.rearFacilityEnabled.value,
        rearFacilityTemperatureSetPointValue: convertTemperatureToCelsius(options.rearFacility.rearFacilityTemperatureSetPoint.value),
        rearFacilityAirConditioningTempOffsetValue: convertTemperatureToCelsius(options.rearFacility.rearFacilityAirConditioningTempOffset.value),
        rearFacilityHeatingTempOffsetValue: convertTemperatureToCelsius(options.rearFacility.rearFacilityHeatingTempOffset.value),
        rearFacilityEngineRunInitial: options.rearFacility.rearFacilityEngineRunInitial.value,
        rearFacilityEngineAssistRearAir: options.rearFacility.rearFacilityEngineAssistRearAir.value,
        rearFacilityEngineAssistCabAir: options.rearFacility.rearFacilityEngineAssistCabAir.value,
        rearFacilityEngineAssistAirOffsetValue: convertTemperatureToCelsius(options.rearFacility.rearFacilityEngineAssistAirOffset.value),
        rearFacilityEngineAssistAirTimer: options.rearFacility.rearFacilityEngineAssistAirTimer.value,
        secondaryTempSensorEnabled: options.secondaryTempSensor.secondaryTempSensorEnabled.value,
        heatingEnabled: options.heatingOptions.heatingEnabled.value,
        heatingOption: options.heatingOptions.heatingOption.value,
        heatContinuousOperationOnOff: options.heatingOptions.heatContinuousOperationOnOff.value,
        heatContinuousOperationSetPointValue: convertTemperatureToCelsius(options.heatingOptions.heatContinuousOperationSetPoint.value),
        heatingClimateControlEnabled: options.heatingOptions.climateControlEnable.value,
        heaterTempOffsetValue: convertTemperatureToCelsius(options.heatingOptions.heaterTempOffset.value),
        heatingSchedulerManager: options.heatingOptions.heatingSchedulerManager.value,
        heatingSchedulerRequired: options.auxHeatingOptions.heatingSchedulerRequired.value,
        auxiliaryHeaterInitialRun: options.auxHeatingOptions.auxHeaterInitialRun.value,
        manualAUxiliaryHeaterOption: options.auxHeatingOptions.manualAuxiliaryHeater.value,
        coolantPumpHighCoolantTempSetPointValue: convertTemperatureToCelsius(options.heatingOptions.coolantPumpHighCoolantTempSetPoint.value),
        coolantPumpLowEngineCoolantTempSetPointValue: convertTemperatureToCelsius(options.heatingOptions.coolantPumpLowEngineCoolantTempSetPoint.value),
        aCEnabled: options.airConditioningOptions.ACEnabled.value,
        aCOptions: options.airConditioningOptions.ACOption.value,
        aCDontStartSetPointValue: convertTemperatureToCelsius(options.airConditioningOptions.dontStart.value),
        aCTemperatureOffsetValue: convertTemperatureToCelsius(options.airConditioningOptions.ACTemperatureOffset.value),
        aCContinuousOperationOnOff: options.airConditioningOptions.ACContinuousOperationOnOff.value,
        aCContinuousOperationSetPointValue: convertTemperatureToCelsius(options.airConditioningOptions.ACContinuousOperationSetPoint.value),
        aCClimateControlEnabled: options.airConditioningOptions.ACClimateControlEnable.value,
        engineStartLowCoolantTemp: options.engineStartOptions.engineStartEnabled.value,
        engineBoostAuxHeater: options.engineStartOptions.engineBoostAuxHeater.value,
        auxHeaterHighValue: convertTemperatureToCelsius(options.engineStartOptions.auxHeaterHigh.value),
        auxHeaterLowValue: convertTemperatureToCelsius(options.engineStartOptions.auxHeaterLow.value),
        engineStartHighEngineCoolantTempSetPointValue: convertTemperatureToCelsius(options.engineStartOptions.engineStartHigh.value),
        engineStartLowEngineCoolantTempSetPointValue: convertTemperatureToCelsius(options.engineStartOptions.engineStartLow.value),
        engineRunInitialLowCoolant: options.engineRunOptions.engineRunEnabled.value,
        engineRunLowCoolantSetPointValue: convertTemperatureToCelsius(options.engineRunOptions.engineRunForLowCoolantSetPoint.value),
      };

      await instance.put(`/Vehicle/CreateOptionalEquipment`, optionalEquipment); // TODO: Handle response?
    },
    /**
     * Perform an network request to persist the updates made to the general info of the vehicle
     */
    async updateVehicle({ state }) {
      const general: GeneralInfo = state.generalInfo;

      const vehicle: CreateVehicle = {
        vin: general.VIN ?? "",
        kitId: general.kit?.kitId ?? null,
        idleTrackerIMEI: general.IMEI ?? "",
        assetName: general.assetName,
        vehicleZoneId: general.vehicleZoneId ?? null,
        vehicleClientId: general.vehicleClientId ?? null,
        year: general.year,
        make: general.make ?? "",
        model: general.model ?? "",
        series: general.series ?? "",
        bodyType: general.bodyType ?? "",
        gvwrClass: general.gvwrClass ?? "",
        vehicleType: general.vehicleType ?? "",
        fuelType: general.fuelType ?? "",
        fuelCost: general.fuelCost,
        engineType: general.engineType ?? "",
        engineManufacturer: general.engineManufacturer ?? "",
        brakeSystem: general.brakeSystem ?? "",
        driveLineType: general.driveLineType ?? "",
        other: general.other ?? "",
        frequency: general.frequency ?? "",
        fuelConsumption: convertFuelToLitres(general.fuelConsumption),
        engineDisplacement: general.engineDisplacement,
        transmissionType: general.transmissionType,
        vehicleId: state.vehicleID ?? -1,
      };

      const response: any = await instance.put(`/Vehicle/${state.vehicleID}`, vehicle);
      if (!response || response.response?.status >= 400) {
        throw new Error(response?.response?.data?.detail);
      }
    },

    /**
     * Perform an network request to persist the updates made to the operator controls
     */
    async updateOperatorControls({ state }) {
      const options: OperatorControls = state.operatorControls;
      const vehicleId = state.vehicleID ?? 0;

      const operatorControls: CreateOperatorControls = {
        vehicleID: vehicleId,
        celsiusOrFahrenheit: options.climateSettingOptions.celsiusOrFahrenheit.value,
        dimScreen: options.operatorControl.dimScreen.value,
        sevenDayScheduler: options.auxiliaryHeaterFunctionOptions.sevenDayScheduler.value,
        clockDisplayOnOff: options.operatorControl.clockDisplayOnOff.value,
        clockDisplayHours: options.operatorControl.clockDisplayHours.value,
        daylightSavings: options.operatorControl.daylightSavings.value,
        climateControlEnable: options.climateSettingOptions.climateControlEnable.value,
        screenOnInDrive: options.operatorControl.screenOnInDrive.value,
        temperatureSetPointMinimumValue: convertTemperatureToCelsius(options.climateSettingOptions.temperatureSetPointMinimum.value),
        temperatureSetPointMaximumValue: convertTemperatureToCelsius(options.climateSettingOptions.temperatureSetPointMaximum.value),
      };

      await instance.put(`/Vehicle/CreateOperatorControls`, operatorControls); // TODO: Handle response?
    },
    /**
     * Perform a network request to a new vehicle. This will save the general info, then call saveStandardOptions
     */
    async saveNewVehicle({ state, dispatch }) {
      return new Promise<void>(async (myResolve, myReject) => {
        const general: GeneralInfo = state.generalInfo;
        const vehicle: CreateVehicle = {
          vin: general.VIN ?? "",
          kitId: general.kit?.kitId ?? null,
          idleTrackerIMEI: general.IMEI ?? "",
          assetName: general.assetName,
          vehicleZoneId: general.vehicleZoneId ?? null,
          vehicleClientId: general.vehicleClientId ?? null,
          year: general.year,
          make: general.make ?? "",
          model: general.model ?? "",
          series: general.series ?? "",
          bodyType: general.bodyType ?? "",
          gvwrClass: general.gvwrClass ?? "",
          vehicleType: general.vehicleType ?? "",
          fuelType: general.fuelType ?? "",
          fuelCost: general.fuelCost,
          engineType: general.engineType ?? "",
          engineManufacturer: general.engineManufacturer ?? "",
          brakeSystem: general.brakeSystem ?? "",
          driveLineType: general.driveLineType ?? "",
          other: general.other ?? "",
          frequency: general.frequency ?? "",
          fuelConsumption: convertFuelToLitres(general.fuelConsumption),
          engineDisplacement: general.engineDisplacement,
          transmissionType: general.transmissionType,
          vehicleId: state.vehicleID ?? -1,
        };

        const response = await instance.post("/Vehicle", vehicle).catch(() => myReject());

        if (response && response.status == 201) {
          const vehicleId = response.data.vehicleId;
          dispatch("saveStandardOptions", vehicleId)
            .then(() => myResolve())
            .catch(() => myReject());
        } else {
          myReject((response as any)?.response?.data?.detail);
        }
      });
    },
    /**
     * Perform a network request to create the standard options for a vehicle. This will save the standard options, then call saveOptionalFeatures
     */
    async saveStandardOptions({ state, dispatch }, vehicleId) {
      return new Promise<void>(async (myResolve, myReject) => {
        const options: StandardOptions = state.standardOptions;
        const standardOptions: CreateStandardOptions = {
          vehicleID: vehicleId,
          maxRunTime: options.idleControlOptions.maxRunTime.value,
          maxIdleTime: options.idleControlOptions.maxIdleTime.value,
          maxRPMForIdle: options.idleControlOptions.maxRPMForIdle.value,
          startStopWarningTime: options.idleControlOptions.startStopWarningTime.value,
          throttleOverride: options.idleControlOptions.throttleOverride.value,
          throttleOverrideLimit: options.idleControlOptions.throttleOverrideLimit.value,
          watchDog: options.ignitionSignalControl.watchDog.value,
          watchDogVoltageSetPoint: options.ignitionSignalControl.watchDogVoltageSetPoint.value,
          accessoryTimeDelay: options.ignitionSignalControl.accessoryTimeDelay.value,
          batteryEnabled: options.batteryOptions.batteryEnabled.value,
          batteryOption: options.batteryOptions.batteryOption.value,
          mainBatteryVoltage: options.batteryOptions.mainBatteryVoltage.value,
          auxiliaryBatteryVoltage: options.batteryOptions.auxiliaryBatteryVoltage.value,
          mainBatteryLowSetPoint: options.batteryOptions.mainBatteryLowSetPoint.value,
          controllerShutdownLowBatterySetPoint: options.batteryOptions.controllerShutdownLowBatterySetPoint.value,
          controllerShutdownTime: options.batteryOptions.controllerShutdownTime.value,
          batterySaverShutdownOverride: options.batteryOptions.batterySaverShutdownOverride.value,
          auxilliaryBatteryLowSetPoint: options.batteryOptions.auxiliaryBatteryLowSetPoint.value,
          mainBatteryMonitoring: options.batteryOptions.batteryMonitoringEnabled.value,
        };

        const response = await instance.post("/Vehicle/CreateStandardOptions", standardOptions).catch(() => myReject());

        if (response && response.status == 201) {
          dispatch("saveOptionalFeatures", vehicleId)
            .then(() => myResolve())
            .catch(() => myReject());
        } else {
          myReject();
        }
      });
    },
    /**
     * Perform a network request to create the optional features for a vehicle. This will save the optional features, then call saveOptionalEquipment
     */
    async saveOptionalFeatures({ state, dispatch }, vehicleId) {
      return new Promise<void>(async (myResolve, myReject) => {
        const options = state.optionalFeatures;
        const optionalFeatures: CreateOptionalFeatures = {
          vehicleID: vehicleId,
          airPressureMonitoringEnabled: options.airPressureMonitoring.airPressureMonitoringEnabled.value,
          airPressureSetPoint: options.airPressureMonitoring.airPressureSetPoint.value,
          airPressureOffset: options.airPressureMonitoring.airPressureOffset.value,
          brakeStartEnabled: options.brakeStart.brakeStartEnabled.value,
          driverDoorStartEnabled: options.doorStart.driverDoorStartEnabled.value,
          doorOpenStartEnabled: options.doorStart.doorOpenStartEnabled.value,
          maxIdleNoOccupants: options.doorStart.maxIdleNoOccupants.value,
          doorStartActivationSignal: options.doorStart.doorStartActivationSignal.value,
          doorOpenDelay: options.doorStart.doorOpenDelay.value,
          maxIdleTimeDoorOpen: options.doorStart.maxIdleTimeDoorOpen.value,
          engineStartHydraulicEnabled: options.engineStartHydraulic.engineStartHydraulicEnabled.value,
          highHydraulicTempSetPointValue: convertTemperatureToCelsius(options.engineStartHydraulic.highHydraulicTempSetPoint.value),
          lowHydraulicTempSetPointValue: convertTemperatureToCelsius(options.engineStartHydraulic.lowHydraulicTempSetPoint.value),
          highIdleEnabled: options.highIdle.highIdleEnabled.value,
          seatBeltStartEnabled: options.seatBeltStart.seatBeltStartEnabled.value,
          seatBeltOffOnly: options.seatBeltStart.seatBeltOffOnly.value,
          shorePowerEnabled: options.shorePower.shorePowerEnabled.value,
          antiTheftEnabled: options.antiTheft.AntiTheftEnabled.value,
          auxiliaryAntiTheftEnabled: options.antiTheft.AuxiliaryAntiTheftEnabled.value,
          antiTheftOnlyEnabled: options.antiTheft.AntiTheftOnlyEnabled.value,
          safeModeEnabled: options.safeMode.safeModeEnabled.value,
          safeMainBatteryLowSetPoint: options.safeMode.safeMainBatteryLowSetPoint.value,
          safeAuxiliaryBatteryLowSetPoint: options.safeMode.safeAuxiliaryBatteryLowSetPoint.value,
          safeMonitorAirConditioningEnabled: options.safeMode.safeMonitorAirConditioningEnabled.value,
          safeMonitorAirConditioningHighTempSetPointValue: convertTemperatureToCelsius(options.safeMode.safeMonitorAirConditioningHighTempSetPoint.value),
          safeMonitorHeatEnabled: options.safeMode.safeMonitorHeatEnabled.value,
          safeMonitorHeatLowTempSetPointValue: convertTemperatureToCelsius(options.safeMode.safeMonitorHeatLowTempSetPoint.value),
          safeEnginePumpLowCoolantTempEnabled: options.safeMode.safeEnginePumpLowCoolantTempEnabled.value,
          safeEnginePumpLowCoolantTempHighSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEnginePumpLowCoolantTempHighSetPoint.value),
          safeEnginePumpLowCoolantTempLowSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEnginePumpLowCoolantTempLowSetPoint.value),
          safeEngineStartHydraulicTempEnabled: options.safeMode.safeEngineStartHydraulicTempEnabled.value,
          safeEngineStartHydraulicTempHighSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEngineStartHydraulicTempHighSetPoint.value),
          safeEngineStartHydraulicTempLowSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEngineStartHydraulicTempLowSetPoint.value),
          safeEngineStartLowCoolantTempEnabled: options.safeMode.safeEngineStartLowCoolantTempEnabled.value,
          safeEngineStartLowCoolantTempHighSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEngineStartLowCoolantTempHighSetPoint.value),
          safeEngineStartLowCoolantTempLowSetPointValue: convertTemperatureToCelsius(options.safeMode.safeEngineStartLowCoolantTempLowSetPoint.value),
          safeAuxiliaryHeaterEngineEnabled: options.safeMode.safeAuxiliaryHeaterEngineEnabled.value,
          safeAuxiliaryHeaterEngineHighSetPointValue: convertTemperatureToCelsius(options.safeMode.safeAuxiliaryHeaterEngineHighSetPoint.value),
          safeAuxiliaryHeaterEngineLowSetPointValue: convertTemperatureToCelsius(options.safeMode.safeAuxiliaryHeaterEngineLowSetPoint.value),
          safeMonitorRearEnabled: options.safeMode.safeMonitorRearEnabled.value,
          safeMonitorRearHighSetPointValue: convertTemperatureToCelsius(options.safeMode.safeMonitorRearHighSetPoint.value),
          safeMonitorRearLowSetPointValue: convertTemperatureToCelsius(options.safeMode.safeMonitorRearLowSetPoint.value),
          ptoAuxLightEnabled: options.ptoAuxLight.ptoLightAuxEnabled.value,
          electricParkingBrakeEnabled: options.electricParkBrake.electricParkingBrakeEnabled.value,
          overrideShutdown: options.overrideShutdown.overrideShutdown.value
        };

        const response = await instance.post("/Vehicle/CreateOptionalFeatures", optionalFeatures).catch(() => myReject());

        if (response && response.status == 201) {
          dispatch("saveOptionalEquipment", vehicleId)
            .then(() => myResolve())
            .catch(() => myReject());
        } else {
          myReject();
        }
      });
    },
    /**
     * Perform a network request to create the optional equipment for a vehicle. This will save the optional equipment, then call saveOperatorControls
     */
    async saveOptionalEquipment({ state, dispatch }, vehicleId) {
      return new Promise<void>(async (myResolve, myReject) => {
        const options = state.optionalEquipment;
        const optionalEquipment: CreateOptionalEquipment = {
          vehicleID: vehicleId,
          startStopEnabled: options.startStopAlarm.startStopAlarmEnabled.value,
          startWarningAlaram: options.startStopAlarm.startWarningAlarm.value,
          startAlarmFrequency: options.startStopAlarm.startAlarmFrequency.value,
          stopWarningAlaram: options.startStopAlarm.stopWarningAlarm.value,
          stopAlarmFrequency: options.startStopAlarm.stopAlarmFrequency.value,
          breakPedalAlarm: options.startStopAlarm.breakPedalAlarm.value,
          automaticClimateControlEnabled: options.automaticClimateControl.automaticClimateControlEnabled.value,
          blowerInitialSpeed: options.automaticClimateControl.blowerInitialSpeed.value,
          dontShutBlowerOff: options.automaticClimateControl.dontShutBlowerOff.value,
          dontRampUpBlower: options.automaticClimateControl.dontRampUpBlower.value,
          passengerSeatBelt: options.automaticClimateControl.passengerSeatbelt.value,
          currentSensorOption: options.currentSensor.currentSensorOption.value,
          chargeDischargeSetPoint: options.currentSensor.chargeDischargeSetPoint.value,
          humidityEnabled: options.humidity.humidityEnabled.value,
          ignitionBypassEnabled: options.ignitionBypass.ignitionBypassEnabled.value,
          rearFacilityEnabled: options.rearFacility.rearFacilityEnabled.value,
          rearFacilityTemperatureSetPointValue: convertTemperatureToCelsius(options.rearFacility.rearFacilityTemperatureSetPoint.value),
          rearFacilityAirConditioningTempOffsetValue: convertTemperatureToCelsius(options.rearFacility.rearFacilityAirConditioningTempOffset.value),
          rearFacilityHeatingTempOffsetValue: convertTemperatureToCelsius(options.rearFacility.rearFacilityHeatingTempOffset.value),
          rearFacilityEngineRunInitial: options.rearFacility.rearFacilityEngineRunInitial.value,
          rearFacilityEngineAssistRearAir: options.rearFacility.rearFacilityEngineAssistRearAir.value,
          rearFacilityEngineAssistCabAir: options.rearFacility.rearFacilityEngineAssistCabAir.value,
          rearFacilityEngineAssistAirOffsetValue: convertTemperatureToCelsius(options.rearFacility.rearFacilityEngineAssistAirOffset.value),
          rearFacilityEngineAssistAirTimer: options.rearFacility.rearFacilityEngineAssistAirTimer.value,
          secondaryTempSensorEnabled: options.secondaryTempSensor.secondaryTempSensorEnabled.value,
          heatingEnabled: options.heatingOptions.heatingEnabled.value,
          heatingOption: options.heatingOptions.heatingOption.value,
          heatContinuousOperationOnOff: options.heatingOptions.heatContinuousOperationOnOff.value,
          heatContinuousOperationSetPointValue: convertTemperatureToCelsius(options.heatingOptions.heatContinuousOperationSetPoint.value),
          heatingClimateControlEnabled: options.heatingOptions.climateControlEnable.value,
          heaterTempOffsetValue: convertTemperatureToCelsius(options.heatingOptions.heaterTempOffset.value),
          heatingSchedulerManager: options.heatingOptions.heatingSchedulerManager.value,
          heatingSchedulerRequired: options.auxHeatingOptions.heatingSchedulerRequired.value,
          auxiliaryHeaterInitialRun: options.auxHeatingOptions.auxHeaterInitialRun.value,
          manualAUxiliaryHeaterOption: options.auxHeatingOptions.manualAuxiliaryHeater.value,
          coolantPumpHighCoolantTempSetPointValue: convertTemperatureToCelsius(options.heatingOptions.coolantPumpHighCoolantTempSetPoint.value),
          coolantPumpLowEngineCoolantTempSetPointValue: convertTemperatureToCelsius(options.heatingOptions.coolantPumpLowEngineCoolantTempSetPoint.value),
          aCEnabled: options.airConditioningOptions.ACEnabled.value,
          aCOptions: options.airConditioningOptions.ACOption.value,
          aCDontStartSetPointValue: convertTemperatureToCelsius(options.airConditioningOptions.dontStart.value),
          aCTemperatureOffsetValue: convertTemperatureToCelsius(options.airConditioningOptions.ACTemperatureOffset.value),
          aCContinuousOperationOnOff: options.airConditioningOptions.ACContinuousOperationOnOff.value,
          aCContinuousOperationSetPointValue: convertTemperatureToCelsius(options.airConditioningOptions.ACContinuousOperationSetPoint.value),
          aCClimateControlEnabled: options.airConditioningOptions.ACClimateControlEnable.value,
          engineStartLowCoolantTemp: options.engineStartOptions.engineStartEnabled.value,
          engineBoostAuxHeater: options.engineStartOptions.engineBoostAuxHeater.value,
          auxHeaterHighValue: convertTemperatureToCelsius(options.engineStartOptions.auxHeaterHigh.value),
          auxHeaterLowValue: convertTemperatureToCelsius(options.engineStartOptions.auxHeaterLow.value),
          engineStartHighEngineCoolantTempSetPointValue: convertTemperatureToCelsius(options.engineStartOptions.engineStartHigh.value),
          engineStartLowEngineCoolantTempSetPointValue: convertTemperatureToCelsius(options.engineStartOptions.engineStartLow.value),
          engineRunInitialLowCoolant: options.engineRunOptions.engineRunEnabled.value,
          engineRunLowCoolantSetPointValue: convertTemperatureToCelsius(options.engineRunOptions.engineRunForLowCoolantSetPoint.value),
        };

        const response = await instance.post("/Vehicle/CreateOptionalEquipment", optionalEquipment).catch(() => myReject());

        if (response && response.status == 201) {
          dispatch("saveOperatorControls", vehicleId)
            .then(() => myResolve())
            .catch(() => myReject());
        } else {
          myReject();
        }
      });
    },
    /**
     * Perform a network request to create the operator controls for a vehicle.
     */
    async saveOperatorControls({ state, dispatch }, vehicleId) {
      return new Promise<void>(async (myResolve, myReject) => {
        const options = state.operatorControls;
        const operatorControls: CreateOperatorControls = {
          vehicleID: vehicleId,
          celsiusOrFahrenheit: options.climateSettingOptions.celsiusOrFahrenheit.value,
          dimScreen: options.operatorControl.dimScreen.value,
          sevenDayScheduler: options.auxiliaryHeaterFunctionOptions.sevenDayScheduler.value,
          clockDisplayOnOff: options.operatorControl.clockDisplayOnOff.value,
          clockDisplayHours: options.operatorControl.clockDisplayHours.value,
          daylightSavings: options.operatorControl.daylightSavings.value,
          climateControlEnable: options.climateSettingOptions.climateControlEnable.value,
          screenOnInDrive: options.operatorControl.screenOnInDrive.value,
          temperatureSetPointMinimumValue: convertTemperatureToCelsius(options.climateSettingOptions.temperatureSetPointMinimum.value),
          temperatureSetPointMaximumValue: convertTemperatureToCelsius(options.climateSettingOptions.temperatureSetPointMaximum.value),
        };

        const response = await instance.post("/Vehicle/CreateOperatorControls", operatorControls).catch(() => myReject());

        if (response && response.status == 201) {
          if (response && response.status == 201) {
            dispatch("saveKitEquipment", vehicleId)
              .then(() => myResolve())
              .catch(() => myReject());
          } else {
            myReject();
          }
        } else {
          myReject();
        }
      });
    },
    /**
     * Perform a network request to create the operator controls for a vehicle.
     */
    async saveKitEquipment({ state, dispatch }, vehicleId) {
      return new Promise<void>(async (myResolve, myReject) => {
        const options = state.vehicleEquipment.selected;
        const kitEquipment: CreateVehicleKitEquipment = {
          vehicleID: vehicleId,
          ignitionBypass: options.ignitionBypass?.partNumber ?? null,
          alarm: options.alarm?.partNumber ?? null,
          antiTheft: options.antiTheft?.partNumber ?? null,
          solenoid: options.solenoid?.partNumber ?? null,
          battery: options.battery?.partNumber ?? null,
          batteryCount: options.batteryCount,
          heat: options.heat?.partNumber ?? null,
          airConditioning: options.airConditioning?.partNumber ?? null,
          currentSensor: options.currentSensor?.partNumber ?? null,
          autoHVAC: options.autoHVAC?.partNumber ?? null,
          humidity: options.humidity?.partNumber ?? null,
          tempSensing: options.tempSensing?.partNumber ?? null,
          rearFacilityControl: options.rearFacilityControl?.partNumber ?? null,
          useMyOwnAlarm: options.useMyOwnAlarm,
          useMyOwnBattery: options.useMyOwnBattery,
          useMyOwnHeating: options.useMyOwnHeating,
          useMyOwnSolenoid: options.useMyOwnSolenoid,
        };

        const response = await instance.post("/Vehicle/CreateKitEquipment", kitEquipment).catch(() => myReject());

        if (response && response.status == 201) {
          dispatch("saveInputsOutputs", vehicleId)
            .then(() => myResolve())
            .catch(() => myReject());
        } else {
          myReject();
        }
      });
    },
    /**
     * Perform a network request to create the input/output configuration for a vehicle.
     */
    async saveInputsOutputs({ state }, vehicleId) {
      return new Promise<void>(async (myResolve, myReject) => {
        const inputsOutputs = state.inputsOutputs;
        const createInputsOutputs: CreateInputsOutputs = {
          vehicleID: vehicleId,
          outputDcac: inputsOutputs.outputs.dcac.value,
          outputComboHeater: inputsOutputs.outputs.comboHeater.value,

          outputAirHeater: inputsOutputs.outputs.airHeater.value,
          outputHighIdle: inputsOutputs.outputs.highIdle.value,
          outputAntiTheft: inputsOutputs.outputs.antiTheft.value,
          outputClutch: inputsOutputs.outputs.clutch.value,
          outputHvac: inputsOutputs.outputs.hvac.value,
          outputCondensor: inputsOutputs.outputs.condensor.value,
          outputEngiineRunning: inputsOutputs.outputs.engineRunning.value,

          inputEngineStart: inputsOutputs.inputs.engineStart.value,
          inputEngineStartSignalType: inputsOutputs.inputs.engineStart.signalType,
          inputEngineStop: inputsOutputs.inputs.engineStop.value,
          inputEngineStopSignalType: inputsOutputs.inputs.engineStop.signalType,
          inputHeater: inputsOutputs.inputs.heater.value,
          inputHeaterSignalType: inputsOutputs.inputs.heater.signalType,
          inputSolenoid: inputsOutputs.inputs.solenoid.value,
          inputSolenoidSignalType: inputsOutputs.inputs.solenoid.signalType,
          inputAlarm: inputsOutputs.inputs.alarm.value,
          inputAlarmSignalType: inputsOutputs.inputs.alarm.signalType,
          inputBunkHeater: inputsOutputs.inputs.bunkHeater.value,
          inputBunkHeaterSignalType: inputsOutputs.inputs.bunkHeater.signalType,
          inputBunkAC: inputsOutputs.inputs.bunkAC.value,
          inputBunkACSignalType: inputsOutputs.inputs.bunkAC.signalType,
          inputParkBrake: inputsOutputs.inputs.parkBrake.value,
          inputParkBrakeSignalType: inputsOutputs.inputs.parkBrake.signalType,
          inputNeutralSwitch: inputsOutputs.inputs.neutralSwitch.value,
          inputNeutralSwitchSignalType: inputsOutputs.inputs.neutralSwitch.signalType,
          inputHighIdle: inputsOutputs.inputs.inputHighIdle.value,
          inputHighIdleSignalType: inputsOutputs.inputs.inputHighIdle.signalType,
          inputShorePower: inputsOutputs.inputs.shorePower.value,
          inputShorePowerSignalType: inputsOutputs.inputs.shorePower.signalType,
          inputPTO: inputsOutputs.inputs.pto.value,
          inputPTOSignalType: inputsOutputs.inputs.pto.signalType,
          inputAuxAntiTheft: inputsOutputs.inputs.auxAntiTheft.value,
          inputAuxAntiTheftSignalType: inputsOutputs.inputs.auxAntiTheft.signalType,
          inputDoorStart: inputsOutputs.inputs.doorStart.value,
          inputDoorStartSignalType: inputsOutputs.inputs.doorStart.signalType,
          inputCompressor: inputsOutputs.inputs.compressor.value,
          inputCompressorSignalType: inputsOutputs.inputs.compressor.signalType,
          inputRearFacility: inputsOutputs.inputs.rearFacility.value,
          inputRearFacilitySignalType: inputsOutputs.inputs.rearFacility.signalType,
          inputSafeMode: inputsOutputs.inputs.safeMode.value,
          inputSafeModeSignalType: inputsOutputs.inputs.safeMode.signalType,
        };

        const response = await instance.post("/Vehicle/CreateInputsOutputs", createInputsOutputs).catch(() => myReject());

        if (response && response.status == 201) {
          myResolve();
        } else {
          myReject();
        }
      });
    },
  },
  mutations: {
    SET_GENERAL_INFO(state, generalInfo: GeneralInfo) {
      state.generalInfo = generalInfo;
    },
    SET_STANDARD_OPTIONS(state, standardOptions: StandardOptions) {
      state.standardOptions = standardOptions;
    },
    SET_OPTIONAL_FEATURES(state, optionalFeatures: OptionalFeatures) {
      state.optionalFeatures = optionalFeatures;
    },
    SET_OPTIONAL_EQUIPMENT(state, optionalEquipment: OptionalEquipment) {
      state.optionalEquipment = optionalEquipment;
    },
    SET_VEHICLES(state, vehicles: Vehicle[]) {
      state.vehicles = vehicles;
    },
    SET_OPERATOR_CONTROLS(state, operatorControls: OperatorControls) {
      state.operatorControls = operatorControls;
    },
    /**
     * Reset the store back to default values.
     * Note: This does not reset whether a vehicle is being updated or created.
     */
    RESET_STORE(state: VehicleState) {
      state.standardOptions = defaultStandardOptions() as StandardOptions;
      state.generalInfo = defaultGeneralInfo() as GeneralInfo;
      state.optionalFeatures = defaultOptionalFeatures() as OptionalFeatures;
      state.operatorControls = defaultOperatorControls() as OperatorControls;
      state.optionalEquipment = defaultOptionalEquipment() as OptionalEquipment;
      state.vehicleEquipment = defaultVehicleEquipment() as VehicleEquipment;
      state.inputsOutputs = defaultIntputsOutputs() as InputsOutputs;
      state.isCreatingNewVehicle = true;
      state.vehicle = null;
      state.vehicleID = null;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const standardOptions: any = defaultStandardOptions() as StandardOptions;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const optionalFeatures: any = defaultOptionalFeatures() as OptionalFeatures;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const operatorControls: any = defaultOperatorControls() as OperatorControls;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const optionalEquipment: any = defaultOptionalEquipment() as OptionalEquipment;

      // Convert temperature values to the user's temperature unit
      for (const group in standardOptions) {
        for (const param in standardOptions[group]) {
          const p = standardOptions[group][param];
          if (p.type == InputType.temperature) {
            p.value = convertTemperatureFromCelsius(p.value);
          }
        }
      }

      // Convert temperature values to the user's temperature unit
      for (const group in optionalFeatures) {
        for (const param in optionalFeatures[group]) {
          const p = optionalFeatures[group][param];
          if (p.type == InputType.temperature) {
            p.value = convertTemperatureFromCelsius(p.value);
          }
        }
      }

      // Convert temperature values to the user's temperature unit
      for (const group in operatorControls) {
        for (const param in operatorControls[group]) {
          const p = operatorControls[group][param];
          if (p.type == InputType.temperature) {
            p.value = convertTemperatureFromCelsius(p.value);
          }
        }
      }

      // Convert temperature values to the user's temperature unit
      for (const group in optionalEquipment) {
        for (const param in optionalEquipment[group]) {
          const p = optionalEquipment[group][param];
          if (p.type == InputType.temperature) {
            p.value = convertTemperatureFromCelsius(p.value);
          }
        }
      }

      state.standardOptions = standardOptions;
      state.optionalFeatures = optionalFeatures;
      state.operatorControls = operatorControls;
      state.optionalEquipment = optionalEquipment;
    },
    /**
     * Set the flags to indicate that a vehicle is being updated and set the property of what vehicle is being updated
     */
    UPDATING_VEHICLE(state, vehicle: Vehicle) {
      state.isCreatingNewVehicle = false;
      state.isUpdatingVehicle = true;
      state.vehicleID = vehicle.vehicleId;
      state.vehicle = vehicle;
    },
    /**
     * Set the flags to indicate that a new vehicle is being created
     */
    CREATING_VEHICLE(state: VehicleState) {
      state.isCreatingNewVehicle = true;
      state.isUpdatingVehicle = false;
      state.vehicle = null;
      state.vehicleID = null;
    },
    SET_VEHICLE(state, vehicle: Vehicle) {
      state.vehicle = vehicle;
      state.vehicleID = vehicle.vehicleId;
    },
    /**
     * Sets the equipment that is optional for this vehicle
     */
    SET_EQUIPMENT_OPTIONS(state, options: Equipment) {
      state.vehicleEquipment.options = options;
    },
    /**
     * Sets the optional equipment that the user has selected
     */
    SET_EQUIPMENT_OPTIONS_SELECTED(state, equipment: EquipmentSelected) {
      state.vehicleEquipment.selected = equipment;
    },
    /**
     * Sets the input output configurations that the user has selected
     */
    SET_INPUTS_OUTPUTS(state, inputsOutputs: InputsOutputs) {
      state.inputsOutputs = inputsOutputs;
    },
  },
};
