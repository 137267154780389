











































import { Part } from "@/model/API/Part";
import Vue from "vue";
import instance from "@/axios";

import { mapGetters } from "vuex";
import { VehicleEquipment } from "@/model/VehicleProfile/VehicleEquipment";
import PartMedia from "@/components/AddVehicle/PartMedia.vue";
import { Media } from "@/model/API/Media";
import { MediaType } from "@/model/API/MediaType";

export default Vue.extend({
  name: "QuoteModal",
  components: {
    PartMedia,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  created: async function() {
    if (this.selectedKit) {
      const response = await instance.get<Part>(`/Part/${this.selectedKit.hardwareKit}`);

      if (response && response.status == 200 && response.data) {
        this.kitPart = response.data;
      }

      this.getIgnitionSheet();
    }
  },
  data: () => ({
    drawer: null,
    kitPart: null as Part | null,
    wiringDocument: null as Media | null,
  }),
  methods: {
    async getIgnitionSheet() {
      const response = await instance.get<Media>(`/Media/name?name=${this.selectedKit.wiringDocument}`);
      if (response && response.status == 200 && response.data) {
        this.wiringDocument = response.data;
      }
    },
    close() {
      this.$emit("close");
    },
    filterMediaTypesToPdf(media: Media[]) {
      return media.filter((m) => m.mediaType == MediaType.Pdf && m.fileNameOriginal.includes(".pdf"));
    },
  },
  computed: {
    ...mapGetters(["selectedKit", "vehicleEquipment"]),
    selectedEquipment(): Part[] {
      let equipment = [] as Part[];
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;

      for (const [, value] of Object.entries(vehicleEquipment.selected)) {
        let part: Part | null = value;

        if (part) {
          equipment.push(part);
        }
      }

      return equipment;
    },
    allParts(): Part[] {
      let parts = [] as Part[];

      if (this.kitPart) {
        parts.push(this.kitPart);
      }
      parts.push(...this.selectedEquipment);

      return parts;
    },
  },
});
