import { InputType, listBoolParameter, numberParameter, temperatureParameter } from "../OptionParameter";

import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface SafeModeOptions {
  safeModeEnabled: listBoolParameter;
  safeMainBatteryLowSetPoint: numberParameter;
  safeAuxiliaryBatteryLowSetPoint: numberParameter;
  safeMonitorAirConditioningEnabled: listBoolParameter;
  safeMonitorAirConditioningHighTempSetPoint: temperatureParameter;
  safeMonitorHeatEnabled: listBoolParameter;
  safeMonitorHeatLowTempSetPoint: temperatureParameter;
  safeEnginePumpLowCoolantTempEnabled: listBoolParameter;
  safeEnginePumpLowCoolantTempHighSetPoint: temperatureParameter;
  safeEnginePumpLowCoolantTempLowSetPoint: temperatureParameter;
  safeEngineStartHydraulicTempEnabled: listBoolParameter;
  safeEngineStartHydraulicTempHighSetPoint: temperatureParameter;
  safeEngineStartHydraulicTempLowSetPoint: temperatureParameter;
  safeEngineStartLowCoolantTempEnabled: listBoolParameter;
  safeEngineStartLowCoolantTempHighSetPoint: temperatureParameter;
  safeEngineStartLowCoolantTempLowSetPoint: temperatureParameter;
  safeAuxiliaryHeaterEngineEnabled: listBoolParameter;
  safeAuxiliaryHeaterEngineHighSetPoint: temperatureParameter;
  safeAuxiliaryHeaterEngineLowSetPoint: temperatureParameter;
  safeMonitorRearEnabled: listBoolParameter;
  safeMonitorRearHighSetPoint: temperatureParameter;
  safeMonitorRearLowSetPoint: temperatureParameter;
}

export const defaultSafeModeOptions: SafeModeOptions = {
  safeModeEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Safe Mode",
    info:
      "This will put the system into safe mode operation. The ‘Safe Mode Option’ must be enabled in the ‘Options Settings’ tab for the function to work. After turning the ‘Safe Mode’ on, the key must be removed from the ignition within 10 seconds, otherwise safe mode will be disabled. This is primarily used for testing the safe mode operation (default is OFF).",
  },
  safeMainBatteryLowSetPoint: {
    type: InputType.number,
    value: 11.8,
    defaultValue: 11.8,
    display: "Main battery Safe Low Set Point",
    info: "If safe mode is enabled, the engine will start when the main battery voltage falls below this set point (default is 11.8V).",
    rules: ["required"],
    minValue: 11.0,
    maxValue: 30.0,
    increment: 0.1,
  },
  safeAuxiliaryBatteryLowSetPoint: {
    type: InputType.number,
    value: 11.7,
    defaultValue: 11.7,
    display: "Auxiliary Battery Safe Low Set Point",
    info: " If safe mode is enabled, the engine will start when the auxiliary battery voltage falls below this set point (default is 11.7V).",
    rules: ["required"],
    minValue: 11.0,
    maxValue: 30.0,
    increment: 0.1,
  },
  safeMonitorAirConditioningEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Monitor For Air Conditioning",
    info:
      "Air Conditioning Option Selector - This selection chooses if restarting for air conditioning is required and whether the vehicle engine or an electric A/C unit is installed (default is OFF).",
  },
  safeMonitorHeatEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Monitor For Heat",
    info: "Monitor for Heat - This feature if enabled and the system is in safe mode, will monitor the cab for heating (default is OFF).",
  },
  safeMonitorHeatLowTempSetPoint: {
    type: InputType.temperature,
    value: 10,
    defaultValue: 10,
    display: "Low Temp Set Point - Safe Mode",
    info:
      "If safe mode is enabled and the cab temperature drops below this set point, the heating will come on and remain on until this set point plus the ‘Heater Temp Offset’ has been reached (default is 10°C/50°F).",
    rules: ["required"],
    minValue: 0,
    maxValue: 20,
  },
  safeMonitorAirConditioningHighTempSetPoint: {
    type: InputType.temperature,
    value: 35,
    defaultValue: 35,
    display: "High Temp Set Point - Safe Mode",
    info:
      "If safe mode is enabled and the cab temperature reaches this set point, the air conditioning will come on and remain on until this set point minus the ‘Air Conditioning Temp Offset’ has been reached (default is 35°C/95°F). ",
    rules: ["required"],
    minValue: 21,
    maxValue: 40,
  },
  safeEnginePumpLowCoolantTempEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Coolant Pump Run for Low Coolant Temp On/Off",
    info:
      "If safe mode is enabled, this feature will run the coolant pump when cab heating is required. It will also start and stop the engine to maintain the coolant temperature (default is OFF).",
  },
  safeEnginePumpLowCoolantTempHighSetPoint: {
    type: InputType.temperature,
    value: 75,
    defaultValue: 75,
    display: "High Engine Coolant Temp Set Point",
    info: "If safe mode is enabled, once the coolant temperature reached this set point, the engine will shut down. (default is 75°C/167°F).",
    rules: ["required"],
    minValue: 60,
    maxValue: 100,
  },
  safeEnginePumpLowCoolantTempLowSetPoint: {
    type: InputType.temperature,
    value: 45,
    defaultValue: 45,
    display: "Low Engine Coolant Temp Set Point",
    info: "If safe mode is enabled, Once the Coolant temperature has reached this set point, the engine will start. (default is 45°C/113°F).",
    rules: ["required"],
    minValue: 30,
    maxValue: 100,
  },
  safeEngineStartHydraulicTempEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Engine Start For Hydraulic Temp On/Off",
    info: "The system will start to keep Hydraulic fluid warm.",
  },
  safeEngineStartHydraulicTempHighSetPoint: {
    type: InputType.temperature,
    value: 20,
    defaultValue: 20,
    display: "High Hydraulic Temp Set Point",
    info: "If safe mode is enabled, this settingwill shut the engine down once the hydraulic temperature has reachedthis setpoint (default is 20°C/68°F)",
    rules: ["required"],
    minValue: 3,
    maxValue: 100,
  },
  safeEngineStartHydraulicTempLowSetPoint: {
    type: InputType.temperature,
    value: 10,
    defaultValue: 10,
    display: "Low Hydraulic Temp Set Point",
    info:
      "If safe mode is enabled and the cab temperature drops below this set point, the heating will come on and remain on until this set point plus the ‘Heater Temp Offset’ has been reached (default is 10°C/50°F).",
    rules: ["required"],
    minValue: 0,
    maxValue: 97,
  },
  safeEngineStartLowCoolantTempEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Engine Start For Low Coolant Temp On/Off",
    info: "The system will start the engine to maintain the coolant temperature of the engine.",
  },
  safeEngineStartLowCoolantTempHighSetPoint: {
    type: InputType.temperature,
    value: 20,
    defaultValue: 20,
    display: "High Engine Coolant Temp - Safe Mode",
    info:
      "If safe mode is enabled, this is the temperature at which the engine will shut off, after it has been started for ‘Low Engine Coolant Temp – Safe Mode’ (default is 20°C/68°F).",
    rules: ["required"],
    minValue: 3,
    maxValue: 100,
  },
  safeEngineStartLowCoolantTempLowSetPoint: {
    type: InputType.temperature,
    value: 0,
    defaultValue: 0,
    display: "Low Engine Coolant Temp - Safe Mode",
    info:
      "If safe mode is enabled this is the temperature at which the engine will start due to the ‘Low Engine Coolant Temp - Safe Mode’ (default is -10°C/14°F)",
    rules: ["required"],
    minValue: 0,
    maxValue: 97,
  },
  safeAuxiliaryHeaterEngineEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Auxiliary Heater Engine Help On/Off",
    info:
      "This feature if enabled and the system is in safe mode, will start the engine if the fuel fire heater is unable to sufficiently heat the coolant (default is OFF).",
  },
  safeAuxiliaryHeaterEngineHighSetPoint: {
    type: InputType.temperature,
    value: 75,
    defaultValue: 75,
    display: "High Engine Coolant Temp - Safe Mode",
    info:
      "If safe mode is enabled, this setting will shut the engine down once the coolant temperature has reached this set point (default is 75°C/167°F).",
    rules: ["required"],
    minValue: 40,
    maxValue: 100,
  },
  safeAuxiliaryHeaterEngineLowSetPoint: {
    type: InputType.temperature,
    value: 45,
    defaultValue: 45,
    display: "Low Engine Coolant Temp - Safe Mode",
    info: "If safe mode is enabled, this setting will start the engine once the coolant temperature drops below this set point (default is 45°C/113°F).",
    rules: ["required"],
    minValue: 40,
    maxValue: 100,
  },
  safeMonitorRearEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Monitor Rear for Climate",
    info:
      "This option is used to maintain the climate in a second location. The secondary temp sensor uses the same set point on the operator screen as the main temp sensor. An auxiliary heater or air conditioning unit must be installed in the location which needs to be maintained and the signal wire for this unit must be connected to the EPEQ® IM controller. NOTE: For this option to work, a secondary temp sensor must be installed on the vehicle.",
  },
  safeMonitorRearHighSetPoint: {
    type: InputType.temperature,
    value: 35,
    defaultValue: 35,
    display: "Rear High Temp Set Point - Safe Mode",
    info:
      "If safe mode is enabled and the cab temperature reaches this set point, the air conditioning will come on and remain on until this set point minus the ‘Air Conditioning Temp Offset’ has been reached (default is 35°C/95°F).",
    rules: ["required"],
    minValue: 21,
    maxValue: 40,
  },
  safeMonitorRearLowSetPoint: {
    type: InputType.temperature,
    value: 15,
    defaultValue: 15,
    display: "Rear Low Temp Set Point - Safe Mode",
    info:
      "If safe mode is enabled and the cab temperature drops below this set point, the heating will come on and remain on until this set point plus the ‘Heater Temp Offset’ has been reached (default is 10°C/50°F)",
    rules: ["required"],
    minValue: 0,
    maxValue: 20,
  },
};
