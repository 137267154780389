import { Kit } from "../API/Kit";
import { VehicleTransmissionType } from "../API/TransmissionType";

export interface GeneralInfo {
  assetName: string;
  VIN: string | null;
  IMEI: string | null;
  make: string | null;
  model: string | null;
  year: number | null;
  engineDisplacement: number | null;
  fuelConsumption: number | null;
  fuelType: string | null;
  fuelCost: number | null;
  engineType: string | null;
  engineManufacturer: string | null;
  brakeSystem: string | null;
  driveLineType: string | null;
  gvwrClass: string | null;
  vehicleType: string | null;
  frequency: string | null;
  vehicleZoneId: string | null;
  vehicleClientId: string | null;
  series: string | null;
  bodyType: string | null;
  other: string | null;
  kit: Kit | null;
  transmissionType: VehicleTransmissionType;
}

const defaultInfo: GeneralInfo = {
  assetName: "",
  VIN: null,
  IMEI: null,
  make: null,
  model: null,
  year: null,
  engineDisplacement: null,
  fuelConsumption: null,
  fuelType: null,
  fuelCost: null,
  engineType: null,
  engineManufacturer: null,
  brakeSystem: null,
  driveLineType: null,
  gvwrClass: null,
  vehicleType: null,
  frequency: null,
  vehicleZoneId: null,
  vehicleClientId: null,
  series: null,
  bodyType: null,
  other: null,
  kit: null,
  transmissionType: VehicleTransmissionType.Automatic,
};

export function defaultInfo1(): GeneralInfo {
  return JSON.parse(JSON.stringify(defaultInfo));
}

export const defaultGeneralInfo = defaultInfo1;
