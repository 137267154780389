import { InputType, numberParameter, listParameter } from "../OptionParameter";

export interface CurrentSensorOptions {
  currentSensorOption: listParameter;
  chargeDischargeSetPoint: numberParameter;
}

export enum CurrentSensorOption {
  None = "Disabled",
  Charging = "Charging",
  Discharging = "Discharging",
}

export const defaultCurrentSensorOptions: CurrentSensorOptions = {
  currentSensorOption: {
    type: InputType.list,
    value: CurrentSensorOption.None,
    defaultValue: CurrentSensorOption.None,
    list: [
      {
        display: "Disabled",
        value: CurrentSensorOption.None,
      },
      {
        display: "Charging",
        value: CurrentSensorOption.Charging,
      },
      {
        display: "Discharging",
        value: CurrentSensorOption.Discharging,
      },
    ],
    display: "Current Sensor Option",
    info: `
        <ul>
            <li>
                Charging - If this is selected, the current sensor can be used to monitor the current going back ○ into the battery, to know when the battery is fully charged instead of using the ‘Max Run Time’. Sometimes the battery may require additional time for recharging or it may require less time. This will maximize the efficiency for engine run time for battery charging. It will also ensure that the battery is kept at a better state of charge (SOC)
            </li>
            <li>
                Discharging - If this is selected, you can use the current sensor to keep the engine running once it has started for low battery. If you have a device that draws a great deal of current, requiring the engine to run, this will ensure that the engine continues to run while the current is being drawn. 
            </li>
        </ul>
        
    `,
  },
  chargeDischargeSetPoint: {
    type: InputType.number,
    value: 5,
    defaultValue: 5,
    display: "Charge/Discharge Set Point",
    info:
      "For the ‘Charging’ option the set point should be between 1-3 Amps per battery. For the ‘Discharging’ option the set point should be just under the current draw of the vehicle while the device is in use so that the engine stays running while the current load device is operating (default is 5). <br><strong>Mounting location of this sensor is crucial to what the sensor will monitor.</strong>",
    rules: ["required"],
    minValue: -45,
    maxValue: 45,
    increment: 1.0,
  },
};
