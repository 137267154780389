import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import VehicleProfiles from "@/views/VehicleProfiles.vue";
import AddVehicle from "@/views/AddVehicle.vue";
import Callback from "@/views/Callback.vue";
import UserGroups from "@/views/UserGroups.vue";
import Users from "@/views/Users.vue";
import Charts from "@/views/Charts.vue";
import Resources from "@/views/Resources.vue";
import Account from "@/views/AccountManagement.vue";
import Unauthorized from "@/views/Unauthorized.vue";
import Events from "@/views/Events.vue";
import RawData from "@/views/RawData.vue";
import SavingsCalculator from "@/views/SavingsCalculator.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: { name: "SavingsCalculator" },
    meta: {
      requiresAuthentication: false,
    }
  },
  {
    path: "/callback",
    component: Callback,
  },
  {
    path: "/vehicleprofiles",
    name: "VehicleProfiles",
    component: VehicleProfiles,
  },
  {
    path: "/addvehicle",
    name: "AddVehicle",
    component: AddVehicle,
  },
  {
    path: "/UserGroups",
    name: "UserGroups",
    component: UserGroups,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/charts",
    name: "Charts",
    component: Charts,
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
  },
  {
    path: "/rawdata",
    name: "RawData",
    component: RawData,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/savingscalculator",
    name: "SavingsCalculator",
    component: SavingsCalculator,
    meta: {
      requiresAuthentication: false,
    },
  },
  {
    path: "/Unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
