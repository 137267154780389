
























































































import { User } from "@/model/Identity/User";
import Vue from "vue";

import LoadingModal from "@/components/LoadingModal.vue";

import instance, { identityInstance } from "@/axios";

import { identityServerURL } from "@/helpers/Settings";

import { mapGetters, mapActions } from "vuex";
import { UpdateUser } from "@/model/Identity/UpdateUser";

import { UpdateUser as APIUpdateUser } from "@/model/API/UpdateUser";
import { User as APIUser } from "@/model/API/User";
import { Country, Currency, Timezone } from "@/model/Localization";
import { DistanceUnit, FuelUnit, TemperatureUnit } from "@/model/Units";

export default Vue.extend({
  name: "AccountManagement",
  components: { LoadingModal },
  props: {},
  created: async function() {
    if (this.userId) {
      identityInstance.get<User>(`/user/${this.userId}`).then((e) => {
        let user = e.data;
        this.user = user;

        instance.get<APIUser>(`/user/${this.userId}`).then((e) => {
          let user = e.data;
          this.apiUser = user;
        });
      });
    }
  },
  data: () => ({
    user: {} as User,
    apiUser: {} as APIUser,
    isSaving: false,
    loadingMessage: "Saving user information",
    hasSaved: false,
    countries: [
      {
        text: "Canada",
        value: Country.Canada,
      },
      {
        text: "United States",
        value: Country.UnitedStates,
      },
    ],
    currencies: [
      {
        text: "CAD",
        value: Currency.CAD,
      },
      {
        text: "USD",
        value: Currency.USD,
      },
    ],
    timezones: [
      {
        text: "Atlantic",
        value: Timezone.Atlantic,
      },
      {
        text: "Eastern",
        value: Timezone.Eastern,
      },
      {
        text: "Central",
        value: Timezone.Central,
      },
      {
        text: "Mountain",
        value: Timezone.Mountain,
      },
      {
        text: "Pacific",
        value: Timezone.Pacific,
      },
    ],
    temperatureUnits: [
      {
        text: "Celsius",
        value: TemperatureUnit.Celsius,
      },
      {
        text: "Fahrenheit",
        value: TemperatureUnit.Fahrenheit,
      },
    ],
    distanceUnits: [
      {
        text: "Miles",
        value: DistanceUnit.Miles,
      },
      {
        text: "Kilometers",
        value: DistanceUnit.Kilometers,
      },
    ],
    fuelUnits: [
      {
        text: "Litres",
        value: FuelUnit.Litres,
      },
      {
        text: "Gallons",
        value: FuelUnit.Gallons,
      },
    ],
  }),
  methods: {
    ...mapActions(["setAPIUser"]),
    submit() {
      let user: UpdateUser = {
        id: this.userId,
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        role: this.user.role,
        isEnabled: true,
      };
      this.isSaving = true;
      this.hasSaved = false;
      identityInstance
        .put(`/user/${this.userId}`, user)
        .then(() => {
          let apiUser: APIUpdateUser = {
            userId: this.userId,
            email: this.user.email,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            userRole: this.apiUser.userRole,
            clientIds: this.apiUser.clients.map((e) => e.clientId),
            zoneId: this.apiUser.zone?.zoneId ?? null,
            country: this.apiUser.country,
            currency: this.apiUser.currency,
            timezone: this.apiUser.timezone,
            fuelUnit: this.apiUser.fuelUnit,
            distanceUnit: this.apiUser.distanceUnit,
            temperatureUnit: this.apiUser.temperatureUnit,
          };
          instance
            .put(`/user/${this.userId}`, apiUser)
            .then(() => {
              instance.get<APIUser>(`/user/${this.userId}`).then((e) => {
                let apiUser = e.data;
                this.setAPIUser(apiUser);
              });
              this.hasSaved = true;
            })
            .finally(() => (this.isSaving = false));
        })
        .finally(() => (this.isSaving = false));
    },
    changePassword() {
      window.location.href = `${identityServerURL}/Manage/ChangePassword`;
    },
    countryChanged() {
      switch(this.apiUser.country) {
        case Country.Canada:
          this.apiUser.currency = Currency.CAD;
          this.apiUser.temperatureUnit = TemperatureUnit.Celsius;
          this.apiUser.distanceUnit = DistanceUnit.Kilometers;
          this.apiUser.fuelUnit = FuelUnit.Litres;
          break;
        case Country.UnitedStates:
          this.apiUser.currency = Currency.USD;
          this.apiUser.temperatureUnit = TemperatureUnit.Fahrenheit;
          this.apiUser.distanceUnit = DistanceUnit.Miles;
          this.apiUser.fuelUnit = FuelUnit.Gallons;
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["userId", "isSuperAdmin"]),
  },
});
