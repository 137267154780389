export enum InputType {
  number,
  other,
  temperature,
  list,
  output,
  input,
}

export interface listItem {
  display: string;
  value: string | boolean;
}

export interface numberListItem {
  display: string;
  value: number;
}

export enum Rule {
  required,
  maxLength,
  minLength,
}

export interface Rules {
  rule: Rule;
  value?: number;
}

export interface optionParameter {
  type: InputType;
  value: boolean | string | number | null;
  defaultValue: boolean | string | number | null;
  display: string;
  info: string;
}

export enum AvailableOutputs {
  x2107 = "x2107",
  x2108 = "x2108",
  x2006 = "x2006",
  x2008 = "x2008",
}

export enum AvailableInputsPositive {
  x1214 = "x1214",
  x1216 = "x1216",
  x1218 = "x1218",
  x1012 = "x1012",
  x1016 = "x1016",
  x1018 = "x1018",
}

export enum AvailableInputsNegative {
  x1012 = "x1012",
  x1016 = "x1016",
  x1018 = "x1018",
}

export enum SignalType {
  Positive = "positive",
  Negative = "negative",
  Software = "software",
}

export interface inputParameter extends optionParameter {
  type: InputType.input;
  value: string | null;
  signalType: string | null;
  defaultValue: null;
  defaultSignalType: string | null;
}

export interface outputParameter extends optionParameter {
  type: InputType.output;
  value: string | null;
  defaultValue: null;
}

export interface numberParameter extends optionParameter {
  type: InputType.number;
  value: number;
  defaultValue: number;
  rules: string[];
  minValue: number | null;
  maxValue: number | null;
  increment?: number;
}

export interface listNumberParameter extends optionParameter {
  type: InputType.list;
  value: number;
  defaultValue: number;
  list: numberListItem[];
}

export interface listBoolParameter extends optionParameter {
  type: InputType.list;
  value: boolean;
  defaultValue: boolean;
  list: listItem[];
}

export interface listParameter extends optionParameter {
  type: InputType.list;
  value: string;
  defaultValue: string;
  list: listItem[];
}

export interface temperatureParameter extends optionParameter {
  type: InputType.temperature;
  value: number;
  defaultValue: number;
  rules: string[];
  minValue: number | null;
  maxValue: number | null;
}
