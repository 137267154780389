import { InputType, listBoolParameter, listParameter, temperatureParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export enum AirConditioningOption {
  EngineOnly = "EngineOnly",
  ECoolPark = "ECoolPark",
  DCACSystem = "DCACSystem",
}

export interface airConditioningOptions {
  ACEnabled: listBoolParameter;
  ACOption: listParameter;
  dontStart: temperatureParameter;
  ACTemperatureOffset: temperatureParameter;
  ACContinuousOperationOnOff: listBoolParameter;
  ACContinuousOperationSetPoint: temperatureParameter;
  ACClimateControlEnable: listBoolParameter;
}

export const defaultAirConditioningOptions: airConditioningOptions = {
  ACEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Enabled",
    info:
      "This selection chooses if restarting for air conditioning is required and whether the vehicle engine or an electric A/C unit is installed (default is OFF).",
  },
  ACOption: {
    type: InputType.list,
    value: AirConditioningOption.ECoolPark,
    defaultValue: AirConditioningOption.ECoolPark,
    list: [
      {
        display: "Engine Only",
        value: AirConditioningOption.EngineOnly,
      },
      {
        display: "E-Cool Park",
        value: AirConditioningOption.ECoolPark,
      },
      {
        display: "DC A/C System",
        value: AirConditioningOption.DCACSystem,
      },
    ],
    display: "Air Conditioning Option",
    info: `<ul>
    <li>Engine Only - this is selected when you wish to have the vehicle start for air conditioning as required. </li>
    <li>E-Cool Park- This should be selected when the Bergstrom E Cool Park has been installed.</li>
    <li>DC A/C System - this is selected when an additional air conditioning unit is used other than the engine.</li>
  </ul>`,
  },
  dontStart: {
    type: InputType.temperature,
    value: 10,
    defaultValue: 10,
    display: "Don't Start for A/C Set Point",
    info:
      "If the outside temperature drops below this set point the vehicle will not start for air conditioning. This is to ensure that if the vehicle gets too hot in the winter, the air conditioning will not be turned on (default is 10°C/50°F).",
    rules: ["required"],
    minValue: 0,
    maxValue: 10,
  },
  ACTemperatureOffset: {
    type: InputType.temperature,
    value: 5,
    defaultValue: 5,
    display: "Air Conditioning Temperature Offset",
    info: "This setting is the number of degrees above the Temperature Set Point that the air conditioning will be activated (default is 5°C/9°F).",
    rules: ["required"],
    minValue: 0,
    maxValue: 40,
  },
  ACContinuousOperationOnOff: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: [
      {
        display: "On",
        value: true,
      },
      {
        display: "Off",
        value: false,
      },
    ],
    display: "A/C Continuous Operation On/Off",
    info:
      "his setting will allow the vehicle to stay running for air conditioning when the outside temperature is greater than the ‘A/C Continuous Operation Set Point’ (default is ON).",
  },
  ACContinuousOperationSetPoint: {
    type: InputType.temperature,
    value: 35,
    defaultValue: 35,
    display: "A/C Continuous Operation Set Point",
    info:
      "This setting is the outside temperature in which the system will prevent the engine from shutting down for Max Run Time. This should be set to a degree at which the vehicles climate system will struggle to cool the cab (default is 35°C/95°F).",
    rules: ["required"],
    minValue: 25,
    maxValue: 45,
  },
  ACClimateControlEnable: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Climate Control Enable",
    info:
      "This setting enables the operator to have control over the ‘Temperature Set Point’. In some cases, the temperature is best to be fixed such as heating or cooling products (default is ON).",
  },
};
