import { listItem } from "@/model/VehicleProfile/OptionParameter";

export const enabledUnits: listItem[] = [
  {
    display: "Enabled",
    value: true,
  },
  {
    display: "Disabled",
    value: false,
  },
];

export const onOffUnits: listItem[] = [
  {
    display: "On",
    value: true,
  },
  {
    display: "Off",
    value: false,
  },
];
