













































































import Vue from "vue";

import { mapActions, mapGetters } from "vuex";

import instance from "@/axios";

import GeneralInfo from "@/components/AddVehicle/GeneralInfo.vue";
import StandardOptions from "@/components/AddVehicle/StandardOptions.vue";
import OptionalFeatures from "@/components/AddVehicle/OptionalFeatures.vue";
import OptionalEquipment from "@/components/AddVehicle/OptionalEquipment.vue";
import OperatorControls from "@/components/AddVehicle/OperatorControls.vue";
import Equipment from "@/components/AddVehicle/Equipment.vue";
import Kit from "@/components/AddVehicle/Kits.vue";
import InputsOutputs from "@/components/AddVehicle/InputsOutputs.vue";
import { Vehicle } from "@/model/API/Vehicle";

import LoadingModal from "@/components/LoadingModal.vue";
import ImportVehicleDialog from "@/components/AddVehicle/ImportVehicleDialog.vue";
import QuoteDialog from "@/components/AddVehicle/QuoteDialog.vue";
import InfoModal from "@/components/InfoModal.vue";
import DocumentDialog from "@/components/AddVehicle/DocumentDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default Vue.extend({
  name: "AddVehicle",
  created: async function() {
    // If a vehicle has been passed by query param, load the vehicle
    if (this.$route.query.id) {
      this.isLoading = true;
      this.loadingMessage = "Loading Vehicle";
      await instance
        .get<Vehicle>(`/Vehicle/${this.$route.query.id}`)
        .then((result) => {
          this.loadVehicle(result.data).then(() => {
            this.isLoading = false;
          });
        })
        .catch(() => {
          this.$router.push({ name: "VehicleProfiles" });
        });
    } else {
      // Otherwise, check if we are creating or updating a vehicle from the vuex store
      if (!this.isCreatingOrUpdating) {
        this.$router.push({ name: "VehicleProfiles" });
      }
    }
  },
  components: {
    ConfirmDialog,
    GeneralInfo,
    StandardOptions,
    OptionalFeatures,
    OptionalEquipment,
    LoadingModal,
    ImportVehicleDialog,
    OperatorControls,
    Equipment,
    Kit,
    InputsOutputs,
    QuoteDialog,
    DocumentDialog,
    InfoModal,
  },
  data: () => ({
    isConfirmDialogOpen: false,
    confirmTitle: "",
    confirmMessage: "",
    isInfoModalOpen: false,
    infoModalTitle: "",
    infoModalMessage: "",
    isLoading: false,
    isSaving: false,
    isImportingVehicleDialogOpen: false,
    isQuoteDialogOpen: false,
    isDocumentDialogOpen: false,
    vehicles: [],
    tab: 0,
    action: "",
    loadingMessage: "Creating vehicle",
    tabs: [
      {
        title: "General Info",
        routeName: "GeneralInfo",
      },
      {
        title: "Kit",
        routeName: "Kit",
      },
      {
        title: "Optional Equipment",
        routeName: "Equipment",
      },
      {
        title: "Standard Settings",
        routeName: "StandardOption",
      },

      {
        title: "Optional Equipment Settings",
        routeName: "OptionalEquipment",
      },
      {
        title: "Optional Features",
        routeName: "OptionalFeatures",
      },
      {
        title: "Inputs/Outputs",
        routeName: "InputsOutputs",
      },
      {
        title: "Operator Control Settings",
        routeName: "OperatorControls",
      },
    ],
    vehicleSaveError: false,
    vehicleSaveErrorMessage: "",
  }),
  methods: {
    ...mapActions(["saveNewVehicle", "loadVehicle", "setVehicle", "importVehicle"]),
    closeInfoModal() {
      this.isInfoModalOpen = false;
    },
    retrieveSettings() {
      this.loadingMessage = "Retrieving Settings";
      this.isSaving = true;
      instance
        .get(`/Vehicle/retrieveSettings?machineId=${this.machineId}`)
        .then((response) => {
          this.isSaving = false;
          if (response && response.status == 200) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (response.data.success) {
              // get the vehicle again from the api
              instance
                .get<Vehicle>(`/Vehicle/${this.vehicleId}`)
                .then(async (response) => {
                  this.loadVehicle(response.data);
                  this.isInfoModalOpen = true;
                  this.infoModalTitle = "Retrieve Settings";
                  this.infoModalMessage = "Settings retrieved";
                  this.isSaving = false;
                })
                .catch(() => {
                  this.$router.push({ name: "VehicleProfiles" });
                })
                .finally(() => {
                  this.isSaving = false;
                });
            } else {
              this.isSaving = false;
              this.isInfoModalOpen = true;
              this.infoModalTitle = "Retrieve Settings";
              this.infoModalMessage = "No new setting files have been uploaded. Try again later.";
            }
          } else {
            this.isSaving = false;
            this.isInfoModalOpen = true;
            this.infoModalTitle = "Retrieve Settings";
            this.infoModalMessage = "Settings not retrieved. Try again later.";
          }
        })
        .catch(() => {
          this.isSaving = false;
          this.isInfoModalOpen = true;
          this.infoModalTitle = "Retrieve Settings";
          this.infoModalMessage = "Settings not retrieved. Try again later.";
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    retrieveSettingsClicked() {
      this.action = "retrieve";
      this.isConfirmDialogOpen = true;
      this.confirmTitle = "Confirm Retrieve Settings";
      this.confirmMessage = `Please Note: This will override all settings according to the settings on the vehicle`;
    },
    submitSettings() {
      this.action = "submit";
      this.isConfirmDialogOpen = true;
      this.confirmTitle = "Confirm Submit Settings";
      this.confirmMessage = `Please Note:
          <ul>
            <li>Not all settings are editable remotely.</li>
            <li>Changes will take effect when the vehicle comes online and is in a safe state.</li>
          </ul>
            `;
    },
    syncSettings() {
      instance
        .get(`/Vehicle/syncSettings?machineId=${this.machineId}`)
        .then((response) => {
          if (response && response.status == 200) {
            // this.isInfoModalOpen = true;
            // this.infoModalTitle = "Submit Settings";
            // this.infoModalMessage = "Vehicle settings submitted. Note: This can take up to an hour to take effect.";
          } else {
            this.isInfoModalOpen = true;
            this.infoModalTitle = "Submit Settings";
            this.infoModalMessage = "Vehicle settings not submitted. Try again later.";
          }
        })
        .catch(() => {
          this.isInfoModalOpen = true;
          this.infoModalTitle = "Submit Settings";
          this.infoModalMessage = "Vehicle settings not submitted Try again later.";
        });
    },
    sync() {
      instance
        .get<Vehicle>(`/Vehicle/SyncProemion?VIN=${this.vehicleVIN}&IMEI=${this.vehicleIMEI}&VehicleId=${this.vehicleId}`)
        .then((response) => {
          if (response && response.status == 200) {
            this.setVehicle(response.data);
            this.isInfoModalOpen = true;
            this.infoModalTitle = "Sync";
            this.infoModalMessage = "Vehicle connected.";
          } else {
            this.isInfoModalOpen = true;
            this.infoModalTitle = "Sync";
            this.infoModalMessage = "Vehicle not connected. Try again later.";
          }
        })
        .catch(() => {
          this.isInfoModalOpen = true;
          this.infoModalTitle = "Sync";
          this.infoModalMessage = "Vehicle not connected. Try again later.";
        });
    },
    closeConfirm() {
      this.isConfirmDialogOpen = false;
    },
    confirmed() {
      this.isConfirmDialogOpen = false;

      if (this.action == "retrieve") {
        this.retrieveSettings();
      } else if (this.action == "submit") {
        this.syncSettings();
      }

      this.action = "";
    },

    async importVehicleDialogSelected(vehicle: Vehicle) {
      await this.importVehicle(vehicle);
      this.isImportingVehicleDialogOpen = false;
    },
    /**
     * Close the import vehicle dialog.
     */
    closeImportVehicleDialog() {
      this.isImportingVehicleDialogOpen = false;
    },
    async save() {
      /** Save General Info if there are no errors */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      var generalForm: any = this.$refs.GeneralInfoForm;
      let generalInfoComponent = generalForm[0];
      generalInfoComponent.save();
      let generalInfoForm = generalInfoComponent.$v.form;
      generalInfoForm.$touch();
      if (generalInfoForm.$pending || generalInfoForm.$error) {
        this.tab = 0;
        return;
      }

      /** Save Standard Options if there are no errors */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      var standardForm: any = this.$refs.StandardOptionsForm;
      let standardOptionsComponent = standardForm[0];
      standardOptionsComponent.save();
      let standardOptionsForm = standardOptionsComponent.$v.form;
      standardOptionsForm.$touch();
      if (standardOptionsForm.$pending || standardOptionsForm.$error) {
        this.tab = 1;
        return;
      }

      /** Save Optional Features if there are no errors */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      var featuresForm: any = this.$refs.OptionalFeaturesForm;
      let optionalFeaturesComponent = featuresForm[0];
      optionalFeaturesComponent.save();
      let optionalFeaturesForm = optionalFeaturesComponent.$v.form;
      optionalFeaturesForm.$touch();
      if (optionalFeaturesForm.$pending || optionalFeaturesForm.$error) {
        this.tab = 3;
        return;
      }

      /** Save Optional Equipment if there are no errors */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      var equipmenitForm: any = this.$refs.OptionalEquipmentForm;
      let optionalEquipmentComponent = equipmenitForm[0];
      optionalEquipmentComponent.save();
      let optionalEquipmentForm = optionalEquipmentComponent.$v.form;
      optionalEquipmentForm.$touch();
      if (optionalEquipmentForm.$pending || optionalEquipmentForm.$error) {
        this.tab = 2;
        return;
      }

      this.isSaving = true;
      this.vehicleSaveError = false;
      this.vehicleSaveErrorMessage = "";
      this.loadingMessage = "Creating vehicle";
      this.saveNewVehicle()
        .then(() => {
          this.isSaving = false;
          this.$router.push({ name: "VehicleProfiles" });
        })
        .catch((e: string | undefined) => {
          this.isSaving = false;
          this.vehicleSaveErrorMessage = e ?? "An error occurred while saving the vehicle.";
          this.vehicleSaveError = true;
        });
    },
    getQuote() {
      this.isQuoteDialogOpen = true;
    },
    getDocuments() {
      this.isDocumentDialogOpen = true;
    },
  },
  computed: {
    ...mapGetters([
      "isCreatingNewVehicle",
      "vehicleVIN",
      "assetName",
      "vehicleId",
      "machineId",
      "isCreatingOrUpdating",
      "isKitSelected",
      "vehicleIMEI",
      "vehicles",
    ]),
  },
});
