import { InputType, listBoolParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface IgnitionBypassOptions {
  ignitionBypassEnabled: listBoolParameter;
}

export const defaultIgnitionBypassOptions: IgnitionBypassOptions = {
  ignitionBypassEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Ignition Bypass",
    info:
      "This feature, if enabled and the ignition bypass module installed, will ensure that the EPEQ® IM controller only connects to the ignition when a change of run state is required. This is a safety measure to prevent operation failures",
  },
};
