














































import { Vehicle } from "@/model/API/Vehicle";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ImportVehicleDialog",
  props: {
    availableVehicles: {
      type: Array as PropType<Array<Vehicle>>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    selected: [] as Vehicle[],
    search: "" as string,
    headers: [
      { text: "Asset Name", value: "assetName" },
      { text: "Year", value: "year" },
      { text: "Make", value: "make" },
      { text: "Model", value: "model" },
    ],
  }),
  methods: {
    close(): void {
      if (this.isVehicleSelected) {
        this.$emit("import", this.selectedVehicle);
      } else {
        this.$emit("close");
      }
    },
  },
  computed: {
    selectedVehicle(): Vehicle {
      return this.selected[0];
    },
    isVehicleSelected(): boolean {
      return this.selected.length > 0;
    },
  },
});
