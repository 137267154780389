import { InputType, listBoolParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface OverrideShutdownOptions {
  overrideShutdown: listBoolParameter;
}

export const defaultOverrideShutdownOptions: OverrideShutdownOptions = {
  overrideShutdown: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "OEM Shutdown Override",
    info:
      "This feature if enabled, in the case of an OEM shutdown while the vehicle is idling, will keep the vehicle in EPEQ® IM monitoring mode.",
  },
};
