import { Part } from "@/model/API/Part";

export interface EquipmentSelected {
  ignitionBypass: Part | null;
  antiTheft: Part | null;
  alarm: Part | null;
  solenoid: Part | null;
  battery: Part | null;
  batteryCount: number;
  heat: Part | null;
  airConditioning: Part | null;
  currentSensor: Part | null;
  autoHVAC: Part | null;
  humidity: Part | null;
  tempSensing: Part | null;
  rearFacilityControl: Part | null;
  useMyOwnBattery: boolean;
  useMyOwnHeating: boolean;
  useMyOwnAlarm: boolean;
  useMyOwnSolenoid: boolean;
}

const defaultEquip: EquipmentSelected = {
  ignitionBypass: null,
  antiTheft: null,
  solenoid: null,
  alarm: null,
  battery: null,
  batteryCount: 0,
  heat: null,
  airConditioning: null,
  currentSensor: null,
  autoHVAC: null,
  humidity: null,
  tempSensing: null,
  rearFacilityControl: null,
  useMyOwnBattery: false,
  useMyOwnHeating: false,
  useMyOwnAlarm: false,
  useMyOwnSolenoid: false,
};

export function defaultEquipmentSelected(): EquipmentSelected {
  return JSON.parse(JSON.stringify(defaultEquip));
}
