































import Vue from "vue";

export default Vue.extend({
  name: "InfoModal",
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    drawer: null,
    items: [],
  }),
  methods: {
    close() {
      this.$emit("close");
    },
  },
});
