/* eslint-disable @typescript-eslint/no-explicit-any */

import { v4 as uuid } from "uuid";

import { DistanceUnit, FuelUnit } from "@/model/Units";
import { calculateEmissions, calculateEmissionsGallon, calculateMaintenanceCost, parseGVWRClass } from "./vehicleProfiles";
import { Currency } from "@/model/Localization";

interface ChartInfo {
  title: string; // The title of the chart
  description: string; // The description that will appear above the chart
  chartType: ChartType; // The type of chart. Correspondes to type of charts supported by chartJS. Use the ChartType enum to set this value
  labels: string[]; // This is the labels that will be displayed in the legend of the chart. These will usually include the % for pie charts
  dataset: number[];
  data: number[]; // This is the numebr that will be displayed in the tooltip for the segment. Typically is the same as the dataset number but will be different if the dataset is in percentage as the tooltip should display actual value not percentages
  segmentLabels: string[]; // This is the segments that exist in the chart. This will usually be the same as the labels but without the % included.
  segmentDescriptions: string[]; // Longer description of each label. Must be in the same order as the labels. Will be displayed on hover and in info dialogs
  additionalChartInfo?: string; // Additional info that will be displayed at the bottom of the info dialog for the chart
  columns?: number; // How many columns the chart should take up in the row. Defaults to 6. Some charts should take the entire width and be set to 12
  innerColumns?: number; // How many columns the chart should take up inside the outer column. Defaults to 12. The chart will be centered inside the outer column
  colors: string[]; // List of colors for each segment. Must be the same length as the dataset
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (context: any) => string[]; // Callback to display the label on hover of the chart.
  hasData: boolean;
  quoteDataSummary: string[]; // Used when sending the chart information in the quote request email
}

enum ChartType {
  pie = "pie",
  bar = "bar",
}

const COLORS = {
  blue: "rgb(65,105,255)",
  red: "rgb(220,20,60)",
  green: "rgb(34,139,34)",
  yellow: "rgb(227,214,30)",
  orange: "rgb(244, 149, 66)",
  purple: "rgb(128, 0, 128)",
  lightBlue: "rgb(88, 112, 115)",
  otherBlue: "rgb(30, 144, 255)",
  lightGreen: "rgb(72, 119, 72)",
  darkGreen: "rgb(107, 142, 35)",
  lighterGreen: "rgb(0, 241, 154)",
  lightOrange: "rgb(244, 164, 96)",
  brown: "rgb(205, 133, 63)",
  otherBrown: "rgb(210, 105, 30)",
  darkRed: "rgb(188, 0, 28)",
  darkBlue: "rgb(33, 73, 95)",
  darkerGreen: "rgb(2, 107, 2)",
};

function getChartObject(chartInfo: ChartInfo) {
  return {
    meta: {
      title: chartInfo.title,
      description: chartInfo.description,
      columns: chartInfo.columns ?? 6,
      innerColumns: chartInfo.innerColumns ?? 12,
      segmentLabels: chartInfo.segmentLabels,
      segmentDescriptions: chartInfo.segmentDescriptions,
      additionalInfo: chartInfo.additionalChartInfo,
      quoteDataSummary: chartInfo.quoteDataSummary,
    },
    data: {
      id: `chart_${uuid()}`,
      type: chartInfo.chartType as string,
      hasData: true,
      data: {
        labels: chartInfo.labels,
        datasets: [
          {
            data: chartInfo.dataset,
            backgroundColor: chartInfo.colors,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            position: "right",
            display: chartInfo.chartType != ChartType.bar,
          },
          tooltip: {
            callbacks: {
              label: function(context: any) {
                return chartInfo?.callback(context);
              },
              title: function() {
                return "";
              },
            },
          },
        },
      },
    },
  };
}

function formatNumberWithCommas(value: number | undefined): string {
  if (value != undefined) {
    return roundTo2Decimal(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
}

function roundTo2Decimal(value: number): number {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function operationOverviewChart(
  enteredParkAndNeutralPercent: number,
  enteredIdlingInDrivePercent: number,
  runHours: number,
  titleAppend: string | null = null
): any {
  const parkAndNeutral = (enteredParkAndNeutralPercent / 100) * runHours;
  const idlingInDrive = (enteredIdlingInDrivePercent / 100) * runHours;
  const drivingHours = runHours - (parkAndNeutral + idlingInDrive);

  let total = parkAndNeutral + idlingInDrive + drivingHours;
  let hasData = true;
  if (total == 0) {
    total = 1;
    hasData = false;
  }

  const parkAndNeutralPercent = Math.round((parkAndNeutral / total) * 1000) / 10;
  const idlingInDrivePercent = Math.round((idlingInDrive / total) * 1000) / 10;
  const drivingHoursPercent = Math.round((drivingHours / total) * 1000) / 10;

  const chartInfo: ChartInfo = {
    title: `How much time does my vehicle idle? (Hours) ${titleAppend != null ? titleAppend : ""}`,
    description: "Compares what state the vehicle is in as a percentage of total operation.",
    chartType: ChartType.pie,
    labels: [`Park and Neutral: ${parkAndNeutralPercent}%`, `Idling in Drive: ${idlingInDrivePercent}%`, `Driving: ${drivingHoursPercent}%`],
    segmentLabels: [`Park and Neutral`, `Idling in Drive`, `Driving`],
    segmentDescriptions: [
      "Total hours that the vehicle has been running in park or neutral.",
      "Total hours that the vehicle has been running while not in park or neutral and moving less than 1 km/h",
      "Total hours that the vehicle has been running while not in park or neutral and moving faster than 1 km/h",
    ],
    data: [parkAndNeutral, idlingInDrive, drivingHours],
    dataset: [parkAndNeutral, idlingInDrive, drivingHours],
    colors: [COLORS.darkRed, COLORS.otherBlue, COLORS.darkBlue],
    callback: (context: any) => {
      const firstLabel = `${chartInfo.segmentDescriptions[context.dataIndex]}`;
      const secondLabel = `${formatNumberWithCommas(chartInfo.data[context.dataIndex])} Hours`;
      return [firstLabel, secondLabel];
    },
    hasData: hasData,
    quoteDataSummary: [
      `Park and Neutral: ${parkAndNeutralPercent}% (${formatNumberWithCommas(parkAndNeutral)} Hours)`,
      `Idling in Drive: ${idlingInDrivePercent}% (${formatNumberWithCommas(idlingInDrive)} Hours)`,
      `Driving: ${drivingHoursPercent}% (${formatNumberWithCommas(drivingHours)} Hours)`,
    ],
  };

  return getChartObject(chartInfo);
}

export function idleReducedChart(
  enteredParkAndNeutralPercent: number,
  enteredMonitorPercent: number,
  runHours: number,
  titleAppend: string | null = null,
  outerColumns: number | null = null,
  innerColumns: number | null = null
): any {
  const monitorHours = (enteredMonitorPercent / 100) * runHours;
  const parkAndNeutral = (enteredParkAndNeutralPercent / 100) * runHours;

  let total = monitorHours + parkAndNeutral;

  let hasData = true;
  if (total == 0) {
    total = 1;
    hasData = false;
  }

  const monitorPercent = Math.round((monitorHours / total) * 100);
  const parkAndNeutralPercent = 100 - monitorPercent;
  const chartInfo: ChartInfo = {
    title: `How much idling have I reduced when the vehicle is in park? (%) ${titleAppend != null ? titleAppend : ""}`,
    description: "Displays the actual percentage of idling and the percentage of idling saved using EPEQ® IM.",
    chartType: ChartType.pie,
    labels: [`Your Savings: ${monitorPercent}%`, `Actual Idling: ${parkAndNeutralPercent}%`],
    segmentLabels: ["Your Savings", "Actual Idling"],
    segmentDescriptions: ["Amount of idle saved using the EPEQ® IM system.", "Actual Idling:	Actual amount of idling using the EPEQ® IM system."],
    data: [monitorHours, parkAndNeutral],
    dataset: [monitorPercent, parkAndNeutralPercent],
    colors: [COLORS.green, COLORS.red],
    callback: (context) => {
      const firstLabel = `${chartInfo.segmentDescriptions[context.dataIndex]}`;
      const secondLabel = `${formatNumberWithCommas(chartInfo.dataset[context.dataIndex])}%`;
      return [firstLabel, secondLabel];
    },
    hasData,
    quoteDataSummary: [
      `Your Savings: ${formatNumberWithCommas(monitorPercent)}%`,
      `Actual Idling: ${formatNumberWithCommas(parkAndNeutralPercent)}%`,
    ],
    columns: outerColumns ?? undefined,
    innerColumns: innerColumns ?? undefined,
  };

  return getChartObject(chartInfo);
}

export function co2Chart(
  enteredMonitorPercent: number,
  fuelUnit: FuelUnit,
  fuelConsumption: number,
  fuelType: string,
  runHours: number,
  titleAppend: string | null = null
): any {
  const savedHours = (enteredMonitorPercent / 100) * runHours;
  const actualHours = runHours - savedHours;

  let emissionRate = 0;

  switch (fuelUnit) {
    case FuelUnit.Litres:
      emissionRate = calculateEmissions(fuelType);
      break;
    case FuelUnit.Gallons:
      emissionRate = calculateEmissionsGallon(fuelType);
      break;
  }
  const vehicleSavings = savedHours * fuelConsumption * emissionRate;
  const vehicleConsumed = actualHours * fuelConsumption * emissionRate;

  const savings = roundTo2Decimal(vehicleSavings);
  const consumed = roundTo2Decimal(vehicleConsumed);
  const totalWithoutGrip = roundTo2Decimal(vehicleSavings + vehicleConsumed);

  const units = fuelUnit == FuelUnit.Gallons ? "Pounds" : "Kilograms";

  const chartInfo: ChartInfo = {
    title: `How much CO₂ have I reduced? (${units}) ${titleAppend != null ? titleAppend : ""}`,
    description: "Displays the actual amount of CO₂ emmissions, amount saved using EPEQ® IM, and total without EPEQ® IM installed.",
    chartType: ChartType.bar,
    labels: ["Your Savings", "Actual CO₂ Emissions", "Total without EPEQ® IM"],
    data: [savings, consumed, totalWithoutGrip],
    dataset: [savings, consumed, totalWithoutGrip],
    segmentDescriptions: [
      "Amount of CO₂ saved at idle using the EPEQ® IM system.",
      "Actual amount of CO₂ emissions at idle with the EPEQ® IM system.",
      "Amount of CO₂ emissions that would have occurred at idle without the EPEQ® IM.",
    ],
    additionalChartInfo: `CO₂ Multipliers used from:
            <a href="https://www.epa.gov/sites/production/files/2015-07/documents/emission-factors_2014.pdf" target="_blank">
              https://www.epa.gov/sites/production/files/2015-07/documents/emission-factors_2014.pdf
            </a>`,
    segmentLabels: ["Your Savings", "Actual CO₂ Emissions", "Total without EPEQ® IM"],
    colors: [COLORS.green, COLORS.red, COLORS.blue],
    callback: (context) => {
      const firstLabel = `${chartInfo.segmentDescriptions[context.dataIndex]}`;
      const secondLabel = `${formatNumberWithCommas(chartInfo.data[context.dataIndex])} ${units}`;
      return [firstLabel, secondLabel];
    },
    hasData: savings != 0 || consumed != 0 || totalWithoutGrip != 0,
    quoteDataSummary: [
      `Your Savings: ${formatNumberWithCommas(savings)} ${units}`,
      `Actual CO₂ Emissions: ${formatNumberWithCommas(consumed)} ${units}`,
      `Total without EPEQ® IM: ${formatNumberWithCommas(totalWithoutGrip)} ${units}`,
    ],
  };

  return getChartObject(chartInfo);
}

export function fuelSavingsDollarsChart(
  enteredMonitorPercent: number,
  fuelConsumption: number,
  fuelCost: number,
  runHours: number,
  titleAppend: string | null = null
): any {
  const savedHours = (enteredMonitorPercent / 100) * runHours;
  const actualHours = runHours - savedHours;

  const vehiclesavings = savedHours * fuelConsumption * fuelCost;
  const vehicleconsumed = actualHours * fuelConsumption * fuelCost;

  const savings = roundTo2Decimal(vehiclesavings);
  const consumed = roundTo2Decimal(vehicleconsumed);
  const totalWithoutGrip = roundTo2Decimal(vehiclesavings + vehicleconsumed);

  const chartInfo: ChartInfo = {
    title: `What was my fuel savings? (Dollars) ${titleAppend != null ? titleAppend : ""}`,
    description: "Displays the actual fuel cost, amount saved using EPEQ® IM, and total cost without EPEQ® IM installed.",
    chartType: ChartType.bar,
    labels: ["Your Savings", "Amount Spent", "Total without EPEQ® IM"],
    data: [savings, consumed, totalWithoutGrip],
    dataset: [savings, consumed, totalWithoutGrip],
    segmentLabels: ["Your Savings", "Amount Spent", "Total without EPEQ® IM"],
    segmentDescriptions: [
      "Amount of fuel cost saved at idle using the EPEQ® IM system.",
      "Actual fuel cost at idle using the EPEQ® IM system.",
      "Amount that would have been spent on fuel at idle without the EPEQ® IM installed.",
    ],
    colors: [COLORS.green, COLORS.red, COLORS.blue],
    callback: (context) => {
      const firstLabel = `${chartInfo.segmentDescriptions[context.dataIndex]}`;
      const secondLabel = `Dollars ($): ${formatNumberWithCommas(chartInfo.data[context.dataIndex])}`;
      return [firstLabel, secondLabel];
    },
    hasData: savings != 0 || consumed != 0 || totalWithoutGrip != 0,
    quoteDataSummary: [
      `Your Savings: $${formatNumberWithCommas(savings)}`,
      `Amount Spent: $${formatNumberWithCommas(consumed)}`,
      `Total without EPEQ® IM: $${formatNumberWithCommas(totalWithoutGrip)}`,
    ],
  };

  return getChartObject(chartInfo);
}

export function fuelConsumptionChart(
  enteredMonitorPercent: number,
  fuelUnitDisplay: string,
  fuelConsumption: number,
  runHours: number,
  titleAppend: string | null = null
): any {
  const savedHours = (enteredMonitorPercent / 100) * runHours;
  const actualHours = runHours - savedHours;

  const vehiclesavings = savedHours * fuelConsumption;
  const vehicleconsumed = actualHours * fuelConsumption;

  const savings = roundTo2Decimal(vehiclesavings);
  const consumed = roundTo2Decimal(vehicleconsumed);
  const totalWithoutGrip = roundTo2Decimal(vehiclesavings + vehicleconsumed);

  const chartInfo: ChartInfo = {
    title: `What was my fuel savings by volume? (${fuelUnitDisplay}) ${titleAppend != null ? titleAppend : ""}`,
    description: "Displays the actual amount of fuel consumed, amount saved using EPEQ® IM, and total without EPEQ® IM installed.",
    chartType: ChartType.bar,
    labels: ["Your Savings", "Amount Consumed", "Total without EPEQ® IM"],
    data: [savings, consumed, totalWithoutGrip],
    dataset: [savings, consumed, totalWithoutGrip],
    segmentLabels: ["Your Savings", "Amount Consumed", "Total without EPEQ® IM"],
    segmentDescriptions: [
      "Amount of fuel saved at idle using the EPEQ® IM system.",
      "Actual amount of fuel consumed at idle with the EPEQ® IM system.",
      "Amount of fuel that would have been consumed at idle without the EPEQ® IM installed.",
    ],
    colors: [COLORS.green, COLORS.red, COLORS.blue],
    callback: (context) => {
      const firstLabel = `${chartInfo.segmentDescriptions[context.dataIndex]}`;
      const secondLabel = `${fuelUnitDisplay}: ${formatNumberWithCommas(chartInfo.data[context.dataIndex])}`;
      return [firstLabel, secondLabel];
    },
    hasData: savings != 0 || consumed != 0 || totalWithoutGrip != 0,
    quoteDataSummary: [
      `Your Savings: ${formatNumberWithCommas(savings)} ${fuelUnitDisplay}`,
      `Amount Consumed: ${formatNumberWithCommas(consumed)} ${fuelUnitDisplay}`,
      `Total without EPEQ® IM: ${formatNumberWithCommas(totalWithoutGrip)} ${fuelUnitDisplay}`,
    ],
  };

  return getChartObject(chartInfo);
}

export function engineWearChart(
  enteredMonitorPercent: number,
  distanceUnits: DistanceUnit,
  distanceUnitDisplay: string,
  runHours: number,
  titleAppend: string | null = null
): any {
  const savedHours = (enteredMonitorPercent / 100) * runHours;
  const actualHours = runHours - savedHours;

  const multiplier = distanceUnits == DistanceUnit.Kilometers ? 53.1 : 33; // Wear is 53.1 kilometers per hour or 33 miles per hour
  const savedWear = savedHours * multiplier;
  const actualWear = actualHours * multiplier;
  const totalKM = (savedHours + actualHours) * multiplier;

  const savings = roundTo2Decimal(savedWear);
  const actual = roundTo2Decimal(actualWear);
  const total = roundTo2Decimal(totalKM);

  // How much engine wear have I removed?
  const chartInfo: ChartInfo = {
    title: `How much engine wear have I removed? (${distanceUnitDisplay}) ${titleAppend != null ? titleAppend : ""}`,
    description: "Displays the actual amount of engine wear, amount saved using EPEQ® IM, and total without EPEQ® IM installed.",
    chartType: ChartType.bar,
    labels: ["Your Savings", "Actual Engine Wear", "Total without EPEQ® IM"],
    segmentLabels: ["Your Savings", "Actual Engine Wear", "Total without EPEQ® IM"],
    segmentDescriptions: [
      "Amount of engine wear saved at idle using the EPEQ® IM system.",
      "Amount of engine wear at idle using the EPEQ® IM system.",
      "Amount of engine wear that would have occurred at idle without the EPEQ® IM installed.",
    ],
    additionalChartInfo: "Calculated based on engine wear of 33 miles or 53.1 kilometers per hour of idling.",
    data: [savings, actual, total],
    dataset: [savings, actual, total],
    colors: [COLORS.green, COLORS.red, COLORS.blue],
    callback: (context) => {
      const firstLabel = `${chartInfo.segmentDescriptions[context.dataIndex]}`;
      const secondLabel = `${distanceUnitDisplay}: ${formatNumberWithCommas(chartInfo.data[context.dataIndex])}`;
      return [firstLabel, secondLabel];
    },
    hasData: savings != 0 || actual != 0 || total != 0,
    quoteDataSummary: [
      `Your Savings: ${formatNumberWithCommas(savings)} ${distanceUnitDisplay}`,
      `Actual Engine Wear: ${formatNumberWithCommas(actual)} ${distanceUnitDisplay}`,
      `Total without EPEQ® IM: ${formatNumberWithCommas(total)} ${distanceUnitDisplay}`,
    ],
  };

  return getChartObject(chartInfo);
}

export function maintenanceCostChart(
  enteredMonitorPercent: number,
  distanceUnits: DistanceUnit,
  currency: Currency,
  gvwrClass: string,
  runHours: number,
  titleAppend: string | null = null
): any {
  const savedHours = (enteredMonitorPercent / 100) * runHours;
  const actualHours = runHours - savedHours;

  const wearDistanceMultiplier = distanceUnits == DistanceUnit.Kilometers ? 53.1 : 33; // Wear is 53.1 kilometers per hour or 33 miles per hour
  const maintenanceCost = calculateMaintenanceCost(parseGVWRClass(gvwrClass), currency);

  const vehicleSavings = savedHours * wearDistanceMultiplier * maintenanceCost;
  const vehicleActual = actualHours * wearDistanceMultiplier * maintenanceCost;

  const vehicleTotal = vehicleSavings + vehicleActual;

  const savings = roundTo2Decimal(vehicleSavings);
  const actual = roundTo2Decimal(vehicleActual);
  const total = roundTo2Decimal(vehicleTotal);

  // How much engine wear have I removed?
  const chartInfo: ChartInfo = {
    title: `Maintenance Costs (Dollars) ${titleAppend != null ? titleAppend : ""}`,
    description: "Displays the actual amount of engine wear, amount saved using EPEQ® IM, and total without EPEQ® IM installed.",
    chartType: ChartType.bar,
    labels: ["Your Savings", "Actual Engine Wear", "Total without EPEQ® IM"],
    segmentLabels: ["Your Savings", "Actual Engine Wear", "Total without EPEQ® IM"],
    segmentDescriptions: [
      "Amount of engine wear saved at idle using the EPEQ® IM system.",
      "Amount of engine wear at idle using the EPEQ® IM system.",
      "Amount of engine wear that would have occurred at idle without the EPEQ® IM installed.",
    ],
    additionalChartInfo: "Calculated based on engine wear of 33 miles or 53.1 kilometers per hour of idling.",
    data: [savings, actual, total],
    dataset: [savings, actual, total],
    colors: [COLORS.green, COLORS.red, COLORS.blue],
    callback: (context) => {
      const firstLabel = `${chartInfo.segmentDescriptions[context.dataIndex]}`;
      const secondLabel = `$: ${formatNumberWithCommas(chartInfo.data[context.dataIndex])}`;
      return [firstLabel, secondLabel];
    },
    hasData: savings != 0 || actual != 0 || total != 0,
    quoteDataSummary: [
      `Your Savings: $${formatNumberWithCommas(savings)}`,
      `Actual Engine Wear: $${formatNumberWithCommas(actual)}`,
      `Total without EPEQ® IM: $${formatNumberWithCommas(total)}`,
    ],
  };

  return getChartObject(chartInfo);
}
