import { InputType, listBoolParameter, temperatureParameter, listNumberParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface RearFacilityOptions {
  rearFacilityEnabled: listBoolParameter;
  rearFacilityTemperatureSetPoint: temperatureParameter;
  rearFacilityAirConditioningTempOffset: temperatureParameter;
  rearFacilityHeatingTempOffset: temperatureParameter;
  rearFacilityEngineRunInitial: listBoolParameter;
  rearFacilityEngineAssistRearAir: listBoolParameter;
  rearFacilityEngineAssistCabAir: listBoolParameter;
  rearFacilityEngineAssistAirOffset: temperatureParameter;
  rearFacilityEngineAssistAirTimer: listNumberParameter;
}

export const defaultRearFacilityOptions: RearFacilityOptions = {
  rearFacilityEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Rear Facility Control",
    info: "This is used to configure and trouble shoot the Rear Facility Application.",
  },
  rearFacilityTemperatureSetPoint: {
    type: InputType.temperature,
    value: 18,
    defaultValue: 18,
    display: "Temperature Set Point",
    info: "This is used to set the Rear Facility desired temperature (default is 18°C/64°F)",
    rules: ["required"],
    minValue: 5,
    maxValue: 35,
  },
  rearFacilityAirConditioningTempOffset: {
    type: InputType.temperature,
    value: 5,
    defaultValue: 5,
    display: "Air Conditioning Temp Offset",
    info:
      "This setting is the number of degrees above the Temperature Set Point that the rear air conditioning will be activated (default is 5°C/9°F).",
    rules: ["required"],
    minValue: 2,
    maxValue: 40,
  },
  rearFacilityHeatingTempOffset: {
    type: InputType.temperature,
    value: 5,
    defaultValue: 5,
    display: "Heating Temp Offset",
    info: "This setting is the number of degrees below the Temperature Set Point that the rear heat will be activated (default is 5°C/9°F).",
    rules: ["required"],
    minValue: 2,
    maxValue: 40,
  },
  rearFacilityEngineRunInitial: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Engine Run for Initial Air Conditioning",
    info:
      "This feature if enabled will keep the engine running upon initial start, until the coolant has reached the ‘Engine Coolant Temp on Initial Set Point’. This is to ensure that the vehicle will be able to adequately heat the cab. When the vehicle is running for this option, the coolant temperature icon will be displayed on the screen (default disabled).",
  },
  rearFacilityEngineAssistRearAir: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Engine Assist Rear Air Conditioning Enable",
    info: "This allows the vehicles engine to start and run to assist in the cooling of the rear facility.",
  },
  rearFacilityEngineAssistCabAir: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Engine Assist Cab Air Conditioning Enable",
    info: "This allows the vehicles engine to start and run to assist in the cooling of the cab.",
  },
  rearFacilityEngineAssistAirOffset: {
    type: InputType.temperature,
    value: 10,
    defaultValue: 10,
    display: "Engine Assist Air Conditioning Offsets",
    info:
      "This is the difference in temperature between either the cab or the rear facility that will allow the Engine Assist Air Conditioning Timer’ to start counting.",
    rules: ["required"],
    minValue: 1,
    maxValue: 20,
  },
  rearFacilityEngineAssistAirTimer: {
    type: InputType.list,
    value: 4,
    defaultValue: 4,
    display: "Engine Assist Air Conditioning Timer",
    info:
      "This is the time that they the EPEQ® IM system will wait to start the engine to assist in the cooling of either the cab or the rear facility after the ‘Engine Assist Air Conditioning Offset’ has been met.",
    list: [
      {
        display: "Off",
        value: 1,
      },
      {
        display: "5 minutes",
        value: 2,
      },
      {
        display: "10 minutes",
        value: 3,
      },
      {
        display: "15 minutes",
        value: 4,
      },
      {
        display: "20 minutes",
        value: 5,
      },
      {
        display: "25 minutes",
        value: 6,
      },
      {
        display: "30 minutes",
        value: 7,
      },
      {
        display: "35 minutes",
        value: 8,
      },
      {
        display: "40 minutes",
        value: 9,
      },
      {
        display: "45 minutes",
        value: 10,
      },
      {
        display: "50 minutes",
        value: 11,
      },
      {
        display: "55 minutes",
        value: 12,
      },
      {
        display: "60 minutes",
        value: 13,
      },
    ],
  },
};
