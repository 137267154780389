



























































































































































































































































































































import Vue from "vue";

import { mapGetters, mapActions } from "vuex";
import EquipmentOption from "@/components/AddVehicle/EquipmentOption.vue";
import { Part } from "@/model/API/Part";

export default Vue.extend({
  name: "OptionalFeatures",
  components: { EquipmentOption },
  data: () => ({}),
  methods: {
    ...mapActions(["setEquipmentOptionSelected", "updateKitEquipment"]),
    useMyOwnBatteryClicked(value: boolean) {
      let selectedEquipment = this.vehicleEquipment.selected;
      selectedEquipment.useMyOwnBattery = value;
      selectedEquipment.battery = null;
      this.setEquipmentOptionSelected(selectedEquipment);
      this.save();
    },
    useMyOwnHeatingClicked(value: boolean) {
      let selectedEquipment = this.vehicleEquipment.selected;
      selectedEquipment.useMyOwnHeating = value;
      selectedEquipment.heating = null;
      this.setEquipmentOptionSelected(selectedEquipment);
      this.save();
    },
    useMyOwnAlarmClicked(value: boolean) {
      let selectedEquipment = this.vehicleEquipment.selected;
      selectedEquipment.useMyOwnAlarm = value;
      this.setEquipmentOptionSelected(selectedEquipment);
      selectedEquipment.alarm = null;
      this.save();
    },
    useMyOwnSolenoidClicked(value: boolean) {
      let selectedEquipment = this.vehicleEquipment.selected;
      selectedEquipment.useMyOwnSolenoid = value;
      selectedEquipment.solenoid = null;
      this.setEquipmentOptionSelected(selectedEquipment);
      this.save();
    },
    /**
     * Adds a new part to the list of equipment that the user has selected
     * This will override the previous part for the group as the user is only allowed one part per grouping
     * Certain groups (Solenoid and heating) have multiple groups inside the group but they still need to be 1 part per so they are grouped together
     * under one key for the selected options
     */
    addPart(part: Part, key: string, quantity: number) {
      let equipment = this.vehicleEquipment;
      let selectedEquipment = equipment.selected;

      if (key == "solenoid85" || key == "solenoid200") {
        selectedEquipment.solenoid = part;
      } else if (key == "heatCoolantPump" || key == "heatCoolantHeater" || key == "heatAirHeater") {
        selectedEquipment.heat = part;
      } else {
        selectedEquipment[key] = part;
      }

      if (key == "battery") {
        selectedEquipment.useMyOwnBattery = false;
        selectedEquipment.batteryCount = quantity;
      } else if (key == "heatCoolantPump" || key == "heatCoolantHeater" || key == "heatAirHeater") {
        selectedEquipment.useMyOwnHeating = false;
      } else if (key == "alarm") {
        selectedEquipment.useMyOwnAlarm = false;
      } else if (key == "solenoid85" || key == "solenoid200") {
        selectedEquipment.useMyOwnSolenoid = false;
      }

      this.setEquipmentOptionSelected(selectedEquipment);
      this.save();
    },
    /**
     * Remove the selected part from the list of equipment selected
     */
    removePart(key: string) {
      let equipment = this.vehicleEquipment;

      if (key == "solenoid85" || key == "solenoid200") {
        equipment.selected.solenoid = null;
      } else if (key == "heatCoolantPump" || key == "heatCoolantHeater" || key == "heatAirHeater") {
        equipment.selected.heat = null;
      } else {
        equipment.selected[key] = null;
      }

      if (key == "battery") {
        equipment.selected.batteryCount = 1;
      }

      this.setEquipmentOptionSelected(equipment.selected);
      this.save();
    },
    /**
     * If the user is updating a vehicle, submit the request to update the information.
     * When creating a new vehicle, the equipment is submitted as part of one request at the end
     */
    save() {
      if (!this.isCreatingNewVehicle) {
        this.updateKitEquipment();
      }
    },
  },
  computed: {
    ...mapGetters(["vehicleEquipment", "isCreatingNewVehicle", "isKitSelected"]),
  },
});
