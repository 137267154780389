


































































































































































































































import Vue from "vue";

// Validation Helpers
import { required, maxLength, minLength, minValue, maxValue, decimal } from "vuelidate/lib/validators";

// Components
import InfoModal from "@/components/InfoModal.vue";

// Plugin Helpers
import instance from "@/axios";
import { mapActions, mapGetters } from "vuex";

// Models
import { VehicleIdentificationNumberData } from "@/model/API/VIN";
import { GeneralInfo } from "@/model/VehicleProfile/GeneralInfo";
import { Zone } from "@/model/API/Zone";
import { Client } from "@/model/API/Client";

// Helper Functions
import { calculateFuelConsumption, getLabel } from "@/helpers/vehicleProfiles";
import { convertFuelFromLitres } from "@/helpers/UnitService";
import { VehicleTransmissionType } from "@/model/API/TransmissionType";

export default Vue.extend({
  name: "GeneralInfo",
  components: { InfoModal },
  destroyed: function() {
    this.save();
  },
  created: async function() {
    const responseCompany = await instance.get<Client[]>(`/Client`);

    this.getZones();
    this.companies = responseCompany.data;
    if (this.companies && this.companies.length == 1) {
      this.form.vehicleClientId = this.companies[0].clientId;
    }

    if (!this.form.frequency) this.form.frequency = "daily";
  },
  /**
   * Define validation rules for the form
   */
  validations: {
    form: {
      assetName: { required },
      year: {
        minValue: minValue(1950),
        maxValue: maxValue(9999),
        decimal,
      },
      engineDisplacement: { decimal },
      fuelConsumption: { decimal },
      fuelCost: { decimal, required },
      // vehicleClientId: { required },
    },
  },
  data: () => ({
    vinNetworkErrors: [] as string[],
    isVINDecoding: false,
    isFuelConsumptionReadOnly: false,
    isInfoModalOpen: false,
    infoModalTitle: "",
    infoModalMessage: "",
    zones: [] as Zone[],
    companies: [] as Client[],
    transmissionTypes: [
      {
        value: VehicleTransmissionType.Manual,
        text: "Manual",
      },
      {
        value: VehicleTransmissionType.Automatic,
        text: "Automatic",
      },
    ],
    frequencies: [
      {
        value: "daily",
        text: "Daily",
      },
      {
        value: "weekly",
        text: "Weekly",
      },
      {
        value: "monthly",
        text: "Monthly",
      },
    ],
    vehicleSaveError: false,
    vehicleSaveErrorMessage: "",
  }),
  methods: {
    ...mapActions(["setGeneralInfo", "updateVehicle", "setEquipmentOptions"]),
    save() {
      this.setGeneralInfo(this.form);
    },
    /**
     * Called everytime an input value is changed.
     * If it's an existing vehicle submit the updated settings.
     * If the vehicle already exists, don't submit the updates and wait for the user to submit the entire settings file
     */
    updated() {
      if (!this.isCreatingNewVehicle) {
        this.$v.form.$touch();
        if (this.$v.form.$pending || this.$v.form.$error) return;
        this.setGeneralInfo(this.form);
        this.vehicleSaveError = false;
        this.vehicleSaveErrorMessage = "";
        this.updateVehicle().catch((e: string | undefined) => {
          this.vehicleSaveErrorMessage = e ?? "An error occurred while saving the vehicle.";
          this.vehicleSaveError = true;
        });
      } else {
        this.setGeneralInfo(this.form);
      }
    },
    companyUpdated() {
      this.form.vehicleZoneId = null;
      this.updated();
      this.getZones();
    },
    /**
     * Get the display label for the field
     */
    getLabel(key: string) {
      return getLabel(key);
    },
    /**
     * Close the Info Dialog
     */
    close() {
      this.isInfoModalOpen = false;
    },
    /**
     * Take the VIN entered by the user and make a request to the VINDecoder to get vehicle information
     */
    async decodeVIN() {
      this.$v.form?.VIN?.$touch();
      this.vinNetworkErrors = [];
      this.isFuelConsumptionReadOnly = false;
      this.isVINDecoding = true;
      const VIN = this.form.VIN;

      await instance
        .get<VehicleIdentificationNumberData>(`/VehicleIdentificationNumber?vehicleIdentificationNumber=${VIN}`)
        .then((response) => {
          // If the response is valid, set the form fields
          if (response && response.status == 200 && response.data) {
            const VINData = response.data;
            this.form.make = VINData.make;
            this.form.model = VINData.model;
            this.form.year = VINData.modelYear;
            this.form.series = VINData.series;
            this.form.engineDisplacement = VINData.displacement;
            this.form.fuelType = VINData.fuelType;
            this.form.engineType = VINData.engineType;
            this.form.engineManufacturer = VINData.engineManufacturer;
            this.form.brakeSystem = VINData.brakeSystem;
            this.form.driveLineType = VINData.driveLineType;
            this.form.gvwrClass = VINData.gvwrClass;
            this.form.vehicleType = VINData.vehicleType;

            // Calculate the fuel consumption and set the field to readonly
            if (this.form.engineDisplacement) {
              this.form.fuelConsumption = convertFuelFromLitres(calculateFuelConsumption(VINData.displacement));
              this.isFuelConsumptionReadOnly = true;
            }
            this.updated();
          } else {
            this.vinNetworkErrors.push("VIN not found");
          }
        })
        .catch((e) => {
          if (e.response && e.response.status == 404) {
            this.vinNetworkErrors.push("VIN not found");
          } else {
            this.vinNetworkErrors.push("Error decoding VIN");
          }
        })
        .finally(() => {
          this.isVINDecoding = false;
        });
    },
    async getZones() {
      let url = "/Zone/Filter";
      if (this.form.vehicleClientId) {
        url += `?clientId=${this.form.vehicleClientId}`;
      }

      const response = await instance.get<Zone[]>(url);

      this.zones = response.data;
    },
  },
  computed: {
    ...mapGetters(["generalInfo", "isCreatingNewVehicle", "userFuelUnitsDisplay"]),
    assetNameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.form || !this.$v.form.assetName) return errors;
      if (!this.$v.form.assetName.$dirty) return errors;
      !this.$v.form.assetName.required && errors.push("Asset Name is required");
      return errors;
    },
    yearErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.form || !this.$v.form.year) return errors;
      if (!this.$v.form.year.$dirty) return errors;
      !this.$v.form.year.minValue && errors.push("Year is invalid");
      !this.$v.form.year.maxValue && errors.push("Year is invalid");
      !this.$v.form.year.decimal && errors.push("Year is invalid");
      return errors;
    },
    displacementErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.form || !this.$v.form.engineDisplacement) return errors;
      if (!this.$v.form.engineDisplacement.$dirty) return errors;
      !this.$v.form.engineDisplacement.decimal && errors.push("Engine Displacement must be a number");
      return errors;
    },
    fuelConsumptionErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.form || !this.$v.form.fuelConsumption) return errors;
      if (!this.$v.form.fuelConsumption.$dirty) return errors;
      !this.$v.form.fuelConsumption.decimal && errors.push("Fuel Consumption must be a number");
      return errors;
    },
    fuelCostErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.form || !this.$v.form.fuelCost) return errors;
      if (!this.$v.form.fuelCost.$dirty) return errors;
      !this.$v.form.fuelCost.required && errors.push("Fuel Cost is required");
      !this.$v.form.fuelCost.decimal && errors.push("Fuel Cost must be a number");
      return errors;
    },
    fuelConsumptionHint(): string {
      if (this.form.fuelConsumption === null || this.form.engineDisplacement === null) return "";

      let userValue: number = this.form.fuelConsumption;
      let estimatedValue: number = calculateFuelConsumption(this.form.engineDisplacement);

      let percentError = Math.abs(estimatedValue - userValue) / Math.abs(estimatedValue);

      if (percentError > 0.1) {
        return `Value has been changed from the default of ${estimatedValue}.`;
      }

      return "";
    },
    // vehicleClientIdErrors(): string[] {
    //   const errors: string[] = [];
    //   if (!this.$v.form || !this.$v.form.vehicleClientId) return errors;
    //   if (!this.$v.form.vehicleClientId.$dirty) return errors;
    //   !this.$v.form.vehicleClientId.required && errors.push("Company is required");
    //   return errors;
    // },
    form(): GeneralInfo {
      return this.generalInfo;
    },
    screen(): string | null {
      return this.form.kit?.screenProgram ?? null;
    },
    controller(): string | null {
      return this.form.kit?.controllerProgram ?? null;
    },
  },
});
