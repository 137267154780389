import { InputType, numberParameter, listParameter, listNumberParameter } from "../OptionParameter";

export enum WatchDogOption {
  main = "main",
  auxiliary = "auxiliary",
  both = "both",
  off = "off",
}

export interface ignitionSignalControl {
  watchDog: listParameter;
  watchDogVoltageSetPoint: numberParameter;
  accessoryTimeDelay: listNumberParameter;
}

export const defaultIgnitionSignalControl: ignitionSignalControl = {
  watchDog: {
    type: InputType.list,
    value: WatchDogOption.off,
    defaultValue: WatchDogOption.off,
    list: [
      {
        display: "Main",
        value: WatchDogOption.main,
      },
      {
        display: "Auxiliary",
        value: WatchDogOption.auxiliary,
      },
      {
        display: "Both",
        value: WatchDogOption.both,
      },
      {
        display: "Off",
        value: WatchDogOption.off,
      },
    ],
    display: "Accessory Battery Watch Dog",
    info: `This controls the 5 Amp orange ignition supply wire that operates the additional equipment added to the vehicle, replacing a vehicle ignition signal. If the voltage drops too low on whichever battery is selected, the output will be shut off to protect the battery from discharging fully if an accessory component has a large current draw (default is OFF).
    <ul> 
      <li>Main - If the auxiliary equipment is connected to the vehicle battery this should be selected.</li>
      <li>Auxiliary - If the auxiliary equipment is connected to the auxiliary battery this should be selected.</li>
      <li>Both - If some of the auxiliary equipment is connected to the vehicle and some to the auxiliary battery, then this should be selected.</li>
      <li>Off - if you do not want the orange wire to be shut off regardless of voltage you should select this setting</li>
    </ul>
    `,
  },
  watchDogVoltageSetPoint: {
    type: InputType.number,
    value: 12.0,
    defaultValue: 12.0,
    display: "Watch Dog Voltage Set Point",
    info:
      "If the Accessory Battery Watchdog has been selected to Main, Auxiliary or Both, you can select the voltage at which you would like the power to be shut off on the orange wire (default is 12.0).",
    rules: ["required"],
    minValue: 11.0,
    maxValue: 30.0,
    increment: 0.1,
  },
  accessoryTimeDelay: {
    type: InputType.list,
    value: 1,
    defaultValue: 1,
    display: "Accessory Time Delay",
    info:
      "This is the duration of time after the key has been turned to the OFF position that the orange wire will stay powered. If “Infinite” is selected, the OFF for ‘Accessory Battery Watch Dog’ will not be selectable. The output will only shut off based on Voltage. (default is 3s)",
    list: [
      {
        display: "3 seconds",
        value: 1,
      },
      {
        display: "5 seconds",
        value: 2,
      },
      {
        display: "1 minute",
        value: 3,
      },
      {
        display: "10 minutes",
        value: 4,
      },
      {
        display: "30 minutes",
        value: 5,
      },
      {
        display: "45 minutes",
        value: 6,
      },
      {
        display: "1 hour",
        value: 7,
      },
      {
        display: "2 hours",
        value: 8,
      },
      {
        display: "4 hours",
        value: 9,
      },
      {
        display: "8 hours",
        value: 10,
      },
      {
        display: "INF",
        value: 11,
      },
    ],
  },
};
