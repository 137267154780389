













































































import Vue, { PropType } from "vue";

import { Kit } from "@/model/API/Kit";
import { Part } from "@/model/API/Part";
import instance from "@/axios";
import { Media } from "@/model/API/Media";

import PartMedia from "@/components/AddVehicle/PartMedia.vue";

import { mapActions, mapGetters } from "vuex";
import { Currency } from "@/model/Localization";

export default Vue.extend({
  name: "KitInfo",
  components: {
    PartMedia,
  },
  props: {
    kit: Object as PropType<Kit>,
    isKitAdded: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    part: (null as unknown) as Part,
    kitChecked: true,
    wiringDocument: null as Media | null,
    requiredPart1: null as Part | null,
    requiredPart2: null as Part | null,
    requiredPart3: null as Part | null,
    defaultImage:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAS4AAACnCAMAAACYVkHVAAAAJ1BMVEX09PTa2trc3Nz29vbj4+Pg4ODv7+/Y2Njy8vLt7e3q6urm5ubn5+eHk7pVAAAEMklEQVR4nO2ci46jMAxFSZw3/P/3ru0QFtoppSPtUsn3rDSLmFaCIycxjplpAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPxzSLj7Ir4b8UPdU6lL8MvdF/TtiKjSQnQ5O+cClbsv6OvQEddDKrEo7xTPuAhdeySUivgqqc5xmBq6+MfdF/hVSFxRSYuEVGY93j/ocunuS/wCxujjsTeLKOe3aFJlfMKHJfDJXK2vjUVNldpCl7SLJvXlY2hJBmiSs4vlXGJM596tpvxel+OQSrt0S05Gk7aKGuhjT91sAbUeSUhNx7SUIv8i3nXFt8KiJKTyELVN5jz2ZhY1PSfwNMnk5S0mEhT6JL4be1kjqhZN4Pkj5dELURO5xeBopPnvbJXV1CKz+ekzIesSu8mirjrCSseemprerXmUOCDdbFAXJwWsK3ZR178VOXXdL4125jGJrjBUlbKWHd4QZSXYn7j3Hv4fPSn48HYpcHTtl8ZkJbxUlx/B5a+yJWcrVuZ9muXOR1LgfosZXVXutkLXNahwQp9HKfmQ20PXT4igsB6LrvgZIZrSJXO9H7VR0RXW1OBSRkHr5GdHVxBdayIgh+GTO2dhwZSuadHb7cerrpKuMpnTlTLPXm3U/USXrJZZGf+/pE7WdBWpR8xHXb6vkMctjWe8QV381Ohz/FlXCKfpg0ldUl5Yl8ajrkWWvvkkvizq4kyAh1xfGg+65t4XMUPXHi0l57407nR5N61Pkg+Zvl//GdXFSyPfb9PDva5RpiB/mO99f1Cyq0sfrHty+l4X/6ZuFWuTuki2GPu24WEw1j531d1glNJW5QefmvvOtkldum2oh4ep3nddx+abntDyfGdVF1GT7F3n9aMu30ppx4nLz9p2UihY1aVL41ohfEhTnxKHv8/fFMzqKnLDywVduz0Q0iK/RV0TbSbe6OLPbNGl+yA2dUlPiS6Np7rysYuEik1dWrLqj0Gnuh57biiZHIxEC1vSx6DXurzLz92oNVdz5cHRVyIJ1Ul0/dSMSnMzqEtmbacVwidd+cyWtv4a1CUVQu2oOejiTCGUIM3O2buXjc4GdfFTo1QIy0N0xUaFmqajy0sdFnVJhdCnH8qD8irLItu2L1tsLOpqWvKS9F51ScEwbsOP0nzSj2RRl75YMG+6qne7zdly2rRrUBcvjU4rhCO6fDv00Z991aIubbmMI7okni7fvkVda1/J0PXcS3/yVXu6aJr1DdjwcUuJSV3T1NzY3IGuCyS3vbf4WcOSSV2l9N0x+Rnef3yHxUdsJm4t4D58grVmyw6F0Goqr2r0J/QvGNM19TenPnW1YU1Xz7Sg6yL9di+/5PII/kwCeMsv/1JLmSy+aQwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABjiD11yHkXgwpzGAAAAAElFTkSuQmCC",
  }),
  created: async function() {
    let part = await this.getPart(this.kit.hardwareKit);
    if (part) {
      this.part = part;
    }
    this.getIgnitionSheet();

    if (this.isNotNullOrEmpty(this.kit.required1)) {
      this.requiredPart1 = await this.getPart(this.kit.required1);
    }
    if (this.isNotNullOrEmpty(this.kit.required2)) {
      this.requiredPart2 = await this.getPart(this.kit.required2);
    }
    if (this.isNotNullOrEmpty(this.kit.required3)) {
      this.requiredPart3 = await this.getPart(this.kit.required3);
    }
  },
  methods: {
    ...mapActions(["login"]),
    getPrice(part: Part) {
      if (this.userCurrency == Currency.USD) {
        return part.msrpUSD ? `$${part.msrpUSD.toFixed(2)}USD` : "Unknown Price";
      } else {
        return part.msrpCAD ? `$${part.msrpCAD.toFixed(2)}CAD` : "Unknown Price";
      }
    },
    async getPart(partNumber: string) {
      const response = await instance.get<Part>(`/Part/${partNumber}`).catch(() => {
        //
      });

      if (response && response.status == 200 && response.data) {
        return response.data;
      }

      return null;
    },
    async getIgnitionSheet() {
      const response = await instance.get<Media>(`/Media/name?name=${this.kit.wiringDocument}`);
      if (response && response.status == 200 && response.data) {
        this.wiringDocument = response.data;
      }
    },
    /**
     * Get the image associated with the part.
     * If there is no image associated, then use a default image
     */
    getImage() {
      if (this.part.media) {
        for (let i = 0; i < this.part.media.length; i++) {
          let media: Media = this.part.media[i];
          if (media.contentType.toLowerCase().includes("image")) {
            return media.filePath;
          }
        }
      }
      return this.defaultImage;
    },
    addKit() {
      this.$emit("kitAdded", this.kit);
    },
    removeKit() {
      this.$emit("kitRemoved");
    },
    isNotNullOrEmpty(value: string) {
      return value != null && value != "";
    },
  },
  computed: {
    ...mapGetters(["userCurrency"]),
    price(): string {
      if (this.userCurrency == Currency.USD) {
        return this.part.msrpUSD ? `$${this.part.msrpUSD.toFixed(2)}USD` : "Unknown Price";
      } else {
        return this.part.msrpCAD ? `$${this.part.msrpCAD.toFixed(2)}CAD` : "Unknown Price";
      }
    },
    hasRequiredParts(): boolean {
      return this.isNotNullOrEmpty(this.kit.required1) || this.isNotNullOrEmpty(this.kit.required2) || this.isNotNullOrEmpty(this.kit.required3);
    },
  },
});
