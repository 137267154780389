import { InputType, listBoolParameter, temperatureParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface engineRunOptions {
  engineRunEnabled: listBoolParameter;
  engineRunForLowCoolantSetPoint: temperatureParameter;
}

export const defaultEngineRunOptions: engineRunOptions = {
  engineRunEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Engine Run For Initial Low Coolant",
    info: "The system will keep the engine running if the coolant temperature is not high enough to improve cab heat and to improve fuel efficiency",
  },
  engineRunForLowCoolantSetPoint: {
    type: InputType.temperature,
    value: 65,
    defaultValue: 65,
    display: "Engine Run For Low Coolant Set Point",
    info:
      "If ‘Engine Run for Initial Low Coolant’ is enabled in the ‘Options Setting Tab’, the engine will stay on during initial run until this setpoint is reached. This is only applicable on the initial startup by the operator (default is 65°C/149°F).",
    rules: ["required"],
    minValue: 0,
    maxValue: 75,
  },
};
