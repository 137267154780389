import {
  AuxiliaryHeaterFunctionOptions,
  defaultAuxiliaryHeaterFunctionOptions,
} from "@/model/VehicleProfile/OperatorControls/AuxiliaryHeaterFunction";
import { OperatorControlOptions, defaultOperatorControlOptions } from "@/model/VehicleProfile/OperatorControls/SystemSettings";
import { ClimateSettingOptions, defaultClimateSettingOptions } from "@/model/VehicleProfile/OperatorControls/ClimateSettings";

export interface OperatorControls {
  operatorControl: OperatorControlOptions;
  climateSettingOptions: ClimateSettingOptions;
  auxiliaryHeaterFunctionOptions: AuxiliaryHeaterFunctionOptions;
}

const defaultOperatorControlsObject: OperatorControls = {
  operatorControl: Object.assign({}, defaultOperatorControlOptions),
  climateSettingOptions: Object.assign({}, defaultClimateSettingOptions),
  auxiliaryHeaterFunctionOptions: Object.assign({}, defaultAuxiliaryHeaterFunctionOptions),
};

function defaultOperatorControlsCopy(): OperatorControls {
  return JSON.parse(JSON.stringify(defaultOperatorControlsObject));
}

export const defaultOperatorControls = defaultOperatorControlsCopy;
