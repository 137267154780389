
























import Vue from "vue";

// import IgnitionSheet from "@/components/Resources/IgnitionSheets.vue";
import ServiceBulletins from "@/components/Resources/ServiceBulletins.vue";
import Software from "@/components/Resources/Software.vue";
import Manuals from "@/components/Resources/Manuals.vue";
import AnalysisManuals from "@/components/Resources/AnalysisManuals.vue";
import TrainingDocuments from "@/components/Resources/TrainingDocuments.vue";
import KitInstructions from "@/components/Resources/KitInstructions.vue";
import GripControllerApplication from "@/components/Resources/GripControllerApplication.vue";

export default Vue.extend({
  name: "Resources",
  components: { ServiceBulletins, Software, Manuals, AnalysisManuals, KitInstructions, GripControllerApplication, TrainingDocuments },
  data: () => ({}),
  methods: {},
  computed: {},
});
