import { InputType, listBoolParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface SeatBeltStartOptions {
  seatBeltStartEnabled: listBoolParameter;
  seatBeltOffOnly: listBoolParameter;
}

export const defaultSeatBeltStartOptions: SeatBeltStartOptions = {
  seatBeltStartEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Seat Belt Start",
    info:
      "This feature if enabled and the system is in the monitoring mode, will start the engine by the driver buckling their seat (default disabled). NOTE: This option will be disabled in anti-theft mode.",
  },
  seatBeltOffOnly: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Seat Belt Off Only",
    info:
      "When this option is enabled, the EPEQ® IM system will only go into monitoring while driver seat belt is unbuckled or operator shuts down the vehicle with the EPEQ® IM shutdown button (default disabled).NOTE: Not all vehicle models can read the  driver’s seat belt. Test Driver Seat Belt Position on the ‘Real Time Information 2’ tab to ensure your vehicles seat belt signal reads correctly (default disabled).",
  },
};
