















import Vue from "vue";

import { mgr } from "@/helpers/AuthService";

export default Vue.extend({
  name: "About",
  data: () => ({}),
  methods: {},
  computed: {},
  created() {
    if (this.$route.query["error"] == "login_required") {
      mgr.signinRedirect();
      return;
    }

    let previousRoute = localStorage.getItem("previouspath");
    localStorage.removeItem("previouspath");

    if (!previousRoute || previousRoute == "") {
      this.$router.push({ name: "SavingsCalculator" });
    } else {
      this.$router.push({ path: previousRoute });
    }
  },
});
