import { InputType, listBoolParameter } from "../OptionParameter";
import { enabledUnits } from "@/model/VehicleProfile/Lists";

export interface ShorePowerOptions {
  shorePowerEnabled: listBoolParameter;
}

export const defaultShorePowerOptions: ShorePowerOptions = {
  shorePowerEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Shore Power",
    info:
      "This feature, if enabled, will not allow the engine to start or run if the vehicle is plugged into an outlet. When connected, the flashing battery icon will be displayed on the screen.",
  },
};
