import axios from "axios";

import store from "@/store/index";
import { mgr } from "@/helpers/AuthService";
import { apiURL, identityServerURL } from "@/helpers/Settings";

const defaultOptions = {
  baseURL: `${apiURL}/api`,
  headers: {
    "Content-Type": "application/json",
    "api-version": "1.0",
  },
};

const instance = axios.create(defaultOptions);

// If an access token is stored, add it to the Authorization header on every request
instance.interceptors.request.use(function(config) {
  const token = store.getters.token;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      mgr.signinPopupCallback().then((user) => {
        if (user) {
          store.dispatch("setUser", user);
        } else {
          mgr.signinRedirect();
        }
      });
    } else {
      const errorData = error?.response?.data ?? null;
      const genericMessage = "Error making request";
      if (errorData) {
        if (errorData.errors) {
          try {
            store.dispatch(
              "setErrorMessage",
              Object.keys(errorData.errors).reduce(function(res, v) {
                return res.concat(errorData.errors[v]);
              }, [])
            );
          } catch {
            store.dispatch("setErrorMessage", genericMessage);
          }
        } else if (errorData.title) {
          store.dispatch("setErrorMessage", errorData.title);
        } else {
          store.dispatch("setErrorMessage", genericMessage);
        }
      } else {
        store.dispatch("setErrorMessage", genericMessage);
      }
    }

    return error;
  }
);

export default instance;

const defaultIdentityOptions = {
  baseURL: `${identityServerURL}/api`,
  headers: {
    "Content-Type": "application/json",
    "api-version": "1.0",
  },
};

const identityInstancee = axios.create(defaultIdentityOptions);

// If an access token is stored, add it to the Authorization header on every request
identityInstancee.interceptors.request.use(function(config) {
  const token = store.getters.token;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export const identityInstance = identityInstancee;
