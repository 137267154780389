





































































































































import Vue from "vue";

// Plugin Helpers
import instance, { identityInstance } from "@/axios";

import LoadingModal from "@/components/LoadingModal.vue";

// Models
import { Client } from "@/model/API/Client";
import { User } from "@/model/API/User";
import { CreateUser } from "@/model/API/CreateUser";
import { UpdateUser } from "@/model/API/UpdateUser";
import { Country, Currency, Timezone } from "@/model/Localization";
import { DistanceUnit, FuelUnit, TemperatureUnit } from "@/model/Units";
import { Zone } from "@/model/API/Zone";

export default Vue.extend({
  name: "Users",
  components: {
    LoadingModal,
  },
  created: async function() {
    const responseCompany = await instance.get<Client[]>(`/Client`);

    this.error = null;
    if (responseCompany.data) {
      this.companies = responseCompany.data;
      if (this.companies.length == 1) {
        this.newUserClientId = this.companies[0].clientId;
      }
      this.getUsers();
    }
  },
  data: () => ({
    dialog: false,
    isSaving: false,
    dialogDelete: false,
    dialogReactivate: false,
    error: null as string | null,
    users: [] as User[],
    companies: [] as Client[],
    selectedUser: null as User | null,
    savingMessage: "Creating user",
    headers: [
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Client", value: "clients[0].company" },
      { text: "Deactivated", value: "isDeleted" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    newUserFirstName: null as string | null,
    newUserLastName: null as string | null,
    newUserEmail: null as string | null,
    newUserClientId: null as string | null,
    newUserZoneId: null as string | null,
    zones: [] as Zone[],
    roles: [
      {
        text: "User",
        value: 3,
      },
      {
        text: "Resource Viewer",
        value: 5,
      },
    ],
    newUserRole: 3,
  }),
  methods: {
    async getUsers() {
      let url = "/User?role=3";
      const response = await instance.get<User[]>(url);

      this.users = response.data;
      this.getResourceUsers();
    },
    async getResourceUsers() {
      let url = "/User?role=5";
      const response = await instance.get<User[]>(url);

      this.users.push(...response.data);
    },
    async getZones() {
      if (this.newUserClientId) {
        let url = `/Zone/Filter?clientId=${this.newUserClientId}`;

        const response = await instance.get<Zone[]>(url);

        this.zones = response.data;
      } else {
        this.zones = [];
      }
    },
    companyUpdated() {
      this.newUserZoneId = null;
      this.getZones();
    },
    close() {
      this.dialog = false;
      this.selectedUser = null;
      this.newUserFirstName = null;
      this.newUserLastName = null;
      this.newUserEmail = null;
      this.newUserRole = 3;
    },
    async save() {
      this.error = null;
      if (this.selectedUser) {
        this.isSaving = true;
        this.savingMessage = "Updating user";
        let newUser: UpdateUser = {
          userId: this.selectedUser.userId,
          firstName: this.newUserFirstName ?? "",
          lastName: this.newUserLastName ?? "",
          email: this.newUserEmail ?? "",
          userRole: this.newUserRole,
          clientIds: [],
          zoneId: this.newUserZoneId,
          country: Country.Canada,
          currency: Currency.CAD,
          timezone: Timezone.Eastern,
          temperatureUnit: TemperatureUnit.Celsius,
          distanceUnit: DistanceUnit.Kilometers,
          fuelUnit: FuelUnit.Litres,
        };

        if (this.newUserClientId && this.newUserRole != 5) {
          newUser.clientIds = [this.newUserClientId];
        }

        identityInstance
          .put(`/user/${newUser.userId}`, {
            id: newUser.userId,
            email: newUser.email,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            isEnabled: true,
            role: "gripidle.gripportaluser",
          })
          .then(() => {
            instance
              .put(`/User/${newUser.userId}`, newUser)
              .then(() => {
                this.dialog = false;
                this.close();
                this.getUsers();
                this.isSaving = false;
              })
              .catch(() => {
                this.error = "Error updating user";
                this.isSaving = false;
              });
          })
          .catch(() => {
            this.error = "Error updating user";
            this.isSaving = false;
          });
      } else {
        this.isSaving = true;
        this.savingMessage = "Creating user";

        let newUser: CreateUser = {
          firstName: this.newUserFirstName ?? "",
          lastName: this.newUserLastName ?? "",
          email: this.newUserEmail ?? "",
          userId: "TODO",
          userRole: this.newUserRole,
          zoneId: this.newUserZoneId,
          clientIds: [],
          country: Country.Canada,
          currency: Currency.CAD,
          temperatureUnit: TemperatureUnit.Celsius,
          distanceUnit: DistanceUnit.Kilometers,
          fuelUnit: FuelUnit.Litres,
        };

        if (this.newUserClientId && this.newUserRole != 5) {
          newUser.clientIds = [this.newUserClientId];
        }

        identityInstance
          .post("/user", {
            email: newUser.email,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            isEnabled: true,
            role: "gripidle.gripportaluser",
          })
          .then((e) => {
            newUser.userId = e.data.id;

            instance
              .post("/User", newUser)
              .then(() => {
                this.dialog = false;
                this.close();
                this.getUsers();
                this.isSaving = false;
              })
              .catch(() => {
                this.error = "Error creating user";
                this.isSaving = false;
              });
          })
          .catch((e) => {
            if (e && e.response && e.response.status && e.response.status == 400) {
              this.error = "Email address already in use";
            } else {
              this.error = "Error creating user";
            }
            this.isSaving = false;
          });
      }
    },
    async editUser(item: User) {
      this.dialog = true;
      this.selectedUser = item;
      this.newUserFirstName = item.firstName;
      this.newUserLastName = item.lastName;
      this.newUserEmail = item.email;
      this.newUserRole = item.userRole;
      if (item.clients.length > 0) {
        this.newUserClientId = item.clients[0].clientId;
      } else {
        this.newUserClientId = null;
      }
      
      await this.getZones();
      this.newUserZoneId = item.zone?.zoneId ?? null;
    },
    reactivateUser(item: User) {
      this.selectedUser = item;
      this.dialogReactivate = true;
    },
    closeReactivate() {
      this.selectedUser = null;
      this.dialogReactivate = false;
    },
    deleteUser(item: User) {
      this.selectedUser = item;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedUser = null;
    },
    async reactivateUserConfirm() {
      if (this.selectedUser) {
        this.newUserFirstName = this.selectedUser.firstName;
        this.newUserLastName = this.selectedUser.lastName;
        this.newUserEmail = this.selectedUser.email;

        if (this.selectedUser.clients.length > 0) {
          this.newUserClientId = this.selectedUser.clients[0].clientId;
        } else {
          this.newUserClientId = null;
        }
        

        this.save();
        this.closeReactivate();
      }
    },
    async deleteUserConfirm() {
      if (this.selectedUser) {
        await instance
          .delete(`/User/${this.selectedUser.userId}`)
          .then(() => {
            this.closeDelete();
            this.getUsers();
          })
          .catch(() => {
            // TODO
          });
      }
    },
  },
});
