import { InputType, numberParameter, listBoolParameter } from "../OptionParameter";
import { enabledUnits, onOffUnits } from "@/model/VehicleProfile/Lists";

export interface StartStopAlarmOptions {
  startStopAlarmEnabled: listBoolParameter;
  startWarningAlarm: listBoolParameter;
  startAlarmFrequency: numberParameter;
  stopWarningAlarm: listBoolParameter;
  stopAlarmFrequency: numberParameter;
  breakPedalAlarm: listBoolParameter;
}

export const defaultStartStopAlarmOptions: StartStopAlarmOptions = {
  startStopAlarmEnabled: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: enabledUnits,
    display: "Start/Stop Alarm",
    info: "Requires the Alarm kit or may be used to control an external alarm such as a horn or light",
  },
  startWarningAlarm: {
    type: InputType.list,
    value: true,
    defaultValue: true,
    list: onOffUnits,
    display: "Start Warning Alarm",
    info: "If ON is selected, the start warning alarm output will be enabled. (default is ON)",
  },
  startAlarmFrequency: {
    type: InputType.number,
    value: 15,
    defaultValue: 15,
    display: "Start Alarm Frequency",
    info: "This controls how often the alarm output is turned on during the Start Warning Time (default is 15s).",
    rules: ["required"],
    minValue: 0,
    maxValue: 15,
  },
  stopWarningAlarm: {
    type: InputType.list,
    value: true,
    defaultValue: true,
    list: onOffUnits,
    display: "Stop Warning Alarm",
    info: "If ON is selected, the stop warning alarm output will be enabled (default is ON).",
  },
  stopAlarmFrequency: {
    type: InputType.number,
    value: 15,
    defaultValue: 15,
    display: "Stop Alarm Frequency",
    info: "This controls how often the alarm output is turned on during the Stop Warning Time (default is 15s)",
    rules: ["required"],
    minValue: 0,
    maxValue: 15,
  },
  breakPedalAlarm: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    list: onOffUnits,
    display: "Brake Pedal Alarm",
    info:
      "This setting will turn the audible alarm on when the foot has been left on the brake pedal on a push-button start vehicle to remind the operator that the vehicle can't start back up automatically until they release the pedal (default is OFF)",
  },
};
