

































































































import Vue from "vue";

import InfoModal from "@/components/InfoModal.vue";

import { mapGetters, mapActions } from "vuex";

import { optionParameter, InputType, temperatureParameter, listBoolParameter, listItem, listParameter } from "@/model/VehicleProfile/OptionParameter";

import { OptionalEquipment } from "@/model/VehicleProfile/OptionalEquipment/OptionalEquipment";

import { CurrentSensorOption } from "@/model/VehicleProfile/OptionalEquipment/CurrentSensor";

import { required, minValue, maxValue, ValidationRule } from "vuelidate/lib/validators";
import { KitOptionTypes, VehicleKitOptions } from "@/model/VehicleProfile/KitOptions";
import { KitOption } from "@/model/API/KitOption";
import { VehicleEquipment } from "@/model/VehicleProfile/VehicleEquipment";
import { convertTemperatureFromCelsius } from "@/helpers/UnitService";
import { HeatingOption } from "@/model/VehicleProfile/StandardOptions/HeatingOptions";
import { AirConditioningOption } from "@/model/VehicleProfile/StandardOptions/AirConditioning";
import { TemperatureUnit } from "@/model/Units";
import { SetHiddenFieldValidation } from "@/helpers/VehicleValidation";

export default Vue.extend({
  name: "OptionalFeatures",
  components: { InfoModal },
  /**
   * Ensure the form is saved when the component is destroyed
   */
  destroyed: function() {
    this.save();
  },
  /**
   * Create the vuelidate validation object by looping through all the input's to create the object.
   */
  validations() {
    let validations: Record<string, Record<string, Record<string, Record<string, Record<string, ValidationRule | (() => ValidationRule)>>>>> = {
      form: {},
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var form: Record<string, any> = this.form;

    // For every property in the form, loop through and generate the vuelidates rules.
    // For example: Go through every property in the optionalEquipment interface, then go through every property inside the startStopAlarm interface.
    for (const section in this.form) {
      validations["form"][section] = {};
      for (const param in form[section]) {
        // If we shouldn't display the input we do not want to add validation for it, but we may need to update the form values
        if (!this.shouldDisplay(param)) {
          form = SetHiddenFieldValidation(
            form,
            section,
            param,
            this.userTemperatureUnits
          );
          continue;
        }

        validations["form"][section][param] = {
          value: {},
        };

        // If the input is required, add the required method from vuelidate
        if (form[section][param].rules && form[section][param].rules.includes("required")) {
          validations["form"][section][param]["value"] = {
            ...validations["form"][section][param]["value"],
            required,
          };
        }

        // If the input has a minValue, add the minValue method from vuelidate
        if (form[section][param].minValue != null) {
          let value = form[section][param].minValue;
          if (form[section][param].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          validations["form"][section][param]["value"] = {
            ...validations["form"][section][param]["value"],
            minValue: minValue(value),
          };
        }

        // If the input has a maxValue, add the maxValue method from vuelidate
        if (form[section][param].maxValue != null) {
          let value = form[section][param].maxValue;
          if (form[section][param].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          validations["form"][section][param]["value"] = {
            ...validations["form"][section][param]["value"],
            maxValue: maxValue(value),
          };
        }
      }
    }

    return validations;
  },
  data: () => ({
    isInfoModalOpen: false,
    infoModalTitle: "",
    infoModalMessage: "",
    InputType: InputType,
    heatingOption: HeatingOption,
    resetIcon: "mdi-close",
    infoIcon: "mdi-information",
    temperatureUnits: [
      {
        text: "C",
        value: "c",
      },
      {
        text: "F",
        value: "f",
      },
    ],

    validationSnackbar: false,
  }),
  methods: {
    ...mapActions(["setOptionalEquipment", "updateOptionalEquipment"]),
    // Saves the data to the vuex store
    save() {
      this.setOptionalEquipment(this.form);
    },
    getListOptions(key: string, parameter: listParameter | listBoolParameter) {
      if (key == "ACOption") {
        let list: listItem[] = [];

        for (let i = 0; i < parameter.list.length; i++) {
          // Don't display "AuxBatteryEither" or "AuxBatteryExtend" options unless solenoid equipment is installed
          if (parameter.list[i].value == AirConditioningOption.ECoolPark || parameter.list[i].value == AirConditioningOption.DCACSystem) {
            if (this.isACEquipped()) {
              list.push(parameter.list[i]);
            }
          } else {
            list.push(parameter.list[i]);
          }
        }

        return list;
      } else if (key == "heatingOption") {
        let list: listItem[] = [];

        for (let i = 0; i < parameter.list.length; i++) {
          // Only display "EngineOnly" unless heating equipment is installed
          if (parameter.list[i].value != HeatingOption.EngineOnly) {
            if (this.isHeatingEquippedNotUseMyOwn()) {
              list.push(parameter.list[i]);
            }
          } else {
            list.push(parameter.list[i]);
          }
        }

        return list;
      } else {
        return parameter.list;
      }
    },
    getPartDetails(kitOption: KitOptionTypes) {
      let kitOp: KitOption | null = null;
      var kitOptions: VehicleKitOptions = this.kitOptions;
      switch (kitOption) {
        case KitOptionTypes.currentSensor:
          kitOp = kitOptions.possibleOptions.currentSensor ?? null;
          break;
      }

      if (kitOp != null) {
        return `You must purchase ${kitOp.heading} `;
      } else {
        return "";
      }
    },
    /**
     * Determine whether a section should be displayed.
     * The section should be displayed if at least one property within the section should be displayed
     */
    shouldDisplaySection(properties: Record<string, unknown>) {
      for (const property in properties) {
        if (this.shouldDisplay(property)) return true;
      }
      return false;
    },
    /**
     * Called everything an input value is changed.
     * If it's an existing vehicle submit the updated settings.
     * If the vehicle already exists, don't submit the updates and wait for the user to submit the entire settings file
     */
    updated() {
      if (!this.isCreatingNewVehicle) {
        if (!this.$v.form.$invalid) {
          this.setOptionalEquipment(this.form);
          this.updateOptionalEquipment();
        } else {
          this.validationSnackbar = true;
        }
      }
    },
    isHeatingEquippedNotUseMyOwn(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.heat != null;
    },
    isHeatingEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.heat != null || vehicleEquipment.selected.useMyOwnHeating;
    },
    isAlarmEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.alarm != null;
    },
    isACEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.airConditioning != null;
    },
    resetTemperature(key: temperatureParameter) {
      key.value = convertTemperatureFromCelsius(key.defaultValue, this.userTemperatureUnits);
      this.updated();
    },
    /**
     * Reset the input back to the default value
     */
    reset(key: optionParameter) {
      key.value = key.defaultValue;
      this.updated();
    },
    /**
     * Determine the error messages for the input field.
     * @classKey The key of the outer interface. This would be the keys of the OptionalEquipment interface (Such as startStopAlarm)
     * @classKey The key of the property being displayed. For example this would be the stopAlarmFrequency that is inside the startStopAlarm interface.
     * @label The display value of the input. This is used to generate a specific error message with the input name in it.
     */
    errorMessages(classKey: string, propertyKey: string, label: string) {
      const errors: string[] = [];

      // Ensure that the form is created
      if (!this.$v.form) {
        return errors;
      }

      // Ensure that the interface is part of the form
      if (!this.$v.form[classKey]) {
        return errors;
      }

      // Ensure that the property exists in the interface
      if (!this.$v.form[classKey]?.[propertyKey]) {
        return errors;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let form: any = this.form;

      // If the property has the required rule and there is a required error, add the error message
      if (form[classKey][propertyKey].rules && form[classKey][propertyKey].rules.includes("required")) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        !this.$v.form[classKey]![propertyKey]["value"].required && errors.push(`${label} is required`);
      }

      // If the property is a number type and has the min value rule and there is a min error, display a message
      if (form[classKey][propertyKey].type == InputType.number || form[classKey][propertyKey].type == InputType.temperature) {
        if (form[classKey][propertyKey].minValue != null) {
          let value = form[classKey][propertyKey].minValue;
          if (form[classKey][propertyKey].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          !this.$v.form[classKey]![propertyKey]["value"].minValue && errors.push(`${label} must be at least ${value}`);
        }
        // If the property is a number type and has the max value rule and there is a max error, display a message
        if (form[classKey][propertyKey].maxValue != null) {
          let value = form[classKey][propertyKey].maxValue;
          if (form[classKey][propertyKey].type == InputType.temperature && this.userTemperatureUnits == TemperatureUnit.Fahrenheit) {
            value = convertTemperatureFromCelsius(value, TemperatureUnit.Fahrenheit);
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          !this.$v.form[classKey]![propertyKey]["value"].maxValue && errors.push(`${label} must not exceed ${value}`);
        }
      }

      return errors;
    },
    /**
     * Determine whether the input should be displayed. This will check to ensure that the option has been enabled before asking for settings
     */
    shouldDisplay(key: string) {
      let heatingOptions = this.form.heatingOptions;
      let acOptions = this.form.airConditioningOptions;
      let auxHeatingOptions = this.form.auxHeatingOptions;
      let startOptions = this.form.engineStartOptions;
      let runOptions = this.form.engineRunOptions;

      switch (key) {
        // Start Stop Alarm
        case "startStopAlarmEnabled":
          return this.isAlarmEquipped();
        case "startWarningAlarm":
          return this.isAlarmEquipped() && this.form.startStopAlarm.startStopAlarmEnabled.value;
        case "startAlarmFrequency":
          return this.isAlarmEquipped() && this.form.startStopAlarm.startStopAlarmEnabled.value && this.form.startStopAlarm.startWarningAlarm.value;
        case "stopWarningAlarm":
          return this.isAlarmEquipped() && this.form.startStopAlarm.startStopAlarmEnabled.value;
        case "stopAlarmFrequency":
          return this.isAlarmEquipped() && this.form.startStopAlarm.startStopAlarmEnabled.value && this.form.startStopAlarm.stopWarningAlarm.value;
        case "breakPedalAlarm":
          return this.isAlarmEquipped() && this.form.startStopAlarm.startStopAlarmEnabled.value;

        // Current Sensor
        case "currentSensorOption":
          return this.isCurrentSensorEquipped();
        case "chargeDischargeSetPoint":
          return (
            this.isCurrentSensorEquipped() &&
            (this.form.currentSensor.currentSensorOption.value == CurrentSensorOption.Charging ||
              this.form.currentSensor.currentSensorOption.value == CurrentSensorOption.Discharging)
          );

        // Automatic Climate Control
        case "automaticClimateControlEnabled":
          return this.isHVACEquipped();
        case "blowerInitialSpeed":
          return this.isHVACEquipped() && this.form.automaticClimateControl.automaticClimateControlEnabled.value;
        case "dontShutBlowerOff":
          return this.isHVACEquipped() && this.form.automaticClimateControl.automaticClimateControlEnabled.value;
        case "dontRampUpBlower":
          return this.isHVACEquipped() && this.form.automaticClimateControl.automaticClimateControlEnabled.value;
        case "passengerSeatbelt":
          return this.isHVACEquipped() && this.form.automaticClimateControl.automaticClimateControlEnabled.value;

        // Humidity
        case "humidityEnabled":
          return this.isHumidityEquipped();

        // Secondary Temp Sensor
        case "secondaryTempSensorEnabled":
          return this.isTempSensingEquipped();

        // Rear Facility Control
        case "rearFacilityEnabled":
          return this.isRearFacilityEquipped();
        case "rearFacilityTemperatureSetPoint":
          return this.isRearFacilityEquipped() && this.form.rearFacility.rearFacilityEnabled.value;
        case "rearFacilityAirConditioningTempOffset":
          return this.isRearFacilityEquipped() && this.form.rearFacility.rearFacilityEnabled.value;
        case "rearFacilityHeatingTempOffset":
          return this.isRearFacilityEquipped() && this.form.rearFacility.rearFacilityEnabled.value;
        case "rearFacilityEngineRunInitial":
          return this.isRearFacilityEquipped() && this.form.rearFacility.rearFacilityEnabled.value;
        case "rearFacilityEngineAssistRearAir":
          return this.isRearFacilityEquipped() && this.form.rearFacility.rearFacilityEnabled.value;
        case "rearFacilityEngineAssistCabAir":
          return this.isRearFacilityEquipped() && this.form.rearFacility.rearFacilityEnabled.value;
        case "rearFacilityEngineAssistAirOffset":
          return this.isRearFacilityEquipped() && this.form.rearFacility.rearFacilityEnabled.value;
        case "rearFacilityEngineAssistAirTimer":
          return this.isRearFacilityEquipped() && this.form.rearFacility.rearFacilityEnabled.value;

        // Ignition Bypass
        case "ignitionBypassEnabled":
          return this.isIgnitionBypassEquipped();

        // Heater Options
        case "heatingOption":
          return this.isHeatingEquipped() && heatingOptions.heatingEnabled.value;
        case "heaterTemperatureSetPointMinimum":
          return this.isHeatingEquipped() && heatingOptions.heatingEnabled.value;
        case "heaterTemperatureSetPointMaximum":
          return this.isHeatingEquipped() && heatingOptions.heatingEnabled.value;
        case "heatContinuousOperationOnOff":
          return (
            this.isHeatingEquipped() &&
            heatingOptions.heatingEnabled.value &&
            (heatingOptions.heatingOption.value == this.heatingOption.EngineOnly ||
              heatingOptions.heatingOption.value == this.heatingOption.CoolantPump ||
              heatingOptions.heatingOption.value == this.heatingOption.AuxiliaryHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ComboHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ProheatX30)
          );
        case "heatContinuousOperationSetPoint":
          return (
            this.isHeatingEquipped() &&
            heatingOptions.heatingEnabled.value &&
            (heatingOptions.heatingOption.value == this.heatingOption.EngineOnly ||
              heatingOptions.heatingOption.value == this.heatingOption.CoolantPump ||
              heatingOptions.heatingOption.value == this.heatingOption.AuxiliaryHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ComboHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ProheatX30)
          );
        case "climateControlEnable":
          return false;
        case "auxHeaterInitialRun":
          return (
            this.isHeatingEquipped() &&
            heatingOptions.heatingEnabled.value &&
            (heatingOptions.heatingOption.value == this.heatingOption.AuxiliaryHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ComboHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ProheatX30)
          );
        case "manualAuxiliaryHeater":
          return (
            this.isHeatingEquipped() &&
            heatingOptions.heatingEnabled.value &&
            (heatingOptions.heatingOption.value == this.heatingOption.AuxiliaryHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ComboHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ProheatX30)
          );
        case "coolantPumpHighCoolantTempSetPoint":
          return (
            this.isHeatingEquipped() && heatingOptions.heatingEnabled.value && heatingOptions.heatingOption.value == this.heatingOption.CoolantPump
          );
        case "coolantPumpLowEngineCoolantTempSetPoint":
          return (
            this.isHeatingEquipped() && heatingOptions.heatingEnabled.value && heatingOptions.heatingOption.value == this.heatingOption.CoolantPump
          );
        case "heaterTempOffset":
          return (
            this.isHeatingEquipped() &&
            heatingOptions.heatingEnabled.value &&
            (heatingOptions.heatingOption.value == this.heatingOption.CoolantPump ||
              heatingOptions.heatingOption.value == this.heatingOption.AuxiliaryHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.AirHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ComboHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ProheatX30)
          );
        case "heatingSchedulerRequired":
          return false;
        case "heatingSchedulerManager":
          return this.isHeatingEquipped() && heatingOptions.heatingEnabled.value && auxHeatingOptions.heatingSchedulerRequired.value;

        // acOptions
        case "ACOption":
          return acOptions.ACEnabled.value;
        case "ACTemperatureSetPointMinimum":
          return acOptions.ACEnabled.value;
        case "ACTemperatureSetPointMaximum":
          return acOptions.ACEnabled.value;
        case "dontStart":
          return acOptions.ACEnabled.value;
        case "ACTemperatureOffset":
          return acOptions.ACEnabled.value;
        case "ACContinuousOperationOnOff":
          return acOptions.ACEnabled.value;
        case "ACContinuousOperationSetPoint":
          return acOptions.ACEnabled.value;
        case "ACClimateControlEnable":
          return false;

        // Engine Start Options
        case "engineStartEnabled":
          return (
            heatingOptions.heatingEnabled.value &&
            heatingOptions.heatingOption.value != this.heatingOption.AuxiliaryHeater &&
            heatingOptions.heatingOption.value != this.heatingOption.ComboHeater &&
            heatingOptions.heatingOption.value != this.heatingOption.ProheatX30
          );
        case "engineBoostAuxHeater":
          // Is Aux Heater or Proheat heat Option selected
          return (
            heatingOptions.heatingEnabled.value &&
            (heatingOptions.heatingOption.value == this.heatingOption.AuxiliaryHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ProheatX30 ||
              heatingOptions.heatingOption.value == this.heatingOption.ComboHeater) &&
            startOptions.engineStartEnabled.value
          );
        case "engineStartHigh":
          return (
            heatingOptions.heatingEnabled.value &&
            heatingOptions.heatingOption.value != this.heatingOption.AuxiliaryHeater &&
            heatingOptions.heatingOption.value != this.heatingOption.ComboHeater &&
            heatingOptions.heatingOption.value != this.heatingOption.ProheatX30 &&
            startOptions.engineStartEnabled.value
          );
        case "engineStartLow":
          return (
            heatingOptions.heatingEnabled.value &&
            heatingOptions.heatingOption.value != this.heatingOption.AuxiliaryHeater &&
            heatingOptions.heatingOption.value != this.heatingOption.ComboHeater &&
            heatingOptions.heatingOption.value != this.heatingOption.ProheatX30 &&
            startOptions.engineStartEnabled.value
          );
        case "auxHeaterHigh":
          return (
            heatingOptions.heatingEnabled.value &&
            (heatingOptions.heatingOption.value == this.heatingOption.AuxiliaryHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ProheatX30 ||
              heatingOptions.heatingOption.value == this.heatingOption.ComboHeater) &&
            startOptions.engineStartEnabled.value &&
            startOptions.engineBoostAuxHeater.value
          );
        case "auxHeaterLow":
          return (
            heatingOptions.heatingEnabled.value &&
            (heatingOptions.heatingOption.value == this.heatingOption.AuxiliaryHeater ||
              heatingOptions.heatingOption.value == this.heatingOption.ProheatX30 ||
              heatingOptions.heatingOption.value == this.heatingOption.ComboHeater) &&
            startOptions.engineStartEnabled.value &&
            startOptions.engineBoostAuxHeater.value
          );

        // Engine Run Options
        case "engineRunEnabled":
          return (
            heatingOptions.heatingEnabled.value &&
            heatingOptions.heatingOption.value != this.heatingOption.AuxiliaryHeater &&
            heatingOptions.heatingOption.value != this.heatingOption.ComboHeater &&
            heatingOptions.heatingOption.value != this.heatingOption.ProheatX30
          );
        case "engineRunForLowCoolantSetPoint":
          return (
            heatingOptions.heatingEnabled.value &&
            heatingOptions.heatingOption.value != this.heatingOption.AuxiliaryHeater &&
            heatingOptions.heatingOption.value != this.heatingOption.ComboHeater &&
            heatingOptions.heatingOption.value != this.heatingOption.ProheatX30 &&
            runOptions.engineRunEnabled.value
          );
      }

      return true;
    },
    /**
     * Determine if current sensor equipment is either installed in the vehicle by default or if the user has indicated to install equipment for this feature
     */
    isCurrentSensorEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.options.currentSensor == null || vehicleEquipment.selected.currentSensor != null;
    },
    /**
     * Determine if HVAC equipment is either installed in the vehicle by default or if the user has indicated to install equipment for this feature
     */
    isHVACEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.autoHVAC != null;
    },
    /**
     * Determine if humidity equipment is either installed in the vehicle by default or if the user has indicated to install equipment for this feature
     */
    isHumidityEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.humidity != null;
    },
    /**
     * Determine if secondary temp sensor equipment is either installed in the vehicle by default or if the user has indicated to install equipment for this feature
     */
    isTempSensingEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.tempSensing != null;
    },
    /**
     * Determine if rear facility equipment is either installed in the vehicle by default or if the user has indicated to install equipment for this feature
     */
    isRearFacilityEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.rearFacilityControl != null;
    },
    /**
     * Determine if ignition bypass equipment is either installed in the vehicle by default or if the user has indicated to install equipment for this feature
     */
    isIgnitionBypassEquipped(): boolean {
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;
      return vehicleEquipment.selected.ignitionBypass != null;
    },

    /**
     * Display the info modal to the user for the selected field
     */
    displayMessage(key: optionParameter) {
      this.isInfoModalOpen = true;
      this.infoModalTitle = this.getLabel(key);
      this.infoModalMessage = key.info;
    },
    /**
     * Get the display label for the field
     */
    getLabel(key: optionParameter) {
      return key.display;
    },
    /**
     * Close the Info Dialog
     */
    close() {
      this.isInfoModalOpen = false;
    },
    /**
     * Determine the display value of the tab based on the key
     */
    getKeyDisplay(key: string) {
      switch (key) {
        case "startStopAlarm":
          return "Start Stop Alarm";
        case "currentSensor":
          return "Current Sensor";
        case "automaticClimateControl":
          return "Automatic Climate Control";
        case "humidity":
          return "Humidity";
        case "secondaryTempSensor":
          return "Secondary Temp Sensor";
        case "rearFacility":
          return "Rear Facility Control";
        case "ignitionBypass":
          return "Ignition Bypass";
        case "heatingOptions":
          return "Heating Option";
        case "airConditioningOptions":
          return "Air Conditioning Option";
        case "auxHeatingOptions":
          return "Auxiliary Heating Options";
        case "solar":
          return "Solar";
        case "engineStartOptions":
          return "Engine Start For Low Coolant Temperature";
        case "engineRunOptions":
          return "Engine Run For Initial Low Coolant";
      }
      return "";
    },
  },
  computed: {
    ...mapGetters([
      "optionalEquipment",
      "isCreatingNewVehicle",
      "kitOptions",
      "vehicleEquipment",
      "isKitSelected",
      "userTemperatureUnitsDisplay",
      "userTemperatureUnits",
    ]),
    form(): OptionalEquipment {
      return this.optionalEquipment;
    },
    validationErrors(): string[] {
      let errors = this.$v
        .$flattenParams()
        .map((x) => this.errorMessages(x.path[1], x.path[2], (this.form as any)[x.path[1]][x.path[2]].display))
        .flat()
        .filter((value, index, array) => array.indexOf(value) == index);
      return errors;
    },
  },
});
