



























































































import { Part } from "@/model/API/Part";
import Vue from "vue";
import instance from "@/axios";

import { mapGetters } from "vuex";
import { VehicleEquipment } from "@/model/VehicleProfile/VehicleEquipment";
import { Currency } from "@/model/Localization";

export default Vue.extend({
  name: "QuoteModal",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  created: async function() {
    if (this.selectedKit) {
      const response = await instance.get<Part>(`/Part/${this.selectedKit.hardwareKit}`);

      if (response && response.status == 200 && response.data) {
        this.kitPart = response.data;
      }

      if (this.isNotNullOrEmpty(this.selectedKit.required1)) {
        this.requiredPart1 = await this.getPart(this.selectedKit.required1);
      }
      if (this.isNotNullOrEmpty(this.selectedKit.required2)) {
        this.requiredPart2 = await this.getPart(this.selectedKit.required2);
      }
      if (this.isNotNullOrEmpty(this.selectedKit.required3)) {
        this.requiredPart3 = await this.getPart(this.selectedKit.required3);
      }
    }
  },
  data: () => ({
    drawer: null,
    kitPart: null as Part | null,
    items: [],
    requiredPart1: null as Part | null,
    requiredPart2: null as Part | null,
    requiredPart3: null as Part | null,
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    isNotNullOrEmpty(value: string) {
      return value != null && value != "";
    },
    async getPart(partNumber: string) {
      const response = await instance.get<Part>(`/Part/${partNumber}`).catch(() => {
        //
      });

      if (response && response.status == 200 && response.data) {
        return response.data;
      }

      return null;
    },
    getKitPrice(): string {
      if (this.userCurrency == Currency.CAD) {
        return `${this.determineKitPrice().toFixed(2)}CAD`;
      } else {
        return `${this.determineKitPrice().toFixed(2)}USD`;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPartPrice(part: any, quantity: number | null = null): string {
      if (this.userCurrency == Currency.CAD) {
        return `${this.determinePartPrice(part, quantity).toFixed(2)}CAD`;
      } else {
        return `${this.determinePartPrice(part, quantity).toFixed(2)}USD`;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    determinePartPrice(part: any, quantity: number | null = null): number {
      if (this.userCurrency == Currency.CAD) {
        return (part.msrpCAD ?? 0) * (quantity ?? part.quantity);
      } else {
        return (part.msrpUSD ?? 0) * (quantity ?? part.quantity);
      }
    },
    determineKitPrice(): number {
      if (this.kitPart) {
        if (this.userCurrency == Currency.CAD) {
          return this.kitPart.msrpCAD ?? 0;
        } else {
          return this.kitPart.msrpUSD ?? 0;
        }
      } else {
        return 0;
      }
    },
  },
  computed: {
    ...mapGetters(["selectedKit", "vehicleEquipment", "userCurrency"]),
    totalPrice(): string {
      var totalPrice = this.determineKitPrice();

      if (this.requiredPart1) {
        totalPrice += this.determinePartPrice(this.requiredPart1, 1);
      }
      if (this.requiredPart2) {
        totalPrice += this.determinePartPrice(this.requiredPart2, 1);
      }
      if (this.requiredPart3) {
        totalPrice += this.determinePartPrice(this.requiredPart3, 1);
      }

      for (let i = 0; i < this.selectedEquipment.length; i++) {
        totalPrice += this.determinePartPrice(this.selectedEquipment[i]);
      }

      if (this.userCurrency == Currency.CAD) {
        return `${totalPrice.toFixed(2)}CAD`;
      } else {
        return `${totalPrice.toFixed(2)}USD`;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedEquipment(): any[] {
      let equipment = []; // as Part[];
      const vehicleEquipment: VehicleEquipment = this.vehicleEquipment;

      for (const [, value] of Object.entries(vehicleEquipment.selected)) {
        let part: Part | null = value;

        if (part) {
          let quantity = 1;
          if (part.partNumber == vehicleEquipment.selected.battery?.partNumber) {
            quantity = vehicleEquipment.selected.batteryCount;
          }
          equipment.push({
            ...part,
            quantity,
          });
        }
      }

      return equipment.filter((e) => e.partNumber != null && e.partNumber != "");
    },
  },
});
