












































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";

import instance from "@/axios";
import { User as APIUser } from "@/model/API/User";
import { Vehicle } from "@/model/API/Vehicle";
import { VehicleIdentificationNumberData } from "@/model/API/VIN";

import dayjs from "dayjs";
import { Country, Currency } from "@/model/Localization";
import { DistanceUnit, FuelUnit } from "@/model/Units";
import { convertFuelFromLitres, convertFuelToLitres, distanceDisplay, fuelDisplay } from "@/helpers/UnitService";
import { calculateFuelConsumption } from "@/helpers/vehicleProfiles";
import {
  co2Chart,
  engineWearChart,
  fuelConsumptionChart,
  fuelSavingsDollarsChart,
  idleReducedChart,
  maintenanceCostChart,
  operationOverviewChart,
} from "@/helpers/SavingsCalculatorCharts";

import ImportVehicleDialog from "@/components/AddVehicle/ImportVehicleDialog.vue";
import ChartCard from "@/components/ChartCard.vue";
import { RequestQuoteDto } from "@/model/API/RequestQuoteDto";

export default Vue.extend({
  name: "SavingsCalculator",
  components: { ImportVehicleDialog, ChartCard },
  mounted() {
    this.loadUserInfo();
  },
  validations: {
    contactInfo: {
      email: { required, email },
    },
  },
  data: () => ({
    contactInfo: {
      email: "",
      firstName: "",
      lastName: "",
      location: {
        address: "",
        country: null as Country | null,
        city: "",
        region: "",
      },
      company: {
        name: "",
        title: "",
      },
    },
    vehicleInfo: {
      vin: "",
      make: "",
      model: "",
      year: null as number | null,
      displacement: 0.0,
      fuelType: "",
      GVWRClass: "",
      series: "",
      vehicleType: "",
      engineType: "",
      engineManufacturer: "",
      brakeSystem: "",
      driveLineType: "",
      fuelConsumption: 1.2,
      fuelCost: null as number | null,
    },
    applicationSettings: {
      hoursPerDay: 8,
      daysPerWeek: 5,
      idleInDrivePercent: 10,
      parkAndNeutralPercent: 50,
      monitoringPercent: 40,
      vehicleLifeYears: 5,
      numberOfVehicles: 1,
      vehicleUse: null as string | false | null,
      customVehicleUse: "",
    },
    additionalQuestions: {
      cabHeating: false,
      airConditioning: false,
      longestConsecutiveIdleHours: 1,
      operatorRequireSecured: false,
      ptoOperatedSystem: false,
    },

    userVehicles: null as Vehicle[] | null,

    requiredRule: (v: string | number | boolean | null) =>
      v !== null && ((v as string).trim == undefined || (v as string).trim() != "") ? true : "Required",
    isContactFormValid: false,
    isVehicleFormValid: false,
    isApplicationFormValid: false,

    countries: [
      {
        text: "Canada",
        value: Country.Canada,
      },
      {
        text: "United States",
        value: Country.UnitedStates,
      },
    ],
    importVehicleHeaders: [
      { text: "IMEI", value: "idleTrackerIMEI" },
      { text: "VIN", value: "vin" },
      { text: "Asset Name", value: "assetName" },
      { text: "Client Company", value: "vehicleClient.company" },
      { text: "Last Date Reported", value: "lastDateReported" },
      { text: "Frequency", value: "frequency" },
      { text: "Zone", value: "vehicleZone.zoneName" },
      { text: "Year", value: "year" },
      { text: "Make", value: "make" },
      { text: "Model", value: "model" },
      { text: "Series", value: "series" },
      { text: "Body Type", value: "bodyType" },
      { text: "GVWR Class", value: "gvwrClass" },
      { text: "Vehicle Type", value: "vehicleType" },
      { text: "Vehicle Class", value: "vehicleClass" },
      { text: "Fuel Type", value: "fuelType" },
      { text: "Fuel Cost", value: "fuelCost" },
      { text: "Engine Type", value: "engineType" },
      { text: "Engine Manufacturer", value: "engineManufacturer" },
      { text: "Brake System", value: "brakeSystem" },
      { text: "Drive Line Type", value: "driveLineType" },
      { text: "Controller", value: "controller" },
      { text: "Screen", value: "screen" },
      { text: "Other", value: "other" },
    ],
    decodingVin: false,
    gettingGraphs: false,
    requestingQuote: false,
    importVehicleDialog: false,
    vinDecodeMessage: "",
    additionalQuestionsStepper: 1,
    doneRequestingQuote: false,
    expansionPanel: 0,
    showGraphs: false,
  }),
  computed: {
    ...mapGetters(["isUserAuthorized", "apiUser", "userFuelUnits", "userDistanceUnits", "userCurrency"]),

    hasContactInformation(): boolean {
      this.$v.contactInfo.$touch();
      return this.isContactFormValid && !this.$v.contactInfo.$pending && !this.$v.contactInfo.$error;
    },

    hasVehicleInformation(): boolean {
      return this.isVehicleFormValid;
    },

    hasApplicationSettings(): boolean {
      return this.isApplicationFormValid;
    },

    emailMessage(): string {
      return this.contactInfo.email != "" && this.$v.contactInfo.email?.$error ? "Must be a valid email" : "";
    },

    fuelUnits(): FuelUnit | null {
      const country = this.contactInfo.location.country;
      if (country == null) return null;
      switch (country) {
        case Country.Canada:
          return FuelUnit.Litres;
        case Country.UnitedStates:
          return FuelUnit.Gallons;
      }
      return null;
    },
    fuelUnitsDisplay(): string | null {
      if (this.fuelUnits == null) return null;
      return fuelDisplay(this.fuelUnits);
    },

    distanceUnits(): DistanceUnit | null {
      const country = this.contactInfo.location.country;
      if (country == null) return null;
      switch (country) {
        case Country.Canada:
          return DistanceUnit.Kilometers;
        case Country.UnitedStates:
          return DistanceUnit.Miles;
      }
      return null;
    },
    distanceUnitsDisplay(): string | null {
      if (this.distanceUnits == null) return null;
      return distanceDisplay(this.distanceUnits);
    },

    currency(): Currency | null {
      const country = this.contactInfo.location.country;
      if (country == null) return null;
      switch (country) {
        case Country.Canada:
          return Currency.CAD;
        case Country.UnitedStates:
          return Currency.USD;
      }
      return null;
    },

    country(): Country | null {
      // used for conversion watcher
      return this.contactInfo.location.country;
    },

    availableVehicleUses(): Array<{ value: string | false; text: string }> {
      return ([
        "Utility and Public Works",
        "Work Trucks",
        "Emergency Medical",
        "Police and Security",
        "Mining and Metals",
        "Military and Defense",
        "Airport and Airline",
        "Port Authorities and Container Terminals",
      ].map((x) => ({ value: x, text: x })) as Array<{ value: string | false; text: string }>).concat([{ value: false, text: "Other" }]);
    },

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    displayedCharts(): Array<any> {
      const v = this.vehicleInfo;
      const a = this.applicationSettings;

      const monitorPercent = a.monitoringPercent;
      const idleDrivePercent = a.idleInDrivePercent;
      const parkNeutralPercent = a.parkAndNeutralPercent;

      const runHoursYear = a.hoursPerDay * (parkNeutralPercent / 100) * a.daysPerWeek * 52 * a.numberOfVehicles;

      const lifeYears = a.vehicleLifeYears;

      return [
        operationOverviewChart(parkNeutralPercent, idleDrivePercent, runHoursYear, "(Over 1 year)"),
        operationOverviewChart(parkNeutralPercent, idleDrivePercent, runHoursYear * lifeYears, `(Over ${a.vehicleLifeYears} years)`),

        idleReducedChart(parkNeutralPercent, monitorPercent, runHoursYear * lifeYears, null, 12, 6),

        co2Chart(parkNeutralPercent, this.fuelUnits ?? FuelUnit.Litres, v.fuelConsumption, v.fuelType, runHoursYear, "(Over 1 year)"),
        co2Chart(
          parkNeutralPercent,
          this.fuelUnits ?? FuelUnit.Litres,
          v.fuelConsumption,
          v.fuelType,
          runHoursYear * lifeYears,
          `(Over ${a.vehicleLifeYears} years)`
        ),

        fuelSavingsDollarsChart(parkNeutralPercent, v.fuelConsumption, v.fuelCost ?? 3.0, runHoursYear, "(Over 1 year)"),
        fuelSavingsDollarsChart(
          parkNeutralPercent,
          v.fuelConsumption,
          v.fuelCost ?? 3.0,
          runHoursYear * lifeYears,
          `(Over ${a.vehicleLifeYears} years)`
        ),

        fuelConsumptionChart(
          parkNeutralPercent,
          this.fuelUnitsDisplay ?? fuelDisplay(FuelUnit.Litres),
          v.fuelConsumption,
          runHoursYear,
          "(Over 1 year)"
        ),
        fuelConsumptionChart(
          parkNeutralPercent,
          this.fuelUnitsDisplay ?? fuelDisplay(FuelUnit.Litres),
          v.fuelConsumption,
          runHoursYear * lifeYears,
          `(Over ${a.vehicleLifeYears} years)`
        ),

        engineWearChart(
          parkNeutralPercent,
          this.distanceUnits ?? DistanceUnit.Kilometers,
          this.distanceUnitsDisplay ?? distanceDisplay(DistanceUnit.Kilometers),
          runHoursYear,
          "(Over 1 year)"
        ),
        engineWearChart(
          parkNeutralPercent,
          this.distanceUnits ?? DistanceUnit.Kilometers,
          this.distanceUnitsDisplay ?? distanceDisplay(DistanceUnit.Kilometers),
          runHoursYear * lifeYears,
          `(Over ${a.vehicleLifeYears} years)`
        ),

        maintenanceCostChart(
          parkNeutralPercent,
          this.distanceUnits ?? DistanceUnit.Kilometers,
          this.currency ?? Currency.CAD,
          v.GVWRClass,
          runHoursYear,
          "(Over 1 year)"
        ),
        maintenanceCostChart(
          parkNeutralPercent,
          this.distanceUnits ?? DistanceUnit.Kilometers,
          this.currency ?? Currency.CAD,
          v.GVWRClass,
          runHoursYear * lifeYears,
          `(Over ${a.vehicleLifeYears} years)`
        ),
      ];
    },
  },
  methods: {
    ...mapActions([]),

    loadUserInfo(): void {
      const apiUser = this.apiUser as APIUser | null;
      if (this.isUserAuthorized && apiUser != null) {
        this.contactInfo.email = apiUser.email;
        this.contactInfo.firstName = apiUser.firstName;
        this.contactInfo.lastName = apiUser.lastName;
        this.contactInfo.location.country = apiUser.country;
        if (apiUser.clients.length > 0) {
          this.contactInfo.company.name = apiUser.clients[0].company;
        }
        this.loadUserVehicles();
      }
    },

    contactNext(): void {
      (this.$refs.contactForm as HTMLFormElement | undefined)?.validate();
      if (this.hasContactInformation) this.expansionPanel = 1;
    },

    vehicleInformationNext(): void {
      (this.$refs.vehicleForm as HTMLFormElement | undefined)?.validate();
      if (this.hasVehicleInformation) this.expansionPanel = 2;
    },

    applicationSettingsNext(): void {
      (this.$refs.applicationForm as HTMLFormElement | undefined)?.validate();
      if (this.hasApplicationSettings) this.expansionPanel = 3;
    },

    chartsAndGraphsNext(): void {
      this.expansionPanel = 4;
    },

    async decodeVIN(): Promise<void> {
      this.decodingVin = true;
      this.vinDecodeMessage = "";
      try {
        const response = await instance.get<VehicleIdentificationNumberData>(
          `/VehicleIdentificationNumber?vehicleIdentificationNumber=${encodeURIComponent(this.vehicleInfo.vin)}`
        );
        const vinData = response.data;
        if (vinData != undefined) {
          this.vehicleInfo.make = vinData.make;
          this.vehicleInfo.model = vinData.model;
          this.vehicleInfo.year = vinData.modelYear;
          this.vehicleInfo.displacement = vinData.displacement;
          this.vehicleInfo.fuelType = vinData.fuelType;
          this.vehicleInfo.GVWRClass = vinData.gvwrClass;
          this.vehicleInfo.series = vinData.series;
          this.vehicleInfo.vehicleType = vinData.vehicleType;
          this.vehicleInfo.engineType = vinData.engineType;
          this.vehicleInfo.engineManufacturer = vinData.engineManufacturer;
          this.vehicleInfo.brakeSystem = vinData.brakeSystem;
          this.vehicleInfo.driveLineType = vinData.driveLineType;

          // Calculate the fuel consumption
          if (this.vehicleInfo.displacement) {
            this.vehicleInfo.fuelConsumption =
              convertFuelFromLitres(calculateFuelConsumption(vinData.displacement), this.fuelUnits ?? FuelUnit.Litres) ?? 0;
          }
        } else {
          this.vinDecodeMessage = "VIN not found";
        }
      } catch (ex) {
        if ((ex as Record<string, unknown>).response && (ex as Record<string, Record<string, unknown>>).response.status == 404) {
          this.vinDecodeMessage = "VIN not found";
        } else {
          this.vinDecodeMessage = "Error decoding VIN";
        }
      }
      this.decodingVin = false;
    },

    async loadUserVehicles(): Promise<void> {
      this.userVehicles = (await instance.get<Vehicle[]>("/Vehicle")).data;
    },

    loadVehicle(vehicle: Vehicle): void {
      this.vehicleInfo.vin = vehicle.vin ?? "";
      this.vehicleInfo.make = vehicle.make ?? "";
      this.vehicleInfo.model = vehicle.model ?? "";
      this.vehicleInfo.year = vehicle.year;
      this.vehicleInfo.displacement = vehicle.engineDisplacement ?? 0;
      this.vehicleInfo.fuelConsumption = vehicle.fuelConsumption ?? 1.2;
      this.vehicleInfo.fuelType = vehicle.fuelType ?? "";
      this.vehicleInfo.fuelCost = vehicle.fuelCost ?? 0.0;
      this.vehicleInfo.GVWRClass = vehicle.gvwrClass ?? "";
      this.vehicleInfo.series = vehicle.series ?? "";
      this.vehicleInfo.vehicleType = vehicle.vehicleType ?? "";
      this.vehicleInfo.engineType = vehicle.engineType ?? "";
      this.vehicleInfo.engineManufacturer = vehicle.engineManufacturer ?? "";
      this.vehicleInfo.brakeSystem = vehicle.brakeSystem ?? "";
      this.vehicleInfo.driveLineType = vehicle.driveLineType ?? "";
      this.importVehicleDialog = false;
    },

    getQuoteDto(): RequestQuoteDto {
      return {
        isRequest: false,

        email: this.contactInfo.email,
        firstName: this.contactInfo.firstName,
        lastName: this.contactInfo.lastName,
        address: this.contactInfo.location.address,
        country: this.contactInfo.location.country ?? Country.Canada,
        city: this.contactInfo.location.city,
        region: this.contactInfo.location.region,
        companyName: this.contactInfo.company.name,
        companyTitle: this.contactInfo.company.title,

        fuelUnit: this.fuelUnits ?? FuelUnit.Litres,
        distanceUnit: this.distanceUnits ?? DistanceUnit.Kilometers,
        currency: this.currency ?? Currency.CAD,

        vin: this.vehicleInfo.vin,
        make: this.vehicleInfo.make,
        model: this.vehicleInfo.model,
        year: this.vehicleInfo.year ?? new Date().getFullYear(),
        displacement: this.vehicleInfo.displacement,
        fuelType: this.vehicleInfo.fuelType,
        gvwrclass: this.vehicleInfo.GVWRClass,
        series: this.vehicleInfo.series,
        vehicleType: this.vehicleInfo.vehicleType,
        engineType: this.vehicleInfo.engineType,
        engineManufacturer: this.vehicleInfo.engineManufacturer,
        brakeSystem: this.vehicleInfo.brakeSystem,
        driveLineType: this.vehicleInfo.driveLineType,
        fuelConsumption: this.vehicleInfo.fuelConsumption,
        fuelCost: this.vehicleInfo.fuelCost ?? 3.0,

        hoursPerDay: this.applicationSettings.hoursPerDay,
        daysPerWeek: this.applicationSettings.daysPerWeek,
        idleDrivePercent: this.applicationSettings.idleInDrivePercent,
        parkNeutralPercent: this.applicationSettings.parkAndNeutralPercent,
        monitoringPercent: this.applicationSettings.monitoringPercent,
        vehicleUse:
          (this.applicationSettings.vehicleUse === false ? this.applicationSettings.customVehicleUse : this.applicationSettings.vehicleUse) ?? "",
        vehicleLifeYears: this.applicationSettings.vehicleLifeYears,
        vehicleCount: this.applicationSettings.numberOfVehicles,

        cabHeating: this.additionalQuestions.cabHeating,
        airConditioning: this.additionalQuestions.airConditioning,
        longestConsecutiveHours: this.additionalQuestions.longestConsecutiveIdleHours,
        operatorRequireSecured: this.additionalQuestions.operatorRequireSecured,
        ptosystem: this.additionalQuestions.ptoOperatedSystem,

        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        graphInformation: (this.displayedCharts as any[]).map((x: any) => {
          let chartData = [x.meta.title];
          for (let i = 0; i < x.data.data.datasets[0].data.length; i++) {
            chartData.push(x.meta.quoteDataSummary[i]);
          }
          return chartData;
        }),
      };
    },

    async getGraphs(): Promise<void> {
      this.gettingGraphs = true;

      // const model = this.getQuoteDto();

      // (model.cabHeating = null),
      //   (model.airConditioning = null),
      //   (model.longestConsecutiveHours = null),
      //   (model.operatorRequireSecured = null),
      //   (model.ptosystem = null),
      //   await instance.post("/SavingsCalculator/RequestQuote", model);

      this.showGraphs = true;
      this.gettingGraphs = false;
    },

    async requestQuote(): Promise<void> {
      this.requestingQuote = true;

      const model = this.getQuoteDto();

      model.isRequest = true;

      await instance.post("/SavingsCalculator/RequestQuote", model);

      this.additionalQuestionsStepper = 6;
      this.doneRequestingQuote = true;
      this.requestingQuote = false;
    },

    shortenString(value: string, maxLength = 15): string {
      if (value == undefined) return "";
      var valueString = value.toString();
      if (valueString.length > maxLength) {
        return `${valueString.substring(0, maxLength)}...`;
      } else {
        return valueString;
      }
    },

    formatDate(date: string): string {
      if (date) {
        return dayjs(date).format("YYYY/MM/DD");
      }
      return "";
    },
  },
  watch: {
    country(newVal, oldVal) {
      if (newVal != null && oldVal != null && newVal != oldVal) {
        switch (newVal) {
          case Country.Canada:
            this.vehicleInfo.fuelConsumption = convertFuelToLitres(this.vehicleInfo.fuelConsumption, FuelUnit.Gallons) ?? 0;
            break;
          case Country.UnitedStates:
            this.vehicleInfo.fuelConsumption = convertFuelFromLitres(this.vehicleInfo.fuelConsumption, FuelUnit.Gallons) ?? 0;
            break;
        }
      }
    },
  },
});
