import { InputType, listBoolParameter } from "../OptionParameter";

export interface AuxiliaryHeaterFunctionOptions {
  sevenDayScheduler: listBoolParameter;
}

export const defaultAuxiliaryHeaterFunctionOptions: AuxiliaryHeaterFunctionOptions = {
  sevenDayScheduler: {
    type: InputType.list,
    value: false,
    defaultValue: false,
    display: "Seven Day Scheduler On/Off",
    list: [
      {
        display: "On",
        value: true,
      },
      {
        display: "Off",
        value: false,
      },
    ],
    info: "This setting will enable the operator to access the 7-day scheduler (default is OFF)",
  },
};
