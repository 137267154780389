











































import Vue from "vue";
import dayjs from "dayjs";
import instance from "@/axios";
import { mapGetters } from "vuex";
import { GetMachineDto } from "@/model/API/ChartsGraphs/GetMachineDto";
import { GetEventDto } from "@/model/API/GetEventsDto";
import { Vehicle } from "@/model/API/Vehicle";
import VehicleFilter from "@/components/VehicleFilter.vue"

export default Vue.extend({
  components: { VehicleFilter },
  name: "Events",
  created() {
    this.loadMachines();
  },
  data: () => ({
    machines: [] as GetMachineDto[], // Contains all of the machines returned from API
    allVins: [] as string[], // Contains all of the VIN's for all the vehicles the user can see.
    allVehicles: [] as Vehicle[], // Contains all of the vehicles the user has access to, including ones without a machine
    events: [] as GetEventDto[],
    isLoadingEvents: false,
    headers: [
      // Header for the add filter data table
      { text: "IMEI", value: "idleTrackerIMEI" },
      { text: "VIN", value: "vin" },
      { text: "Asset Name", value: "assetName" },
      { text: "Client Company", value: "vehicleClient.company" },
      { text: "Last Date Reported", value: "lastDateReported" },
      { text: "Frequency", value: "frequency" },
      { text: "Zone", value: "vehicleZone.zoneName" },
      { text: "Year", value: "year" },
      { text: "Make", value: "make" },
      { text: "Model", value: "model" },
      { text: "Series", value: "series" },
      { text: "Body Type", value: "bodyType" },
      { text: "GVWR Class", value: "gvwrClass" },
      { text: "Vehicle Type", value: "vehicleType" },
      { text: "Vehicle Class", value: "vehicleClass" },
      { text: "Fuel Type", value: "fuelType" },
      { text: "Fuel Cost", value: "fuelCost" },
      { text: "Engine Type", value: "engineType" },
      { text: "Engine Manufacturer", value: "engineManufacturer" },
      { text: "Brake System", value: "brakeSystem" },
      { text: "Drive Line Type", value: "driveLineType" },
      { text: "Controller", value: "controller" },
      { text: "Screen", value: "screen" },
      { text: "Other", value: "other" },
    ],
    eventHeaders: [
      {
        text: "Machine Name",
        value: "machineName",
      },
      {
        text: "Machine VIN",
        value: "machineVin",
      },
      {
        text: "Code",
        value: "code",
      },
      {
        text: "Date Occurred",
        value: "dateOccurred",
      },
    ],
  }),
  methods: {
    loadMachines() {
      instance.get<Vehicle[]>("/vehicle").then((e) => {
        this.allVehicles = e.data;

        if (this.vehiclesFiltered.length > 0) {
          let allVins = [] as string[];
          for (let i = 0; i < this.vehiclesFiltered.length; i++) {
            let vehicle = this.vehiclesFiltered[i];
            if (vehicle.machineId) {
              allVins.push(vehicle.machineId);
            }
          }
          this.allVins = allVins;

          instance.post<GetMachineDto[]>(`Machine/machineId`, allVins).then((e) => {
            this.machines = e.data;
          });
        }

        this.loadEvents();
      });
    },
    getFormattedDate(dateReleased: string) {
      return dayjs(dateReleased).format("YYYY MMM D HH:mm:ss");
    },
    loadEvents() {
      if (this.vehiclesFiltered.length > 0) {
        if (this.selectedVehiclesFiltered.length == 0 && this.selectedVehicles.length != this.selectedVehiclesFiltered.length) {
          this.events = [];
          return;
        }

        let vehicles = this.vehiclesFiltered;

        if (this.selectedVehiclesFiltered.length > 0) {
          vehicles = this.selectedVehiclesFiltered;
        }

        let allVins = [] as string[];
        for (let i = 0; i < vehicles.length; i++) {
          let vehicle = vehicles[i];
          if (vehicle.machineId) {
            allVins.push(vehicle.machineId);
          }
        }
        this.allVins = allVins;

        this.isLoadingEvents = true;
        this.events = [];
        instance
          .post<GetEventDto[]>(`Events/machineId?`, allVins)
          .then((e) => {
            this.events = e.data;
            this.isLoadingEvents = false;
          })
          .catch(() => {
            this.isLoadingEvents = false;
          });
      }
    },
  },
  computed: {
    ...mapGetters(["selectedVehicles"]),
    vehiclesFiltered(): Vehicle[] { // Contains all of the vehicles the user has access to
      return this.allVehicles.filter((e) => e.machineId != null);
    },
    selectedVehiclesFiltered(): Vehicle[] { // Contains all the selected vehicles that have a machine id
      return this.selectedVehicles.filter((e: Vehicle) => e.machineId != null);
    },
  },
});
